import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal as UiKitModal, Button, Tooltip } from "@skyportal/ui-kit";
import CheckBox from "ui-kit/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import cn from "classnames";
import Modal from "@material-ui/core/Modal";
import { deleteDCAct, getDcSettingsAct, setDcSettingsAct } from "../../../../store/actions/datacenterActions";
import { REQUEST_FAILED, REQUEST_PENDING, REQUEST_SUCCESS } from "../../../../constants/request";
import ModalBody from "./ModalBody";

import styles from "./styles";

const useStyles = makeStyles(styles);

const DatacenterRow = ({ rowProps, regions, deleteRequestStatus, updateSettingsRequestStatus }) => {
  const { t } = useTranslation("datacentersPage");
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isConfirmDeleteModalShow, setIsConfirmDeleteModalShow] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const [isReadonlyMode, setIsReadonlyMode] = useState(false);
  const [isDisableSyncMode, setIsDisableSyncMode] = useState(false);
  const [isDescriptionHovered, setDescriptionHoverState] = useState(false);
  const [isUrlHovered, setUrlHoverState] = useState(false);
  const regionName = (rowProps.region && rowProps.region.name) || "";
  const regionFromState = useMemo(
    () => regions.find((region) => region.id === rowProps.region && rowProps.region._id),
    [regions, rowProps.region]
  );

  useEffect(() => {
    if (rowProps.settings.requestStatus === REQUEST_SUCCESS) {
      setIsReadonlyMode(rowProps.settings.mode.readOnly);
      setIsDisableSyncMode(rowProps.settings.mode.disableSync);
    } else if (rowProps.settings.requestStatus === REQUEST_FAILED) {
      setSettingsModalOpen(false);
    }
  }, [rowProps.settings, rowProps.settings.requestStatus]);

  useEffect(() => {
    if (isConfirmDeleteModalShow && deleteRequestStatus === REQUEST_SUCCESS) {
      setIsConfirmDeleteModalShow(false);
    }
  }, [deleteRequestStatus, isConfirmDeleteModalShow]);

  const toggleEditModal = useCallback(() => setEditModalOpen(!isEditModalOpen), [isEditModalOpen]);

  const toggleSettingsModal = useCallback(() => setSettingsModalOpen(!isSettingsModalOpen), [isSettingsModalOpen]);
  const handleSettingsBtnClick = () => {
    setSettingsModalOpen(true);
    dispatch(getDcSettingsAct(rowProps));

    setIsReadonlyMode(rowProps.settings?.mode?.readOnly || false);
    setIsDisableSyncMode(rowProps.settings?.mode?.disableSync || false);
  };

  const toggleReadonlyMode = useCallback(() => setIsReadonlyMode(!isReadonlyMode), [isReadonlyMode]);
  const toggleDisableSyncMode = useCallback(() => {
    setIsDisableSyncMode(!isDisableSyncMode);
  }, [isDisableSyncMode]);

  const handleSubmitSettingsBtnClick = () => {
    const { requestStatus, ...rest } = rowProps.settings;
    dispatch(
      setDcSettingsAct(
        rowProps,
        { ...rest, mode: { readOnly: isReadonlyMode, disableSync: isDisableSyncMode } },
        setSettingsModalOpen
      )
    );
  };

  const descriptionHover = () => {
    setDescriptionHoverState(true);
  };
  const descriptionLeave = () => {
    setDescriptionHoverState(false);
  };

  const urlHover = () => setUrlHoverState(true);
  const urlLeave = () => setUrlHoverState(false);

  const onDeleteDC = () => dispatch(deleteDCAct(rowProps));

  const handleConfirmModalClose = () => setIsConfirmDeleteModalShow(false);
  const handleDeleteBtnClick = () => setIsConfirmDeleteModalShow(true);

  return (
    <>
      <div
        className={cn(classes.row, { [classes.greyedOutRow]: rowProps.disabled })}
        key={rowProps.id}
        data-testid="DatacenterRow"
      >
        <div className={classes.cell}>
          <div className={classes.cellShell}>
            <span className={classes.inCell}>{rowProps.disabled ? `${rowProps.name} (disabled)` : rowProps.name}</span>
          </div>
        </div>
        <div className={cn(classes.cell, classes.smallerCell)}>
          <div className={classes.cellShell} onMouseOver={descriptionHover} onMouseLeave={descriptionLeave}>
            <span className={classes.inCell}>{rowProps.description}</span>
            {isDescriptionHovered && <span className={classes.hiddenCell}>{rowProps.description}</span>}
          </div>
        </div>
        <div className={cn(classes.cell, classes.smallerCell)}>
          <div className={classes.cellShell}>
            <span className={classes.inCell}>{regionFromState ? regionFromState.name : regionName}</span>
          </div>
        </div>
        <div className={classes.cell}>
          <div className={classes.cellShell} onMouseOver={urlHover} onMouseLeave={urlLeave}>
            {isUrlHovered && <span className={classes.hiddenCell}>{rowProps.api.url}</span>}
            <span className={classes.inCell}>{rowProps.api.url}</span>
          </div>
        </div>
        <div className={cn(classes.cell, classes.actionsCell)}>
          <div className={classes.dcRowControls}>
            <Tooltip title={t("iaasCommon:Settings")}>
              <Button
                data-testid="datacenterSettingsBtn"
                type="tertiary"
                leftIcon="service"
                className={classes.settingsBtn}
                onClick={handleSettingsBtnClick}
              />
            </Tooltip>
            <Tooltip title={t("iaasCommon:Edit")}>
              <Button
                data-testid="editDatacenterBtn"
                type="tertiary"
                leftIcon="pen"
                className={classes.leftMargin}
                onClick={toggleEditModal}
              />
            </Tooltip>
            <Tooltip title={t("iaasCommon:Delete")}>
              <Button
                data-testid="deleteDatacenterBtn"
                type="tertiary"
                leftIcon="trash-bin"
                className={classes.leftMargin}
                onClick={handleDeleteBtnClick}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <UiKitModal
        title={t("dcSettingsModal.title", { dcName: rowProps.name })}
        open={isSettingsModalOpen}
        className={classes.dcSettingsModal}
        loading={rowProps.settings.requestStatus === REQUEST_PENDING}
        controls={
          <>
            <Button onClick={handleSubmitSettingsBtnClick} loading={updateSettingsRequestStatus === REQUEST_PENDING}>
              {t("iaasCommon:Save")}
            </Button>
            <Button type="secondary" onClick={toggleSettingsModal}>
              {t("iaasCommon:Close")}
            </Button>
          </>
        }
      >
        {rowProps.settings.requestStatus === REQUEST_SUCCESS && (
          <>
            <div className={classes.settingRow}>
              <CheckBox isChecked={isReadonlyMode} checkHandler={toggleReadonlyMode} />
              <span className={classes.checkboxLabel} onClick={toggleReadonlyMode}>
                {t("dcSettingsModal.readOnlyMode")}
              </span>
            </div>
            <div className={classes.settingRow}>
              <CheckBox isChecked={isDisableSyncMode} checkHandler={toggleDisableSyncMode} />
              <span className={classes.checkboxLabel} onClick={toggleDisableSyncMode}>
                {t("dcSettingsModal.disableSyncMode")}
              </span>
            </div>
          </>
        )}
      </UiKitModal>
      <Modal open={isEditModalOpen} onClose={toggleEditModal}>
        <ModalBody isEdit={isEditModalOpen} data={rowProps} closeModal={toggleEditModal} />
      </Modal>
      <UiKitModal
        data-testid="confirmDeleteModal"
        open={isConfirmDeleteModalShow}
        onClose={handleConfirmModalClose}
        title={t("confirmDeleteModal.title")}
        controls={
          <>
            <Button disabled={deleteRequestStatus === REQUEST_PENDING} onClick={onDeleteDC}>
              {t("iaasCommon:Yes")}
            </Button>
            <Button type="secondary" onClick={handleConfirmModalClose}>
              {t("iaasCommon:No")}
            </Button>
          </>
        }
      >
        {t("confirmDeleteModal.confirmQuestion")}
      </UiKitModal>
    </>
  );
};

export default DatacenterRow;
