const btn = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "40px",
  borderRadius: "2px",
  boxSizing: "border-box",
  padding: "12px 40px 14px",
  border: "none",
  outline: "none",
  outlineOffset: "0",
  textDecoration: "none",
  "&:hover": {
    cursor: "pointer",
    opacity: "0.75",
  },
};

const control = {
  lineHeight: "16px",
  padding: "4px 8px",
};

const styles = (theme) => ({
  btn: {
    ...btn,
  },
  disabled: {
    opacity: "0.5",
    cursor: "not-allowed",

    "&:hover": {
      opacity: "0.5",
      cursor: "not-allowed",
    },
  },
  regular: {
    ...btn,
    ...control,
    fontSize: theme.fonts.small,
    backgroundColor: theme.colors.grey,
    color: theme.colors.darkGrey,
    width: "70px",
    height: "24px",
    display: "flex",
    justifyContent: "space-around",
    fill: theme.colors.darkGrey,
  },
  confirm: {
    ...btn,
    backgroundColor: theme.colors.grassGreen,
    color: theme.colors.darkGreen,
    padding: "7px 24px",
    height: "32px",
  },
  edit: {
    ...btn,
    ...control,
    fontSize: theme.fonts.small,
    backgroundColor: theme.colors.skyBlue,
    color: theme.colors.navyBlue,
    width: "70px",
    height: "24px",
    display: "flex",
    justifyContent: "space-around",
    fill: theme.colors.navyBlue,
  },
  danger: {
    ...btn,
    ...control,
    width: "70px",
    height: "24px",
    fontSize: theme.fonts.small,
    backgroundColor: theme.colors.lightRed,
    color: theme.colors.ruby,
    display: "flex",
    justifyContent: "space-around",
  },
  medium: {
    height: "32px",
  },
});
export default styles;
