import React, { useMemo } from "react";
import MUITooltip from "@material-ui/core/Tooltip";

/**
 * @param {string} title
 * @param {"top"|"bottom"} [position]
 * @param {React.ReactChildren} [children]
 * @param {object} [rest]
 * @return {JSX.Element}
 * @constructor
 */
const Tooltip = ({ title, position = "bottom", children, ...rest }) => {
  const PopperProps = useMemo(
    () => ({
      placement: position,
    }),
    [position]
  );

  return (
    <MUITooltip title={title} position={position} PopperProps={PopperProps} {...rest}>
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
