import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

const AppBuildInfo = () => {
  const classes = useStyles();

  if (!window.IAAS_VERSION) {
    return null;
  }

  return (
    <div className={classes.root}>
      <span className={classes.text}>{window.IAAS_VERSION}</span>
    </div>
  );
};

export default AppBuildInfo;
