const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    minHeight: "30px",
    padding: "0 10px",
    borderRadius: "4px",
    fontWeight: "700",
  },
  success: {
    backgroundColor: theme.colors.lightGreen,
    color: theme.colors.darkGreen,
  },
  danger: {
    backgroundColor: theme.colors.lightRed,
    color: theme.colors.ruby,
  },
  info: {
    backgroundColor: theme.colors.lightBrown,
    color: theme.colors.brown,
  },
});

export default styles;
