import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Title } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";

import styles from "./styles";
import ModuleBreadCrumbs from "components/ModuleBreadCrumbs";

const useStyles = makeStyles(styles);

const PageHeader = ({ title = "", backPath, children = null }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.header} data-testid="PageHeader">
      <div className={classes.headerContent}>
        <ModuleBreadCrumbs />
        <div className={classes.titleWrapper}>
          {backPath && (
            <Link to={backPath} title={t("Back")}>
              <Button leftIcon="arrow-left" />
            </Link>
          )}
          <Title level={2} className={classes.headerText}>
            {title}
          </Title>
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default PageHeader;
