import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@skyportal/ui-kit";
import { sortReportsAct } from "../../../../store/actions/reportsActions";
import { reportSel } from "../../../../store/selectors/reportSelectors";
import { iconResolver } from "../../../helpers";
import styles from "./styles";

const useStyles = makeStyles(styles);

const ReportsTableHeadColumnControls = ({ columns }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sortWay, sorterValue } = useSelector(reportSel);
  const makeSortBtnClickHandler = (value) => () => dispatch(sortReportsAct(value));

  return (
    <div className={classes.tableRowShell}>
      <div className={classes.controlsRow}>
        {columns
          .filter(({ selected }) => selected)
          .map((col) => (
            <span
              key={col.key}
              className={classes.titleCell}
              onClick={!col.unSortable ? makeSortBtnClickHandler(col.key) : undefined}
            >
              {!col.unSortable && (
                <Icon type="fontawesome" className={classes.icon} icon={iconResolver(sortWay, sorterValue, col.key)} />
              )}
              <span className={classes.columnName}>{col.name.replace(/_/g, " ")}</span>
            </span>
          ))}
      </div>
    </div>
  );
};

export default ReportsTableHeadColumnControls;
