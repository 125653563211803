const styles = (theme) => ({
  tile: {
    display: "flex",
    flexGrow: 1,
    height: "90px",
    borderRadius: "4px",
    cursor: "pointer",
    position: "relative",
  },
  iconsSquare: {
    height: "100%",
    width: "90px",
    borderRadius: "4px 0 0 4px",
    opacity: 0.7,
    ...theme.classes.centralized,
  },
  icon: {
    height: "46px",
    display: "inline-block",
  },
  content: { flexGrow: 1 },
  header: {
    height: "18px",
    padding: "8px 8px 4px 8px",
    fontSize: theme.fonts.smaller,
    textTransform: "uppercase",
  },
  boldHeader: {
    height: "20px",
    padding: "4px 8px 6px",
    fontWeight: "600",
    fontSize: theme.fonts.normal,
  },
  footer: {
    height: "18px",
    padding: "6px 8px 8px",
    fontSize: theme.fonts.smaller,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  blueIcon: {
    backgroundColor: theme.colors.shadowedBlue,
  },
  greenIcon: {
    backgroundColor: theme.colors.shadowedGreen,
  },
  brownIcon: {
    backgroundColor: theme.colors.shadowedBrown,
  },
  redIcon: {
    backgroundColor: theme.colors.shadowedRed,
  },
  blue: {
    backgroundColor: theme.colors.skyBlue,
    color: theme.colors.navyBlue,
  },
  green: {
    backgroundColor: theme.colors.lightGreen,
    color: theme.colors.darkGreen,
  },
  brown: {
    backgroundColor: theme.colors.lightBrown,
    color: theme.colors.brown,
  },
  red: {
    backgroundColor: theme.colors.lightRed,
    color: theme.colors.ruby,
  },
  divider: {
    height: "2px",
  },
  statusIcon: {
    height: "42px",
  },
  progress: {
    backgroundColor: "white",
    height: "2px",
  },
  shadowCoverage: {
    position: "absolute",
    zIndex: "10",
    backgroundColor: "white",
    opacity: "0.4",
    width: "100%",
    height: "100%",
  },
});

export default styles;
