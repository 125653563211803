import React, { useMemo } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { imageSel } from "../../../../store/selectors/imgSelectors";
import { formatSize } from "../../../../utils/size";
import { filterListByDcId, getDcIdFromEntity } from "../../../../utils/groups";
import { composeHumanFriendlyValidationInfo, getParsedTemplateHardwareValidations } from "../constantsNHelpers";

import styles from "../styles";

const useStyles = makeStyles(styles);

const TemplateItem = ({ template }) => {
  const { t } = useTranslation("vmPage");
  const classes = useStyles();
  const { images } = useSelector(imageSel);
  const { name, hardware, dcOwner, logo } = template;
  const validations = getParsedTemplateHardwareValidations(template);
  const disks = useMemo(() => {
    const diskIdList = Array.isArray(hardware.disks)
      ? hardware.disks
      : [hardware.disks].filter(Boolean).map((item) => String(item.IMAGE_ID));
    return filterListByDcId(images, getDcIdFromEntity(template))
      .filter((item) => diskIdList.includes(String(item.id)))
      .map((item) => `"${item.name}" (${formatSize(item.size)})`);
  }, [template, images, hardware.disks]);

  return (
    <div className={classes.templateShell}>
      <div className={classes.imgBox}>
        <div
          className={classes.templateImage}
          style={{ backgroundImage: `url(${dcOwner.api.url}/v1/logos/${logo})` }}
        />
      </div>
      <div className={classes.templateInfoContainer}>
        <div className={cn(classes.templateHeader, classes.infoText)}>{name}</div>
        {hardware.memory !== null && (
          <div className={classes.infoText}>
            {`${t("createOrEdit.Memory")}: ${composeHumanFriendlyValidationInfo(
              validations.memory,
              formatSize,
              hardware.memory
            )}`}
          </div>
        )}
        {hardware.vcpu !== null && (
          <div className={classes.infoText}>
            {`${t("createOrEdit.CPU")}: ${composeHumanFriendlyValidationInfo(
              validations.vcpu,
              (item) => Number(item),
              hardware.vcpu
            )}`}
          </div>
        )}
        {disks.length > 0 && (
          <div className={classes.infoText}>
            {`${t("createOrEdit.Disk", { count: disks.length })}: ${disks.join(", ")}`}{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateItem;
