import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { Spinner } from "@skyportal/ui-kit";
import { REQUEST_PENDING, REQUEST_UNCALLED } from "../../../constants/request";
import { setAllVmsAct } from "../../../store/actions/virtualMachinesActions";
import { getAllImagesAct } from "../../../store/actions/imagesActions";
import { useDispatch, useSelector } from "react-redux";
import { dcSel } from "../../../store/selectors/dcSelectors";
import { vmSel } from "../../../store/selectors/vmSelectors";
import { getUserProfile } from "store/selectors/userSelectors";
import PageHeader from "../../../pages/shareableComponents/PageHeader";
import VncContent from "./VncContent";
import { hasContractAccessToFeature, CONTRACT_TYPES, FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";

import styles from "./styles";

const useStyles = makeStyles(styles);

const VncPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("vnc");
  const { datacenters } = useSelector(dcSel);
  const { vmListRequest, vms } = useSelector(vmSel);
  const userProfile = useSelector(getUserProfile);
  const { id } = useParams();

  /**
   * @type {VmStateModel}
   */
  const currentVm = vms.find((item) => item._id === id);

  useEffect(() => {
    if (datacenters.length && vmListRequest === REQUEST_UNCALLED) {
      dispatch(setAllVmsAct());
      dispatch(getAllImagesAct());
    }
  }, [vmListRequest, datacenters, dispatch]);

  if ([REQUEST_PENDING, REQUEST_UNCALLED].includes(vmListRequest)) {
    return (
      <div className={classes.page} style={{ marginTop: "50px" }}>
        <Paper className={classes.formContainer} elevation={0} style={{ minHeight: "600px" }}>
          <Spinner show />
        </Paper>
      </div>
    );
  }

  if (!currentVm) {
    return (
      <div className={classes.page} style={{ marginTop: "50px" }}>
        <Paper className={classes.formContainer} elevation={0} style={{ minHeight: "600px" }}>
          Virtual Machine not found.
        </Paper>
      </div>
    );
  }

  if (
    !hasContractAccessToFeature(userProfile, currentVm.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_VNC_CONSOLE, [
      USER_ACCESS.VIEW,
    ])
  ) {
    return (
      <div className={classes.page} style={{ marginTop: "50px" }}>
        <Paper className={classes.formContainer} elevation={0} style={{ minHeight: "600px" }}>
          Sorry, you don't have access to VNC console of this Virtual Machine.
        </Paper>
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <PageHeader title={t("title")} backPath="/infra/vm" />
      <VncContent vm={currentVm} />
    </div>
  );
};

export default VncPage;
