import { DAILY_TYPE } from "../../../constants/report";
import {
  DETAILED_REPORTS_REQUEST,
  FILTER_REPORTS,
  REPORTS_ERROR,
  REPORTS_REQUEST,
  RESET_REPORTS_SORT_STATE,
  SEND_EMAIL_REPORT_REQUEST,
  SEND_EMAIL_REPORT_REQUEST_FAILED,
  SEND_EMAIL_REPORT_REQUEST_SUCCESS,
  SET_CURRENT_REPORT_PAGE,
  GET_ENABLED_DATACENTERS_REQUEST_SUCCESS,
  SET_DETAILED_DATE,
  SET_DETAILED_REPORTS,
  SET_REPORT_DATACENTER,
  SET_REPORT_END_DATE,
  SET_REPORT_GROUP,
  SET_REPORT_FILTER_TYPE,
  SET_REPORT_FILTER_VALUE,
  SET_REPORT_START_DATE,
  SET_REPORTS,
  SET_REPORTS_PER_PAGE,
  SORT_REPORTS,
  STORAGE_DETAILS_REPORTS_REQUEST,
  STORAGE_DETAILS_REPORTS_REQUEST_SUCCESS,
  STORAGE_DETAILS_REPORTS_REQUEST_FAILURE,
  SET_REPORT_FILTER_STATUSES,
} from "../../types";
import { REQUEST_FAILED, REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_UNCALLED } from "../../../constants/request";
import { PAGINATION_STEPS } from "../../../constants/pagination";
import { EMPTY_GROUP_VALUE } from "../../../pages/Reports/ReportsFilterBar/constants";
import { SummaryReportVmStatuses } from "../../../constants/options";

/**
 * @typedef {object} StorageDetailsReportItem
 * @property {number} _id
 * @property {string} name
 * @property {string} tier
 * @property {number} size
 * @property {object[]} vms
 */

/**
 * @typedef {""|"_id"|"vm_count"|"vcpu_count"|"memory_size"|"image_count"|"image_size"} SummaryReportSortField
 * @typedef {""|"name"|"status"|"vcpu_count"|"memory_size"|"image_size"} DetailedReportSortField
 */

/**
 * @typedef {object} DetailedReportTierItem
 * @property {Number} image_size
 * @property {String} name
 * @property {Number} image_count
 */

/** @typedef {Object} DetailedReportStatus
 * @property {String} _id
 * @property {String} name
 * @property {Number} poll
 * @property {Number} start
 * @property {Number} state
 * @property {Number} substate
 */

/** @typedef {Object} DetailedReportAttributes
 * @property {String|Null} agree_type
 * @property {String|Null} backup
 * @property {String|Null} guest_os
 * @property {String|Null} hostname
 * @property {String|Null} labels
 * @property {String|Null} role
 * @property {String|Null} set_hostname
 * @property {String|Null} sqldb
 * @property {String|Null} sqlinstance
 * @property {String|Null} vc_policy
 * @property {String|Null} xaas_role
 */

/**
 * @typedef {object} DetailedReport
 * @property {Number} _id
 * @property {DetailedReportAttributes} attributes
 * @property {Number} cpu_count
 * @property {Number} image_size
 * @property {Number} memory_size
 * @property {String} name
 * @property {DetailedReportStatus} status
 * @property {DetailedReportTierItem[]} tiers
 * @property {String} time
 * @property {Number} vcpu_count
 */

const reportInitialState = {
  reports: [],
  reportRequest: REQUEST_UNCALLED,
  detailedReports: [],
  detailedReportsRequest: REQUEST_UNCALLED,
  selectedCols: [
    { name: "Month", key: "_id", selected: true },
    { name: "Vms", key: "vm_count", selected: true },
    { name: "Cpu", key: "vcpu_count", selected: true },
    { name: "Memory", key: "memory_size", selected: true },
    { name: "ImageCount", key: "image_count", selected: true },
    { name: "ImageSize", key: "image_size", selected: true },
  ],
  selectedDetailedCols: [
    { name: "VmName", key: "name", selected: true, sortable: true },
    { name: "Group", key: "_groupWithAgreementID", selected: true, unSortable: true },
    { name: "VmStatus", key: "status", selected: true, unSortable: true },
    { name: "Cpu", key: "vcpu_count", selected: true },
    { name: "Memory", key: "memory_size", selected: true },
    { name: "ImageSize", key: "image_size", selected: true },
  ],
  sorterValue: "_id",
  selectedFilter: "",
  filterValue: "",
  sortWay: "desc",
  currentReportPage: 1,
  perPage: PAGINATION_STEPS[0],
  message: "",
  reportFilterType: DAILY_TYPE,
  reportDatacenterId: EMPTY_GROUP_VALUE,
  reportGroupId: EMPTY_GROUP_VALUE,
  summaryReportFilterStatuses: SummaryReportVmStatuses.filter((status) => status !== "pending"),
  selectionStartDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30),
  selectionEndDate: new Date(),
  selectionDetailedDate: new Date(),
  sendEmailReportRequest: REQUEST_UNCALLED,
  storageDetailsReportRequest: REQUEST_UNCALLED,
  storageDetailsReportList: [],
};

const reportReducer = (state = reportInitialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REPORT_FILTER_STATUSES: {
      return {
        ...state,
        summaryReportFilterStatuses: action.payload,
      };
    }
    case SET_REPORTS: {
      return {
        ...state,
        reports: payload,
        reportRequest: REQUEST_SUCCESS,
      };
    }
    case REPORTS_REQUEST: {
      return {
        ...state,
        reportRequest: REQUEST_PENDING,
      };
    }
    case SET_DETAILED_REPORTS: {
      return {
        ...state,
        detailedReports: payload,
        detailedReportsRequest: REQUEST_SUCCESS,
      };
    }
    case DETAILED_REPORTS_REQUEST: {
      return {
        ...state,
        detailedReportsRequest: REQUEST_PENDING,
      };
    }
    case SORT_REPORTS: {
      return {
        ...state,
        sorterValue: payload,
        sortWay: state.sorterValue === payload && state.sortWay === "desc" ? "asc" : "desc",
      };
    }
    case FILTER_REPORTS: {
      return {
        ...state,
        selectedFilter: payload,
      };
    }
    case SET_REPORTS_PER_PAGE: {
      return {
        ...state,
        perPage: payload,
      };
    }
    case RESET_REPORTS_SORT_STATE: {
      return {
        ...state,
        sorterValue: reportInitialState.sorterValue,
        sortWay: reportInitialState.sortWay,
        currentReportPage: 1,
        perPage: reportInitialState.perPage,
      };
    }
    case SET_REPORT_FILTER_VALUE: {
      return {
        ...state,
        filterValue: payload,
      };
    }
    case SET_CURRENT_REPORT_PAGE: {
      return {
        ...state,
        currentReportPage: payload,
      };
    }
    case REPORTS_ERROR: {
      return {
        ...state,
        message: payload,
      };
    }
    case SET_REPORT_FILTER_TYPE: {
      return {
        ...state,
        reportFilterType: payload,
      };
    }
    case SET_REPORT_GROUP: {
      return {
        ...state,
        reportGroupId: payload,
      };
    }
    case SET_REPORT_START_DATE: {
      return {
        ...state,
        selectionStartDate: payload,
      };
    }
    case SET_REPORT_DATACENTER: {
      return {
        ...state,
        reportDatacenterId: payload,
        reportGroupId: EMPTY_GROUP_VALUE,
      };
    }
    case GET_ENABLED_DATACENTERS_REQUEST_SUCCESS: {
      return {
        ...state,
        reportDatacenterId: payload.length === 1 ? payload[0].id : state.reportDatacenterId,
      };
    }
    case SET_REPORT_END_DATE: {
      return {
        ...state,
        selectionEndDate: payload,
      };
    }
    case SET_DETAILED_DATE: {
      return {
        ...state,
        selectionDetailedDate: payload,
      };
    }
    case SEND_EMAIL_REPORT_REQUEST:
      return {
        ...state,
        sendEmailReportRequest: REQUEST_PENDING,
      };

    case SEND_EMAIL_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        sendEmailReportRequest: REQUEST_SUCCESS,
      };

    case SEND_EMAIL_REPORT_REQUEST_FAILED:
      return {
        ...state,
        sendEmailReportRequest: REQUEST_FAILED,
      };
    case STORAGE_DETAILS_REPORTS_REQUEST: {
      return {
        ...state,
        storageDetailsReportRequest: REQUEST_PENDING,
      };
    }
    case STORAGE_DETAILS_REPORTS_REQUEST_SUCCESS: {
      return {
        ...state,
        storageDetailsReportRequest: REQUEST_SUCCESS,
        storageDetailsReportList: action.payload,
      };
    }
    case STORAGE_DETAILS_REPORTS_REQUEST_FAILURE: {
      return {
        ...state,
        storageDetailsReportRequest: REQUEST_FAILED,
        storageDetailsReportList: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default reportReducer;
