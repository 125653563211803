import { getPaginationSlice, makeUniversalSort } from "pages/helpers";

export const pricelistSel = (state) => state.pricelist;

const makeProductIdSortHandler = makeUniversalSort((item) => item.productId);
const makeDescriptionSortHandler = makeUniversalSort((item) => item.description.toLowerCase());
const makeUnitPriceSortHandler = makeUniversalSort((item) => Number(item.unitPrice));

const pricelistComposer = ({ list, sortField, sortOrder, currentPage, perPage }) => {
  let _list = [...list];

  const sortHandler = {
    productId: makeProductIdSortHandler(sortOrder),
    description: makeDescriptionSortHandler(sortOrder),
    unitPrice: makeUnitPriceSortHandler(sortOrder),
  }[sortField];

  if (sortHandler) {
    _list.sort(sortHandler);
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getPricelistTable = (state) => {
  const pricelist = pricelistSel(state);
  return pricelistComposer(pricelist);
};
