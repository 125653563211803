const styles = () => ({
  root: {
    margin: "0 15px",
  },
  muiSelectRoot: {
    height: "32px",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    borderRadius: "4px",
    backgroundColor: "transparent",
    border: "1px solid rgba(0, 0, 0, 0.23);",
  },
  icon: {
    width: "18px",
    right: "5px",
  },
});

export default styles;
