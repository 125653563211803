import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkUserAuthState, getUserInfo } from "../store/actions/authActions";
import { setEnabledDatacentersAct } from "../store/actions/datacenterActions";
import { getAllGroupsAct } from "../store/actions/groupActions";
import { getNetworkListRequestAct } from "../store/actions/networkActions";
import { fetchImageTiers } from "../store/actions/imagesActions";
import { authSlice } from "../store/selectors/authSelectors";
import * as userSelectors from "../store/selectors/userSelectors";
import { getDataCenterList } from "../store/selectors/dcSelectors";
import { isUserAdmin, hasUserContracts } from "../utils/user";
import * as redirect from "../utils/redirect";

/**
 * !Use this hook only once!
 * Each call will trigger reload all user/datacenters/networks/tiers/groups data
 */
const useInitApp = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(authSlice);
  const profile = useSelector(userSelectors.getUserProfile);
  const dcList = useSelector(getDataCenterList);

  useEffect(() => {
    dispatch(checkUserAuthState());
    dispatch(getUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn && profile) {
      if (isUserAdmin(profile) || hasUserContracts(profile)) {
        dispatch(setEnabledDatacentersAct());
      } else {
        redirect.toMainApp();
      }
    }
  }, [isLoggedIn, profile, dispatch]);

  useEffect(() => {
    if (dcList.length) {
      dispatch(getAllGroupsAct());
      dispatch(getNetworkListRequestAct());
      dispatch(fetchImageTiers());
    }
  }, [dcList, dispatch]);

  return { isInit: Boolean(isLoggedIn && profile), profile };
};

export default useInitApp;
