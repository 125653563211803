import { CTR_ALT_DEL_SIGNAL, REBOOT_SIGNAL, RESET_SIGNAL, SHUTDOWN_SIGNAL } from "./constants";

/**
 * @param {RFB} rfb
 * @param {MachineSignals} signal
 */
export const sendRFBSignal = (rfb, signal) => {
  if (!rfb) {
    return null;
  }
  switch (signal) {
    case CTR_ALT_DEL_SIGNAL: {
      return rfb.sendCtrlAltDel();
    }
    case REBOOT_SIGNAL: {
      return rfb.machineReboot();
    }
    case RESET_SIGNAL: {
      return rfb.machineReset();
    }
    case SHUTDOWN_SIGNAL: {
      return rfb.machineShutdown();
    }
    default: {
      return null;
    }
  }
};

/**
 * @param {function} t - i18n with 'vnc' context
 * @param {MachineSignals} signal
 */
export const getSignalTexts = (signal, t) => ({
  title: {
    [REBOOT_SIGNAL]: t("confirmRebootModal.title"),
    [SHUTDOWN_SIGNAL]: t("confirmShutdownModal.title"),
    [RESET_SIGNAL]: t("confirmResetModal.title"),
    [CTR_ALT_DEL_SIGNAL]: t("confirmCrtAltDelModal.title"),
  }[signal],
  hint: {
    [REBOOT_SIGNAL]: t("confirmRebootModal.hint"),
    [RESET_SIGNAL]: t("confirmResetModal.hint"),
    [SHUTDOWN_SIGNAL]: t("confirmShutdownModal.hint"),
    [CTR_ALT_DEL_SIGNAL]: t("confirmCrtAltDelModal.hint"),
  }[signal],
});
