const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const integersPattern = /^\d+$/;

/**
 * @param {string} email
 * @return {boolean}
 */
export const isValidEmail = email => emailPattern.test(email);

/**
 * @param {string} input
 * @return {boolean}
 */
export const checkIsStringIsIntegerOnly = input => integersPattern.test(input);
