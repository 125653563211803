import { axiosClient } from "./web";

/**
 * @typedef {object} ImageTierApiModel
 * @property {string} _id - Mongo DB id
 * @property {string} name
 * @property {string} [desc]
 */

/**
 * @typedef {object} ImageApiModel
 * @property {string} _id - MongoDB
 * @property {number} id - OpenNebula
 * @property {string} name
 * @property {boolean} persistent
 * @property {object} attributes
 * @property {number} created
 * @property {number} group
 * @property {number} size
 * @property {object} snapshot
 * @property {{ID: string}[]} vms
 * @property {string|null} tier - ImageTierApiModel.name
 */

/**
 * @param {string} baseURL - dc URL
 */
export const getImages = (baseURL) =>
  axiosClient.request({
    method: "GET",
    baseURL,
    url: "/v1/image",
  });

/**
 * @param {string} baseURL - dc URL
 */
export const getImageTierList = (baseURL) =>
  axiosClient.request({
    method: "GET",
    baseURL,
    url: "/v1/tier",
  });

/**
 * @typedef {object} CreateImagePayload
 * @property {string} name
 * @property {boolean} persistent
 * @property {number} group - group ID OpenNebula
 * @property {string} tier - enum
 * @property {number} size - value in megabytes
 */

/**
 * @param {string} baseURL - dc URL
 * @param {CreateImagePayload} payload
 */
export const createImage = (baseURL, payload) =>
  axiosClient.request({
    method: "POST",
    baseURL,
    url: "/v1/image",
    data: payload,
  });

/**
 * @typedef {object} PatchImagePayload
 * @property {string} name
 * @property {boolean} persistent
 * @property {number} group - group ID OpenNebula
 * @property {string} tier - enum
 * @property {number} size - value in megabytes
 */

/**
 * @param {string} baseURL - datacenter URL
 * @param {string} imageId - image id Mongo
 * @param {PatchImagePayload} payload
 */
export const updateImage = async (baseURL, imageId, payload) =>
  axiosClient.request({
    baseURL,
    method: "PUT",
    url: `/v1/image/${imageId}`,
    data: payload,
  });

/**
 * @param {string} baseURL - datacenter URL
 * @param {string} imageId - Mongo id
 */
export const deleteImage = (baseURL, imageId) =>
  axiosClient.request({
    method: "DELETE",
    baseURL,
    url: `/v1/image/${imageId}`,
  });

/**
 * @param {string} baseURL - datacenter URL
 */
export const getTemplates = (baseURL) =>
  axiosClient.request({
    method: "GET",
    baseURL,
    url: "/v1/template",
  });
