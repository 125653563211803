import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@skyportal/ui-kit";
import { sortVMsAct, toggleSelectAllVMsAct } from "../../../../store/actions/virtualMachinesActions";
import Checkbox from "../../../../ui-kit/Checkbox";
import { iconResolver } from "../../../helpers";
import { getSelectedColsSel, getTableVms, vmSel } from "../../../../store/selectors/vmSelectors";

import styles from "./styles";

const useStyles = makeStyles(styles);
const VMsTableHeadColumnControls = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedVMs, sortWay, sorterValue } = useSelector(vmSel);
  const { list } = useSelector(getTableVms);
  const selectedCols = useSelector(getSelectedColsSel);

  const sortVms = (value) => dispatch(sortVMsAct(value));
  const isAllVmsSelected = list.length > 0 && list.every((item) => selectedVMs.includes(item._id));

  const toggleSelectVMs = () => {
    dispatch(toggleSelectAllVMsAct(isAllVmsSelected ? [] : list.map((item) => item._id)));
  };

  return (
    <div className={classes.tableRowShell}>
      <div className={classes.controlsRow}>
        <Checkbox isChecked={isAllVmsSelected} checkHandler={toggleSelectVMs} />
        {selectedCols
          .filter((col) => col.selected)
          .map((col) => (
            <span key={col.name} className={cn(classes.headCell, classes[col.name])} onClick={() => sortVms(col.name)}>
              <Icon type="fontawesome" className={classes.icon} icon={iconResolver(sortWay, sorterValue, col.name)} />
              <span className={classes.columnName}>{t(`iaasCommon:${col.label}`)}</span>
            </span>
          ))}
      </div>
      <div className={classes.snapShot} />
    </div>
  );
};

export default VMsTableHeadColumnControls;
