import React, { useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import Highcharts from "highcharts";
import { composeChartOptions } from "./utils";

const ConsumptionChart = ({ categories = [], data = [] }) => {
  const { t } = useTranslation("consumptionChart");
  const chartOptions = useMemo(() => composeChartOptions({ categories, data, t }), [categories, data]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default ConsumptionChart;
