/**
 * @param {GroupStateModel[]} list
 * @param {number} id - OpenNebula id
 * @param {string} [namePlaceholder] = ""
 * @return {string}
 */
export const getGroupNameFromListById = (list, id, namePlaceholder = "") => {
  const { displayname, name } = (list || []).find((group) => group.id === id) || { name: namePlaceholder };
  return displayname || name;
};

/**
 *
 * @param {GroupStateModel[]} list
 */
export const getGroupMapByDcId = (list) =>
  list.reduce((acc, group) => {
    if (!acc[group.dcOwner.id]) {
      acc[group.dcOwner.id] = [];
    }

    acc[group.dcOwner.id].push(group);

    return acc;
  }, {});

/**
 * @param {*} entity
 * @return {string|null}
 */
export const getDcIdFromEntity = (entity) => (entity && entity.dcOwner ? entity.dcOwner.id : null);

/**
 * @param {T[]} list
 * @param {string|null} id - Datacenter id
 * @return {T[]}
 */
export const filterListByDcId = (list, id) => list.filter((item) => id !== null && getDcIdFromEntity(item) === id);

/**
 * @param {GroupStateModel[]} list
 * @param {number} id
 * @return {object|null}
 */
export const getDcFromGroupListById = (list, id) => {
  const { dcOwner } = list.find((group) => group.id === id) || { dcOwner: null };
  return dcOwner;
};

/**
 * @param {GroupStateModel[]} list
 * @param {string} id
 * @return {number|null}
 */
export const getOpenNebulaId = (list, id) => {
  const group = list.find((item) => item._id === id);
  return group && typeof group.id === "number" ? group.id : null;
};

/**
 * @param {GroupStateModel[]} list
 * @return {Option}
 */
export const composeGroupOptions = (list) =>
  list.map((item) => ({
    label: item.displayname || item.name,
    value: item._id,
  }));
