const styles = (theme) => ({
  tableWrapper: {
    position: "relative",
    fontSize: "14px",
  },
  scrollArea: {
    width: "100%",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
      borderRadius: "3px",
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "3px",
      backgroundColor: "rgba(0,0,0,.8)",
    },

    "&::-webkit-scrollbar-track": {
      borderRadius: "3px",
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    tableLayout: "fixed",

    "@media(max-width: 767px)": {
      width: "767px",
      overflowX: "auto",
    },
  },
  tableHead: {
    height: "40px",
    borderBottom: `3px solid ${theme.colors.lightGrey}`,
  },
  tableHeadMask: {
    height: "1px",
    overflow: "hidden",
  },
  tableHeadCell: {
    textAlign: "start",
    userSelect: "none",
    padding: "0 5px",
    boxSizing: "box-sizing",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0,0,0,0.02)",
    },
  },
  tableRow: {
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
    height: "50px",
  },
  tableCell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tableFooterMask: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "80px",
    background: "linear-gradient(0deg, #fff, transparent)",
    pointerEvents: "none",

    "@media(max-width: 767px)": {
      display: "none",
    },
  },
  icon: {
    color: "#8D8D8D",
    marginRight: "8px",
  },
  iconActive: {
    color: "#000",
  },
});

export default styles;
