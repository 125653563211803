import React, { useCallback, useEffect, useRef, useState } from "react";
import RFB from "@novnc/novnc/core/rfb";
import { useHistory } from "react-router-dom";
import Spinner from "../../../pages/shareableComponents/Spinner/Spinner";
import { makeStyles } from "@material-ui/core/styles";
import * as vmActions from "../../../store/actions/virtualMachinesActions";
import { useSelector, useDispatch } from "react-redux";
import { vmSel } from "../../../store/selectors/vmSelectors";
import { REQUEST_PENDING } from "../../../constants/request";
import { CAPABILITIES_EVENT, CONNECT_EVENT, DISCONNECT_EVENT, RFB_DEFAULT_OPTIONS } from "./constants";
import VncHeader from "../../../pages/VMs/Vnc/VncHeader";
import { isVmRunning } from "../../../utils/vm";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {VmStateModel} [vm]
 * @return {JSX.Element}
 * @constructor
 */
const VncContent = ({ vm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const rfbRef = useRef(null);
  const viewRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [capabilities, setCapabilities] = useState(null);
  const { vncUrlRequest, vncUrl } = useSelector(vmSel);

  useEffect(() => {
    if (vm && isVmRunning(vm)) {
      dispatch(vmActions.vncUrlRequest(vm.dcOwner.api.url, vm._id));
    }
  }, [vm, dispatch]);

  const handleConnect = useCallback(() => {
    setIsConnected(true);
  }, []);

  const handleDisconnect = useCallback(
    (event) => {
      if (event.detail.clean) {
        setIsConnected(false);
        history.push("/infra/vm");
      }
    },
    [history]
  );

  const handleCapabilities = useCallback((event) => {
    if (event.detail) {
      setCapabilities(event.detail);
    }
  }, []);

  useEffect(() => {
    if (vncUrl && viewRef.current) {
      const rfb = new RFB(viewRef.current, vncUrl, RFB_DEFAULT_OPTIONS);

      rfb.addEventListener(CONNECT_EVENT, handleConnect);
      rfb.addEventListener(DISCONNECT_EVENT, handleDisconnect);
      rfb.addEventListener(CAPABILITIES_EVENT, handleCapabilities);

      rfb.viewOnly = false;
      rfb.clipViewport = false;
      rfb.scaleViewport = false;
      rfb.resizeSession = true;
      setCapabilities(rfb.capabilities);

      rfbRef.current = rfb;

      return () => {
        rfb.disconnect();
        rfb.removeEventListener(CONNECT_EVENT, handleConnect);
        rfb.removeEventListener(DISCONNECT_EVENT, handleDisconnect);
        rfb.removeEventListener(CAPABILITIES_EVENT, handleCapabilities);

        rfbRef.current = null;
      };
    }
  }, [vncUrl, handleConnect, handleDisconnect, handleCapabilities]);

  useEffect(
    () => () => {
      dispatch(vmActions.resetVncUrl());
    },
    [dispatch]
  );

  return (
    <div className={classes.pageBox}>
      <Spinner show={vncUrlRequest === REQUEST_PENDING || !vm} />
      {vm && (
        <VncHeader vm={vm} rfbRef={rfbRef} isConnected={isConnected} capabilities={capabilities} viewRef={viewRef} />
      )}
      <div className={classes.vncUiRoot} ref={viewRef} />
    </div>
  );
};

export default VncContent;
