import { directoryClient } from "./web";

/**
 * @return {Promise<AxiosResponse<DataCenterApiModel[]>>}
 */
export const getEnabledDatacenters = () =>
  directoryClient.request({
    method: "GET",
    url: "/v1/datacenter",
  });

/**
 * @return {Promise<AxiosResponse<DataCenterApiModel[]>>}
 */
export const getAllDatacenters = () =>
  directoryClient.request({
    method: "GET",
    url: "/v1/datacenter?includeDisabled=true",
  });

/**
 * @param {DataCenterApiModel} dc
 * @return {Promise<AxiosResponse<DataCenterApiModel>>}
 */
export const updateDatacenter = (dc) =>
  directoryClient.request({
    method: "PUT",
    url: `/v1/datacenter/${dc.id}`,
    data: {
      name: dc.name,
      description: dc.description,
      disabled: dc.disabled,
      region: dc.region.id,
      api: dc.api,
    },
  });

/**
 * @param {DataCenterApiModel} dc
 * @return {Promise<AxiosResponse<DataCenterApiModel>>}
 */
export const createNewDatacenter = (dc) =>
  directoryClient.request({
    method: "POST",
    url: "/v1/datacenter",
    data: {
      name: dc.name,
      description: dc.description,
      disabled: dc.disabled,
      region: dc.region.id,
      api: dc.api,
    },
  });

/**
 * @param {DataCenterApiModel} dc
 * @return {Promise<AxiosResponse<void>>}
 */
export const deleteDatacenter = (dc) =>
  directoryClient.request({
    method: "DELETE",
    url: `/v1/datacenter/${dc.id}`,
  });
