import React from "react";
import { useTranslation } from "react-i18next";
import { USAGE_REPORT_DISK_VALUES_BY_TIERS } from "../../../../constants/features";
import { formatSize } from "../../../../utils/size";

const formatters = {
  image_size: (value) => formatSize(value, true),
  image_count: (value) => String(value),
};

/**
 * @param {SummaryReportApiModel|DetailedReportApiModel} record
 * @param {"image_count"|"image_size"} keyIndex
 * @param {string} tierClassName
 * @return {JSX.Element}
 * @constructor
 */
const DiskCell = ({ record, keyIndex, tierClassName }) => {
  const { t } = useTranslation();
  const hasTiers = USAGE_REPORT_DISK_VALUES_BY_TIERS && Array.isArray(record.tiers) && record.tiers.length > 0;

  return (
    <div data-testid="DiskCell">
      <div>{formatters[keyIndex](record[keyIndex])}</div>
      {hasTiers &&
        record.tiers.map((tierItem, index) => (
          <div className={tierClassName} key={String(index)}>
            {`${formatters[keyIndex](tierItem[keyIndex])} - ${tierItem.name || t("iaasCommon:noTier")}`}
          </div>
        ))}
    </div>
  );
};

export default DiskCell;
