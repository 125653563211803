import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner } from "@skyportal/ui-kit";
import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import { formatSize } from "../../../../utils/size";
import useAllMetricsRequestPending from "../../../../hooks/useAllMetricsRequestPending";
import { getPercentInteger } from "../VmTotalsCard/utils";
import { getTopUsedDataVmList } from "../../../../store/selectors/dashboardSelectors";
import TopUsedDataVmItem from "./TopUsedDataVmItem";

import styles from "../../../../pages/Dashboard/styles";

const useStyles = makeStyles(styles);

const TopUsedDataCard = () => {
  const { t } = useTranslation("topUsedDataBlock");
  const classes = useStyles();
  const topMemoryVmList = useSelector(getTopUsedDataVmList);
  const totalSize = topMemoryVmList.reduce((acc, item) => acc + item.size, 0);

  return (
    <Card className={classes.smallCard}>
      <Spinner show={useAllMetricsRequestPending()} />
      <CardHeader
        title={t("title")}
        subheader={t("hint")}
        className={classes.basicHeader}
        classes={{
          title: classes.headerTitle,
          subheader: classes.subHeader,
        }}
      />

      <CardContent classes={{ root: classes.cardContentPadding }}>
        {topMemoryVmList.map((item) => (
          <TopUsedDataVmItem
            key={item.id}
            name={item.name}
            sizeLabel={formatSize(item.size)}
            percents={getPercentInteger(totalSize, item.size)}
          />
        ))}
      </CardContent>
    </Card>
  );
};

export default TopUsedDataCard;
