import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@skyportal/ui-kit";
import Input from "../../../../ui-kit/Input";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { formatSize } from "../../../../utils/size";
import { filterListByDcId, getDcIdFromEntity, getGroupNameFromListById } from "../../../../utils/groups";
import { DEFAULT_CONFIRMATION_TEXT } from "../../../../constants/texts";
import { detachDiskRequest, setAllVmsAct } from "../../../../store/actions/virtualMachinesActions";
import { selectImageForEditAct } from "../../../../store/actions/imagesActions";
import { isDiskAttachedToVm } from "../../../../utils/vm";
import IconTooltip from "../../../../components/IconTooltip";
import { hasContractAccessToFeature, CONTRACT_TYPES, FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";

import styles from "../styles";

const useStyles = makeStyles(styles);

/**
 * @param {VmStateModel} vm
 * @param {DiskStateModel[]} images
 * @param {GroupStateModel[]} groups
 * @param {boolean} [disabled]
 * @param {UserInfoModel} userProfile
 * @return {JSX.Element}
 * @constructor
 */
const VmDisksList = ({ images, vm, groups, disabled, userProfile }) => {
  const { t } = useTranslation("vmPage");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDetaching, setIsDetaching] = useState(false);
  const [detachDiskId, setDetachDiskId] = useState(null);
  const [confirmationText, setConfirmationText] = useState("");
  const confirmWord = t("confirmDetachDiskModal.confirmWord") || DEFAULT_CONFIRMATION_TEXT;
  const dcDisks = filterListByDcId(images, getDcIdFromEntity(vm));
  /**
   * @type {DiskStateModel[]}
   */
  const attachedDisks = dcDisks.filter((disk) => isDiskAttachedToVm(vm, disk));

  const makeOnRemoveBtnClickHandler = (id) => () => setDetachDiskId(id);

  const handleConfirmDiskDetach = async () => {
    try {
      setIsDetaching(true);
      await dispatch(detachDiskRequest(vm, detachDiskId));
      await dispatch(setAllVmsAct());
      setDetachDiskId(null);
    } catch (error) {
      console.error(error);
    }
    setIsDetaching(false);
  };

  const handleCloseDetachDiskModal = () => setDetachDiskId(null);

  const openEditImagePopup = (image) => () => dispatch(selectImageForEditAct(image));

  useEffect(() => {
    setConfirmationText("");
  }, [detachDiskId]);

  if (!attachedDisks.length) {
    return null;
  }

  return (
    <>
      <div className={classes.scrollContainer}>
        <div className={classes.listTable}>
          <div className={classes.selectedImgBar}>
            <div className={classes.selImgName}>{t("iaasCommon:Name")}</div>
            <div className={classes.selImgMiddle}>{t("iaasCommon:Group")}</div>
            <div className={classes.selImgMiddle}>{t("iaasCommon:Tier")}</div>
            <div className={classes.selImgMiddle}>{t("iaasCommon:Size")}</div>
            <div className={classes.selImgCross} />
          </div>
          {attachedDisks.filter(Boolean).map((img) => (
            <div key={img._id} className={classes.selectedImgRow} data-testid="VmDiskListItem">
              <div className={classes.selImgName} data-testid="VmDiskListItem_name">
                {img.name}
              </div>
              <div className={classes.selImgMiddle} data-testid="VmDiskListItem_group">
                {getGroupNameFromListById(groups, img.group, "-")}
              </div>
              <div className={classes.selImgMiddle}>{img.tier || "-"}</div>
              <div className={classes.selImgMiddle}>{formatSize(img.size)}</div>
              {disabled ? (
                <IconTooltip
                  testId="detach_disk_info_icon"
                  title={t("editDetachDiskDisallowTooltip")}
                  className={classes.iconTooltip}
                />
              ) : (
                <>
                  <div className={classes.selImgCross}>
                    <Button
                      type="tertiary"
                      leftIcon="pen"
                      onClick={openEditImagePopup(img)}
                      data-testid="VmDiskListItem_editBtn"
                      disabled={
                        !hasContractAccessToFeature(
                          userProfile,
                          img.agreement,
                          CONTRACT_TYPES.IA,
                          FEATURES.IAAS_DISKS,
                          [USER_ACCESS.UPDATE]
                        )
                      }
                    ></Button>
                  </div>
                  <div className={classes.selImgCross}>
                    <Button
                      type="tertiary"
                      leftIcon="close"
                      onClick={makeOnRemoveBtnClickHandler(img.id)}
                      data-testid="VmDiskListItem_detachBtn"
                      disabled={
                        !hasContractAccessToFeature(
                          userProfile,
                          img.agreement,
                          CONTRACT_TYPES.IA,
                          FEATURES.IAAS_DISKS,
                          [USER_ACCESS.UPDATE]
                        )
                      }
                    ></Button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <ConfirmationModal
        isRequesting={isDetaching}
        title={t("confirmDetachDiskModal.title")}
        open={detachDiskId !== null}
        onClose={handleCloseDetachDiskModal}
        onConfirmed={handleConfirmDiskDetach}
        onCancel={handleCloseDetachDiskModal}
        isDisabled={confirmWord !== confirmationText.toLowerCase()}
      >
        <p>{t("confirmDetachDiskModal.confirmQuestion", { text: confirmWord })}</p>
        <Input
          className={classes.fullWidthInput}
          placeholder={t("confirmDetachDiskModal.placeholder", { text: confirmWord })}
          type="text"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          disabled={isDetaching}
        />
      </ConfirmationModal>
    </>
  );
};

export default VmDisksList;
