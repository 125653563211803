import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PAGINATION_STEPS } from "../../constants/pagination";
import Dropdown from "../../ui-kit/Dropdown";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {function(step: number)} onChange
 * @param {number} value
 * @param {number[]} steps
 * @return {JSX.Element}
 * @constructor
 */
const PaginationPerSizeSelect = ({ onChange, value, steps = PAGINATION_STEPS }) => {
  const classes = useStyles();

  const handlePerPageCountChange = (event) => onChange(Number(event.target.value));
  const options = useMemo(() => steps.map((step) => ({ value: String(step), label: String(step) })), [steps]);

  return (
    <Dropdown
      testId="PaginationPerSizeSelect"
      className={classes.root}
      value={value}
      onChange={handlePerPageCountChange}
      options={options}
      selectClasses={{ select: classes.muiSelectRoot, icon: classes.icon }}
    />
  );
};

export default PaginationPerSizeSelect;
