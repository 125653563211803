/**
 * @typedef {object} HighchartsDataItem
 * @property {string} name
 * @property {number} yAxis
 * @property {number[]} data
 */

import { MONTH_LABEL_LIST } from "../../../Reports/ReportsFilterBar/constants";
import { getPastDay } from "../../../../utils/dates";
import axisLabelFormatter from "./formatters/axisLabelFormatter";
import makeTooltipFormatter from "./formatters/tooltipFormatter";

/**
 * @param {Date} date
 * @param {ConsumptionTimeLabels} type
 * @param {function(key: string): string} t - i18n
 * @return string
 */
export const composeTimeLabel = (date, type, t) => {
  try {
    switch (type) {
      case "days": {
        return String(date.getDate());
      }
      case "months": {
        return t(`months:${MONTH_LABEL_LIST[date.getMonth()]}`);
      }
      case "years": {
        return String(date.getFullYear());
      }
      default: {
        return String(date.getDate());
      }
    }
  } catch (_) {
    return "";
  }
};

/**
 * @param {ConsumptionTimeLabels} type
 * @return {Date}
 */
export const getStartFetchMomentByTimeType = (type) => {
  switch (type) {
    case "days": {
      return getPastDay(5);
    }
    case "months": {
      return getPastDay(30 * 5);
    }
    case "years": {
      return getPastDay(365 * 5);
    }
    default: {
      return new Date();
    }
  }
};

/**
 *
 * @param {ConsumptionMetric[]} consumption
 * @param {function(key: string): string} t - i18n
 * @param {ConsumptionTimeLabels} timeGroup
 * @return {{ data: HighchartsDataItem[], categories: string[] }}
 */
export const composeConsumptionChartData = (consumption, timeGroup, t) => ({
  data: [
    {
      name: t("cpu"),
      yAxis: 0,
      data: consumption.map((item) => item.vcpu_count || 0),
    },
    {
      name: t("memory"),
      yAxis: 1,
      data: consumption.map((item) => item.memory_size || 0),
    },
  ],
  categories: consumption.map((item) => composeTimeLabel(new Date(Number(item._id)), timeGroup, t)),
});

export const composeChartOptions = ({ categories, data, t }) => ({
  chart: {
    height: 246,
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "column",
  },
  plotOptions: {
    column: {
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
    },
    series: {
      groupPadding: 0.3,
      pointWidth: 8,
      states: {
        hover: {
          enabled: false,
        },
        inactive: {
          enabled: false,
        },
      },
    },
  },
  tooltip: {
    formatter: makeTooltipFormatter(t),
  },
  yAxis: [
    {
      allowDecimals: false,
      title: {
        text: t("cpuAxisTitle"),
      },
      opposite: true,
    },
    {
      gridLineWidth: 0,
      title: {
        text: t("sizeAxisTitle"),
      },
      labels: {
        formatter: axisLabelFormatter,
      },
    },
  ],
  xAxis: { categories },
  title: {
    text: null,
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#c48df7", "#e59a7f"],
  series: data,
  credits: {
    enabled: false,
  },
  legend: {
    align: "left",
    verticalAlign: "bottom",
  },
});
