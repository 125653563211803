import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getNetworkList } from "../../../../store/selectors/networkSelectors";
import { getDcIdFromEntity } from "../../../../utils/groups";
import { formStyles } from "../styles";

const useStyles = makeStyles(formStyles);

/**
 * @param {number} value
 * @param {function(value: number): void} onChange
 * @param {boolean} [disabled]
 * @param {boolean} [autoSelect]
 * @param {string} [id]
 * @param {string} datacenterId
 * @return {JSX.Element}
 * @constructor
 */
export const NetworkSelect = ({ value, onChange, disabled, datacenterId, id, autoSelect = false }) => {
  const onChangeRef = useRef(null);
  onChangeRef.current = onChange;
  const classes = useStyles();
  const networks = useSelector(getNetworkList);
  /**
   * @type NetworkStateModel[]
   */
  const datacenterNetworks = useMemo(
    () => networks.filter((network) => datacenterId === getDcIdFromEntity(network)),
    [networks, datacenterId]
  );

  useEffect(() => {
    if (autoSelect && datacenterNetworks.length === 1) {
      onChangeRef.current(Number(datacenterNetworks[0].id));
    }
  }, [autoSelect, datacenterNetworks]);

  const handleChange = (event) => onChange(Number(event.target.value));

  return (
    <Select
      id={id}
      IconComponent={KeyboardArrowDownIcon}
      disableUnderline
      value={value ?? ""}
      displayEmpty
      onChange={handleChange}
      disabled={disabled}
      classes={{
        select: classes.muiSelectRoot,
        icon: classes.muiSelectIcon,
      }}
      className={classes.groupsListRoot}
    >
      {datacenterNetworks.map((network) => (
        <MenuItem
          value={network.id}
          key={network.id}
          classes={{ root: classes.listItemRoot }}
          className={classes.networkListItem}
        >
          {network.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default React.memo(NetworkSelect);
