/**
 * @param {UserInfoModel|null} profile
 * @return {boolean}
 */
export const isUserAdmin = (profile) => Boolean(profile && profile.is_admin);

/**
 * @param {UserInfoModel} profile
 * @return {boolean}
 */
export const hasUserContracts = (profile) => {
  return profile.permissions?.entity?.filter((contract) => contract.entityType === "IA").length > 0;
};
