import { SET_USER_PROFILE } from "../../types";

/**
 * @typedef {"BA"|"DR"|"IA"|"ST"} ContractType
 */

/**
 * @typedef {object} MenuItemConfig
 * @property {string} name
 * @property {string} icon - FontAwesome 4.7
 * @property {string} path
 */

/**
 * @typedef {object} ViewCapability
 * @property {boolean} view
 */

/**
 * @typedef {object} UserInfoModel
 * @property {number} id
 * @property {string} name
 * @property {string} email
 * @property {boolean} is_admin
 * @property {{[ContractType]: string[]}} contracts
 * @property {object} capabilities
 * @property {ViewCapability} capabilities.pricing
 * @property {MenuItemConfig[]} capabilities.menuSettings
 * @property {MenuItemConfig[]} capabilities.menuObjects
 * @property {object} roles
 * @property {object} permissions
 * @property {number} exp
 * @property {number} iat
 * @property {number} iss
 * @property {number} sub
 */
export const userInitialState = null;

/**
 * @typedef {object} UserState
 * @property {UserInfoModel} profile
 */

/**
 * @type {UserState}
 */
export const initState = {
  profile: userInitialState,
};

/**
 * @param {UserState} state
 * @param {SetUserProfileAction|{}} action
 * @return {UserState}
 */
const userReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default userReducer;
