import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@skyportal/ui-kit";
import { makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Grid } from "@material-ui/core";
import SummaryFilterForm from "./SummaryFilterForm";
import VMDetailedFilterForm from "./VMDetailedFilterForm";
import StorageDetailsFilterForm from "./StorageDetailsFilterForm";

import styles from "./styles";

const useStyles = makeStyles(styles);

const ReportsFilterBar = () => {
  const { t } = useTranslation("usageReportsPage");
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpandPanelChange = (_, expanded) => setIsExpanded(expanded);
  const handlePricelistLinkClick = () => {
    const isInsideShell = () => typeof window.renderInfra === "function";
    window.open(isInsideShell() ? window.location.origin + "/pricelist" : window.PRICELIST_WEB_URL, "_blank");
  };

  return (
    <div className={classes.root} data-testid="reports_filter_bar">
      <MuiAccordion onChange={handleExpandPanelChange} expanded={isExpanded}>
        <MuiAccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          classes={{
            expandIcon: classes.expandIcon,
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <span className={classes.panelLabel}>{t("filterBar.title")}</span>
            </Grid>
            <Grid item>
              <span className={classes.panelStateHint} data-testid="reports_filter_bar__collapse_label">
                {isExpanded ? t("filterBar.hide") : t("filterBar.show")}
              </span>
            </Grid>
          </Grid>
        </MuiAccordionSummary>
        <MuiAccordionDetails className={classes.filterContainer}>
          <Switch>
            <Route path="/infra/reports/summary" exact component={SummaryFilterForm} />
            <Route path="/infra/reports/detailed" exact component={VMDetailedFilterForm} />
            <Route path="/infra/reports/storage-details" exact component={StorageDetailsFilterForm} />
          </Switch>
        </MuiAccordionDetails>
        <Button
          type="secondary"
          rightIcon="arrow-right"
          className={classes.pricelistLink}
          onClick={handlePricelistLinkClick}
        >
          {t("iaasCommon:priceList")}
        </Button>
      </MuiAccordion>
    </div>
  );
};

export default ReportsFilterBar;
