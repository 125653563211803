import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@skyportal/ui-kit";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { imageSel, getModalImages } from "../../../../store/selectors/imgSelectors";
import {
  resetImageSortAC,
  setCurrentPageAC,
  setSearchTextAC,
  setSortFieldAC,
} from "../../../../store/actions/imagesActions";
import Input from "../../../../ui-kit/Input";
import Radio from "../../../../ui-kit/Radio";
import SortableTable from "../../../../ui-kit/SortableTable/SortableTable";
import { formatTimestampToFullDate } from "../../../helpers";
import { formatSize } from "../../../../utils/size";
import { attachDiskRequest, setAllVmsAct } from "../../../../store/actions/virtualMachinesActions";
import Spinner from "../../../../pages/shareableComponents/Spinner/Spinner";

import styles from "../styles";

const useStyles = makeStyles(styles);

/**
 * @param {boolean} open
 * @param {function} onClose
 * @param {VmStateModel} currentVm
 * @param {UserInfoModel} userProfile
 * @return {JSX.Element}
 * @constructor
 */
const AttachDiskModal = ({ open, onClose, currentVm, userProfile }) => {
  const { t } = useTranslation("vmPage");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isRequesting, setIsRequesting] = useState(false);
  const { currentPage, sortField, sortOrder } = useSelector(imageSel);
  const [diskToAttacheId, setDiskToAttacheId] = useState(null);
  const { pageCount, list } = useSelector(getModalImages(currentVm, userProfile));
  /**
   * @type {ColumnConfig[]}
   */
  const columnConfig = useMemo(
    () => [
      {
        key: "",
        width: "30px",
        label: "",
        sortDisabled: true,
        cellClassName: classes.checkboxCell,
        render: (diskListItem) => (
          <Radio
            name="disk"
            onChange={(event) => setDiskToAttacheId(event.target.value)}
            value={String(diskListItem.id)}
            checked={diskToAttacheId === String(diskListItem.id)}
          />
        ),
      },
      { key: "name", label: "Name" },
      { key: "tier", label: "Tier" },
      { key: "__groupName", label: "Group" },
      { key: "size", label: "Size", render: (disk) => formatSize(disk.size) },
      { key: "created", label: "Created", render: (disk) => formatTimestampToFullDate(disk.created) },
    ],
    [diskToAttacheId, classes.checkboxCell]
  );

  const onChangePage = (_, page) => dispatch(setCurrentPageAC(page));

  const handleAddBtnClick = async () => {
    try {
      setIsRequesting(true);
      await dispatch(attachDiskRequest(currentVm, Number(diskToAttacheId)));
      await dispatch(setAllVmsAct());
      onClose();
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }

    setIsRequesting(false);
  };

  const handleSearchChange = (event) => dispatch(setSearchTextAC(event.target.value));
  const handleSortFieldChange = (field) => dispatch(setSortFieldAC(field));

  useEffect(
    () => () => {
      dispatch(resetImageSortAC());
    },
    [dispatch]
  );

  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <div className={classes.modalBody} data-testid="AttachDiskModal">
          <Spinner show={isRequesting} />
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>{t("addImageModal.title")}</h2>
            <Input onChange={handleSearchChange} placeholder={t("addImageModal.searchPlaceholder")} />
          </div>
          <div className={classes.tableBody}>
            <SortableTable
              testId="AttachDiskModal_table"
              data={list}
              onSortFieldChange={handleSortFieldChange}
              columnsConfig={columnConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              className={classes.table}
            />
          </div>
          <div className={classes.pagingContainer}>
            <div className={classes.imageModalActions}>
              <Button disabled={diskToAttacheId === null} onClick={handleAddBtnClick}>
                {t("iaasCommon:Add")}
              </Button>
              <Button type="secondary" onClick={onClose}>
                {t("iaasCommon:Cancel")}
              </Button>
            </div>
            <div className={classes.pagination}>
              <Pagination
                page={currentPage}
                count={pageCount}
                onChange={onChangePage}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AttachDiskModal;
