import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@skyportal/ui-kit";
import { getGroupNameFromListById } from "../../../../utils/groups";
import IconTooltip from "../../../../components/IconTooltip";

import styles from "../styles";

const useStyles = makeStyles(styles);

/**
 * @param {NetworkStateModel} network
 * @param {GroupStateModel} groups
 * @param {function(networkId: number): void} onDetachClick
 * @param {boolean} [detachAllowed]
 * @constructor
 */
const VmNetworkListItem = ({ groups, network, onDetachClick, detachAllowed = false }) => {
  const classes = useStyles();
  const { t } = useTranslation("vmPage");
  const handleClick = () => onDetachClick(network.id);

  return (
    <div className={classes.selectedImgRow} data-testid="VmNetworkListItem">
      <div className={classes.selImgName} data-testid="VmNetworkListItem_name">
        {network.name}
      </div>
      <div className={classes.selImgMiddle} data-testid="VmNetworkListItem_group">
        {getGroupNameFromListById(groups, network.group, "-")}
      </div>
      <div className={classes.selImgMiddle} />
      <div className={classes.selImgMiddle} />

      <div className={classes.selImgCross}>
        {detachAllowed ? (
          <Button data-testid="VmNetworkListItem_detachBtn" type="tertiary" leftIcon="close" onClick={handleClick} />
        ) : (
          <IconTooltip testId="detach_info_icon" title={t("detachDisallowTooltip")} className={classes.iconTooltip} />
        )}
      </div>
    </div>
  );
};

export default VmNetworkListItem;
