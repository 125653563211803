export const VM_STATUS_CODE_PENDING = 1;
export const VM_STATUS_CODE_RUNNING = 3;
export const VM_STATUS_CODE_STOPPED = 4;
export const VM_STATUS_CODE_SUSPENDED = 5;
export const VM_STATUS_CODE_DONE = 6;
export const VM_STATUS_CODE_POWER_OFF = 8;
export const VM_STATUS_CODE_UNDEPLOYED = 9;

/**
 * @typedef {"running"|"stopped"|"pending"} VmStatusName
 */
/**
 * @type {VmStatusName}
 */
export const VM_STATUS_STRING_PENDING = "pending";
/**
 * @type {VmStatusName}
 */
export const VM_STATUS_STRING_RUNNING = "running";
/**
 * @type {VmStatusName}
 */
export const VM_STATUS_STRING_STOPPED = "stopped";

/**
 * OpenNebula VM statuses
 * @see <https://docs.opennebula.io/5.10/operation/references/vm_states.html>
 */
export const VM_STATUS_INIT = "0.0";
export const VM_STATUS_PENDING = "1.0";
export const VM_STATUS_HOLD = "2.0";
export const VM_STATUS_LCM_INIT = "3.0";
export const VM_STATUS_PROLOG = "3.1";
export const VM_STATUS_BOOT = "3.2";
export const VM_STATUS_RUNNING = "3.3";
export const VM_STATUS_MIGRATE = "3.4";
export const VM_STATUS_SAVE_STOP = "3.5";
export const VM_STATUS_SAVE_SUSPEND = "3.6";
export const VM_STATUS_SAVE_MIGRATE = "3.7";
export const VM_STATUS_PROLOG_MIGRATE = "3.8";
export const VM_STATUS_PROLOG_RESUME = "3.9";
export const VM_STATUS_EPILOG_STOP = "3.10";
export const VM_STATUS_EPILOG = "3.11";
export const VM_STATUS_SHUTDOWN = "3.12";
export const VM_STATUS_CLEANUP_RESUBMIT = "3.15";
export const VM_STATUS_UNKNOWN = "3.16";
export const VM_STATUS_HOTPLUG = "3.17";
export const VM_STATUS_SHUTDOWN_POWEROFF = "3.18";
export const VM_STATUS_BOOT_UNKNOWN = "3.19";
export const VM_STATUS_BOOT_POWEROFF = "3.20";
export const VM_STATUS_BOOT_SUSPENDED = "3.21";
export const VM_STATUS_BOOT_STOPPED = "3.22";
export const VM_STATUS_CLEANUP_DELETE = "3.23";
export const VM_STATUS_HOTPLUG_SNAPSHOT = "3.24";
export const VM_STATUS_HOTPLUG_NIC = "3.25";
export const VM_STATUS_HOTPLUG_SAVEAS = "3.26";
export const VM_STATUS_HOTPLUG_SAVEAS_POWEROFF = "3.27";
export const VM_STATUS_HOTPLUG_SAVEAS_SUSPENDED = "3.28";
export const VM_STATUS_SHUTDOWN_UNDEPLOY = "3.29";
export const VM_STATUS_EPILOG_UNDEPLOY = "3.30";
export const VM_STATUS_PROLOG_UNDEPLOY = "3.31";
export const VM_STATUS_BOOT_UNDEPLOY = "3.32";
export const VM_STATUS_HOTPLUG_PROLOG_POWEROFF = "3.33";
export const VM_STATUS_HOTPLUG_EPILOG_POWEROFF = "3.34";
export const VM_STATUS_BOOT_MIGRATE = "3.35";
export const VM_STATUS_BOOT_FAILURE = "3.36";
export const VM_STATUS_BOOT_MIGRATE_FAILURE = "3.37";
export const VM_STATUS_PROLOG_MIGRATE_FAILURE = "3.38";
export const VM_STATUS_PROLOG_FAILURE = "3.39";
export const VM_STATUS_EPILOG_FAILURE = "3.40";
export const VM_STATUS_EPILOG_STOP_FAILURE = "3.41";
export const VM_STATUS_EPILOG_UNDEPLOY_FAILURE = "3.42";
export const VM_STATUS_PROLOG_MIGRATE_POWEROFF = "3.43";
export const VM_STATUS_PROLOG_MIGRATE_POWEROFF_FAILURE = "3.44";
export const VM_STATUS_PROLOG_MIGRATE_SUSPEND = "3.45";
export const VM_STATUS_PROLOG_MIGRATE_SUSPEND_FAILURE = "3.46";
export const VM_STATUS_BOOT_UNDEPLOY_FAILURE = "3.47";
export const VM_STATUS_BOOT_STOPPED_FAILURE = "3.48";
export const VM_STATUS_PROLOG_RESUME_FAILURE = "3.49";
export const VM_STATUS_PROLOG_UNDEPLOY_FAILURE = "3.50";
export const VM_STATUS_DISK_SNAPSHOT_POWEROFF = "3.51";
export const VM_STATUS_DISK_SNAPSHOT_REVERT_POWEROFF = "3.52";
export const VM_STATUS_DISK_SNAPSHOT_DELETE_POWEROFF = "3.53";
export const VM_STATUS_DISK_SNAPSHOT_SUSPENDED = "3.54";
export const VM_STATUS_DISK_SNAPSHOT_REVERT_SUSPENDED = "3.55";
export const VM_STATUS_DISK_SNAPSHOT_DELETE_SUSPENDED = "3.56";
export const VM_STATUS_DISK_SNAPSHOT = "3.57";
export const VM_STATUS_DISK_SNAPSHOT_DELETE = "3.59";
export const VM_STATUS_PROLOG_MIGRATE_UNKNOWN = "3.60";
export const VM_STATUS_PROLOG_MIGRATE_UNKNOWN_FAILURE = "3.61";
export const VM_STATUS_DISK_RESIZE = "3.62";
export const VM_STATUS_DISK_RESIZE_POWEROFF = "3.63";
export const VM_STATUS_DISK_RESIZE_UNDEPLOYED = "3.64";
export const VM_STATUS_STOPPED = "4.0";
export const VM_STATUS_SUSPENDED = "5.0";
export const VM_STATUS_DONE = "6.0";
export const VM_STATUS_POWER_OFF = "8.0";
export const VM_STATUS_UNDEPLOYED = "9.0";
export const VM_STATUS_CLONING = "10.0";
export const VM_STATUS_CLONING_FAILURE = "11.0";

export const MAX_SNAPSHOT_COUNT = 5;
