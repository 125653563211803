const styles = (theme) => ({
  page: {
    ...theme.classes.page,
  },
  pageBox: {
    ...theme.classes.pageBox,
    padding: "15px",
  },
  marginBottom: {
    marginBottom: "15px",
  },
  relative: {
    position: "relative",
  },
});

export default styles;
