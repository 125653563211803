import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { formatSize } from "../../../../utils/size";
import { getSelectedColsSel } from "../../../../store/selectors/vmSelectors";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {number} cpu
 * @param {number} memory
 * @param {number} size
 * @return {JSX.Element}
 * @constructor
 */
const VmTotalsRow = ({ cpu, memory, size }) => {
  const classes = useStyles();
  const selectedCols = useSelector(getSelectedColsSel);

  const totals = { cpu, memory: formatSize(memory), size: formatSize(size) };

  return (
    <div data-testid="VmTotalsRow" className={classes.totalRow}>
      <div className={classes.dataRow}>
        <div className={classes.vmData}>
          <div className={classes.checkboxMask} />
          {selectedCols
            .filter((col) => col.selected)
            .map((col) => (
              <span key={col.name} className={cn(classes[col.name], classes.border)}>
                <span data-testid={`VmTotalsRow__${col.name}`} className={cn(classes.cellData, classes.totalCell)}>
                  {totals[col.name] || ""}
                </span>
              </span>
            ))}
        </div>
      </div>
      <div className={classes.controlMask} />
    </div>
  );
};

export default VmTotalsRow;
