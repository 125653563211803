import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../../ui-kit/Input";
import classnames from "classnames";
import { updateRegionAct, deleteRegionAct } from "../../../store/actions/regionActions";
import { Modal, Button, Tooltip } from "@skyportal/ui-kit";
import { maxInputLength } from "../../constants";
import useSafeAsyncCallback from "../../../hooks/useSafeAsyncCallback";

import styles from "./styles";

const useStyles = makeStyles(styles);

const RegionRow = ({ regionProps }) => {
  const { t } = useTranslation("regionPage");
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isEdit, setEdit] = useState(false);
  const [code, setCode] = useState(regionProps.code?.toString());
  const [name, setName] = useState(regionProps.name);
  const [isDeleteModalOpen, setDeleteModalState] = useState(false);
  const [isDCMenuOpen, setDCMenuState] = useState(false);

  const openEdit = useCallback(() => setEdit(!isEdit), [isEdit]);

  const save = useSafeAsyncCallback(async () => {
    const updatedRegion = {
      id: regionProps.id,
      code,
      name,
      datacenters: regionProps.datacenters,
    };
    await dispatch(updateRegionAct(updatedRegion));
    setEdit(false);
  });

  const changeRegionCode = (e) => {
    const isInputCorrect = e.target.value.length <= maxInputLength;
    if (isInputCorrect) {
      setCode(e.target.value);
    }
  };

  const changeRegionName = (e) => {
    setName(e.target.value);
  };

  const cancelChange = () => {
    setCode(regionProps.code);
    setName(regionProps.name);
    setEdit(!isEdit);
  };

  const deleteRegion = useSafeAsyncCallback(async () => {
    dispatch(deleteRegionAct(regionProps));
    setDeleteModalState(false);
  });
  const openConfirmDeleteModal = () => setDeleteModalState(true);

  const regLength = regionProps.datacenters ? regionProps.datacenters.length : 0;

  return !isEdit ? (
    <div key={regionProps.code} className={classes.row} data-testid="region_row_item">
      <div className={classes.cell}>{code}</div>
      <div className={classes.cell}>{name}</div>
      <div className={classes.cell} onMouseOver={() => setDCMenuState(true)} onMouseLeave={() => setDCMenuState(false)}>
        <div className={classes.DCLinkShell}>
          <span>{`${regLength}`}</span>
          {isDCMenuOpen && regLength > 0 ? (
            <ul className={classes.DCLinkMenu}>
              {regionProps.datacenters.map((dc) => (
                <li key={dc._id} className={classes.DCMenuLine}>
                  <div className={classes.linkListAligner}>
                    <div className={classes.dcNameShell}>{`${dc.name} `}</div>
                    <div>
                      <a target="_blank" rel="noreferrer" href={`${dc.api.url}`}>
                        {`${dc.api.url}`}
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
      <div className={classes.controls}>
        <div className={classes.buttonShell}>
          <Tooltip title={t("iaasCommon:Edit")}>
            <Button data-testid="editRegionBtn" type="tertiary" leftIcon="pen" onClick={openEdit} />
          </Tooltip>
          <Tooltip title={t("iaasCommon:Delete")}>
            <Button
              data-testid="deleteRegionBtn"
              type="tertiary"
              leftIcon="trash-bin"
              onClick={openConfirmDeleteModal}
            />
          </Tooltip>
          <Modal
            data-testid="region_delete_modal"
            open={isDeleteModalOpen}
            onClose={() => setDeleteModalState(false)}
            title={t("deleteRegionModal.title")}
            controls={
              <>
                <Button type="secondary" onClick={() => setDeleteModalState(false)}>
                  {t("iaasCommon:No")}
                </Button>
                <Button onClick={deleteRegion}>{t("iaasCommon:Yes")}</Button>
              </>
            }
          >
            {t("deleteRegionModal.confirmQuestion")}
          </Modal>
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.row} key={regionProps.code}>
      <div className={classes.cell}>
        <Input
          className={classes.cellInputEdit}
          onChange={changeRegionCode}
          value={code}
          placeholder={t("regionCodePlaceholder")}
        />
      </div>
      <div className={classes.cell}>
        <Input
          className={classes.cellInputEdit}
          onChange={changeRegionName}
          value={name}
          placeholder={t("regionNamePlaceholder")}
        />
      </div>
      <div className={classes.cell}>{regionProps.datacenters.length}</div>
      <div className={classnames(classes.cell, classes.controls)}>
        <div className={classes.buttonsShell}>
          <Button data-testid="cancelRegionBtn" type="secondary" onClick={cancelChange}>
            <span>{t("iaasCommon:Cancel")}</span>
          </Button>
          <Button data-testid="saveRegionBtn" onClick={save} className={classes.saveBtn}>
            <span>{t("iaasCommon:Save")}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegionRow;
