const btn = {
  width: "80px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "$4f4f4f",
  },
};

const styles = (theme) => ({
  cell: {
    ...theme.classes.table.cell,
    height: "50px",
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    boxSizing: "border-box",
    borderBottom: `none`,

    "&:first-child": {
      width: "200px",
      flexGrow: 2,
    },
    "&:nth-child(2)": {
      width: "100px",
    },
    "&:nth-child(3)": {
      width: "150px",
    },
    "&:nth-child(4)": {
      width: "100px",
    },
    "&:nth-child(5)": {
      width: "100px",
    },
    "&:nth-child(6)": {
      width: "100px",
    },
    "&:nth-child(7)": {
      width: "150px",
    },
    "&:last-child": {
      width: "100px",
      flexGrow: 0,
    },
  },
  cellShell: {
    ...theme.classes.table.cellShell,
    marginLeft: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  dcRowControls: {
    display: "flex",
    justifyContent: "flex-end",
  },
  modalBody: {
    position: "absolute",
    width: "360px",
    left: "50%",
    marginLeft: "-180px",
    top: "50%",
    marginTop: "-105px",
    height: "210px",
    backgroundColor: "white",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  modalHeader: {
    padding: "24px",
    fontSize: "28px",
    textAlign: "center",
  },
  card: {
    ...theme.classes.elems.card,
  },
  cardRow: theme.classes.elems.cardRow,
  cardFooter: {
    display: "flex",
    alignItems: "center",
    padding: "24px",
    justifyContent: "center",
  },
  cancelDeleteBtn: {
    ...btn,
    marginRight: "16px",
    backgroundColor: "black",
    color: "white",
  },
  confirmDeleteBtn: {
    ...btn,
    backgroundColor: "#d3d3d3",
  },
  row: {
    display: "flex",
    padding: "0 24px",
    borderBottom: `1px solid #f4f4f4`,
  },
  statusLabel: {
    display: "flex",
    padding: "5px",
    borderRadius: "3px",
    alignItems: "center",
    justifyContent: "center",
  },
  statusLabelText: {
    display: "inline-block",
    fontSize: theme.fonts.small,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  attached: {
    backgroundColor: theme.colors.lightGreen,
    color: theme.colors.darkGreen,
  },
  notAttached: {
    backgroundColor: theme.colors.lightRed,
    color: theme.colors.ruby,
  },
  fullWidthInput: {
    width: "100%",
  },
});

export default styles;
