import { maxImageSizeGB } from "../../../pages/constants";
import { PAGINATION_STEPS } from "../../../constants/pagination";
import { REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_UNCALLED } from "../../../constants/request";
import {
  getDatacenterIncludeMapFromStorage,
  getDiskListColumnsFromStorage,
  getDisksPerPageFromStorage,
} from "../../../utils/disks";
import {
  SET_IMAGES,
  CREATE_IMAGE,
  TOGGLE_IMAGE_MODAL_STATE,
  SET_IMAGE_FOR_EDIT,
  UPDATE_IMAGE,
  CLOSE_IMAGE_MODAL,
  DELETE_IMAGE,
  SET_MAX_IMAGE_SIZE,
  SET_IMAGE_CURRENT_PAGE,
  SET_SEARCH_TEXT_PAGE,
  SET_IMAGE_SORT_ORDER_PAGE,
  SET_IMAGE_SORT_FIELD_PAGE,
  RESET_IMAGE_SORT,
  SET_IMAGE_PER_PAGE,
  IMAGE_TIER_LIST_REQUEST_SUCCESS,
  IMAGE_TIER_LIST_REQUEST,
  SET_IMAGE_DC_INCLUDE,
  SET_USER_PROFILE,
  DISK_LIST_PENDING,
  CREATE_DISK_PENDING,
  UPDATE_DISK_PENDING,
  TOGGLE_IMAGE_COLUMN,
} from "../../types";

/**
 * @typedef {object} DiskStateModel
 * @property {string} _id - MongoDB
 * @property {number} id - OpenNebula
 * @property {string} name
 * @property {boolean} persistent
 * @property {object} attributes
 * @property {number} created
 * @property {number} group
 * @property {number} size
 * @property {object} snapshot
 * @property {{ID: string}[]} vms
 * @property {object|string|number|null} tier
 * @property {DataCenterApiModel} dcOwner
 *
 * @property {string} [__groupName] - field prepared by selectors
 */

/**
 * @typedef {"name"|"size"|"tier"|"group"|"created"|""} ImageSortField
 */

/**
 * @typedef {object} ImageTierModel
 * @property {string} _id
 * @property {string} name
 * @property {string} [desc]
 * @property {DataCenterApiModel} dcOwner
 */

/**
 * @typedef {object} ImagesState
 * @property {DiskStateModel[]} images
 * @property {boolean} isModalOpen
 * @property {ImageTierModel[]} tiers
 * @property {RequestStatus} tierListRequest
 * @property {number} maxImageSize
 * @property {DiskStateModel|null} imageSelectedForEdit
 * @property {null} lastImgActionMsg
 *
 * @property {number} currentPage - Disks page pagination number
 * @property {string} searchText - search input value
 * @property {"asc"|"desc"} sortOrder
 * @property {ImageSortField} sortField
 * @property {PaginationStep} perPage
 * @property {object} datacenterIncludeMap
 * @property {boolean} diskListPending
 * @property {boolean} diskCreatePending
 * @property {boolean} diskUpdatePending
 */

/**
 * @type {ImagesState}
 */
export const initialImagesState = {
  images: [],
  selectedCols: [
    { name: "name", label: "Name", selected: true },
    { name: "tier", label: "Tier", selected: true },
    { name: "group", label: "Group", selected: true },
    { name: "datacenter", label: "Datacenter", selected: false },
    { name: "size", label: "Size", selected: true },
    { name: "created", label: "Created", selected: true },
    { name: "vms", label: "AttachedTo", selected: true },
  ],
  tiers: [],
  tierListRequest: REQUEST_UNCALLED,
  isModalOpen: false,
  imageSelectedForEdit: null,
  maxImageSize: maxImageSizeGB,
  currentPage: 1,
  searchText: "",
  sortOrder: "asc",
  sortField: "",
  perPage: PAGINATION_STEPS[0],
  datacenterIncludeMap: {},
  diskListPending: false,
  diskCreatePending: false,
  diskUpdatePending: false,
};
/**
 *
 * @param {ImagesState} state
 * @param {object} action
 * @param {string} action.type
 * @param {any} action.payload
 * @return {ImagesState}
 */
const imagesReducer = (state = initialImagesState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_IMAGE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload,
      };
    }
    case SET_IMAGE_PER_PAGE: {
      return {
        ...state,
        perPage: payload,
        currentPage: 1,
      };
    }
    case SET_IMAGE_SORT_ORDER_PAGE: {
      return {
        ...state,
        sortOrder: payload,
      };
    }
    case SET_IMAGE_SORT_FIELD_PAGE: {
      return {
        ...state,
        sortField: payload,
        sortOrder: state.sortField === payload && state.sortOrder === "desc" ? "asc" : "desc",
      };
    }
    case SET_SEARCH_TEXT_PAGE: {
      return {
        ...state,
        searchText: payload,
        currentPage: 1,
      };
    }
    case RESET_IMAGE_SORT: {
      return {
        ...state,
        currentPage: 1,
        searchText: "",
        sortOrder: "asc",
        sortField: "",
      };
    }
    case SET_IMAGES: {
      return {
        ...state,
        images: payload,
        diskListPending: false,
      };
    }
    case DISK_LIST_PENDING: {
      return {
        ...state,
        diskListPending: action.payload,
      };
    }
    case IMAGE_TIER_LIST_REQUEST: {
      return {
        ...state,
        tierListRequest: REQUEST_PENDING,
      };
    }
    case IMAGE_TIER_LIST_REQUEST_SUCCESS: {
      return {
        ...state,
        tiers: payload,
        tierListRequest: REQUEST_SUCCESS,
      };
    }
    case CREATE_IMAGE: {
      return {
        ...state,
        images: [payload, ...state.images],
        diskCreatePending: false,
      };
    }
    case CREATE_DISK_PENDING: {
      return {
        ...state,
        diskCreatePending: action.payload,
      };
    }
    case UPDATE_IMAGE: {
      return {
        ...state,
        images: state.images.map((img) => (img._id === payload._id ? { ...payload } : img)),
        imageSelectedForEdit: null,
        diskUpdatePending: false,
      };
    }
    case UPDATE_DISK_PENDING: {
      return {
        ...state,
        diskUpdatePending: action.payload,
      };
    }
    case TOGGLE_IMAGE_MODAL_STATE: {
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      };
    }
    case SET_IMAGE_FOR_EDIT: {
      return {
        ...state,
        imageSelectedForEdit: payload,
        isModalOpen: true,
      };
    }
    case CLOSE_IMAGE_MODAL: {
      return {
        ...state,
        imageSelectedForEdit: null,
        isModalOpen: false,
      };
    }
    case DELETE_IMAGE: {
      return {
        ...state,
        images: state.images.filter((i) => i._id !== payload),
      };
    }
    case SET_MAX_IMAGE_SIZE: {
      return {
        ...state,
        maxImageSize: payload,
      };
    }
    case SET_IMAGE_DC_INCLUDE: {
      return {
        ...state,
        datacenterIncludeMap: action.payload,
      };
    }
    case SET_USER_PROFILE: {
      return {
        ...state,
        selectedCols: action.payload ? getDiskListColumnsFromStorage(action.payload.id) : state.selectedCols,
        datacenterIncludeMap: action.payload
          ? getDatacenterIncludeMapFromStorage(action.payload.id)
          : state.datacenterIncludeMap,
        perPage: action.payload ? getDisksPerPageFromStorage(action.payload.id) : state.perPage,
      };
    }
    case TOGGLE_IMAGE_COLUMN: {
      return {
        ...state,
        selectedCols: action.payload,
      };
    }
    default:
      return state;
  }
};

export default imagesReducer;
