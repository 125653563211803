import { REQUEST_FAILED, REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_UNCALLED } from "../../../constants/request";
import {
  ALL_METRICS_REQUEST,
  ALL_METRICS_REQUEST_FAILED,
  ALL_METRICS_REQUEST_SUCCESS,
  CONSUMPTION_REQUEST,
  CONSUMPTION_REQUEST_FAILED,
  CONSUMPTION_REQUEST_SUCCESS,
  DASHBOARD_EVENT_TABLE_SET_SORT_FIELD,
  STORED_REQUEST,
  STORED_REQUEST_FAILED,
  STORED_REQUEST_SUCCESS,
} from "../../types";

/**
 * @typedef {object} DashboardState
 * @property {UsageStatistic} usageStatistic
 * @property {RequestStatus} allMetricsRequest
 * @property {ConsumptionMetric[]} consumption
 * @property {RequestStatus} consumptionRequest
 * @property {StoredMetric[]} stored
 * @property {RequestStatus} storedRequest
 * @property {TopMemoryVM[]} topMemory
 * @property {TopCpuVM[]} topCpu
 * @property {TopUsedDataVM[]} topUsedData
 * @property {VmTotals} vmTotals
 * @property {ActionHistory[]} actionHistory
 * @property {EventTableSubState} eventTable
 */

/**
 * @type {VmTotals}
 */
export const initVmTotal = {
  pending: 0,
  running: 0,
  stopped: 0,
  total: 0,
};
/**
 * @type {UsageStatistic}
 */
export const initUsageStatistic = {
  cpuTotal: 0,
  vcpuTotal: 0,
  dataStored: 0,
  memoryTotal: 0,
  vmTotal: 0,
};

/**
 * @typedef {"asc"|"desc"} SortOrderEnum
 */

/**
 * @typedef {object} EventTableSubState
 * @property {number} perPage
 * @property {number} currentPage
 * @property {SortOrderEnum} sortOrder
 * @property {EventHistorySortFields} sortField
 */

/**
 * @type {EventTableSubState}
 */
const initEventTable = {
  perPage: 50,
  sortField: "stime",
  sortOrder: "desc",
  currentPage: 1,
};

/**
 * @type {DashboardState}
 */
export const initState = {
  allMetricsRequest: REQUEST_UNCALLED,
  consumption: [],
  consumptionRequest: REQUEST_UNCALLED,
  stored: [],
  storedRequest: REQUEST_UNCALLED,
  topMemory: [],
  topCpu: [],
  topUsedData: [],
  vmTotals: initVmTotal,
  usageStatistic: initUsageStatistic,
  actionHistory: [],
  eventTable: initEventTable,
};

/**
 * @param {DashboardState} [state]
 * @param {object} action
 * @return {DashboardState}
 */
export default function dashboardReducer(state = initState, action = {}) {
  switch (action.type) {
    case ALL_METRICS_REQUEST: {
      return {
        ...state,
        allMetricsRequest: REQUEST_PENDING,
      };
    }
    case ALL_METRICS_REQUEST_FAILED: {
      return {
        ...state,
        allMetricsRequest: REQUEST_FAILED,
      };
    }
    case ALL_METRICS_REQUEST_SUCCESS: {
      return {
        ...state,
        consumption: action.payload.consumption,
        stored: action.payload.stored,
        topCpu: action.payload.topCpu,
        topMemory: action.payload.topMemory,
        vmTotals: action.payload.vmTotals,
        topUsedData: action.payload.topUsedData,
        usageStatistic: action.payload.usageStatistic,
        actionHistory: action.payload.actionHistory,
        allMetricsRequest: REQUEST_SUCCESS,
      };
    }
    case CONSUMPTION_REQUEST: {
      return {
        ...state,
        consumptionRequest: REQUEST_PENDING,
      };
    }
    case CONSUMPTION_REQUEST_SUCCESS: {
      return {
        ...state,
        consumption: action.payload,
        consumptionRequest: REQUEST_SUCCESS,
      };
    }
    case CONSUMPTION_REQUEST_FAILED: {
      return {
        ...state,
        consumptionRequest: REQUEST_FAILED,
      };
    }
    case STORED_REQUEST: {
      return {
        ...state,
        storedRequest: REQUEST_PENDING,
      };
    }
    case STORED_REQUEST_SUCCESS: {
      return {
        ...state,
        stored: action.payload,
        storedRequest: REQUEST_SUCCESS,
      };
    }
    case STORED_REQUEST_FAILED: {
      return {
        ...state,
        storedRequest: REQUEST_FAILED,
      };
    }
    case DASHBOARD_EVENT_TABLE_SET_SORT_FIELD: {
      return {
        ...state,
        eventTable: {
          ...state.eventTable,
          sortField: action.payload,
          sortOrder:
            state.eventTable.sortField === action.payload && state.eventTable.sortOrder === "desc" ? "asc" : "desc",
        },
      };
    }
    default:
      return state;
  }
}
