import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import PaginationPerSizeSelect from "../../../components/PaginationPerSizeSelect";
import { getTableImages, imageSel } from "../../../store/selectors/imgSelectors";
import { getUserProfile } from "store/selectors/userSelectors";
import {
  setSearchTextAC,
  setCurrentPageAC,
  resetImageSortAC,
  setPerPageValue,
} from "../../../store/actions/imagesActions";
import { getVMsSel } from "../../../store/selectors/vmSelectors";
import { Spinner } from "@skyportal/ui-kit";
import ImageSorterBar from "../../shareableComponents/ImageSorterBar";
import DiskRow from "./DiskRow/DiskRow";
import DisksTableHead from "./DisksTableHead";

import styles from "./styles";

const useStyles = makeStyles(styles);

const DisksContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const vms = useSelector(getVMsSel);
  const userProfile = useSelector(getUserProfile);
  const { currentPage, perPage, diskListPending, selectedCols } = useSelector(imageSel);
  const { pageCount, list } = useSelector(getTableImages(userProfile));

  const changeFilterValue = (e) => {
    dispatch(setSearchTextAC(e.target.value));
  };
  const onChangePage = (_, page) => dispatch(setCurrentPageAC(page));
  const handlePerPageChange = (step) => dispatch(setPerPageValue(step));

  useEffect(
    () => () => {
      dispatch(resetImageSortAC());
    },
    [dispatch]
  );

  return (
    <div className={classes.page}>
      <Spinner show={diskListPending} />
      <DisksTableHead changeFilterValue={changeFilterValue} userProfile={userProfile} />
      <div aria-label="simple table" className={classes.tableContainer}>
        <div className={classes.table}>
          <ImageSorterBar selectedCols={selectedCols} />
          <div className={classes.tableBody}>
            {list.map((image) => (
              <DiskRow key={image._id} image={image} vms={vms} selectedCols={selectedCols} userProfile={userProfile} />
            ))}
          </div>
        </div>
      </div>
      <div className={classes.pagingContainer}>
        <PaginationPerSizeSelect value={perPage} onChange={handlePerPageChange} />
        <Pagination page={currentPage} count={pageCount} onChange={onChangePage} variant="outlined" shape="rounded" />
      </div>
    </div>
  );
};

export default DisksContent;
