import * as userService from "../../api/userService";
import { SET_USER_IS_LOGGED_IN } from "../types";
import * as userActions from "../actions/userActions";
import * as redirects from "../../utils/redirect";
import * as vmUtils from "../../utils/vm";

/**
 * @param {boolean} isLoggedIn
 * @return {{payload: boolean, type: string}}
 */
export const setUserIsLoggedInAc = (isLoggedIn) => ({
  type: SET_USER_IS_LOGGED_IN,
  payload: isLoggedIn,
});

export const checkUserAuthState = () => async (dispatch) => {
  try {
    await userService.checkAuth();
    dispatch(setUserIsLoggedInAc(true));
  } catch (error) {
    dispatch(setUserIsLoggedInAc(false));
    vmUtils.removeNewCreateVm();
    redirects.toAuthApp();
  }
};

export const getUserInfo = () => async (dispatch) => {
  try {
    const userProfile = await userService.getUserInfo();
    dispatch(userActions.setUserProfile(userProfile));
  } catch (error) {
    dispatch(userActions.setUserProfile({}));
  }
};
