import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { formStyles } from "../styles";

const useStyles = makeStyles(formStyles);

/**
 *
 * @param {string} title
 * @param {string} [subTitle]
 * @param {React.ReactNode} children
 * @return {JSX.Element}
 * @constructor
 */
export const FormFieldset = React.forwardRef(({ title, subTitle, children }, ref) => {
  const classes = useStyles();

  return (
    <Grid ref={ref} container className={classes.rowContainer}>
      <Grid item className={classes.rowLabelContainer}>
        <h2 className={classes.rowTitle}>{title}</h2>
        {subTitle && <span className={classes.rowTagline}>{subTitle}</span>}
      </Grid>
      <Grid item container className={classes.rowFieldContainer}>
        {children}
      </Grid>
    </Grid>
  );
});

export default FormFieldset;
