import * as React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import DatePicker from "../../../ui-kit/DatePicker";
import DatacenterFilter from "../../../components/DatacenterFilter";
import { getDateRange, getEventDcIncludeListMap } from "../../../store/selectors/eventHistorySelectors";
import { setDateRange, setEventHistoryDcIncludeMa } from "../../../store/actions/eventHistoryActions";
import { makeStrictDatePickerChangeHandler } from "../../../utils/dates";

import styles from "./styles";

const useStyles = makeStyles(styles);

const DateRangeSelectionBar = () => {
  const { t } = useTranslation("eventHistory");
  const classes = useStyles();
  const dispatch = useDispatch();
  const dcIncludeMap = useSelector(getEventDcIncludeListMap);
  /**
   * @type {EventHistoryDateRange}
   */
  const dateRange = useSelector(getDateRange);

  const handleEndDateChange = (end) => dispatch(setDateRange({ end }));
  const handleStartDateChange = (start) => dispatch(setDateRange({ start }));

  return (
    <>
      <div className={classes.container} data-testid="DateRangeSelectionBar">
        <div data-testid="start_date_control" className={cn(classes.controlWrap, classes.datePickerWrap)}>
          <div className={classes.label}>{t("iaasCommon:Start")}</div>
          <DatePicker value={dateRange.start} onDayChange={makeStrictDatePickerChangeHandler(handleStartDateChange)} />
        </div>
        <Grid data-testid="end_date_control" item className={cn(classes.controlWrap, classes.datePickerWrap)}>
          <div className={classes.label}>{t("iaasCommon:End")}</div>
          <DatePicker value={dateRange.end} onDayChange={makeStrictDatePickerChangeHandler(handleEndDateChange)} />
        </Grid>
        <Grid item className={cn(classes.controlWrap, classes.datacenterFilter)}>
          <div className={classes.label}>{t("datacenterFilter")}</div>
          <DatacenterFilter onChangeAction={setEventHistoryDcIncludeMa} dcIncludeMap={dcIncludeMap} />
        </Grid>
      </div>
    </>
  );
};

export default DateRangeSelectionBar;
