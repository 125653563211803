import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Button } from "@skyportal/ui-kit";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";
import { dcSel } from "../../../store/selectors/dcSelectors";
import { getGroups } from "../../../store/selectors/groupSelectors";
import { getTemplatesAct } from "../../../store/actions/ecVMPageActions";
import { getAllImagesAct } from "../../../store/actions/imagesActions";
import { getAllGroupsAct } from "../../../store/actions/groupActions";
import { saveVmAct } from "../../../store/actions/virtualMachinesActions";
import { notificationErrorAC } from "../../../store/actions/notificationActions";
import { getNetworkListRequestAct } from "../../../store/actions/networkActions";
import { attributeParser } from "./constantsNHelpers";
import PageHeader from "../../../pages/shareableComponents/PageHeader";
import { ecVMSel } from "../../../store/selectors/ecVMSelectors";
import FormFieldset from "./controls/FormFieldset";
import Control from "./controls/Control";
import TemplateSelect from "./controls/TemplateSelect";
import GroupSelect from "./controls/GroupSelect";
import NetworkSelect from "./controls/NetworkSelect";
import NameControl from "./controls/NameControl";
import DatacenterSelect from "./controls/DatacenterSelect";
import useVmFormState from "./useVmFormState";
import CpuFieldset from "./controls/CPUFieldset";
import MemoryFieldset from "./controls/MemoryFieldset";
import ControlForInputTypes from "./ControlForInputTypes";
import { composeCreateVmPayload, getPrefixValue, validateVmPayload, validationI18nMapper } from "./utils";
import { formStyles } from "./styles";
import { filterListByDcId } from "utils/groups";
import { pricelistSel } from "store/selectors/pricelistSelectors";
import { REQUEST_PENDING, REQUEST_SUCCESS } from "constants/request";
import { getContractPricelistAct, resetPricelistDataAC } from "store/actions/pricelistActions";
import PricelistModal from "pages/shareableComponents/PricelistModal";

const useStyles = makeStyles(formStyles);

export const VmCreatePage = () => {
  const classes = useStyles();
  const { t } = useTranslation("vmPage");
  const dispatch = useDispatch();
  const history = useHistory();
  const { datacenters } = useSelector(dcSel);
  const groups = useSelector(getGroups);
  const [state, _dispatch, actions] = useVmFormState();
  const {
    datacenterId,
    template,
    name,
    groupId,
    attributes,
    vcPolicy,
    networkId,
    hostname,
    memory,
    memoryAlt,
    vCPU,
    vCPUAlt,
    password,
  } = state;
  const { createPending } = useSelector(ecVMSel);
  const pricelist = useSelector(pricelistSel);
  const templateId = template?._id || "";
  const groupsFromRelatedDc = useMemo(() => filterListByDcId(groups, datacenterId), [groups, datacenterId]);
  const prefixValue = useMemo(() => getPrefixValue(groupsFromRelatedDc, groupId), [groupsFromRelatedDc, groupId]);
  const parsedAttributes = {};
  for (let attribute in state.template?.attributes) {
    if (state.template.attributes[attribute]) {
      parsedAttributes[attribute] = attributeParser(state.template.attributes[attribute]);
    }
  }

  const handleDatacenterChange = useCallback((_id) => _dispatch(actions.setDatacenterId(_id)), [_dispatch]);
  const handleNameChange = useCallback((_name) => _dispatch(actions.setName(_name)), [_dispatch]);
  const handleGroupChange = useCallback((_groupId) => _dispatch(actions.setGroupId(_groupId)), [_dispatch]);
  const handleNetworkChange = useCallback((_networkId) => _dispatch(actions.setNetworkId(_networkId)), [_dispatch]);
  const handleTemplateChange = useCallback((_template) => _dispatch(actions.setTemplate(_template)), [_dispatch]);
  const handleAttributeChange = useCallback(
    (_name, _value) => _dispatch(actions.setAttribute({ _name, _value })),
    [_dispatch]
  );
  const handleVCpuChange = useCallback((_vCpu) => _dispatch(actions.setVCpu(_vCpu)), [_dispatch]);
  const handleMemoryChange = useCallback((_memory) => _dispatch(actions.setMemory(_memory)), [_dispatch]);
  const handleVCpuAltChange = useCallback((_vCpu) => _dispatch(actions.setVCpuAlt(_vCpu)), [_dispatch]);
  const handleMemoryAltChange = useCallback((_memory) => _dispatch(actions.setMemoryAlt(_memory)), [_dispatch]);
  const handlePricelistBtnClick = () =>
    dispatch(getContractPricelistAct(groupsFromRelatedDc.find((group) => group.id === groupId)?.agreement));

  const handleCancelBtnClick = () => history.push("/infra/vm");
  const handleSubmit = async () => {
    const payload = composeCreateVmPayload({
      template,
      networkId,
      hostname,
      vcPolicy,
      memory,
      vCPU,
      groupId,
      name,
      password,
      attributes,
    });
    const {
      errors: [topErrorKey],
      fieldsWithError: [topErrorFieldName],
    } = validateVmPayload(payload, template, parsedAttributes);

    if (topErrorKey) {
      dispatch(notificationErrorAC({ message: t(...validationI18nMapper(topErrorKey, topErrorFieldName)) }));
      return;
    }

    try {
      await dispatch(saveVmAct(payload, template.dcOwner));
      history.push("/infra/vm");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (datacenters.length) {
      dispatch(getTemplatesAct(datacenters));
      dispatch(getAllImagesAct());
      dispatch(getAllGroupsAct());
      dispatch(getNetworkListRequestAct());
    }
  }, [datacenters, dispatch]);

  useEffect(
    () => () => {
      if (pricelist.requestStatus === REQUEST_SUCCESS) {
        return dispatch(resetPricelistDataAC());
      }
    },
    [dispatch, pricelist.requestStatus]
  );

  return (
    <div className={classes.page}>
      <PageHeader title={t("createOrEdit.createTitle")} backPath="/infra/vm" />
      <Paper className={classes.formContainer} elevation={0}>
        <Spinner show={createPending} />
        <FormFieldset
          title={t("createOrEdit.fieldset.datacenter")}
          subTitle={t("createOrEdit.fieldset.datacenterHint")}
        >
          <Control label={t("createOrEdit.fields.datacenter.label")}>
            <DatacenterSelect
              id="datacenterSelect"
              value={datacenterId}
              onChange={handleDatacenterChange}
              autoSelect
              datacenters={datacenters}
              groups={groups}
            />
          </Control>
        </FormFieldset>
        <FormFieldset title={t("createOrEdit.fieldset.template")} subTitle={t("createOrEdit.fieldset.templateHint")}>
          <Control label={t("createOrEdit.fields.template.label")}>
            <TemplateSelect
              datacenterId={datacenterId}
              disabled={!datacenterId}
              value={templateId}
              onChange={handleTemplateChange}
            />
          </Control>
        </FormFieldset>
        <FormFieldset title={t("createOrEdit.fieldset.general")} subTitle={t("createOrEdit.fieldset.generalHint")}>
          <Control label={t("createOrEdit.fields.groups.label")}>
            <GroupSelect
              id="groups_select"
              value={groupId}
              onChange={handleGroupChange}
              disabled={!datacenterId}
              datacenterId={datacenterId}
              autoSelect
              featureUsedIn={FEATURES.IAAS_VIRTUAL_MACHINES}
              accessTypesToCheck={[USER_ACCESS.CREATE]}
            />
            <Button
              type="secondary"
              className={classes.pricelistBtn}
              onClick={handlePricelistBtnClick}
              loading={pricelist.requestStatus === REQUEST_PENDING}
              disabled={groupId === null}
            >
              {t("iaasCommon:priceList")}
            </Button>
          </Control>
          <Control label={t("createOrEdit.fields.name.label")}>
            <NameControl
              value={name}
              onChange={handleNameChange}
              disabled={!datacenterId}
              placeholder={t("createOrEdit.fields.name.placeholder")}
              prefixValue={prefixValue}
              mode="VMs"
            />
          </Control>
        </FormFieldset>
        <CpuFieldset
          value={vCPU}
          altValue={vCPUAlt}
          onChange={handleVCpuChange}
          onAltChange={handleVCpuAltChange}
          template={template}
        />
        <MemoryFieldset
          value={memory}
          altValue={memoryAlt}
          template={template}
          onChange={handleMemoryChange}
          onAltChange={handleMemoryAltChange}
        />
        <FormFieldset title={t("createOrEdit.fieldset.network")} subTitle={t("createOrEdit.fieldset.networkHint")}>
          <Control label={t("createOrEdit.fields.vNet.label")}>
            <NetworkSelect
              id="network_select"
              value={networkId}
              datacenterId={datacenterId}
              onChange={handleNetworkChange}
              disabled={!datacenterId}
            />
          </Control>
        </FormFieldset>
        {template?.attributeOrder?.length > 0 && (
          <FormFieldset title={t("createOrEdit.fieldset.options")} subTitle={t("createOrEdit.fieldset.optionsHint")}>
            {template.attributeOrder
              .replace(" ", "")
              .split(",")
              .filter((attributeName) => parsedAttributes[attributeName])
              .map((attributeName) => (
                <Control
                  label={parsedAttributes[attributeName].type !== "boolean" && parsedAttributes[attributeName].title}
                >
                  <ControlForInputTypes
                    attribute={[attributeName, parsedAttributes[attributeName]]}
                    value={attributes[attributeName] || ""}
                    className={classes.inputField}
                    onChange={handleAttributeChange}
                  />
                </Control>
              ))}
          </FormFieldset>
        )}
        <div className={classes.buttonRowContainer}>
          <Button type="secondary" onClick={handleCancelBtnClick}>
            {t("iaasCommon:Cancel")}
          </Button>
          <Button disabled={!template || !datacenterId} onClick={handleSubmit}>
            {t("iaasCommon:Save")}
          </Button>
        </div>
      </Paper>
      <PricelistModal />
    </div>
  );
};

export default VmCreatePage;
