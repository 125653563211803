import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { formatDate } from "../../../../utils/dates";
import { getVmStatusLabel } from "../../../../utils/vm";
import { formatSize } from "../../../../utils/size";
import DiskCell from "./DiskCell";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {SummaryReportApiModel|DetailedReportApiModel} data
 * @param {[]}columns
 * @return {JSX.Element}
 * @constructor
 */
const ReportsTableRow = ({ data, columns }) => {
  const classes = useStyles();

  return (
    <div className={classes.row} data-testid="report_summary_table__row">
      {columns.map((column) => (
        <div key={column.key} className={classes.cell}>
          <div className={classes.cellShell}>
            {column.key === "_id" && <span>{formatDate(data._id)}</span>}
            {column.key === "status" && <span>{getVmStatusLabel(data)}</span>}
            {column.key === "image_size" && (
              <DiskCell tierClassName={classes.tierClassName} record={data} keyIndex="image_size" />
            )}
            {column.key === "image_count" && (
              <DiskCell tierClassName={classes.tierClassName} record={data} keyIndex="image_count" />
            )}
            {column.key === "memory_size" && <span>{formatSize(data.memory_size, true)}</span>}
            {!["_id", "status", "image_size", "memory_size", "image_count"].includes(column.key) && (
              <span>{data[column.key]}</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReportsTableRow;
