import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Icon, Spinner, Tooltip } from "@skyportal/ui-kit";
import { imageSel } from "../../../store/selectors/imgSelectors";
import { getGroups } from "../../../store/selectors/groupSelectors";
import { getUserProfile } from "store/selectors/userSelectors";
import Input from "../../../ui-kit/Input";
import Slider from "@material-ui/core/Slider";
import { setImageSizeAct } from "../../../store/actions/imagesActions";
import { checkIsStringIsIntegerOnly } from "../../../utils/validation";
import { makeGetVmsByDcId } from "../../../store/selectors/vmSelectors";
import { isAttachedDiskToVmAllowed } from "../../helpers";
import { composeVmOptions } from "../../../utils/vm";
import { composeGroupOptions, filterListByDcId, getDcIdFromEntity } from "../../../utils/groups";
import { composeTierOption } from "../../../utils/disks";
import Autocomplete from "../../../ui-kit/Autocomplete";
import Dropdown from "../../../ui-kit/Dropdown";
import useDatacenterOptions from "../../../hooks/useDatacenterOptions";
import DatacenterFormBadge from "../../../components/DatacenterFormBadge";
import { hasContractAccessToFeature, CONTRACT_TYPES, FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";
import { NameControl } from "pages/VMs/VMs_create_edit/controls/NameControl";
import { getPrefixValue } from "pages/VMs/VMs_create_edit/utils";
import IconTooltip from "components/IconTooltip";
import { gb2Mb } from "utils/size";
import { getDatacentersWithGroups } from "utils/datacenter";
import { dcSel } from "store/selectors/dcSelectors";

import styles from "./styles";

const useStyles = makeStyles(styles);

const limit = 1024 * 1000000;

const DisksModalBody = ({
  newImageName,
  setNewImageName,
  imageGroup,
  setImageGroup,
  imageTier,
  setImageTier,
  size,
  setSize,
  vm,
  setVm = () => {},
  inputSizeValue,
  setInputSizeValue,
  onCreate,
  onUpdate,
  closeImgModal,
  currentVm,
  hasImageNameMatchingPrefix = true,
}) => {
  const { t } = useTranslation("imagesPage");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { imageSelectedForEdit, tiers, maxImageSize, diskUpdatePending, diskCreatePending } = useSelector(imageSel);
  const groups = useSelector(getGroups);
  const userProfile = useSelector(getUserProfile);
  const { datacenters } = useSelector(dcSel);
  const dcWithGroups = getDatacentersWithGroups(datacenters, groups);
  const dcOptions = useDatacenterOptions(dcWithGroups);
  const [sliderValue, setSliderValue] = useState(size);
  const minImageSize = useRef(size).current;
  const maxImageSizeInMb = gb2Mb(maxImageSize);
  const dcOwner = useMemo(
    () => (currentVm ? currentVm.dcOwner : groups.find((item) => item._id === imageGroup)?.dcOwner),
    [groups, imageGroup, currentVm]
  );
  const isImageSizeSliderEnabled = useMemo(
    () =>
      Boolean(imageSelectedForEdit && imageSelectedForEdit.vms.length && minImageSize < maxImageSize) ||
      !imageSelectedForEdit,
    [imageSelectedForEdit, maxImageSize, minImageSize]
  );

  const [datacenterId, setDatacenterId] = useState(dcOwner?.id || "");
  const datacenterGroups = useMemo(
    () => groups.filter((group) => getDcIdFromEntity(group) === datacenterId),
    [datacenterId, groups]
  );
  const groupsFromDcWithAccess = useMemo(
    () =>
      datacenterGroups.filter(
        (groupItem) =>
          hasContractAccessToFeature(userProfile, groupItem.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_DISKS, [
            USER_ACCESS.CREATE,
          ]) || groupItem.agreement === null
      ),
    [datacenterGroups, userProfile]
  );
  const groupOptions = useMemo(() => composeGroupOptions(groupsFromDcWithAccess), [groupsFromDcWithAccess]);
  const vms = useSelector(makeGetVmsByDcId(datacenterId));
  const vmOptions = useMemo(() => composeVmOptions(vms.filter(isAttachedDiskToVmAllowed)), [vms]);
  const datacenterTiers = useMemo(() => filterListByDcId(tiers, datacenterId), [tiers, datacenterId]);
  const tierOptions = useMemo(() => composeTierOption(datacenterTiers), [datacenterTiers]);
  const isCreationAllowed = Boolean(newImageName && imageGroup && datacenterId);

  const onSlide = (_, value) => setSliderValue(value);
  const handleVmAutocompleteChange = ({ value }) => setVm(value);
  const handleGroupSelectChange = useCallback(
    ({ value }) => {
      setImageGroup(value);
      setVm("");
      setImageTier("");
    },
    [setImageGroup, setImageTier, setVm]
  );
  const handleTierSelectChange = ({ value }) => setImageTier(value);
  const handleSizeInputChange = (event) => setInputSizeValue(event.target.value);
  const handleDatacenterChange = (event) => {
    setDatacenterId(event.target.value);
    setImageGroup("");
    setVm("");
    setImageTier("");
  };

  const handleChangeCommitted = (_, value) => {
    setSize(value);
    setInputSizeValue(value.toString());
  };

  useEffect(() => {
    if (dcOptions.length === 1) {
      setDatacenterId(dcOptions[0].value);
    }
  }, [dcOptions]);

  useEffect(() => {
    setSliderValue(size);
  }, [size]);

  useEffect(() => {
    if (groupOptions.length === 1 && !imageSelectedForEdit) {
      handleGroupSelectChange(groupOptions[0]);
    }
  }, [groupOptions, handleGroupSelectChange, imageSelectedForEdit]);

  const enhanceLimits = () => {
    if (inputSizeValue >= limit) {
      setSize(limit);
      setInputSizeValue(limit.toString());
      return;
    }

    if (inputSizeValue === maxImageSize) {
      setSize(inputSizeValue);
      setInputSizeValue(inputSizeValue.toString());
      dispatch(setImageSizeAct(maxImageSize * 2));
    }
  };

  const onInputSizeBlur = (event) => {
    const { value } = event.target;
    if (checkIsStringIsIntegerOnly(value)) {
      const digitalized = parseInt(value, 10);

      if (digitalized >= maxImageSize) {
        setSize(maxImageSize);
        setInputSizeValue(maxImageSize.toString());
      } else if (digitalized <= minImageSize) {
        setSize(minImageSize);
        setInputSizeValue(minImageSize.toString());
      } else {
        setSize(digitalized);
        setInputSizeValue(digitalized.toString());
      }
    }
  };

  const marks = [
    {
      value: minImageSize,
      label: minImageSize.toString(),
    },
    {
      value: maxImageSize,
      label: maxImageSize.toString(),
    },
  ];

  useEffect(() => {
    document.addEventListener("mouseup", enhanceLimits);

    return () => {
      document.removeEventListener("mouseup", enhanceLimits);
    };
  }, [enhanceLimits]);

  return (
    <div className={classes.modalBody} data-testid="DiskModal">
      <Spinner show={diskCreatePending || diskUpdatePending} />
      <div className={classes.modalHeader}>
        {imageSelectedForEdit ? t("editOrCreateModal.editTitle") : t("editOrCreateModal.createTitle")}
      </div>
      <div className={classes.modalCard}>
        {imageSelectedForEdit || currentVm ? (
          <h3 className={classes.datacenterLabel}>
            <DatacenterFormBadge>{dcOwner?.name}</DatacenterFormBadge>
          </h3>
        ) : (
          <div className={classes.cardRow}>
            <div className={classes.cardRowLabel}>{t("labels.datacenter")}</div>
            <Dropdown
              id="datacenterSelect"
              onChange={handleDatacenterChange}
              value={datacenterId}
              options={dcOptions}
              disabled={Boolean(currentVm) || dcOptions.length <= 1}
            />
          </div>
        )}

        <div className={cn(classes.cardRow, classes.box50)}>
          <div className={classes.cardRowLabel}>{t("labels.group")}</div>
          <Autocomplete
            testId="groupSelect"
            value={
              groupOptions.length === 1 && !imageSelectedForEdit
                ? groupOptions[0]
                : groupOptions.find((item) => item.value === imageGroup) || null
            }
            onChange={handleGroupSelectChange}
            disableClearable
            options={groupOptions}
            disabled={Boolean(!currentVm && !datacenterId)}
          />
        </div>
        <div className={cn(classes.cardRow, classes.box50)}>
          <div className={classes.cardRowLabel}>{t("labels.name")}</div>
          <NameControl
            placeholder={t("placeholders.name")}
            value={newImageName}
            onChange={setNewImageName}
            prefixValue={getPrefixValue(datacenterGroups, imageGroup)}
            hasNameMatchingPrefix={hasImageNameMatchingPrefix}
            mode="disks"
          />
        </div>
        <div className={cn(classes.cardRow, classes.box50)}>
          <div className={classes.cardRowLabel}>{t("labels.tier")}</div>
          <Autocomplete
            testId="tierSelect"
            value={tierOptions.find((item) => item.value === imageTier) || null}
            onChange={handleTierSelectChange}
            options={tierOptions}
            disabled={!datacenterId}
          />
        </div>
        {!imageSelectedForEdit && !currentVm && (
          <div className={cn(classes.cardRow, classes.box50)}>
            <div className={classes.cardRowLabel}>{t("labels.attachVm")}</div>
            <Autocomplete
              testId="vmSelect"
              onChange={handleVmAutocompleteChange}
              value={vmOptions.find((item) => item.value === vm) || null}
              options={vmOptions}
              disabled={!datacenterId}
            />
          </div>
        )}

        <div className={classes.cardRow}>
          <div className={classes.cardRowLabel}>
            {t("labels.size")}
            {!isImageSizeSliderEnabled && (
              <IconTooltip className={classes.tooltip} title={t("editOrCreateModal.sizeSliderDisabled")} />
            )}
          </div>
          <div className={classes.imageModalSlider}>
            <div className={cn(classes.sliderBlock, { [classes.disabledSlider]: !isImageSizeSliderEnabled })}>
              {imageSelectedForEdit?.size >= maxImageSizeInMb ? (
                <span className={classes.unableToIncreaseSizeText}>{t("editOrCreateModal.unableToIncreaseSize")}</span>
              ) : (
                <Slider
                  onChange={onSlide}
                  onChangeCommitted={handleChangeCommitted}
                  step={1}
                  value={sliderValue}
                  min={minImageSize}
                  max={maxImageSize}
                  classes={{
                    track: isImageSizeSliderEnabled ? classes.blackBg : classes.disabledBg,
                    rail: isImageSizeSliderEnabled ? classes.rail : classes.disabledBg,
                    thumb: isImageSizeSliderEnabled ? classes.blackBg : classes.disabledBg,
                  }}
                  marks={marks}
                  disabled={!isImageSizeSliderEnabled}
                />
              )}
            </div>
            {imageSelectedForEdit && (
              <div className={classes.warningIconBlock}>
                <Tooltip placement="top" title={t("editOrCreateModal.resizeWarning")}>
                  <div>
                    <Icon icon="info-attention" color="popBrown" height={24} />
                  </div>
                </Tooltip>
              </div>
            )}
            <div className={classes.numberInputBlock}>
              <Input
                data-testid="DiskModal__size"
                onBlur={onInputSizeBlur}
                value={inputSizeValue}
                className={cn(classes.sizeTextInputField, { [classes.disabledSlider]: !isImageSizeSliderEnabled })}
                onChange={handleSizeInputChange}
                disabled={!isImageSizeSliderEnabled}
              />
              <span className={classes.sizeTextInputSpan}>{t("iaasCommon:Gb")}</span>
            </div>
          </div>
        </div>
        <div className={classes.cardFooter}>
          {imageSelectedForEdit ? (
            <Button onClick={onUpdate}>{t("iaasCommon:Save")}</Button>
          ) : (
            <Button data-testid="DiskModal__createBtn" onClick={onCreate} disabled={!isCreationAllowed}>
              {t("iaasCommon:Create")}
            </Button>
          )}
          <Button type="secondary" onClick={closeImgModal}>
            {t("iaasCommon:Cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DisksModalBody;
