const styles = (theme) => ({
  page: theme.classes.page,
  smallCard: { ...theme.classes.card.basic.body, ...theme.classes.card.small, position: "relative", height: "100%" },
  basicHeader: theme.classes.card.basic.header,
  headerLink: {
    color: "inherit",
    textDecoration: "none",
    display: "flex",
    alignItems: "flex-start",
    gap: "4px",

    "&:hover": {
      cursor: "pointer",
      opacity: "0.75",
    },
  },
  chartsContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "-8px",
    alignContent: "strength",
  },
  bigChartContainer: {
    boxSizing: "border-box",
    width: "66%",
    minWidth: "350px",
    maxWidth: "100%",
    flexGrow: 10,
    flexShrink: 0,
    padding: "8px",

    "@global": {
      ".MuiCardHeader-action": {
        alignSelf: "center",
      },
    },
  },

  smChartContainer: {
    boxSizing: "border-box",
    width: "33.33333%",
    minWidth: "275px",
    maxWidth: "100%",
    flexGrow: 1,
    flexShrink: 0,
    padding: "8px",

    "@global": {
      ".MuiCardHeader-action": {
        alignSelf: "center",
      },
    },
  },
  usageBoxContainer: {
    display: "flex",
    margin: "0 -8px",
    flexWrap: "wrap",
  },
  usageBoxItem: {
    width: "25%",
    boxSizing: "border-box",
    minWidth: "200px",
    padding: "0 8px",
    marginBottom: "16px",
    flexGrow: 1,
    flexShrink: 0,
  },
  headerTitle: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "24px",
  },
  subHeader: {
    fontSize: theme.fonts.smaller,
    lineHeight: "18px",
    color: theme.colors.inactiveGrey,
  },
  cardContent: {
    padding: 0,
  },
  cardContentPadding: {
    padding: "0 16px",
    height: "100%",
  },
  brownHeader: {
    ...theme.classes.card.basic.header,
    borderColor: theme.colors.lightBrown,
  },
  blueHeader: {
    ...theme.classes.card.basic.header,
    borderColor: theme.colors.skyBlueAlt,
  },
  title: {
    color: theme.colors.black,
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "24px",
  },
  inActiveTitle: {
    color: theme.colors.inactiveGrey,
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "24px",
  },
  marginTop: {
    marginTop: "20px",
  },
  dropdown: {
    borderRadius: "4px",
    padding: "4px 8px",
    height: "auto",
    fontFamily: `"Dustin Sans", serif`,
    fontSize: "12px",
    lineHeight: "16px",
    backgroundColor: theme.colors.lightBrown,
  },
  vmStatusItem: {
    position: "relative",
    minHeight: "30px",

    "&::after": {
      content: " ",
      display: "block",
      position: "absolute",
      left: "50%",
      bottom: 0,
      transform: "translateX(-50%)",
      width: "90%",
      height: "1px",
      backgroundColor: "#F4F4F4",
    },
  },
});

export default styles;
