/**
 * @typedef {"REQUEST_UNCALLED"|"REQUEST_PENDING"|"REQUEST_SUCCESS"|"REQUEST_FAILED"} RequestStatus
 */

/**
 * @type {RequestStatus}
 */
export const REQUEST_UNCALLED = "REQUEST_UNCALLED";
/**
 * @type {RequestStatus}
 */
export const REQUEST_PENDING = "REQUEST_PENDING";
/**
 * @type {RequestStatus}
 */
export const REQUEST_SUCCESS = "REQUEST_SUCCESS";
/**
 * @type {RequestStatus}
 */
export const REQUEST_FAILED = "REQUEST_FAILED";
