import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@skyportal/ui-kit";
import { iconResolver } from "../helpers";
import { setSortFieldAC } from "../../store/actions/imagesActions";
import { useDispatch, useSelector } from "react-redux";
import { imageSel } from "../../store/selectors/imgSelectors";

import styles from "./styles";

const useStyles = makeStyles(styles);

const ImageSorterCell = ({ field, label, className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sortField, sortOrder } = useSelector(imageSel);

  const handleClick = () => dispatch(setSortFieldAC(field));

  return (
    <div
      className={classnames(classes.cell, classes.sorterCell, className, classes[field] || classes.middle)}
      onClick={handleClick}
      {...rest}
    >
      <span className={classes.sorterCellIcon}>
        <Icon type="fontawesome" icon={iconResolver(sortOrder, sortField, field)} />
      </span>
      <span className={classes.sorterCellText}>{label}</span>
    </div>
  );
};

export default ImageSorterCell;
