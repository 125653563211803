import * as networkService from "../../api/networkService";
import {
  NETWORK_LIST_REQUEST_SUCCESS,
  SET_NETWORK_MODAL_CURRENT_PAGE,
  SET_NETWORK_MODAL_SEARCH_TEXT,
  SET_NETWORK_MODAL_SORT_FIELD,
} from "../types";
import { getDataCenterList } from "../selectors/dcSelectors";

export const networkListRequestSuccessAC = (payload) => ({
  type: NETWORK_LIST_REQUEST_SUCCESS,
  payload,
});

export const getNetworkListRequestAct = () => async (dispatch, getState) => {
  const dcList = getDataCenterList(getState());
  const promises = await Promise.allSettled(dcList.map((dc) => networkService.getList(dc.api.url)));

  const payload = promises.reduce((acc, result, index) => {
    if (result.status !== "rejected") {
      result.value.data.forEach((network) => {
        acc.push({
          ...network,
          dcOwner: dcList[index],
        });
      });
    }

    return acc;
  }, []);

  dispatch(networkListRequestSuccessAC(payload));
};

/**
 * @param {string} payload
 * @return {{payload: string, type: "SET_NETWORK_MODAL_SEARCH_TEXT"}}
 */
export const setModalNetworkSearchTextAC = (payload) => ({
  type: SET_NETWORK_MODAL_SEARCH_TEXT,
  payload,
});
/**
 * @param {string} payload
 * @return {{payload: string, type: "SET_NETWORK_MODAL_CURRENT_PAGE"}}
 */
export const setModalNetworkCurrentPageAC = (payload) => ({
  type: SET_NETWORK_MODAL_CURRENT_PAGE,
  payload,
});
/**
 * @param {string} payload
 * @return {{payload: string, type: "SET_NETWORK_MODAL_CURRENT_PAGE"}}
 */
export const setModalNetworkSortFieldAC = (payload) => ({
  type: SET_NETWORK_MODAL_SORT_FIELD,
  payload,
});
