import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../../../../ui-kit/DatePicker";
import { setDetailedDate } from "../../../../store/actions/reportsActions";
import { reportSel } from "../../../../store/selectors/reportSelectors";

const DetailedReportDatePicker = () => {
  const dispatch = useDispatch();
  const { selectionDetailedDate } = useSelector(reportSel);

  const handleDetailedDateChange = (date) => {
    dispatch(setDetailedDate(date));
  };

  return <DatePicker value={selectionDetailedDate} onDayChange={handleDetailedDateChange} />;
};

export default DetailedReportDatePicker;
