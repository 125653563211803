import i18n from "i18next";

/**
 * @typedef {object} BreadCrumbsItem
 * @property {string} - label
 * @property {string} - path
 * @property {string} - [faIcon]
 * @property {string} - [dustinIcon]
 */

/**
 * @typedef {object} BreadCrumbsConfigItem
 * @property {string} - label
 * @property {string} - path
 * @property {BreadCrumbsItem[]} - [crumbs]
 * @property {object} - match
 * @property {string} - match.path
 * @property {boolean} - match.exact
 * @property {string} - [faIcon]
 * @property {string} - [dustinIcon]
 */

export const getBreadCrumbsConfig = () => {
  const Infrastructure = {
    label: i18n.t("breadcrumbs:Infrastructure"),
    path: "/infra/overview",
  };

  const Vm = {
    label: i18n.t("breadcrumbs:VMs"),
    path: "/infra/vm",
  };

  const StartPage = {
    label: i18n.t("breadcrumbs:Start"),
    path: "/start",
  };

  /**
   * @type {BreadCrumbsConfigItem[]}
   */
  const breadcrumbsConfig = [
    {
      label: Infrastructure.label,
      crumbs: [StartPage],
      match: {
        path: Infrastructure.path,
        exact: true,
      },
    },
    {
      label: Vm.label,
      crumbs: [StartPage, Infrastructure],
      match: {
        path: Vm.path,
        exact: true,
      },
    },
    {
      label: i18n.t("breadcrumbs:Create VM"),
      crumbs: [StartPage, Infrastructure, Vm],
      match: {
        path: "/infra/vm/create",
      },
    },
    {
      label: i18n.t("breadcrumbs:Edit VM"),
      crumbs: [StartPage, Infrastructure, Vm],
      match: {
        path: "/infra/vm/edit/:id",
      },
    },
    {
      label: i18n.t("breadcrumbs:Vnc"),
      crumbs: [StartPage, Infrastructure, Vm],
      match: {
        path: "/infra/vm/vnc/:id",
      },
    },
    {
      label: i18n.t("breadcrumbs:Regions"),
      crumbs: [StartPage, Infrastructure],
      match: {
        path: "/infra/region",
      },
    },
    {
      label: i18n.t("breadcrumbs:Datacenters"),
      crumbs: [StartPage, Infrastructure],
      match: {
        path: "/infra/datacenter",
      },
    },
    {
      label: i18n.t("breadcrumbs:Disks"),
      crumbs: [StartPage, Infrastructure],
      match: {
        path: "/infra/disks",
      },
    },
    {
      label: i18n.t("breadcrumbs:Event History"),
      crumbs: [StartPage, Infrastructure],
      match: {
        path: "/infra/event-history",
      },
    },
    {
      label: i18n.t("breadcrumbs:Usage Report"),
      crumbs: [StartPage, Infrastructure],
      match: {
        path: "/infra/reports",
      },
    },
  ];

  return breadcrumbsConfig;
};

// export default breadcrumbsConfig;
