import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { Button, Modal, Tooltip } from "@skyportal/ui-kit";
import {
  hasUserAccessToFeature,
  hasContractAccessToFeature,
  CONTRACT_TYPES,
  FEATURES,
  USER_ACCESS,
} from "@skyportal/auth-web-client";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getAllGroupsAct } from "../../../store/actions/groupActions";
import { getNetworkListRequestAct } from "../../../store/actions/networkActions";
import { dcSel } from "../../../store/selectors/dcSelectors";
import { getVmListRequest, vmSel } from "../../../store/selectors/vmSelectors";
import { getGroups } from "../../../store/selectors/groupSelectors";
import { notificationErrorAC } from "../../../store/actions/notificationActions";
import { editVmAct, setAllVmsAct } from "../../../store/actions/virtualMachinesActions";
import { getAllImagesAct, toggleImageModalAct } from "../../../store/actions/imagesActions";
import { REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_UNCALLED } from "../../../constants/request";
import { isAttachedDiskToVmAllowed, isAttachedNetworkToVmAllowed } from "../../helpers";
import { getBadgeType, getVmStatusLabel } from "../../../utils/vm";
import { ecVMSel } from "../../../store/selectors/ecVMSelectors";
import { imageSel } from "../../../store/selectors/imgSelectors";
import { getUserProfile } from "store/selectors/userSelectors";
import { filterListByDcId } from "../../../utils/groups";
import { attributeParser } from "./constantsNHelpers";
import PageHeader from "../../../pages/shareableComponents/PageHeader";
import { Spinner } from "@skyportal/ui-kit";
import DatacenterFormBadge from "../../../components/DatacenterFormBadge";
import Badge from "../../../components/Badge";
import AttachDiskModal from "./disk/AttachDiskModal";
import useVmFormState from "./useVmFormState";
import VmDisksList from "./disk/VmDisksList";
import AttachNetworkModal from "./network/AttachNetworkModal";
import ListVmNetworks from "./network/ListVmNetworks";
import CreateDiskModal from "./CreateDiskModal";
import FormFieldset from "./controls/FormFieldset";
import Control from "./controls/Control";
import NameControl from "./controls/NameControl";
import GroupSelect from "./controls/GroupSelect";
import CpuFieldset from "./controls/CPUFieldset";
import MemoryFieldset from "./controls/MemoryFieldset";
import ControlForInputTypes from "./ControlForInputTypes";
import { composeEditVmPayload, validationI18nMapper, validateVmPayload, getPrefixValue } from "./utils";
import { formStyles } from "./styles";
import PricelistModal from "pages/shareableComponents/PricelistModal";
import { getContractPricelistAct, resetPricelistDataAC } from "store/actions/pricelistActions";
import { pricelistSel } from "store/selectors/pricelistSelectors";

const useStyles = makeStyles(formStyles);

export const VmEditPage = () => {
  const classes = useStyles();
  const { t } = useTranslation("vmPage");
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { vms } = useSelector(vmSel);
  const vmListRequest = useSelector(getVmListRequest);
  const { datacenters } = useSelector(dcSel);
  const { images, isModalOpen: isDiskModalOpen, imageSelectedForEdit } = useSelector(imageSel);
  const groups = useSelector(getGroups);
  const { selectedImages, updatePending } = useSelector(ecVMSel);
  const userProfile = useSelector(getUserProfile);
  const pricelist = useSelector(pricelistSel);
  const [isAttachDiskModalOpen, setAttachDiskModalState] = useState(false);
  const [isAttachNetworkModalOpen, setIsAttachNetworkModalOpen] = useState(false);
  const [isPreventModalOpen, setPreventModal] = useState(false);
  const [state, _dispatch, actions] = useVmFormState();
  const {
    attributes,
    currentVm,
    groupId,
    datacenterId,
    vCPU,
    vCPUAlt,
    memoryAlt,
    memory,
    name,
    hasVmNameMatchingPrefix,
  } = state;
  const groupsFromRelatedDc = useMemo(() => filterListByDcId(groups, datacenterId), [groups, datacenterId]);
  const prefixValue = useMemo(() => getPrefixValue(groupsFromRelatedDc, groupId), [groupsFromRelatedDc, groupId]);
  const parsedAttributes = {};
  for (let attribute in state.currentVm?.attributeInputs) {
    if (state.currentVm.attributeInputs[attribute]) {
      parsedAttributes[attribute] = attributeParser(state.currentVm.attributeInputs[attribute]);
    }
  }

  const handleNameChange = useCallback((_name) => _dispatch(actions.setName(_name)), [_dispatch]);
  const handleGroupChange = useCallback((_groupId) => _dispatch(actions.setGroupId(_groupId)), [_dispatch]);
  const handleAttributeChange = useCallback(
    (_name, _value) => _dispatch(actions.setAttribute({ _name, _value })),
    [_dispatch]
  );
  const handleVCpuChange = useCallback((_vCpu) => _dispatch(actions.setVCpu(_vCpu)), [_dispatch]);
  const handleCancelBtnClick = useCallback(() => setPreventModal(true), []);
  const handleMemoryChange = useCallback((_memory) => _dispatch(actions.setMemory(_memory)), [_dispatch]);
  const handleVCpuAltChange = useCallback((_vCpu) => _dispatch(actions.setVCpuAlt(_vCpu)), [_dispatch]);
  const handleMemoryAltChange = useCallback((_memory) => _dispatch(actions.setMemoryAlt(_memory)), [_dispatch]);
  const handleCloseAttachDiskModal = useCallback(() => setAttachDiskModalState(false), []);
  const handleCloseAttachNetworkModal = useCallback(() => setIsAttachNetworkModalOpen(false), []);
  const handleConfirmAbort = useCallback(() => history.push("/infra/vm"), [history]);
  const handlePricelistBtnClick = () => dispatch(getContractPricelistAct(currentVm.agreement));

  const toggleImgModal = () => dispatch(toggleImageModalAct());

  useEffect(() => {
    if (datacenters.length) {
      dispatch(setAllVmsAct());
      dispatch(getAllImagesAct());
      dispatch(getAllGroupsAct());
      dispatch(getNetworkListRequestAct());
    }
  }, [datacenters, dispatch]);

  useEffect(() => {
    const _currentVm = vms.find((vm) => vm._id === id);
    const _currentVmGroup = groupsFromRelatedDc.find((group) => group.id === groupId);

    if (_currentVm) {
      _dispatch(actions.setCurrentVm({ currentVm: _currentVm, currentVmGroup: _currentVmGroup }));
    }
  }, [id, vms, _dispatch, groupsFromRelatedDc, actions]);

  useEffect(
    () => () => {
      if (pricelist.requestStatus === REQUEST_SUCCESS) {
        return dispatch(resetPricelistDataAC());
      }
    },
    [dispatch, pricelist.requestStatus]
  );

  const handleSubmit = async () => {
    const payload = composeEditVmPayload({ name, groupId, memory, vCPU, attributes });
    const {
      errors: [topErrorKey],
      fieldsWithError: [topErrorFieldName],
    } = validateVmPayload(payload, currentVm, parsedAttributes);

    if (topErrorKey) {
      dispatch(notificationErrorAC({ message: t(...validationI18nMapper(topErrorKey, topErrorFieldName)) }));
      return;
    }

    try {
      await dispatch(editVmAct(currentVm.dcOwner.api.url, currentVm._id, payload));
      history.push("/infra/vm");
    } catch (error) {
      console.error(error);
    }
  };

  if ([REQUEST_PENDING, REQUEST_UNCALLED].includes(vmListRequest)) {
    return (
      <div className={classes.page} style={{ marginTop: "50px" }}>
        <Paper className={classes.formContainer} elevation={0} style={{ minHeight: "600px" }}>
          <Spinner show />
        </Paper>
      </div>
    );
  }

  if (!currentVm) {
    return (
      <div className={classes.page} style={{ marginTop: "50px" }}>
        <Paper className={classes.formContainer} elevation={0} style={{ minHeight: "600px" }}>
          Virtual Machine not found.
        </Paper>
      </div>
    );
  }

  if (
    !hasContractAccessToFeature(userProfile, currentVm.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_VIRTUAL_MACHINES, [
      USER_ACCESS.UPDATE,
    ])
  ) {
    return (
      <div className={classes.page} style={{ marginTop: "50px" }}>
        <Paper className={classes.formContainer} elevation={0} style={{ minHeight: "600px" }}>
          Sorry, you don't have access to edit this Virtual Machine.
        </Paper>
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <PageHeader
        title={
          <span className={classes.titleWrap}>
            <span className={classes.title}>{t("createOrEdit.editTitle")}</span>
            {currentVm && <Badge type={getBadgeType(currentVm)}>{getVmStatusLabel(currentVm)}</Badge>}
          </span>
        }
        backPath="/infra/vm"
      />
      <Paper className={classes.formContainer} elevation={0}>
        <Spinner show={updatePending} />
        <FormFieldset title={t("createOrEdit.fieldset.datacenter")}>
          <h2 className={classes.rowTitle}>
            <DatacenterFormBadge>{currentVm.dcOwner.name}</DatacenterFormBadge>
          </h2>
        </FormFieldset>

        <FormFieldset title={t("createOrEdit.fieldset.general")} subTitle={t("createOrEdit.fieldset.generalHint")}>
          <Control label={t("createOrEdit.fields.groups.label")}>
            <GroupSelect
              id="groups_select"
              disabled
              value={groupId}
              onChange={handleGroupChange}
              datacenterId={datacenterId}
              featureUsedIn={FEATURES.IAAS_VIRTUAL_MACHINES}
              accessTypesToCheck={[USER_ACCESS.CREATE]}
            />
            <Button
              type="secondary"
              className={classes.pricelistBtn}
              onClick={handlePricelistBtnClick}
              loading={pricelist.requestStatus === REQUEST_PENDING}
            >
              {t("iaasCommon:priceList")}
            </Button>
          </Control>
          <Control label={t("createOrEdit.fields.name.label")}>
            <NameControl
              value={name}
              onChange={handleNameChange}
              disabled={!datacenterId}
              placeholder={t("createOrEdit.fields.name.placeholder")}
              prefixValue={prefixValue}
              hasNameMatchingPrefix={hasVmNameMatchingPrefix}
              mode="VMs"
            />
          </Control>
        </FormFieldset>
        <CpuFieldset
          isEditVMPage
          currentVm={currentVm}
          value={vCPU}
          altValue={vCPUAlt}
          onChange={handleVCpuChange}
          onAltChange={handleVCpuAltChange}
        />
        <MemoryFieldset
          isEditVMPage
          currentVm={currentVm}
          value={memory}
          altValue={memoryAlt}
          onChange={handleMemoryChange}
          onAltChange={handleMemoryAltChange}
        />
        {currentVm?.attributeOrder?.length > 0 && (
          <FormFieldset title={t("createOrEdit.fieldset.options")} subTitle={t("createOrEdit.fieldset.optionsHint")}>
            {currentVm.attributeOrder
              .replace(" ", "")
              .split(",")
              .filter((attributeName) => parsedAttributes[attributeName])
              .map((attributeName) => (
                <Control
                  label={parsedAttributes[attributeName].type !== "boolean" && parsedAttributes[attributeName].title}
                >
                  <ControlForInputTypes
                    attribute={[attributeName, parsedAttributes[attributeName]]}
                    value={attributes[attributeName] || ""}
                    className={classes.inputField}
                    onChange={handleAttributeChange}
                  />
                </Control>
              ))}
          </FormFieldset>
        )}
        <Grid container justifyContent="flex-end">
          <Grid className={classes.buttonRowContainer}>
            <Button type="secondary" onClick={handleCancelBtnClick}>
              {t("iaasCommon:Cancel")}
            </Button>
            <Button onClick={handleSubmit}>{t("iaasCommon:Save")}</Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.formContainer} elevation={0} data-testid="disk-section">
        <FormFieldset title={t("createOrEdit.fieldset.disk")} subTitle={t("createOrEdit.fieldset.diskHint")}>
          <Grid className={classes.fieldButtonWrapper}>
            {!isAttachedDiskToVmAllowed(currentVm) ? (
              <Tooltip title={t("attachDiskDisallowTooltip")}>
                <span>
                  <Button
                    tag="div"
                    role="button"
                    data-testid="attach_disk_btn"
                    className={classes.addImageButton}
                    disabled
                  >
                    {t("createOrEdit.attachDiskBtn")}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                data-testid="attach_disk_btn"
                onClick={() => setAttachDiskModalState(true)}
                className={classes.addImageButton}
                disabled={!hasUserAccessToFeature(userProfile, FEATURES.IAAS_DISKS, [USER_ACCESS.UPDATE])}
              >
                {t("createOrEdit.attachDiskBtn")}
              </Button>
            )}
            <Button
              data-testid="createDiskBtn"
              onClick={toggleImgModal}
              className={classes.createImageButton}
              disabled={!hasUserAccessToFeature(userProfile, FEATURES.IAAS_DISKS, [USER_ACCESS.CREATE])}
            >
              {t("iaasCommon:createNew")}
            </Button>
          </Grid>
          <VmDisksList
            selectedImages={selectedImages}
            images={images}
            groups={groupsFromRelatedDc}
            vm={currentVm}
            disabled={!isAttachedDiskToVmAllowed(currentVm)}
            userProfile={userProfile}
          />
        </FormFieldset>
        <PricelistModal />
        <AttachDiskModal
          open={isAttachDiskModalOpen}
          onClose={handleCloseAttachDiskModal}
          currentVm={currentVm}
          userProfile={userProfile}
        />
        <CreateDiskModal
          open={isDiskModalOpen}
          currentVm={currentVm}
          imageSelectedForEdit={imageSelectedForEdit}
          groupsFromRelatedDc={groupsFromRelatedDc}
        />
      </Paper>
      <Paper className={classes.formContainer} style={{ marginBottom: 0 }} elevation={0}>
        <FormFieldset title={t("createOrEdit.fieldset.network")} subTitle={t("createOrEdit.fieldset.networkHint")}>
          <Grid className={classes.fieldButtonWrapper}>
            {!isAttachedNetworkToVmAllowed(currentVm) ? (
              <Tooltip title={t("attachNetworkDisallowTooltip")}>
                <Button
                  tag="div"
                  role="button"
                  data-testid="attach_network_btn"
                  className={classes.addImageButton}
                  disabled
                >
                  {t("createOrEdit.attachNetworkBtn")}
                </Button>
              </Tooltip>
            ) : (
              <Button
                data-testid="attach_network_btn"
                onClick={() => setIsAttachNetworkModalOpen(true)}
                className={classes.addImageButton}
              >
                {t("createOrEdit.attachNetworkBtn")}
              </Button>
            )}
          </Grid>
          <ListVmNetworks vm={currentVm} />
        </FormFieldset>
        <AttachNetworkModal open={isAttachNetworkModalOpen} vm={currentVm} onClose={handleCloseAttachNetworkModal} />
      </Paper>
      <Modal
        open={isPreventModalOpen}
        onClose={() => setPreventModal(false)}
        title={t("stopVmChange.title")}
        controls={
          <>
            <Button type="secondary" onClick={() => setPreventModal(false)}>
              {t("iaasCommon:No")}
            </Button>
            <Button onClick={handleConfirmAbort}>{t("iaasCommon:Yes")}</Button>
          </>
        }
      >
        <div>{t("stopVmChange.q1")}</div>
        <div>{t("stopVmChange.q2")}</div>
      </Modal>
    </div>
  );
};

export default VmEditPage;
