import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@skyportal/ui-kit";

import styles from "./styles";

const useStyles = makeStyles(styles);

const defaultOnError = (error) => {
  console.error(error);
};
/**
 * @param {function|function[]} action
 * @param {object} rest
 * @param {boolean} [isRequesting] = false
 * @param {string} [testId]
 * @param {function} [onSuccess]
 * @param {function(error: Error)} [onError]
 * @return {JSX.Element}
 * @constructor
 */

const RefreshButton = ({
  action,
  onSuccess,
  isRequesting = false,
  onError = defaultOnError,
  testId = "RefreshButton",
  ...rest
}) => {
  const classes = useStyles();
  const [requesting, setRequesting] = useState(Boolean(isRequesting));
  const dispatch = useDispatch();

  useEffect(() => {
    setRequesting(isRequesting);
  }, [isRequesting]);

  const handleClick = async () => {
    if (requesting) {
      return;
    }
    try {
      setRequesting(true);
      await dispatch(action());
      typeof onSuccess === "function" && onSuccess();
    } catch (error) {
      onError(error);
    }
    setRequesting(false);
  };

  return (
    <button
      data-testid={testId}
      type="button"
      disabled={requesting}
      onClick={handleClick}
      className={cn(classes.root)}
      {...rest}
    >
      <Icon className={classes.icon} icon="circle" spin={requesting} />
    </button>
  );
};

export default RefreshButton;
