const styles = (theme) => ({
  pageBox: {
    ...theme.classes.pageBox,
    paddingLeft: "16px",
    paddingRight: "16px",
  },

  tableContainer: {
    width: "100%",

    "@media(max-width: 767px)": {
      overflowX: "auto",
    },
  },

  table: {
    minHeight: "100px",
    boxSizing: "border-box",

    "@media(max-width: 767px)": {
      width: "767px",
    },
  },
  tableHeadControls: {
    ...theme.classes.centralized,
    ...theme.classes.table.controls,
    justifyContent: "space-between",
    padding: "4px 10px",
    flexDirection: "row",
    fontSize: theme.fonts.smaller,
  },
  tableSearchLabel: {
    marginRight: "16px",
  },
  headerRowCell: {
    marginBottom: "16px",
    borderBottom: "none",
  },
  sorterCell: {
    borderBottom: `3px solid #f4f4f4`,

    "&:hover": {
      cursor: "pointer",
      opacity: "0.75",
    },
  },
  sorterCellIcon: {
    marginRight: "10px",
    color: theme.colors.inactiveGrey,
  },
  sorterCellText: {
    ...theme.classes.table.sorterCellText,
  },
  pagingContainer: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "white",
    padding: "16px 10px",
  },
  cell: {
    padding: "8px 10px",
    width: "25%",
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
  },
  newRegionBar: {
    borderBottom: `3px solid ${theme.colors.lightGrey}`,
  },
  divControls: {
    display: "flex",
    justifyContent: "space-between",
    margin: "-8px",

    "&> *": {
      boxSizing: "border-box",
      padding: "8px",
    },

    "@media(max-width: 767px)": {
      flexWrap: "wrap",

      "&> *": {
        flexGrow: 1,
        flexShrink: 0,
      },
    },
  },
  sorterBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: theme.fonts.smaller,
  },
  tableSearchGroup: {
    "& > input": {
      width: "100%",
    },
  },
  btnContainer: {
    "& > button": {
      width: "100%",
    },

    "@media(max-width: 767px)": {
      minWidth: "250px",
    },
  },
});

export default styles;
