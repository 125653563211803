import React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";
import HeadCell from "./HeadCell";
import TableRaw from "./TableRaw";
import { defaultKeyGet } from "./utils";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @typedef {object} ColumnConfig
 * @property {string} key - dataItem[key]
 * @property {string} label - table head
 * @property {string} [cellClassName]
 * @property {boolean} [sortDisabled]
 * @property {string} [width] - "25%" | "20px"
 * @property {function(object): React.ReactNode} [render] - (value) => string|JSX.Element
 */

/**
 * @param {ColumnConfig[]} columnsConfig
 * @param {object[]} data
 * @param {SortOrderEnum} sortOrder
 * @param {string} [testId]
 * @param {string} [className]
 * @param {string} [scrollAreaHeight]
 * @param {string} [sortField]
 * @param {function(sortField: string)} [onSortFieldChange]
 * @param {function(object): string} [keyGen]
 * @return {JSX.Element}
 * @constructor
 */

const SortableTable = ({
  data,
  columnsConfig,
  sortField = "",
  onSortFieldChange,
  scrollAreaHeight,
  className,
  sortOrder,
  keyGen = defaultKeyGet,
  testId = "SortableTable",
}) => {
  const classes = useStyles();
  const makeHandleSort = (_sortField) => () => onSortFieldChange(_sortField);

  return (
    <div data-testid={testId} className={cn(classes.tableWrapper, className)}>
      <div className={classes.scrollArea} style={{ height: scrollAreaHeight }}>
        <table className={classes.table}>
          <thead data-testid="table_head">
            <tr className={classes.tableHead}>
              {columnsConfig.map((item) => (
                <HeadCell
                  key={item.key}
                  width={item.width}
                  sortOrder={sortOrder}
                  onSortClick={makeHandleSort(item.key)}
                  isActive={sortField === item.key}
                  sortDisabled={item.sortDisabled}
                >
                  {item.label}
                </HeadCell>
              ))}
            </tr>
          </thead>
          <tbody data-testid="table_body">
            {data.map((item) => (
              <TableRaw key={keyGen(item)} columns={columnsConfig} item={item} />
            ))}
          </tbody>
        </table>
      </div>
      {scrollAreaHeight && <div className={classes.tableFooterMask} />}
    </div>
  );
};

export default React.memo(SortableTable);
