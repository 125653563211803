const btn = {
  width: "80px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "$4f4f4f",
  },
};

const styles = (theme) => ({
  controls: {
    ...theme.classes.centalized,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "8px 10px",
    height: "32px",
    width: "25%",
  },
  cell: {
    padding: "8px 10px",
    width: "25%",
    height: "32px",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
    paddingLeft: "25px",
  },
  cellInputEdit: {
    width: "100%",
  },
  buttonShell: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonsShell: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "6px",
  },
  modalBody: theme.classes.modal.modalBody,
  modalHeader: theme.classes.modal.modalHeader,
  card: {
    ...theme.classes.elems.card,
  },
  cardRow: theme.classes.elems.cardRow,
  cardFooter: theme.classes.modal.cardFooter,
  confirmDeleteBtn: {
    ...btn,
    backgroundColor: "#d3d3d3",
  },
  saveBtn: {
    marginLeft: "8px",
  },
  DCLinkShell: {
    cursor: "pointer",
  },
  DCLinkMenu: {
    position: "absolute",
    padding: "10px",
    borderRadius: "3px",
    minWidth: "200px",
    marginTop: "-20px",
    backgroundColor: theme.colors.lightGrey,
    listStyleType: "none",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.36)",
  },
  DCMenuLine: {
    margin: "5px 0",
  },
  linkListAligner: {
    display: "flex",
    justifyContent: "space-between",
  },
  dcNameShell: {
    marginRight: "15px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
    fontSize: theme.fonts.smaller,
  },
});

export default styles;
