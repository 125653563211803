import isDate from "lodash/isDate";

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
};
const dateFormatPattern = /^\d{4}-\d{2}-\d{2}$/;

/**
 * @param {string} str
 * @return {boolean}
 */
export const isStrictDateStringFormat = (str) => dateFormatPattern.test(str);

export const formatTime = (inputDate) => {
  const date = new Date(inputDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
};

export const formatAMPM = (inputDate) => {
  const date = new Date(inputDate);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours !== 0 ? hours : 12; // the hour '0' should be '12'
  return `${hours}:${String(minutes).padStart(2, "0")} ${ampm}`;
};

/**
 * @see <node_modules/react-day-picker/types/Props.d.ts>
 *
 * @param {function(value: Date)} changeHandler
 * @return {function(string, *, object): void} - DayPickerInputProps.onDayChange
 */
export const makeStrictDatePickerChangeHandler = (changeHandler) => (value, _, dayPickerInput) => {
  if (isDate(value) && isStrictDateStringFormat(dayPickerInput.input.value)) {
    changeHandler(value);
  }
};

/**
 * @param {number} [daysBefore]
 * @return {Date}
 */
export const getPastDay = (daysBefore = 1) => {
  const ms = new Date().getTime();
  const pastOffsetDays = 1000 * 60 * 60 * 24 * daysBefore;
  return new Date(ms - pastOffsetDays);
};
