import * as React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

const PageBox = ({ children, className, marginBottom, relative }) => {
  const classes = useStyles();

  return (
    <div
      className={cn(classes.pageBox, className, { [classes.marginBottom]: marginBottom, [classes.relative]: relative })}
    >
      {children}
    </div>
  );
};

export default PageBox;
