import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@skyportal/ui-kit";
import Modal from "@material-ui/core/Modal";
import Input from "../../../ui-kit/Input";
import { hasUserAccessToFeature, FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";
import {
  createImageAct,
  toggleImageModalAct,
  closeImageModalAct,
  updateImageAct,
  createImageAndAttachToVm,
  setImageDcIncludeMap,
} from "../../../store/actions/imagesActions";
import { getDisksDatacenterIncludeMap, imageSel } from "../../../store/selectors/imgSelectors";
import { getGroups } from "../../../store/selectors/groupSelectors";
import { IMAGES_REPORT_SECTION, minImageSize } from "../../constants";
import DatacenterFilter from "../../../components/DatacenterFilter";
import { gb2Mb, mb2gb } from "../../../utils/size";
import DisksModalBody from "./DisksModalBody";
import IconicDropdown from "ui-kit/IconicDropdown";
import ColumnSwitchCheckbox from "components/ColumnSwitchCheckbox/ColumnSwitchCheckbox";
import ExportModal from "pages/Reports/modals/ExportModal";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {function} changeFilterValue
 * @param {UserInfoModel} userProfile
 * @return {JSX.Element}
 * @constructor
 */

const DisksTableHead = ({ changeFilterValue, userProfile }) => {
  const { t } = useTranslation("imagesPage");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isModalOpen, imageSelectedForEdit, selectedCols } = useSelector(imageSel);
  const groups = useSelector(getGroups);
  const dcIncludeMap = useSelector(getDisksDatacenterIncludeMap);

  const [vm, setVm] = useState("");
  const [newImageName, setNewImageName] = useState("");
  const [hasImageNameMatchingPrefix, setHasImageNameMatchingPrefix] = useState(true);
  const [size, setSize] = useState(minImageSize);
  const [imageTier, setImageTier] = useState("");
  const [imageGroup, setImageGroup] = useState("");
  const [inputSizeValue, setInputSizeValue] = useState(minImageSize.toString());
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const currentGroup = groups.filter(Boolean).find((item) => item._id === imageGroup);
  const isCreateBtnDisabled = !hasUserAccessToFeature(userProfile, FEATURES.IAAS_DISKS, [USER_ACCESS.CREATE]);

  const clearModalFields = () => {
    setNewImageName("");
    setSize(minImageSize);
    setImageTier("");
    setImageGroup("");
    setInputSizeValue(minImageSize.toString());
    setHasImageNameMatchingPrefix(true);
  };

  useEffect(() => {
    if (!isModalOpen) {
      clearModalFields();
    }
  }, [isModalOpen]);

  const toggleImgModal = () => dispatch(toggleImageModalAct());
  const closeImgModal = () => dispatch(closeImageModalAct());

  const preparePayload = () => ({
    group: currentGroup?.id,
    name: newImageName,
    persistent: true,
    size: Math.round(gb2Mb(size)),
    tier: imageTier || null,
  });

  const handleEditClick = async () => {
    if (currentGroup) {
      await dispatch(updateImageAct(currentGroup.dcOwner, imageSelectedForEdit._id, preparePayload()));
      dispatch(closeImageModalAct());
    }
  };

  const handleCreateClick = async () => {
    if (currentGroup) {
      if (vm) {
        await dispatch(createImageAndAttachToVm(currentGroup.dcOwner, preparePayload(), vm));
      } else {
        await dispatch(createImageAct(currentGroup.dcOwner, preparePayload()));
      }
      dispatch(closeImageModalAct());
    }
  };

  useEffect(() => {
    if (imageSelectedForEdit) {
      const groupsFromImageDc = groups.filter((item) => item.dcOwner.id === imageSelectedForEdit.dcOwner.id);
      const group = groupsFromImageDc.find((item) => item.id === imageSelectedForEdit.group);

      let imageNameWithoutPrefix = imageSelectedForEdit.name;
      let hasImageNameMatchingPrefix = false;
      if (group?.prefix) {
        hasImageNameMatchingPrefix = imageSelectedForEdit.name.includes(group.prefix);
        if (hasImageNameMatchingPrefix) {
          imageNameWithoutPrefix = imageNameWithoutPrefix.replace(`${group.prefix}-`, "");
        }
      } else if (group?.name) {
        hasImageNameMatchingPrefix = imageSelectedForEdit.name.includes(group.name);
        if (hasImageNameMatchingPrefix) {
          imageNameWithoutPrefix = imageNameWithoutPrefix.replace(`${group.name}-`, "");
        }
      }

      setNewImageName(imageNameWithoutPrefix);
      setHasImageNameMatchingPrefix(hasImageNameMatchingPrefix);
      setSize(mb2gb(imageSelectedForEdit.size));
      setImageTier(imageSelectedForEdit?.tier ?? "");
      setImageGroup(group?._id ?? "");
      setInputSizeValue(mb2gb(imageSelectedForEdit.size).toString());
    }
  }, [imageSelectedForEdit, groups]);

  const handleExportBtnClick = () => setIsExportModalOpen(true);
  const handleExportModalClose = () => setIsExportModalOpen(false);

  return (
    <>
      <div className={classes.headerRowCell}>
        <div className={classes.tableHeadControls}>
          <Button
            data-testId="createNewDiskBtn"
            leftIcon="plus"
            onClick={toggleImgModal}
            disabled={isCreateBtnDisabled}
            className={cn(classes.createNewImageBtn, { [classes.disableBtn]: isCreateBtnDisabled })}
          />
          <div className={classes.tableSearchGroup}>
            <Input onChange={changeFilterValue} data-testid="DisksSearchInput" placeholder={t("searchPlaceholder")} />
          </div>
          <DatacenterFilter
            onChangeAction={setImageDcIncludeMap}
            dcIncludeMap={dcIncludeMap}
            placeholder={t("datacenterFilter")}
            className={classes.datacenterFilterWrap}
          />
          <IconicDropdown mainIcon="list" data-testid="VMColumnSwitcher">
            <div className={classes.cbList}>
              {selectedCols.map((cb) => (
                <ColumnSwitchCheckbox key={cb.name} column={cb} variant="disksList" />
              ))}
            </div>
          </IconicDropdown>
          <div>
            <Button onClick={handleExportBtnClick}>
              <span>{t("iaasCommon:Export")}</span>
            </Button>
          </div>
        </div>
      </div>
      <Modal open={isModalOpen} onClose={closeImgModal}>
        <div>
          <DisksModalBody
            vm={vm}
            setVm={setVm}
            newImageName={newImageName}
            setNewImageName={setNewImageName}
            imageGroup={imageGroup}
            setImageGroup={setImageGroup}
            imageTier={imageTier}
            setImageTier={setImageTier}
            size={size}
            setSize={setSize}
            inputSizeValue={inputSizeValue}
            setInputSizeValue={setInputSizeValue}
            onUpdate={handleEditClick}
            onCreate={handleCreateClick}
            closeImgModal={closeImgModal}
            hasImageNameMatchingPrefix={hasImageNameMatchingPrefix}
          />
        </div>
      </Modal>
      <ExportModal section={IMAGES_REPORT_SECTION} onClose={handleExportModalClose} open={isExportModalOpen} />
    </>
  );
};

export default DisksTableHead;
