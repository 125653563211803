import {
  NETWORK_LIST_REQUEST_SUCCESS,
  SET_NETWORK_MODAL_CURRENT_PAGE,
  SET_NETWORK_MODAL_SEARCH_TEXT,
  SET_NETWORK_MODAL_SORT_FIELD,
} from "../../types";

/**
 * @typedef {object} NetworkStateModel
 * @property {string} _id - id Mongo
 * @property {number} id - id OpenNebula
 * @property {number} group - group id OpenNebula
 * @property {string} name
 * @property {DataCenterApiModel} dcOwner
 */

/**
 * @typedef {object} NetworkState
 * @property {NetworkStateModel[]} list
 *
 * @property {number} currentPage
 * @property {string} searchText
 * @property {"asc"|"desc"} sortOrder
 * @property {"name"|"group"} sortField
 * @property {number} perPage
 */

/**
 * @type {NetworkState}
 */
export const initState = {
  list: [],
  perPage: 5,
  currentPage: 1,
  searchText: "",
  sortOrder: "asc",
  sortField: "name",
};

/**
 * @param {NetworkState} state
 * @param {object} action
 * @return {NetworkState}
 */
export default function networkReducer(state = initState, action) {
  switch (action.type) {
    case NETWORK_LIST_REQUEST_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case SET_NETWORK_MODAL_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload,
        currentPage: 1,
      };
    }
    case SET_NETWORK_MODAL_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case SET_NETWORK_MODAL_SORT_FIELD: {
      return {
        ...state,
        sortField: action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
      };
    }
    default:
      return state;
  }
}
