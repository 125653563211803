import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { filterListByDcId } from "../../../../utils/groups";
import { getGroups } from "../../../../store/selectors/groupSelectors";
import { getUserProfile } from "store/selectors/userSelectors";
import { hasContractAccessToFeature, CONTRACT_TYPES } from "@skyportal/auth-web-client";

import { formStyles } from "../styles";

const useStyles = makeStyles(formStyles);

/**
 * @param {number|null} value
 * @param {function(value: number): void} onChange
 * @param {boolean} [disabled]
 * @param {string} [id]
 * @param {string} datacenterId
 * @param {boolean} [autoSelect] - auto select when only one group
 * @param {string} featureUsedIn
 * @param {string[]} accessTypesToCheck
 * @return {JSX.Element}
 * @constructor
 */
export const GroupSelect = ({
  value,
  onChange,
  disabled = false,
  datacenterId,
  autoSelect = false,
  id,
  featureUsedIn,
  accessTypesToCheck = [],
}) => {
  const onChangeRef = useRef(onChange);
  const classes = useStyles();
  const groups = useSelector(getGroups);
  const userProfile = useSelector(getUserProfile);
  const handleChange = (event) => onChange(Number(event.target.value));
  /**
   * @type {GroupStateModel[]}
   */
  const groupsFromRelatedDc = useMemo(() => filterListByDcId(groups, datacenterId), [groups, datacenterId]);
  let groupsFromDcWithAccess = groupsFromRelatedDc;
  if (featureUsedIn) {
    groupsFromDcWithAccess = groupsFromRelatedDc.filter(
      (groupItem) =>
        hasContractAccessToFeature(
          userProfile,
          groupItem.agreement,
          CONTRACT_TYPES.IA,
          featureUsedIn,
          accessTypesToCheck
        ) || groupItem.agreement === null
    );
  }

  useEffect(() => {
    if (autoSelect && groupsFromRelatedDc.length === 1) {
      onChangeRef.current(Number(groupsFromRelatedDc[0].id));
    }
  }, [groupsFromRelatedDc, autoSelect]);

  return (
    <Grid item className={classes.fieldContainer}>
      <Select
        id={id}
        IconComponent={KeyboardArrowDownIcon}
        disableUnderline
        className={classes.groupsListRoot}
        value={value ?? ""}
        name="group"
        onChange={handleChange}
        classes={{
          select: classes.muiSelectRoot,
          icon: classes.muiSelectIcon,
        }}
        disabled={disabled}
      >
        {groupsFromDcWithAccess.map((groupItem) => (
          <MenuItem key={groupItem.id} value={groupItem.id}>
            {groupItem.displayname || groupItem.name}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default React.memo(GroupSelect);
