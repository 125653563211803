import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { Spinner } from "@skyportal/ui-kit";
import { createNewRegionAct, setCurrentPageAC, setPerPageAC } from "../../../store/actions/regionActions";
import { getRegionRequest, getTableRegions, regionSel } from "../../../store/selectors/regionSelectors";
import PaginationPerSizeSelect from "../../../components/PaginationPerSizeSelect";
import { REQUEST_UNCALLED, REQUEST_PENDING } from "../../../constants/request";
import RegionsTableHead from "./RegionsTableHead";
import RegionRow from "../RegionRow/RegionRow";
import NewRegionRow from "../NewRegionRow/NewRegionRow";
import RegionTopBar from "./RegionTopBar";

import styles from "./styles";

const useStyles = makeStyles(styles);

const RegionsContent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { currentPage, perPage } = useSelector(regionSel);
  const isDataFetching = [REQUEST_UNCALLED, REQUEST_PENDING].includes(useSelector(getRegionRequest));
  const { list, pageCount } = useSelector(getTableRegions);
  const [onNewRegionCreation, setOnNewRegionCreation] = useState(false);

  const saveNewRegion = (region) => dispatch(createNewRegionAct(region));
  const handleCreateBtnClick = () => setOnNewRegionCreation(true);
  const handleCancelCreationBtnClick = () => setOnNewRegionCreation(false);

  const onChangePage = (_, page) => dispatch(setCurrentPageAC(page));
  const handlePerPageChange = (step) => dispatch(setPerPageAC(step));

  return (
    <div className={classes.pageBox}>
      <Spinner show={isDataFetching} />
      <RegionTopBar onCreateBtnClick={handleCreateBtnClick} />
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          <RegionsTableHead />
          <div>
            <NewRegionRow
              isVisible={onNewRegionCreation}
              saveNewRegion={saveNewRegion}
              cancelCreation={handleCancelCreationBtnClick}
            />
            {list.map((region) => (
              <RegionRow key={region.id} regionProps={region} />
            ))}
          </div>
        </div>
      </div>
      <div className={classes.pagingContainer}>
        <PaginationPerSizeSelect value={perPage} onChange={handlePerPageChange} />
        <Pagination page={currentPage} count={pageCount} onChange={onChangePage} variant="outlined" shape="rounded" />
      </div>
    </div>
  );
};

export default RegionsContent;
