const styles = theme => ({
  root: {
    backgroundColor: theme.colors.lightGrey,

    '@global': {
      '.MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
      '.MuiInput-underline:after': {
        border: 'none',
        transform: 'none',
      },
      '.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
        paddingLeft: '15px',
      },

      '.MuiInputBase-input': {
        color: theme.colors.black,
        fontSize: theme.fonts.smaller,
        lineHeight: '18px',
        minHeight: '20px',
        fontFamily: 'Dustin Sans',
      },

      '.MuiSvgIcon-root': {
        width: '0.9em',
        height: '0.9em',
      },

      '.MuiAutocomplete-endAdornment': {
        top: '50%',
        right: '5px',
        transform: 'translateY(-50%)',
      },
      '.MuiChip-root': {
        height: '24px',
        borderRadius: '2px',
        fontSize: '12px',
      },
      '.MuiChip-deleteIcon': {
        width: '0.6em',
        height: '0.6em',
      },
    },
  },
  option: {
    height: '30px',
    color: theme.colors.black,
    fontSize: theme.fonts.smaller,
    fontFamily: 'Dustin Sans',
  },
  noOptions: {
    height: '20px',
    marginBottom: 0,
  },
});

export default styles;
