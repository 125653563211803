import React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @typedef {"success"|"danger"|"info"} BadgeType
 */

/**
 * @param {BadgeType} type
 * @param {React.ReactChildren} children
 * @return {JSX.Element}
 * @constructor
 */
const Badge = ({ type = "info", children }) => {
  const classes = useStyles();

  return (
    <span
      className={cn(classes.root, {
        [classes.success]: type === "success",
        [classes.info]: type === "info",
        [classes.danger]: type === "danger",
      })}
    >
      {children}
    </span>
  );
};

export default Badge;
