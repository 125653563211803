import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReportsTableHeadColumnControls from "../../../pages/Reports/ReportsContent/ReportsTableComponents/ReportsTableHeadColumnControls";
import ReportsTableRow from "../../../pages/Reports/ReportsContent/ReportsTableComponents/ReportsTableRow";
import { useDispatch, useSelector } from "react-redux";
import { getReportRequest, getSummaryReportTableRegions, reportSel } from "../../../store/selectors/reportSelectors";
import { makeStyles } from "@material-ui/core/styles";
import { getSummaryReportRequest } from "../../../store/actions/reportsActions";
import { REQUEST_UNCALLED } from "../../../constants/request";
import TablesPagination from "./TablesPagination";

import styles from "./styles";

const useStyles = makeStyles(styles);

const SummaryTable = () => {
  const dispatch = useDispatch();
  const reportRequest = useSelector(getReportRequest);
  const { t } = useTranslation("usageReportsPage");
  const classes = useStyles();
  const { selectedCols } = useSelector(reportSel);
  const { list, pageCount } = useSelector(getSummaryReportTableRegions);

  const summaryReportColumns = useMemo(
    () =>
      selectedCols.map((item) => ({
        ...item,
        name: t(`iaasCommon:${item.name}`),
      })),
    [selectedCols, t]
  );

  useEffect(() => {
    if (reportRequest === REQUEST_UNCALLED) {
      dispatch(getSummaryReportRequest());
    }
  }, [reportRequest, dispatch]);

  return (
    <>
      <div className={classes.tableContainer} data-testid="report_summary_table">
        <div className={classes.table}>
          <ReportsTableHeadColumnControls columns={summaryReportColumns} />
          <div className={classes.tableBody}>
            {list.map((item) => (
              <ReportsTableRow key={item._id} data={item} columns={summaryReportColumns} />
            ))}
          </div>
        </div>
      </div>
      <TablesPagination pageCount={pageCount} data-testid="report_summary_pagination" />
    </>
  );
};

export default SummaryTable;
