const styles = theme => ({
  muiSelectRoot: {
    ...theme.classes.dropDown.muiSelectRoot,
    backgroundColor: theme.colors.lightGrey,
    fontSize: theme.fonts.smaller,
  },
  muiSelectIcon: theme.classes.dropDown.muiSelectIcon,
  muiMenuItem: {
    ...theme.classes.dropDown.muiMenuItem,
    fontSize: theme.fonts.smaller,
  },
});

export default styles;
