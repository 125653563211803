import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { addAllOption, getSelectTypeOptions, makeAgreementOptions } from "./utils";
import { EMPTY_GROUP_VALUE } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@skyportal/ui-kit";
import { reportSel } from "../../../store/selectors/reportSelectors";
import {
  getSummaryReportRequest,
  setReportDatacenterId,
  setReportEndDate,
  setReportGroup,
  setReportFilterType,
  setReportStartDate,
} from "../../../store/actions/reportsActions";
import useDatacenterOptions from "../../../hooks/useDatacenterOptions";
import { makeStyles } from "@material-ui/core/styles";
import { getGroups } from "../../../store/selectors/groupSelectors";
import { getUserProfile } from "store/selectors/userSelectors";
import DatePicker from "../../../ui-kit/DatePicker";
import Dropdown from "../../../ui-kit/Dropdown";
import VmMultiSelect from "./summary/VmMultiSelect";
import { FEATURES } from "@skyportal/auth-web-client";

import styles from "./styles";

const useStyles = makeStyles(styles);

const SummaryFilterForm = () => {
  const { t } = useTranslation("usageReportsPage");
  const dispatch = useDispatch();
  const classes = useStyles();
  const dcOptions = useDatacenterOptions();
  const dcOptionsWithAllOption = useMemo(() => addAllOption(dcOptions), [dcOptions]);
  const groups = useSelector(getGroups);
  const userProfile = useSelector(getUserProfile);
  const { reportFilterType, selectionStartDate, selectionEndDate, reportGroupId, reportDatacenterId } =
    useSelector(reportSel);
  const handleTypeSelectChange = (event) => dispatch(setReportFilterType(event.target.value));
  const handleGroupSelectChange = (event) => dispatch(setReportGroup(event.target.value));
  const handleStartDateChange = (date) => dispatch(setReportStartDate(date));
  const handleDatacenterChange = (event) => dispatch(setReportDatacenterId(event.target.value));
  const handleEndDateChange = (date) => dispatch(setReportEndDate(date));
  const selectClasses = {
    select: classes.muiSelectRoot,
    icon: classes.selectIcon,
  };

  const typeOptions = useMemo(() => getSelectTypeOptions(t), [t]);
  const agreementOptions = useMemo(() => {
    const datacenterGroups =
      reportDatacenterId && reportDatacenterId !== EMPTY_GROUP_VALUE
        ? groups.filter((item) => item.dcOwner.id === reportDatacenterId)
        : groups;
    return addAllOption(makeAgreementOptions(datacenterGroups, userProfile, FEATURES.IAAS_USAGE_REPORT));
  }, [reportDatacenterId, groups, userProfile]);

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(getSummaryReportRequest());
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate data-testid="summary_filter_form">
      <div className={classes.controlContainer}>
        <div className={classes.controlBoxItem}>
          <div className={classes.label}>{t("filterBar.fields.type.label")}</div>
          <Dropdown
            id="reportType"
            onChange={handleTypeSelectChange}
            value={reportFilterType}
            options={typeOptions}
            selectClasses={selectClasses}
          />
        </div>
        <div className={classes.controlBoxItem}>
          <div className={classes.label}>{t("filterBar.fields.start.label")}</div>
          <DatePicker value={selectionStartDate} onDayChange={handleStartDateChange} />
        </div>
        <div className={classes.controlBoxItem}>
          <div className={classes.label}>{t("filterBar.fields.end.label")}</div>
          <DatePicker value={selectionEndDate} onDayChange={handleEndDateChange} />
        </div>
        <div className={classes.controlBoxItem}>
          <div className={classes.label}>{t("filterBar.fields.datacenter.label")}</div>
          <Dropdown
            id="datacenterSelect"
            onChange={handleDatacenterChange}
            value={reportDatacenterId}
            options={dcOptionsWithAllOption}
            disabled={dcOptions.length === 0}
            selectClasses={selectClasses}
          />
        </div>
        <div className={classes.controlBoxItem}>
          <div className={classes.label}>{t("filterBar.fields.group.label")}</div>
          <Dropdown
            id="agreementId"
            onChange={handleGroupSelectChange}
            value={reportGroupId || ""}
            options={agreementOptions}
            selectClasses={selectClasses}
          />
        </div>
        <div className={classes.controlBoxItem}>
          <div className={classes.label}>{t("filterBar.fields.status.label")}</div>
          <VmMultiSelect />
        </div>
        <div className={cn(classes.controlBoxItem, classes.controlButtonBoxItem)}>
          <Button data-testid="submitFormBtn" onClick={handleSubmit}>
            <span>{t("iaasCommon:Run")}</span>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SummaryFilterForm;
