import i18n from "i18next";
import initI18n from "@skyportal/web-i18n";

import en from "./en/translation.json";
import no from "./no/translation.json";

const resources = { en, no };

if (!i18n.isInitialized) {
  initI18n({
    react: {
      useSuspense: false,
    },
  });
}

Object.entries(resources).forEach(([ln, translations]) => {
  Object.entries(translations).forEach(([namespace, source]) => {
    i18n.addResourceBundle(ln, namespace, source, true);
  });
});

export default i18n;
