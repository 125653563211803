const btn = {
  height: "32px",
  minWidth: "45px",
  padding: "3px 8px !important",
};

const styles = (theme) => ({
  pageBox: {
    ...theme.classes.pageBox,
    position: "relative",
    padding: "16px",
  },

  tileContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "-8px",
  },

  tileItem: {
    width: "20%",
    minWidth: "200px",
    flexGrow: 1,
    padding: "0 8px 8px 8px",
  },

  tableContainer: {
    width: "100%",
    marginTop: "8px",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "3px",
      backgroundColor: "rgba(0,0,0,.8)",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "3px",
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },

  table: {
    minHeight: "100px",
    boxSizing: "border-box",
    minWidth: "767px",

    "@media(max-width: 767px) ": {
      width: "767px",
    },
  },
  vmsHeadControlsBar: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "-8px",

    "& > *": {
      boxSizing: "border-box",

      "@media(max-width: 767px)": {
        width: "100%",
      },
    },
  },
  vmsRightControls: {
    display: "flex",
    flexWrap: "wrap",

    "@media(max-width: 767px)": {
      justifyContent: "space-around",

      "& > *": {
        flexGrow: 1,

        "& > *": {
          width: "100%",
        },
      },
    },

    "& > *": {
      padding: "8px",
    },
  },
  vmsLeftControls: {
    display: "flex",
    flexWrap: "wrap",

    "& > *": {
      padding: "8px",
      flexGrow: 1,

      "& > *": {
        width: "100%",
      },

      "@media(max-width: 767px)": {
        minWidth: "200px",
      },
    },
  },
  addVMs: {
    ...btn,
    marginRight: 0,
  },
  deleteVMs: {
    ...btn,
    marginRight: 0,
  },
  editVMs: {
    ...btn,
    marginRight: 0,
  },
  vmsActions: {
    color: "white",
    ...btn,
    marginRight: 0,
  },
  vmTableControls: {
    display: "flex",
  },
  VMtileBar: {
    margin: "8px 0 16px",
  },
  shell: {
    width: "900px",
    overflowX: "auto",
  },
  pagingContainer: {
    ...theme.classes.table.pagingContainer,
  },
  tableBody: {
    minHeight: "400px",
  },
  cbList: {
    position: "absolute",
    zIndex: "100",
    width: "110px",
    backgroundColor: theme.colors.lightGrey,
    padding: "15px 10px",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.36)",
    borderRadius: "2px",
    right: "0",
  },
  vmActionList: {
    position: "absolute",
    top: "2px",
    left: "0",
    borderRadius: "2px",
    backgroundColor: "#f6f6f6",
    display: "flex",
    flexDirection: "column",

    "@media(max-width: 767px)": {
      left: "unset",
      right: 0,
    },
  },
  vmActionBtn: {
    display: "block",
    padding: "8px 16px",
    backgroundColor: "#f6f6f6",
    borderRadius: "2px",
    fontWeight: "400",
    border: "none",
    outline: "none",
    color: "black",
    cursor: "pointer",
    transition: ".3s",
    whiteSpace: "nowrap",

    "&:hover": {
      backgroundColor: "#e6e6e6",
    },
  },
  datacenterFilterWrap: { minWidth: "200px" },
  timerDropdown: {
    "& .MuiSelect-select": {
      borderRadius: "2px",
    },
  },
  icon: {
    filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(58deg) brightness(110%) contrast(102%)",
    width: "16px",
    height: "16px",
    verticalAlign: "sub",
  },
});

export default styles;
