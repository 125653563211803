const styles = (theme) => ({
  container: {
    display: "flex",
    margin: "-8px",
    flexWrap: "wrap",
  },
  controlWrap: {
    width: "30%",
    flexGrow: 1,
    flexShrink: 0,
    margin: "8px",

    "@media(max-width: 767px)": {
      minWidth: "180px",
    },
  },
  datePickerWrap: {
    fontSize: "12px",

    "@global": {
      ".DayPickerInput > input": {
        color: theme.colors.darkGrey,
        fontSize: "12px",
      },
      ".MuiSvgIcon-root": {
        color: theme.colors.darkGrey,
        width: "0.9em",
        height: "0.9em",
        position: "relative",
        right: "3px",
      },
    },
  },
  datacenterFilter: {
    "> *": {
      flexGrow: 1,
    },
  },
  panelTitle: {
    color: theme.colors.black,
    fontSize: "20px",
    lineHeight: "24px",
    margin: 0,
    marginBottom: "10px",
  },
  clickable: {
    cursor: "pointer",
  },
  label: {
    color: theme.colors.darkGrey,
    fontSize: "12px",
    lineHeight: "16px",
    marginBottom: "6px",
  },
  controlsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "-8px",
    paddingTop: "8px",

    "@media(max-width: 767px)": {
      flexWrap: "wrap",
    },
  },
  controlItem: {
    flexShrink: 0,
    margin: "8px",

    "@media(max-width: 767px)": {
      minWidth: "180px",
      flexGrow: 1,
    },
  },
});

export default styles;
