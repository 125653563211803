import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useDatacenterOptions from "../../../../hooks/useDatacenterOptions";
import Dropdown from "../../../../ui-kit/Dropdown";
import { formStyles } from "../styles";
import { getDatacentersWithGroups } from "utils/datacenter";

const useStyles = makeStyles(formStyles);

/**
 * @param {string} value
 * @param {function(value: string): void} onChange
 * @param {boolean} autoSelect
 * @param {object[]} datacenters
 * @param {object[]} groups
 * @param {object} rest
 * @return {JSX.Element}
 * @constructor
 */
export const DatacenterSelect = ({ value, onChange, autoSelect = false, datacenters, groups, ...rest }) => {
  const onChangeRef = useRef(onChange);
  const classes = useStyles();
  const dcWithGroups = getDatacentersWithGroups(datacenters, groups);
  const dcOptions = useDatacenterOptions(dcWithGroups);
  const handleChange = (event) => onChange(event.target.value);

  useEffect(() => {
    if (autoSelect && dcOptions.length === 1) {
      onChangeRef.current(dcOptions[0].value);
    }
  }, [autoSelect, dcOptions]);

  return (
    <Dropdown
      onChange={handleChange}
      value={value}
      options={dcOptions}
      className={classes.datacenterSelect}
      disabled={dcOptions.length <= 1}
      {...rest}
    />
  );
};

export default React.memo(DatacenterSelect);
