import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Icon, Spinner } from "@skyportal/ui-kit";
import { REQUEST_UNCALLED } from "../../constants/request";
import cn from "classnames";
import { getVmListRequest } from "../../store/selectors/vmSelectors";

import styles from "./styles";

const useStyles = makeStyles(styles);

const Tile = ({ icon, header, boldHeader, footer, color, clickHandler, progress, isSelected }) => {
  const isMmListRequesting = [REQUEST_UNCALLED].includes(useSelector(getVmListRequest));
  const classes = useStyles();

  return (
    <div className={cn(classes.tile, { [classes[color]]: color })} onClick={clickHandler}>
      <Spinner show={isMmListRequesting} />
      <div className={cn(classes.iconsSquare, { [classes[`${color}Icon`]]: color })}>
        <Icon className={classes.statusIcon} icon={icon} />
      </div>
      <div className={cn(classes.content)}>
        <div className={classes.header}>{header}</div>
        <div className={classes.boldHeader}>{boldHeader}</div>
        <div className={cn(classes.divider, color && classes[`${color}Icon`])}>
          <div className={classes.progress} style={{ width: `${progress || 0}%` }} />
        </div>
        <div className={classes.footer}>{footer}</div>
      </div>
      {!isSelected && <div className={classes.shadowCoverage} />}
    </div>
  );
};

export default Tile;
