import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RefreshButton from "../../components/RefreshButton";
import { notificationAC } from "../../store/actions/notificationActions";
import { setAllVmsAct } from "../../store/actions/virtualMachinesActions";
import { getVmListRequest } from "../../store/selectors/vmSelectors";
import { REQUEST_PENDING, REQUEST_UNCALLED } from "../../constants/request";

const VmRefreshButton = () => {
  const { t } = useTranslation("vmPage");
  const dispatch = useDispatch();
  const isMmListRequesting = [REQUEST_PENDING, REQUEST_UNCALLED].includes(useSelector(getVmListRequest));

  const handleOnSuccess = () => dispatch(notificationAC(t("refreshPageNotificationSuccess")));

  return (
    <RefreshButton
      testId="VmRefreshButton"
      isRequesting={isMmListRequesting}
      action={setAllVmsAct}
      onSuccess={handleOnSuccess}
    />
  );
};

export default VmRefreshButton;
