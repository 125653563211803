/**
 * @param {RootState} state
 * @return {UserState}
 */
export const getSlice = (state) => state.user;

/**
 * @param {RootState} state
 * @return {UserInfoModel|null}
 */
export const getUserProfile = (state) => getSlice(state).profile;
