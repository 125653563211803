import React from "react";
import { Icon } from "@skyportal/ui-kit";

const DatacenterFormBadge = ({ children }) => (
  <>
    <Icon icon="office" /> {children}
  </>
);

export default DatacenterFormBadge;
