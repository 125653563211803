import { gql } from "@apollo/client";
import { createDcClient } from "./clients";

/**
 * @typedef {object} ConsumptionMetric
 * @property {string} _id - timestamp
 * @property {number} cpu_count
 * @property {number} vcpu_count
 * @property {number} memory_size
 */

/**
 * @param {string} dcUrl
 * @param {string} start - YYYY-MM-DD
 * @param {string} end - YYYY-MM-DD
 * @param {"DAILY"|"MONTHLY"|"YEARLY"} typeVM
 * @return {Promise<{consumption: ConsumptionMetric[]}|Promise>}
 */
export const fetchConsumption = async (dcUrl, start, end, typeVM = "DAILY") =>
  createDcClient(dcUrl).query({
    query: gql`
    query DashboardSchema {
      consumption: UsageData(typeVM: ${typeVM}, start: "${start}", end: "${end}") {
        _id
        vcpu_count
        memory_size
      }
    }
  `,
  });

/**
 * @typedef {object} StoredMetric
 * @property {string} _id - timestamp
 * @property {number} vm_count
 * @property {number} image_size
 * @property {ReportTierApiModel[]} tiers
 */

/**
 * @param {string} dcUrl
 * @param {string} start - YYYY-MM-DD
 * @param {string} end - YYYY-MM-DD
 * @return {Promise<{stored: StoredMetric[]}|Promise>}
 */
export const fetchStored = async (dcUrl, start, end) =>
  createDcClient(dcUrl).query({
    query: gql`
      query DashboardSchema {
        stored: UsageData(typeVM: DAILY, start: "${start}", end: "${end}") {
          _id
          vm_count
          image_size,
          tiers {
            name,
            image_size
          }
        }
      }
    `,
  });

/**
 * @typedef {object} VmTotals
 * @property {number} total
 * @property {number} running
 * @property {number} pending
 * @property {number} stopped
 */

/**
 * @typedef {object} TopMemoryVM
 * @property {string} id - VM id OpenNebula
 * @property {string} name
 * @property {object} hardware
 * @property {number} hardware.memory
 */

/**
 * @typedef {object} TopCpuVM
 * @property {string} id - VM id OpenNebula
 * @property {string} name
 * @property {object} hardware
 * @property {number} hardware.cpu
 */

/**
 * @typedef {object} TopUsedDataVM
 * @property {string} id - VM id OpenNebula
 * @property {string} name
 * @property {number} size
 */

/**
 * @typedef {object} UsageStatistic
 * @property {number} vmTotal
 * @property {number} vcpuTotal
 * @property {number} cpuTotal
 * @property {number} dataStored
 * @property {number} memoryTotal
 */

/**
 * @description Combine two events - "Open Nebula" and "Custom API" event
 * Diffs: Open Nebula event has username = null and type = "VM"
 *
 * @typedef {object} ActionHistory
 * @property {string} name
 * @property {string} action_name
 * @property {string} reason_name
 * @property {string} username
 * @property {"VM"|"Image"|"Snapshot"} type
 * @property {number|null} reason
 * @property {string} gname - Group name
 * @property {string} gdisplayname - User-friendly group name
 * @property {number|null} group - Group name
 * @property {number} stime - start time timestamp
 *
 * @property {string} _ID - frontend decoration - unique
 */

/**
 * @typedef {object} AllDashboardMetrics
 * @property {UsageStatistic} usageStatistic
 * @property {TopMemoryVM[]} topMemory
 * @property {TopCpuVM[]} topCpu
 * @property {ConsumptionMetric[]} consumption
 * @property {StoredMetric[]} stored
 * @property {VmTotals} vmTotals
 * @property {TopUsedDataVM[]} topUsedData
 * @property {ActionHistory[]} actionHistory
 */

/**
 * @param {string} dcUrl
 * @param {object} stored
 * @param {string} stored.start - YYYY-MM-DD
 * @param {string} stored.end - YYYY-MM-DD
 * @param {object} consumption
 * @param {string} consumption.start - YYYY-MM-DD
 * @param {string} consumption.end - YYYY-MM-DD
 * @param {number} historyAmount
 * @return {Promise<AllDashboardMetrics>|Promise}
 */
export const fetchAllMetrics = ({ dcUrl, stored, consumption, historyAmount = 50 }) =>
  createDcClient(dcUrl).query({
    query: gql`
      query DashboardSchema {
        topMemory: TopVMByHardware(hardware: MEMORY, amount: 5) {
          id
          name
          hardware {
            memory
          }
        }
        topCpu: TopVMByHardware(hardware: CPU, amount: 5) {
          id
          name
          hardware {
            cpu
          }
        }
        topUsedData: TopUsedVM(amount: 5) {
          id,
          name,
          size
        }
        consumption: UsageData(typeVM: DAILY, start: "${consumption.start}", end: "${consumption.end}") {
          _id
          vcpu_count
          memory_size
        }
        stored: UsageData(typeVM: DAILY, start: "${stored.start}", end: "${stored.end}") {
          _id
          vm_count
          image_size,
          tiers {
            image_size, name
          }
        }
        vmTotals: TotalVM {
          pending,
          running,
          stopped,
          total
        }
        usageStatistic: UsageStatistic {
          cpuTotal,
          vcpuTotal,
          dataStored,
          vmTotal,
          memoryTotal,
        }
        actionHistory: HistoryData (amount: ${historyAmount}) {
          data {
            username,
            type,
            name,
            action_name,
            gname,
            gdisplayname,
            stime,
            reason
          },
          _meta {
            total
          }
        }
      }
    `,
  });

/**
 * @param {string} dcUrl
 * @param {string} start = YYYY-MM-DD
 * @param {string} end = YYYY-MM-DD
 * @param {number} [historyAmount=2000]
 * @return {Promise<AllDashboardMetrics>|Promise}
 */
export const fetchEventHistory = ({ dcUrl, historyAmount = 2000, start, end }) =>
  createDcClient(dcUrl).query({
    query: gql`
    query DashboardSchema {
      actionHistory: HistoryData (amount: ${historyAmount}, start: "${start}", end: "${end}") {
        data {
          username,
          type,
          name,
          action_name,
          gname,
          gdisplayname,
          stime,
          reason
        },
        _meta {
          total
        }
      }
    }
  `,
  });
