const styles = (theme) => ({
  tableRowShell: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 8px",
  },
  titleCell: {
    cursor: "pointer",
    width: "12%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
    userSelect: "none",
    padding: "0 8px",
    boxSizing: "border-box",
  },
  cell: {
    display: "flex",
    alignItems: "center",
    padding: "8px 0",
    width: "12%",
    flexGrow: 1,

    "@media(max-width: 767px)": {
      padding: "4px 0",
    },
  },
  cellShell: {
    ...theme.classes.table.cellShell,
    marginRight: 0,
    maxHeight: "auto",
    padding: "0 8px",
    marginLeft: 0,
  },
  inCell: theme.classes.table.inCell,
  tierClassName: {
    display: "inline-block",
    borderRadius: "2px",
    fontSize: "10px",
    padding: "1px 3px",
    marginRight: "2px",
    marginBottom: "2px",
    color: "#fff",
    backgroundColor: theme.colors.inactiveGrey,
  },
  dcRowControls: theme.classes.centralized,
  controlsRow: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0",
    alignItems: "center",
    borderBottom: `3px solid #f4f4f4`,
    width: "100%",
  },
  icon: {
    color: theme.colors.inactiveGrey,
    marginRight: "8px",
  },
  columnName: {
    fontWeight: "bold",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #f4f4f4",
    margin: "0 8px",
  },
  totalRow: {
    display: "flex",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    padding: "0 8px",
    boxSizing: "border-box",
    borderRadius: "6px",

    "& > div": {
      boxSizing: "border-box",
      padding: "8px",
      minHeight: "52px",
      display: "flex",
      alignItems: "center",
      fontWeight: "600",
    },

    "& > div:nth-child(1)": {
      width: "50%",
    },
    "& > div:nth-child(2)": {
      width: "16.6%",
    },
    "& > div:nth-child(3)": {
      width: "16.6%",
      flexWrap: "wrap",
    },
    "& > div:nth-child(4)": {
      width: "16.6%",
      flexWrap: "wrap",
    },
  },
});

export default styles;
