import React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";
import { Icon } from "@skyportal/ui-kit";

import styles from "./styles";

const useStyles = makeStyles(styles);

const HeadCell = ({ width, sortDisabled, onSortClick, sortOrder = "asc", children, isActive }) => {
  const classes = useStyles();

  return (
    <th className={classes.tableHeadCell} width={width} onClick={sortDisabled ? undefined : onSortClick}>
      {!sortDisabled && (
        <Icon
          type="fontawesome"
          className={cn(classes.icon, { [classes.iconActive]: isActive })}
          icon={sortOrder === "asc" || !isActive ? "sort-amount-down-alt" : "sort-amount-down"}
        />
      )}
      {children}
    </th>
  );
};

export default HeadCell;
