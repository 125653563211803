import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getDataCenterList } from "../store/selectors/dcSelectors";

const useDatacenterOnLoad = (cb) => {
  const savedCb = useRef(cb);
  const dataCenterList = useSelector(getDataCenterList);

  savedCb.current = cb;

  useEffect(() => {
    if (dataCenterList.length) {
      savedCb.current();
    }
  }, [dataCenterList]);
};

export default useDatacenterOnLoad;
