import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from "@apollo/client";

const responseErrorCleaner = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (Array.isArray(response.errors)) {
      console.error("GraphQL ERROR:", { response, errors: [...response.errors] });
      response.errors.length = 0;
    }

    return response;
  });
});

const clientMap = new Map();

/**
 * @param {string} uri - datacenter
 */
export const createDcClient = (uri) => {
  if (!clientMap.has(uri)) {
    clientMap.set(
      uri,
      new ApolloClient({
        cache: new InMemoryCache({
          resultCaching: false,
        }),
        credentials: "include",
        link: responseErrorCleaner.concat(new HttpLink({ uri: `${uri}/v1/dashboard`, credentials: "include" })),
      })
    );
  }

  return clientMap.get(uri);
};
