import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDetailedReportTotals } from "../../../../store/selectors/reportSelectors";
import { formatSize } from "../../../../utils/size";
import styles from "./styles";

const useStyles = makeStyles(styles);

const DetailedTotalRow = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const totals = useSelector(getDetailedReportTotals);

  return (
    <div data-testid="detailed_report_total_row" className={classes.totalRow}>
      <div>
        {t("iaasCommon:Total")}: {totals.count}
      </div>
      <div>{totals.vcpu}</div>
      <div>{formatSize(totals.memory, true)}</div>
      <div>
        <div style={{ width: "100%" }}>{formatSize(totals.storage, true)}</div>
        <div>
          {totals.tiers.map((item) => (
            <span className={classes.tierClassName} key={item.name}>
              {formatSize(item.storage, true)} - {item.name}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DetailedTotalRow;
