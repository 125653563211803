import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import DetailedReportDatePicker from "./DetailedReportDatePicker";
import DetailedReportDatacenterSelect from "./DetailedReportDatacenterSelect";
import DetailedReportGroupSelect from "./DetailedReportGroupSelect";
import styles from "./styles";
import { Button } from "@skyportal/ui-kit";

const useStyles = makeStyles(styles);

const DetailsControls = ({ handleSubmit }) => {
  const { t } = useTranslation("usageReportsPage");
  const classes = useStyles();

  return (
    <div className={classes.controlContainer}>
      <div className={classes.controlBoxItem}>
        <div className={classes.label}>{t("filterBar.fields.date.label")}</div>
        <DetailedReportDatePicker />
      </div>
      <div className={classes.controlBoxItem}>
        <div className={classes.label}>{t("filterBar.fields.datacenter.label")}</div>
        <DetailedReportDatacenterSelect />
      </div>
      <div className={classes.controlBoxItem}>
        <div className={classes.label}>{t("filterBar.fields.group.label")}</div>
        <DetailedReportGroupSelect />
      </div>
      <div className={cn(classes.controlBoxItem, classes.controlButtonBoxItem)}>
        <Button data-testid="submitFormBtn" onClick={handleSubmit}>
          <span>{t("iaasCommon:Run")}</span>
        </Button>
      </div>
    </div>
  );
};

export default DetailsControls;
