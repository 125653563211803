import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { filterVMsAct } from "../../../store/actions/virtualMachinesActions";
import { vmSel } from "../../../store/selectors/vmSelectors";
import { isVmPending, isVmRunning, isVmStopped } from "../../../utils/vm";
import { VM_STATUS_STRING_PENDING, VM_STATUS_STRING_RUNNING, VM_STATUS_STRING_STOPPED } from "../../../constants/vm";
import Tile from "../../../ui-kit/Tile";

import styles from "./styles";

const useStyles = makeStyles(styles);

const getPercentage = (part, total) => Math.round((part / total) * 100);

const VMTileBar = ({ vms }) => {
  const { t } = useTranslation("vmPage");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedFilter } = useSelector(vmSel);
  const { totalAmount, runningAmount, pendingAmount, stoppedAmount } = useMemo(
    () => ({
      totalAmount: vms.length,
      runningAmount: vms.filter((vm) => isVmRunning(vm)).length,
      pendingAmount: vms.filter((vm) => isVmPending(vm)).length,
      stoppedAmount: vms.filter((vm) => isVmStopped(vm)).length,
    }),
    [vms]
  );

  const sortVMs = useCallback(
    (value) => {
      dispatch(filterVMsAct(value));
    },
    [dispatch]
  );

  return (
    <div className={classes.VMtileBar}>
      <div className={classes.tileContainer}>
        <div className={classes.tileItem}>
          <Tile
            icon="thumbs"
            header={t("iaasCommon:total")}
            boldHeader={t("titleAlias")}
            footer={`${t("iaasCommon:vmWithCount", { count: totalAmount })} ${t("iaasCommon:total")}`}
            color="blue"
            clickHandler={() => sortVMs("")}
            progress={100}
            isSelected={selectedFilter === ""}
          />
        </div>
        <div className={classes.tileItem}>
          <Tile
            icon="thumbs-up"
            header={t("iaasCommon:running")}
            boldHeader={t("titleAlias")}
            footer={`${t("iaasCommon:vmWithCount", { count: runningAmount })} ${t("iaasCommon:running")}`}
            color="green"
            clickHandler={() => sortVMs(VM_STATUS_STRING_RUNNING)}
            progress={getPercentage(runningAmount, totalAmount)}
            isSelected={selectedFilter === "" || selectedFilter === VM_STATUS_STRING_RUNNING}
          />
        </div>
        <div className={classes.tileItem}>
          <Tile
            icon="info-attention"
            header={t("iaasCommon:pending")}
            boldHeader={t("titleAlias")}
            footer={`${t("iaasCommon:vmWithCount", { count: pendingAmount })} ${t("iaasCommon:pending")}`}
            color="brown"
            clickHandler={() => sortVMs(VM_STATUS_STRING_PENDING)}
            isSelected={selectedFilter === "" || selectedFilter === VM_STATUS_STRING_PENDING}
            progress={getPercentage(pendingAmount, totalAmount)}
          />
        </div>
        <div className={classes.tileItem}>
          <Tile
            icon="thumbs-down"
            header={t("iaasCommon:stopped")}
            boldHeader={t("titleAlias")}
            footer={`${t("iaasCommon:vmWithCount", { count: stoppedAmount })} ${t("iaasCommon:stopped")}`}
            color="red"
            clickHandler={() => sortVMs(VM_STATUS_STRING_STOPPED)}
            isSelected={selectedFilter === "" || selectedFilter === VM_STATUS_STRING_STOPPED}
            progress={getPercentage(stoppedAmount, totalAmount)}
          />
        </div>
      </div>
    </div>
  );
};

export default VMTileBar;
