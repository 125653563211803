import axios from "axios";
import {
  getSummary,
  getDetailedReports,
  sendSummaryReportViaEmail,
  sendDetailedReportViaEmail,
  getStorageDetailsReport,
  sendDetailedStorageReportViaEmail,
} from "../../api/reportService";
import i18n from "../../locales";
import { formatDate } from "../../utils/dates";
import { reportSel } from "../selectors/reportSelectors";
import { DETAILED_REPORT_TYPE, DETAILED_STORAGE_REPORT_TYPE, SUMMARY_REPORT_TYPE } from "../../pages/constants";
import { notificationAC, notificationErrorAC } from "./notificationActions";
import { EMPTY_GROUP_VALUE } from "../../pages/Reports/ReportsFilterBar/constants";
import { decorateWithMergedGroupAndAgreementIdFiled } from "../../utils/reports";
import {
  SET_REPORTS,
  SET_CURRENT_REPORT_PAGE,
  SORT_REPORTS,
  SET_REPORT_FILTER_TYPE,
  SET_REPORT_START_DATE,
  SET_REPORT_END_DATE,
  SET_REPORT_GROUP,
  SET_DETAILED_DATE,
  SET_DETAILED_REPORTS,
  SEND_EMAIL_REPORT_REQUEST,
  SEND_EMAIL_REPORT_REQUEST_SUCCESS,
  SEND_EMAIL_REPORT_REQUEST_FAILED,
  SET_REPORTS_PER_PAGE,
  RESET_REPORTS_SORT_STATE,
  SET_REPORT_DATACENTER,
  REPORTS_REQUEST,
  DETAILED_REPORTS_REQUEST,
  STORAGE_DETAILS_REPORTS_REQUEST,
  STORAGE_DETAILS_REPORTS_REQUEST_SUCCESS,
  STORAGE_DETAILS_REPORTS_REQUEST_FAILURE,
  SET_REPORT_FILTER_STATUSES,
} from "../types";
import { parseApiErrorMessage } from "api/utils";

const setSummaryReports = (payload) => ({
  type: SET_REPORTS,
  payload,
});

const reportRequestAC = () => ({
  type: REPORTS_REQUEST,
});

const setDetailedReports = (payload) => ({
  type: SET_DETAILED_REPORTS,
  payload,
});

const detailedReportRequestAC = () => ({
  type: DETAILED_REPORTS_REQUEST,
});

const storageDetailsReportRequestAC = () => ({
  type: STORAGE_DETAILS_REPORTS_REQUEST,
});

const storageDetailsReportRequestFailureAC = () => ({
  type: STORAGE_DETAILS_REPORTS_REQUEST_FAILURE,
});

const storageDetailsReportRequestSuccessAC = (payload) => ({
  type: STORAGE_DETAILS_REPORTS_REQUEST_SUCCESS,
  payload,
});

export const sortReportsAct = (payload) => ({
  type: SORT_REPORTS,
  payload,
});

/**
 * @param {number} payload
 * @return {{payload: number, type: "SET_REPORTS_PER_PAGE"}}
 */
export const setReportsPerPageAC = (payload) => ({
  type: SET_REPORTS_PER_PAGE,
  payload,
});

/**
 * @return {{type: "RESET_REPORTS_SORT_STATE"}}
 */
export const resetReportsSortStateAC = () => ({
  type: RESET_REPORTS_SORT_STATE,
});

export const setCurrentReportPageAct = (payload) => ({
  type: SET_CURRENT_REPORT_PAGE,
  payload,
});

export const setReportFilterType = (payload) => ({
  type: SET_REPORT_FILTER_TYPE,
  payload,
});

export const setReportGroup = (payload) => ({
  type: SET_REPORT_GROUP,
  payload,
});

export const setReportFilterStatuses = (payload) => ({
  type: SET_REPORT_FILTER_STATUSES,
  payload,
});

export const setReportStartDate = (payload) => ({
  type: SET_REPORT_START_DATE,
  payload,
});
/**
 * @param {string} datacenterId
 * @return {{payload: string, type: string}}
 */
export const setReportDatacenterId = (datacenterId) => ({
  type: SET_REPORT_DATACENTER,
  payload: datacenterId,
});

/**
 * @param {string} datacenterId
 * @return {{payload: string, type: string}}
 */

export const setReportEndDate = (payload) => ({
  type: SET_REPORT_END_DATE,
  payload,
});

export const setDetailedDate = (payload) => ({
  type: SET_DETAILED_DATE,
  payload,
});

/**
 * @param {"daily"|"monthly"|"yearly"} reportFilterType
 * @param {string} selectionStartDate
 * @param {string} selectionEndDate
 * @param {string|null} reportGroupId
 * @param {string|null} reportDatacenterId
 * @param {string[]} summaryReportFilterStatuses
 * @return {ReportQueryParams}
 */
export const composeSummaryReportParams = ({
  reportFilterType,
  selectionStartDate,
  selectionEndDate,
  reportGroupId,
  reportDatacenterId,
  summaryReportFilterStatuses,
}) => ({
  type: reportFilterType,
  start: formatDate(selectionStartDate),
  end: formatDate(selectionEndDate),
  datacenter: reportDatacenterId && reportDatacenterId !== EMPTY_GROUP_VALUE ? reportDatacenterId : null,
  agreementID: reportGroupId && reportGroupId !== EMPTY_GROUP_VALUE ? reportGroupId : null,
  status: summaryReportFilterStatuses.join(","),
});

export const getSummaryReportRequest = () => async (dispatch, getState) => {
  const reportState = reportSel(getState());
  dispatch(reportRequestAC());
  try {
    /**
     * @type {ReportQueryParams}
     */
    const params = composeSummaryReportParams(reportState);
    const { data } = await getSummary(params);
    dispatch(setSummaryReports(data));
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch(setSummaryReports([]));
    }
  }
};

/**
 * @param {string} date
 * @param {string} [datacenter]
 * @param {string} [group] - each group equal agreementID
 * @returns {DetailedReportQueryParams}
 */
export const composeDetailedReportParams = ({ date, datacenter, group }) => ({
  date: formatDate(date),
  datacenter: datacenter && datacenter !== EMPTY_GROUP_VALUE ? datacenter : null,
  agreementID: group && group !== EMPTY_GROUP_VALUE ? group : null,
});

export const composeDetailsParamsFromState = (state) => {
  const { selectionDetailedDate, reportDatacenterId, reportGroupId } = reportSel(state);
  return composeDetailedReportParams({
    date: selectionDetailedDate,
    datacenter: reportDatacenterId,
    group: reportGroupId,
  });
};

export const getDetailedReportRequest = () => async (dispatch, getState) => {
  dispatch(detailedReportRequestAC());
  try {
    const { data } = await getDetailedReports(composeDetailsParamsFromState(getState()));
    dispatch(setDetailedReports(data.map(decorateWithMergedGroupAndAgreementIdFiled)));
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch(setDetailedReports([]));
    }
  }
};

export const fetchStorageDetailsReport = () => async (dispatch, getState) => {
  dispatch(storageDetailsReportRequestAC());
  try {
    const { data } = await getStorageDetailsReport(composeDetailsParamsFromState(getState()));
    dispatch(storageDetailsReportRequestSuccessAC(data.map(decorateWithMergedGroupAndAgreementIdFiled)));
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch(storageDetailsReportRequestFailureAC());
    }
  }
};

export const sendEmailReportRequestAC = () => ({
  type: SEND_EMAIL_REPORT_REQUEST,
});

export const sendEmailReportRequestSuccessAC = () => ({
  type: SEND_EMAIL_REPORT_REQUEST_SUCCESS,
});

export const sendEmailReportRequestFailureAC = () => ({
  type: SEND_EMAIL_REPORT_REQUEST_FAILED,
});

const REPORT_API_METHOD = {
  [SUMMARY_REPORT_TYPE]: sendSummaryReportViaEmail,
  [DETAILED_REPORT_TYPE]: sendDetailedReportViaEmail,
  [DETAILED_STORAGE_REPORT_TYPE]: sendDetailedStorageReportViaEmail,
};

/**
 * @param {ReportType} type
 * @param {object} params
 */
export const sendReportEmailRequest = (type, params) => async (dispatch) => {
  const apiHandler = REPORT_API_METHOD[type];

  if (!apiHandler) {
    return;
  }

  try {
    dispatch(sendEmailReportRequestAC());
    await apiHandler(params);
    dispatch(sendEmailReportRequestSuccessAC());
    dispatch(notificationAC(i18n.t("notification:emailReportSendSuccess")));
  } catch (error) {
    dispatch(sendEmailReportRequestFailureAC());
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:emailReportSendFailed"),
        description: parseApiErrorMessage(error),
      })
    );
  }
};
