import { axiosReportClient } from "./web";

/**
 * Metric which divide DetailedReportApiModel.image_size | SummaryReportApiModel.(image_count|image_size) by tiers
 *
 * @typedef {object} ReportTierApiModel
 * @property {string|null} name
 * @property {number} image_count
 * @property {number} image_size
 */

/**
 * @typedef {object} ReportQueryParams
 * @property {string} start - Date format YYYY-MM-DD
 * @property {string} end - Date format YYYY-MM-DD
 * @property {"daily"|"monthly"|"yearly"} type
 * @property {string|null} agreementID
 * @property {string|null} datacenter
 */

/**
 * @typedef {object} DetailedReportApiModel
 * @property {string} _id - example 2020-07-14T00:00:00.000Z
 * @property {string} name
 * @property {object} status
 * @property {string} status.state
 * @property {string} status.substate
 * @property {string} status.poll - example 1594748031
 * @property {string} status.start - example 1594748031
 * @property {number} vm_count
 * @property {number} cpu_count
 * @property {number} memory_size
 * @property {number} image_size
 * @property {ReportTierApiModel[]} tiers
 */

/**
 * @param {ReportQueryParams} params
 */
export const getSummary = (params) =>
  axiosReportClient.request({
    url: "/v1/usage/summary",
    method: "GET",
    params,
  });

/**
 * @typedef {object} DetailedReportQueryParams
 * @property {string} date - Date format YYYY-MM-DD
 * @property {string|null} datacenter - id
 * @property {string|null} agreementID - id
 */

/**
 * @typedef {object} SummaryReportApiModel
 * @property {string} _id - example 2020-07-14T00:00:00.000Z
 * @property {number} vm_count
 * @property {number} cpu_count
 * @property {number} vcpu_count
 * @property {number} memory_size
 * @property {number} image_count
 * @property {number} image_size
 * @property {ReportTierApiModel[]} tiers
 */

/**
 * @param {DetailedReportQueryParams} params
 */
export const getDetailedReports = (params) =>
  axiosReportClient.request({
    url: "/v1/usage/detailed",
    method: "GET",
    params,
  });

/**
 * @param {DetailedReportQueryParams} params
 */
export const getStorageDetailsReport = (params) =>
  axiosReportClient.request({
    url: "/v1/usage/detailed-storage",
    method: "GET",
    params,
  });

/**
 * @param {DetailedReportQueryParams} params
 * @param {string} params.email
 * @param {ReportAcceptFileType} params.accept
 */
export const sendSummaryReportViaEmail = (params) =>
  axiosReportClient.request({
    url: "/v1/usage/summary",
    method: "POST",
    params,
    timeout: 30000,
  });

/**
 * @param {DetailedReportQueryParams} params
 * @param {string} params.email
 * @param {ReportAcceptFileType} params.accept
 */
export const sendDetailedReportViaEmail = (params) =>
  axiosReportClient.request({
    url: "/v1/usage/detailed",
    method: "POST",
    params,
    timeout: 30000,
  });

/**
 * @param {DetailedReportQueryParams} params
 * @param {string} params.email
 * @param {ReportAcceptFileType} params.accept
 */
export const sendDetailedStorageReportViaEmail = (params) =>
  axiosReportClient.request({
    url: "/v1/usage/detailed-storage",
    method: "POST",
    params,
    timeout: 30000,
  });
