import React from "react";
import { Icon } from "@skyportal/ui-kit";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DATE_FORMAT } from "../../pages/Reports/ReportsFilterBar/constants";
import { handleDateWrapperClick } from "../../pages/Reports/ReportsFilterBar/utils";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate } from "../../utils/dates";
import DatePickerNavBar from "./DatePickerNavBar";
import DatePickerWeekElement from "./DatePickerWeekElement";

import "react-day-picker/lib/style.css";
import styles from "./styles";

const useStyles = makeStyles(styles);

const DatePicker = ({ onDayChange, value, dayPickerProps, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.inputMask}
      onClick={handleDateWrapperClick}
    >
      <Icon icon="calendar" className={classes.calendarIcon} />
      <DayPickerInput
        onDayChange={onDayChange}
        value={value}
        dayPickerProps={{
          firstDayOfWeek: 1,
          format: DATE_FORMAT,
          selectedDays: value,
          showOutsideDays: true,
          navbarElement: DatePickerNavBar,
          weekdayElement: DatePickerWeekElement,
          ...dayPickerProps,
        }}
        format={DATE_FORMAT}
        formatDate={formatDate}
        placeholder={DATE_FORMAT}
        {...rest}
      />
      <KeyboardArrowDownIcon />
    </Grid>
  );
};

export default DatePicker;
