import React from "react";
import { SplashScreen } from "@skyportal/ui-kit";
import useInitApp from "../../hooks/useInitApp";

const ProfileRenderer = ({ children }) => {
  const { isInit, profile } = useInitApp();

  if (!isInit) {
    return <SplashScreen />;
  }

  return children({ profile });
};

export default ProfileRenderer;
