import { useEffect, useRef } from "react";

const useInterval = (cb, interval) => {
  const savedCb = useRef(cb);
  savedCb.current = cb;

  useEffect(() => {
    const tick = () => savedCb.current();

    if (interval > 0) {
      const id = setInterval(tick, interval);
      return () => clearInterval(id);
    }
  }, [interval]);
};

export default useInterval;
