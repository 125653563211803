import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button } from "@skyportal/ui-kit";
import Input from "../../../../ui-kit/Input";
import Radio from "../../../../ui-kit/Radio";
import SortableTable from "../../../../ui-kit/SortableTable/SortableTable";
import { makeNetworkToAttachList, getNetworkSlice } from "../../../../store/selectors/networkSelectors";
import { attachNetworkRequest, setAllVmsAct } from "../../../../store/actions/virtualMachinesActions";
import Spinner from "../../../../pages/shareableComponents/Spinner/Spinner";
import {
  setModalNetworkCurrentPageAC,
  setModalNetworkSearchTextAC,
  setModalNetworkSortFieldAC,
} from "../../../../store/actions/networkActions";

import styles from "../styles";

const useStyles = makeStyles(styles);

/**
 * @param {boolean} open
 * @param {function} onClose
 * @param {VmStateModel} vm
 * @return {JSX.Element}
 * @constructor
 */
const AttachNetworkModal = ({ open, onClose, vm }) => {
  const { t } = useTranslation("vmPage");
  const dispatch = useDispatch();
  const [networkToAttacheId, setNetworkToAttacheId] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const { searchText, sortOrder, sortField } = useSelector(getNetworkSlice);
  const { list, pageCount } = useSelector(makeNetworkToAttachList(vm));
  const classes = useStyles();
  const columnsConfig = useMemo(
    () => [
      {
        key: "",
        width: "30px",
        label: "",
        sortDisabled: true,
        cellClassName: classes.checkboxCell,
        render: (networkListItem) => (
          <Radio
            name="network"
            onChange={(event) => setNetworkToAttacheId(event.target.value)}
            value={String(networkListItem.id)}
            checked={networkToAttacheId === String(networkListItem.id)}
          />
        ),
      },
      {
        key: "name",
        width: "45%",
        label: t("iaasCommon:Name"),
      },
      {
        key: "__groupName",
        width: "45%",
        label: t("iaasCommon:Group"),
      },
    ],
    [networkToAttacheId, t, classes.checkboxCell]
  );

  const handleAddBtnClick = async () => {
    try {
      setIsRequesting(true);
      await dispatch(attachNetworkRequest(vm, Number(networkToAttacheId)));
      await dispatch(setAllVmsAct());
      onClose();
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
    setIsRequesting(false);
  };

  const handleSearchChange = (event) => dispatch(setModalNetworkSearchTextAC(event.target.value));
  const handlePageChange = (_, page) => dispatch(setModalNetworkCurrentPageAC(page));
  const handleSortFieldChange = (sortFiled) => dispatch(setModalNetworkSortFieldAC(sortFiled || ""));

  useEffect(() => {
    if (!open) {
      setNetworkToAttacheId(null);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <div className={classes.modalBody} data-testid="AttachNetworkModal">
          <Spinner show={isRequesting} />
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>{t("attachNetworkModal.title")}</h2>
            <Input
              onChange={handleSearchChange}
              defaultValue={searchText}
              placeholder={t("attachNetworkModal.searchPlaceholder")}
            />
          </div>
          <div className={classes.tableBody}>
            <SortableTable
              testId="AttachNetworkModal_table"
              data={list}
              onSortFieldChange={handleSortFieldChange}
              columnsConfig={columnsConfig}
              sortField={sortField}
              sortOrder={sortOrder}
              className={classes.table}
            />
          </div>

          <div className={classes.pagingContainer}>
            <div className={classes.imageModalActions}>
              <Button disabled={networkToAttacheId === null || isRequesting} onClick={handleAddBtnClick}>
                {t("iaasCommon:Add")}
              </Button>
              <Button type="secondary" onClick={onClose}>
                {t("iaasCommon:Cancel")}
              </Button>
            </div>
            <div className={classes.pagination}>
              <Pagination page={1} count={pageCount} onChange={handlePageChange} variant="outlined" shape="rounded" />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(AttachNetworkModal);
