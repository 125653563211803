import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { isResizingVmHardwareAllowed } from "../../../helpers";
import IconTooltip from "../../../../components/IconTooltip";
import CpuSlider from "./CpuSlider";
import FormFieldset from "./FormFieldset";
import { formStyles } from "../styles";

const useStyles = makeStyles(formStyles);

export const CPUFieldset = ({ value, onChange, altValue, onAltChange, isEditVMPage = false, template, currentVm }) => {
  const { t } = useTranslation("vmPage");
  const cpuRef = useRef(null);
  const classes = useStyles();
  const isResizeDisallow = isEditVMPage && currentVm && !isResizingVmHardwareAllowed(currentVm);

  const handleCpuChange = (_, _value) => onChange(_value);

  return (
    <FormFieldset
      ref={cpuRef}
      title={
        <>
          {t("createOrEdit.fieldset.vCpu")}
          {isResizeDisallow && (
            <IconTooltip
              testId="cpu_info_icon"
              title={t("createOrEdit.fieldset.vCpuEditTooltip")}
              className={classes.iconTooltip}
            />
          )}
        </>
      }
      subTitle={t("createOrEdit.fieldset.vCpuHint")}
    >
      <div>
        <CpuSlider
          VmCpuSliderValue={value}
          setVmCpuSlider={onChange}
          handleCpuChange={handleCpuChange}
          selectedTemplate={template}
          VmCpuInputValue={altValue}
          setVmCpuInput={onAltChange}
          isEditVMPage={isEditVMPage}
          cpuRef={cpuRef}
          currentVm={currentVm}
        />
      </div>
    </FormFieldset>
  );
};

export default React.memo(CPUFieldset);
