import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import userReducer from "./reducers/User/userReducer";
import regionReducer from "./reducers/Region/regionReducer";
import datacenterReducer from "./reducers/Datacenter/datacenterReducer";
import vmReducer from "./reducers/VM/vmReducer";
import groupReducer from "./reducers/Group/groupReducer";
import networkReducer from "./reducers/Network/networkReducer";
import authReducer from "./reducers/Auth/authReducer";
import dashboardReducer from "./reducers/Dashboard/dashboardReducer";
import imagesReducer from "./reducers/Images/imgReducer";
import reportReducer from "./reducers/Report/reportReducer";
import editCreateVmReducer from "./reducers/EditCreateVm/editCreateVmReducer";
import generalReducer from "./reducers/General/generalReducer";
import eventHistoryReducer from "./reducers/EventHistory/eventHistoryReducer";
import pricelistReducer from "./reducers/Pricelist/pricelistReducer";

/**
 * @typedef {object} RootState
 * @property {UserState} user
 * @property {RegionState} region
 * @property {DataCenterState} dc
 * @property {VmState} vm
 * @property {GroupState} group
 * @property {AuthState} auth
 * @property {DashboardState} dashboard
 * @property {NetworkState} network
 * @property {ImagesState} img
 * @property {EditCreateVmState} ecVmPage
 * @property {object} general
 * @property {object} reports
 * @property {EventHistoryState} eventHistory
 * @property {object} pricelist
 */

const composeEnhancers =
  (process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  combineReducers({
    user: userReducer,
    region: regionReducer,
    dc: datacenterReducer,
    vm: vmReducer,
    group: groupReducer,
    network: networkReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    img: imagesReducer,
    ecVmPage: editCreateVmReducer,
    general: generalReducer,
    reports: reportReducer,
    eventHistory: eventHistoryReducer,
    pricelist: pricelistReducer,
  }),
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
