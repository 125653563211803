import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getUserProfile } from "store/selectors/userSelectors";
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../../pages/shareableComponents/PageHeader";
import ReportsFilterBar from "../../pages/Reports/ReportsFilterBar/ReportsFilterBar";
import ReportsContent from "./ReportsContent/ReportsContent";
import { hasUserAccessToFeature, FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";

import styles from "./styles";

const useStyles = makeStyles(styles);

const Reports = () => {
  const { t } = useTranslation("usageReportsPage");
  const userProfile = useSelector(getUserProfile);

  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const hasUserAccessToSummary = hasUserAccessToFeature(userProfile, FEATURES.IAAS_USAGE_REPORT, [USER_ACCESS.VIEW]);

  useEffect(() => {
    if (pathname === "/infra/reports") {
      hasUserAccessToSummary ? history.replace("/infra/reports/summary") : history.replace("/infra/reports/detailed");
    }
  }, [pathname, history, hasUserAccessToSummary]);

  return (
    <div className={classes.page}>
      <PageHeader title={t("title")} />
      <ReportsFilterBar />
      <ReportsContent userProfile={userProfile} />
    </div>
  );
};

export default Reports;
