import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Dropdown from "../../../../ui-kit/Dropdown";
import { setReportDatacenterId } from "../../../../store/actions/reportsActions";

import useDatacenterOptions from "../../../../hooks/useDatacenterOptions";
import { addAllOption } from "../utils";
import { reportSel } from "../../../../store/selectors/reportSelectors";

import styles from "../styles";

const useStyles = makeStyles(styles);

const DetailedReportDatacenterSelect = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dcOptions = useDatacenterOptions();
  const dcOptionsWithAllOption = useMemo(() => addAllOption(dcOptions), [dcOptions]);
  const { reportDatacenterId } = useSelector(reportSel);
  const handleChange = (event) => dispatch(setReportDatacenterId(event.target.value));

  return (
    <Dropdown
      id="datacenterSelect"
      onChange={handleChange}
      value={reportDatacenterId}
      options={dcOptionsWithAllOption}
      disabled={dcOptions.length === 0}
      classes={{ select: classes.muiSelectRoot, icon: classes.selectIcon }}
    />
  );
};

export default DetailedReportDatacenterSelect;
