import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@skyportal/ui-kit";

import styles from "./styles";

const useStyles = makeStyles(styles);

const isError = (message) => message.toLowerCase().includes("error");

const composeMessage = (message) => message.replace("Error: ", "");

const Notification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { message, description, id } = useSelector((state) => state.general.notification);
  const classes = useStyles();
  useEffect(() => {
    if (!message) {
      return;
    }

    enqueueSnackbar(composeMessage(message), {
      key: id,
      variant: isError(message) ? "error" : "info",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      onClick: () => {
        closeSnackbar(id);
      },
      preventDuplicate: true,
      autoHideDuration: isError(message) ? 5000 : 3000,
      content: (id) => (
        <div
          key={id}
          className={classes.notificationCard}
          style={{ backgroundColor: isError(message) ? "#ff5a5a" : "#4ef09d" }}
        >
          <div className={classes.notificationCardTitle}>
            <Icon
              icon={isError(message) ? "stop" : "info-success"}
              height={20}
              className={classes.notificationCardIcon}
            />
            {composeMessage(message)}
          </div>
          {description ? <div className={classes.notificationCardDescr}>{description}</div> : null}
        </div>
      ),
    });
  }, [message, id, description, enqueueSnackbar, closeSnackbar]);

  return null;
};

export default Notification;
