import React, { useEffect, useMemo, useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "@skyportal/ui-kit";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import Input from "../../../../ui-kit/Input";
import { formatTimestampToFullDate } from "../../../helpers";
import { REVERT_SNAPSHOT, DELETE_SNAPSHOT, SNAPSHOT_MODAL_LIST } from "./constants";
import { getRevertModalTexts } from "./utils";
import { snapshotPanelStyles } from "./styles";

const useStyles = makeStyles(snapshotPanelStyles);

const SnapshotRow = ({ snapshot, columnList, onRollback, onDelete }) => {
  const { t } = useTranslation("vmPage");
  const [confirmationText, setConfirmationText] = useState("");
  const [isRequesting, setIsRequesting] = useState(false);
  const [modalToShow, setModalToShow] = useState(null);
  const classes = useStyles();
  const modalText = useMemo(() => getRevertModalTexts(modalToShow, t), [modalToShow, t]);

  const handleConfirmRevert = async (e) => {
    e.stopPropagation();
    if (!SNAPSHOT_MODAL_LIST.includes(modalToShow)) {
      return;
    }

    setIsRequesting(true);
    switch (modalToShow) {
      case REVERT_SNAPSHOT:
        await onRollback(snapshot.SNAPSHOT_ID);
        break;
      case DELETE_SNAPSHOT:
        await onDelete(snapshot.SNAPSHOT_ID);
        break;
      default:
        console.error("Modal not allowed!");
    }
    setIsRequesting(false);
    setModalToShow(null);
  };
  const handleRollbackBtnClick = (e) => {
    e.stopPropagation();
    setModalToShow(REVERT_SNAPSHOT);
  };
  const handleDeleteBtnClick = (e) => {
    e.stopPropagation();
    setModalToShow(DELETE_SNAPSHOT);
  };
  const handleCloseRevertModal = (e) => {
    e.stopPropagation();
    setModalToShow(null);
  };

  useEffect(() => {
    setConfirmationText("");
  }, [modalToShow]);

  return (
    <TableRow key={snapshot.TIME + snapshot.SNAPSHOT_ID}>
      {columnList.map((col) => {
        switch (col.key) {
          case "index":
            return (
              <TableCell key={col.key} className={classes.tableCell}>
                {snapshot.SNAPSHOT_ID}
              </TableCell>
            );
          case "name":
            return (
              <TableCell key={col.key} className={classes.tableCell}>
                {snapshot.NAME}
              </TableCell>
            );
          case "timestamp":
            return (
              <TableCell key={col.key} className={classes.tableCell}>
                {formatTimestampToFullDate(snapshot.TIME)}
              </TableCell>
            );
          case "actions":
            return (
              <TableCell key={col.key} className={classes.tableCell}>
                <div className={classes.snapshotBtnWrapper}>
                  <Tooltip title={t("iaasCommon:Rollback")}>
                    <Button
                      type="tertiary"
                      leftIcon="circle"
                      onClick={handleRollbackBtnClick}
                      disabled={snapshot.isDisabled}
                    />
                  </Tooltip>
                  <Tooltip title={t("iaasCommon:Delete")}>
                    <Button
                      type="tertiary"
                      leftIcon="trash-bin"
                      onClick={handleDeleteBtnClick}
                      disabled={snapshot.isDisabled}
                    />
                  </Tooltip>
                </div>
              </TableCell>
            );
          default:
            return null;
        }
      })}
      <ConfirmationModal
        isRequesting={isRequesting}
        title={modalText.title}
        open={modalToShow !== null}
        onClose={handleCloseRevertModal}
        onConfirmed={handleConfirmRevert}
        onCancel={handleCloseRevertModal}
        isDisabled={modalText.confirmWord !== confirmationText.toLowerCase()}
      >
        <p>{modalText.confirmQuestion}</p>
        <Input
          className={classes.fullWidthInput}
          placeholder={modalText.placeholder}
          type="text"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          disabled={isRequesting}
        />
      </ConfirmationModal>
    </TableRow>
  );
};

export default SnapshotRow;
