const styles = (theme) => ({
  root: {
    position: "relative",
    padding: "8px 60px 8px 16px",
    "&:hover $iconLabel": {
      opacity: 0.8,
    },
  },
  rootMinHeight: {
    minHeight: "82.2px",
  },
  label: {
    color: "rgba(255 255 255 / 90%)",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "32px",
    marginBottom: "5px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  hintLabel: {
    color: "rgba(255 255 255 / 90%)",
    fontSize: "14px",
    lineHeight: "18px",
    marginBottom: "8px",
  },
  link: {
    "& button": {
      "&:hover": {
        opacity: "0.85 !important",
      },
    },
  },
  linkText: {
    marginRight: "5px",
  },
  vmVariant: {
    backgroundColor: "var(--color-dark-blue)",
  },
  cpuVariant: {
    backgroundColor: "var(--color-dark-grey)",
  },
  dataVariant: {
    backgroundColor: "var(--color-dark-green)",
  },
  memoryVariant: {
    backgroundColor: "var(--color-dark-brown)",
  },
  iconLabel: {
    position: "absolute",
    zIndex: "0",
    right: "18px",
    bottom: "48px",
    color: "#fff",
    opacity: "0.5",
    transform: "scale(5)",
    transition: "0.2s",
  },
});

export default styles;
