import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { fetchConsumptionMetrics } from "../../../../store/actions/dashboardActions";
import * as dashboardSelectors from "../../../../store/selectors/dashboardSelectors";
import { REQUEST_PENDING } from "../../../../constants/request";
import useAllMetricsRequestPending from "../../../../hooks/useAllMetricsRequestPending";
import {
  composeConsumptionChartData,
  getStartFetchMomentByTimeType,
} from "../../../../pages/Dashboard/Components/Consumption/utils";
import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Spinner } from "@skyportal/ui-kit";
import { TIME_RANGE, GROUP_TYPE, DAYS_LABEL } from "./constants";
import styles from "../../../../pages/Dashboard/styles";
import ConsumptionChart from "./ConsumptionChart";

const useStyles = makeStyles(styles);

const ConsumptionCardContent = () => {
  const { t } = useTranslation("consumptionBlock");
  const commonTranslation = useTranslation("iaasCommon");
  const [timeGroup, setTimeGroup] = useState(DAYS_LABEL);
  const dispatch = useDispatch();
  const classes = useStyles();
  /**
   * @type {ConsumptionMetric[]}
   */
  const consumption = useSelector(dashboardSelectors.getConsumption);
  /**
   * @type {RequestStatus}
   */
  const consumptionRequest = useSelector(dashboardSelectors.getConsumptionRequest);

  const { data, categories } = useMemo(
    () => composeConsumptionChartData(consumption, timeGroup, commonTranslation.t),
    [consumption, t]
  );

  const handleTimeRangeChange = ({ target: { value } }) => {
    setTimeGroup(value);
    dispatch(fetchConsumptionMetrics(getStartFetchMomentByTimeType(value), new Date(), GROUP_TYPE[value]));
  };

  return (
    <Card className={classes.smallCard}>
      <Spinner show={useAllMetricsRequestPending() || consumptionRequest === REQUEST_PENDING} />
      <CardHeader
        title={t("title")}
        className={classes.brownHeader}
        classes={{
          title: classes.title,
        }}
        action={
          <Select
            disableUnderline
            value={timeGroup}
            onChange={handleTimeRangeChange}
            classes={{
              select: classes.dropdown,
            }}
          >
            {TIME_RANGE.map((item) => (
              <MenuItem key={item} value={item}>
                {t(`ranges.${item}`)}
              </MenuItem>
            ))}
          </Select>
        }
      />

      <CardContent
        classes={{
          root: classnames(classes.cardContent, classes.marginTop),
        }}
      >
        <ConsumptionChart categories={categories} data={data} />
      </CardContent>
    </Card>
  );
};

export default ConsumptionCardContent;
