import { axiosClient } from "./web";

/**
 * @typedef {object} GroupApiModel
 * @property {string} _id - MongoDB
 * @property {number} id - OpenNebula
 * @property {string} name
 * @property {object|null} agreement
 */

/**
 * @param {string} cdUrl
 */
export const getGroupList = (cdUrl) => axiosClient.get(`${cdUrl}/v1/group`);
