import React, { useCallback, useEffect, useState } from "react";
import DisksModalBody from "../../../pages/Disks/DisksContent/DisksModalBody";
import {
  setVmEcGroupAct,
  setVmEcInputSizeAct,
  setVmEcNameAct,
  setVmEcSizeAct,
  setVmEcTierAct,
} from "../../../store/actions/ecVMPageActions";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import { ecVMSel } from "../../../store/selectors/ecVMSelectors";
import { closeImageModalAct, createImageAct, updateImageAct } from "../../../store/actions/imagesActions";
import { attachDiskRequest, setAllVmsAct } from "../../../store/actions/virtualMachinesActions";
import { getGroups } from "../../../store/selectors/groupSelectors";
import { gb2Mb, mb2gb } from "../../../utils/size";
import { minImageSize } from "pages/constants";

/**
 * @param {boolean} open
 * @param {function} onClose
 * @param {VmStateModel} currentVm
 * @return {JSX.Element}
 * @constructor
 */
export const CreateDiskModal = ({ open, currentVm, imageSelectedForEdit, groupsFromRelatedDc }) => {
  const dispatch = useDispatch();
  const groups = useSelector(getGroups);
  const { name, group, tier, size, inputSizeValue } = useSelector(ecVMSel);
  const [hasImageNameMatchingPrefix, setHasImageNameMatchingPrefix] = useState(true);
  const currentGroup = groups.find((item) => item._id === group);

  useEffect(() => {
    if (imageSelectedForEdit) {
      const currentImageGroup = groupsFromRelatedDc.find((group) => group.id === imageSelectedForEdit.group);
      let imageNameWithoutPrefix = imageSelectedForEdit.name;
      let hasImageNameMatchingPrefix = false;
      if (currentImageGroup?.prefix) {
        hasImageNameMatchingPrefix = imageSelectedForEdit.name.includes(currentImageGroup.prefix);
        if (hasImageNameMatchingPrefix) {
          imageNameWithoutPrefix = imageNameWithoutPrefix.replace(`${currentImageGroup.prefix}-`, "");
        }
      } else if (currentImageGroup?.name) {
        hasImageNameMatchingPrefix = imageSelectedForEdit.name.includes(currentImageGroup.name);
        if (hasImageNameMatchingPrefix) {
          imageNameWithoutPrefix = imageNameWithoutPrefix.replace(`${currentImageGroup.name}-`, "");
        }
      }

      setHasImageNameMatchingPrefix(hasImageNameMatchingPrefix);
      dispatch(setVmEcGroupAct(currentImageGroup._id));
      dispatch(setVmEcNameAct(imageNameWithoutPrefix));
      dispatch(setVmEcTierAct(imageSelectedForEdit.tier));
      dispatch(setVmEcSizeAct(mb2gb(imageSelectedForEdit.size)));
      dispatch(setVmEcInputSizeAct(mb2gb(imageSelectedForEdit.size)));
    }
  }, [dispatch, groupsFromRelatedDc, imageSelectedForEdit]);

  const closeAndCleanImgModal = useCallback(() => {
    setHasImageNameMatchingPrefix(true);
    dispatch(setVmEcGroupAct(""));
    dispatch(setVmEcNameAct(""));
    dispatch(setVmEcTierAct(""));
    dispatch(setVmEcSizeAct(minImageSize));
    dispatch(setVmEcInputSizeAct("1"));
    dispatch(closeImageModalAct());
  }, [dispatch]);

  const preparePayload = () => {
    return {
      group: currentGroup.id,
      name,
      persistent: true,
      size: gb2Mb(size),
      tier,
    };
  };

  const handleCreateClick = async () => {
    if (currentGroup) {
      try {
        const { id } = await dispatch(createImageAct(currentVm.dcOwner, preparePayload()));
        await dispatch(attachDiskRequest(currentVm, id));
        await dispatch(setAllVmsAct());
        closeAndCleanImgModal();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleEditClick = async () => {
    if (currentGroup) {
      await dispatch(updateImageAct(currentGroup.dcOwner, imageSelectedForEdit._id, preparePayload()));
      closeAndCleanImgModal();
    }
  };

  return (
    <Modal open={open} onClose={closeAndCleanImgModal}>
      <div>
        <DisksModalBody
          newImageName={name}
          setNewImageName={(data) => dispatch(setVmEcNameAct(data))}
          imageGroup={group}
          setImageGroup={(data) => dispatch(setVmEcGroupAct(data))}
          imageTier={tier}
          setImageTier={(data) => dispatch(setVmEcTierAct(data))}
          size={size}
          setSize={(data) => dispatch(setVmEcSizeAct(data))}
          inputSizeValue={inputSizeValue}
          setInputSizeValue={(data) => dispatch(setVmEcInputSizeAct(data))}
          onCreate={handleCreateClick}
          onUpdate={handleEditClick}
          closeImgModal={closeAndCleanImgModal}
          currentVm={currentVm}
          imageSelectedForEdit={imageSelectedForEdit}
          hasImageNameMatchingPrefix={hasImageNameMatchingPrefix}
        />
      </div>
    </Modal>
  );
};

export default React.memo(CreateDiskModal);
