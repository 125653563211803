const cell = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  flexGrow: 1,
  flexShrink: 0,
};
const btn = {
  padding: "4px",
  fontSize: "14px",
  maxWidth: "70px",
  minWidth: "70px",
  borderRadius: "2px",
  "&::first-letter": {
    textTransform: "uppercase",
  },
};

const styles = (theme) => ({
  tableRowShell: {
    display: "flex",
    justifyContent: "space-between",
  },
  controlsRow: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: "1",
    padding: "10px 0",
    alignItems: "center",
    borderBottom: `3px solid #f4f4f4`,
  },
  icon: {
    color: theme.colors.inactiveGrey,
    marginRight: "8px",
  },
  starIcon: {
    marginLeft: 4,
  },
  columnName: {
    fontWeight: "600",
  },
  checkboxShell: {
    ...theme.classes.centralized,
  },
  cbContainer: theme.classes.elems.cbContainer,
  cbInput: theme.classes.elems.cbInput,
  cbCheckmark: theme.classes.elems.cbCheckmark,
  headCell: { ...cell, whiteSpace: "nowrap", userSelect: "none" },
  cell: { ...cell },
  checkbox: {
    ...cell,
    width: "50px",
    marginRight: "10px",
  },
  os: {
    display: "flex",
    width: "100px",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 0,
  },
  ip: {
    display: "flex",
    width: "100px",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 0,
  },
  mac: {
    display: "flex",
    width: "110px",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 0,
  },
  status: {
    ...cell,
    width: "110px",
    padding: "0 5px",
    textAlign: "center",
    flexGrow: 0,
    justifyContent: "center",
  },
  badge: {
    minWidth: "85px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  group: {
    ...cell,
    paddingLeft: "10px",
    width: "120px",
    flexGrow: 2,
  },
  datacenter: {
    ...cell,
    width: "80px",
    flexGrow: 2,
  },
  name: {
    ...cell,
    width: "120px",
    flexGrow: 2,
  },
  hostname: {
    ...cell,
    width: "100px",
    textTransform: "none",
  },
  network: {
    ...cell,
    width: "100px",
    textTransform: "none",
  },
  hypervisor: { ...cell, width: "110px" },
  cpu: { ...cell, width: "80px" },
  memory: { ...cell, width: "100px" },
  disk: { ...cell, width: "100px" },
  size: { ...cell, width: "100px" },
  snapShot: { ...cell, flexGrow: 0, width: "100px", borderBottom: `3px solid #f4f4f4` },
  snapshotBody: {},
  vmData: {
    display: "flex",
    justifyContent: "space-between",
  },
  totalRow: {
    backgroundColor: "#f4f4f4",
    display: "flex",
    justifyContent: "space-between",
  },
  muiCell: {
    padding: "0",
    width: "100%",
    border: "none",
  },
  dataRow: {
    padding: "10px 0",
    flexGrow: 1,
  },
  cellData: {
    display: "block",
    color: "black",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    position: "relative",
  },
  cellRightPad: {
    paddingRight: "15px",
  },
  totalCell: {
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    minHeight: "30px",
    color: "#000",
  },
  checkboxMask: {
    width: "18px",
  },
  border: {
    border: "1px solid transparent",
  },
  controlMask: {
    width: "88px",
    flexShrink: 0,
    flexGrow: 0,
  },
  snapShotShell: {
    width: "100px",
    padding: 0,
    ...theme.classes.centralized,
  },
  btn,
  infoMain: {
    backgroundColor: theme.colors.lightBrown,
    color: theme.colors.brown,
    "&:hover": {
      backgroundColor: theme.colors.lightBrown,
      color: theme.colors.brown,
    },
    "&:active": {
      backgroundColor: theme.colors.lightBrown,
      color: theme.colors.brown,
    },
    "&:focus": {
      backgroundColor: theme.colors.lightBrown,
      color: theme.colors.brown,
    },
  },
  secondaryMain: {
    backgroundColor: theme.colors.lightRed,
    color: theme.colors.ruby,
    "&:hover": {
      backgroundColor: theme.colors.lightRed,
      color: theme.colors.ruby,
    },
    "&:active": {
      backgroundColor: theme.colors.lightRed,
      color: theme.colors.ruby,
    },
    "&:focus": {
      backgroundColor: theme.colors.lightRed,
      color: theme.colors.ruby,
    },
  },
  successMain: {
    backgroundColor: theme.colors.lightGreen,
    color: theme.colors.darkGreen,
    "&:hover": {
      backgroundColor: theme.colors.lightGreen,
      color: theme.colors.darkGreen,
    },
    "&:active": {
      backgroundColor: theme.colors.lightGreen,
      color: theme.colors.darkGreen,
    },
    "&:focus": {
      backgroundColor: theme.colors.lightGreen,
      color: theme.colors.darkGreen,
    },
  },
  btnText: {
    textTransform: "capitalize",
  },
  badgeLabel: {
    textTransform: "capitalize",
    fontSize: "14px",
  },
  deleteVmBodySpace: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  modalForm: {
    backgroundColor: "white",
    borderRadius: "5px",
    width: "550px",
  },
  modalHeader: theme.classes.elems.modalHeader,
  modalCard: theme.classes.elems.modalCard,
  cardRow: {
    padding: "8px 16px",
  },
  cardRowInput: {
    width: "100%",
  },
  delVmName: {
    color: "red",
    fontWeight: "600",
  },
  vmModalFooter: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "16px 16px 16px 0px",

    "& button": {
      "&:last-of-type": {
        marginLeft: "8px",
      },
    },
  },
  snapshotIcon: {
    height: "16px",
    filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(58deg) brightness(110%) contrast(102%)",
  },
  snapshotNameModal: {
    width: "90%",
    maxWidth: "400px",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
  },
  snapshotNameModalCard: theme.classes.elems.card,
  snapshotNameModalHeader: theme.classes.elems.modalHeader,
  snapshotNameModalInputShell: {
    padding: "10px 16px",
  },
  cancelSnapshotCreationBtn: {
    padding: "7px 14px",
    width: "93px",
    height: "32px",
    display: "flex",
  },
  snapShotInput: {
    width: "100%",
  },
  submitSnapshotCreation: {
    padding: "7px 14px",
  },
  snapshotNameModalFooter: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "16px 16px",
    margin: "-8px",

    "& > button": {
      margin: "8px",

      "@media(max-width: 767px)": {
        minWidth: "180px",
        flexGrow: 1,
      },
    },
  },
  cellOsImg: {
    display: "block",
    width: 35,
    height: 35,
    backgroundSize: "contain",
    objectPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  selectedImgBar: {
    width: "100%",
    boxSizing: "border-box",
    padding: "24px 8px 8px",
    display: "flex",
    fontSize: "14px",
    borderBottom: `3px solid ${theme.colors.lightGrey}`,
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  selImgName: {
    width: "40%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",
    overflow: "hidden",
    marginLeft: "16px",
  },
  selImgNameTableHead: {
    marginLeft: "36px",
  },
  selImgMiddle: {
    width: "18%",
  },
  selectedImgRow: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    height: "50px",
    padding: "0 8px",
    fontSize: "14px",
  },
  popper: {
    pointerEvents: "auto !important",
  },
  tooltipArrow: {
    color: "white !important",
    "&:before": {
      border: `1px solid ${theme.colors.grey} !important`,
    },
  },
  tooltip: {
    color: "black !important",
    backgroundColor: "white !important",
    border: `1px solid ${theme.colors.grey}`,
    margin: "4px 0px 0px 0px !important",
    fontSize: "14px !important",
    fontFamily: `${theme.typography.fontFamily} !important`,
    width: "400px !important",
    maxWidth: "400px !important",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.36) !important",

    "@media(max-width: 767px) ": {
      width: "250px !important",
    },
  },
  crossIcon: {
    position: "absolute",
    top: "8px",
    right: "12px",
    height: "20px",
    "&:hover": {
      cursor: "pointer",
      opacity: "0.75",
    },
  },
  vmDetailsTitle: {
    fontSize: "16px",
    fontWeight: "700",
    margin: "8px 24px 8px 4px",
  },
  vmDetailsRow: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    margin: "0px 4px",
  },
  vmDetailsFooter: {
    textAlign: "right",
  },
});

const snapControl = (theme) => {
  return {
    width: "27px",
    height: "22px",
    padding: "4px 8px",
    backgroundColor: theme.colors.lightRed,

    "&:disabled": {
      pointerEvents: "none",
    },
  };
};

export const snapshotPanelStyles = (theme) => {
  return {
    snapshotBtnWrapper: {
      display: "flex",
    },
    rollbackSnapshot: {
      ...snapControl(theme),
      marginLeft: "auto",
    },
    deleteButton: {
      ...snapControl(theme),
      marginLeft: "10px",
    },
    snapshotContainer: {
      margin: "0 55px",
    },
    panelHeaderTitle: {
      flexGrow: 1,
      fontSize: "18px",
      lineHeight: "22px",
    },
    panelHeaderContainer: {
      marginBottom: "10px",
    },
    tableContainer: {
      maxHeight: "160px",
    },
    tableHeaderCell: {
      borderBottom: "3px solid white",
    },
    tableHeaderText: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "18px",
      textTransform: "capitalize",
    },
    tableHeaderIdText: {
      textTransform: "uppercase",
    },
    stickyHeader: {
      backgroundColor: "#f3f3f3",
    },
    tableCell: {
      borderBottom: "1px solid white",
      padding: "10px 24px 10px 16px",
    },
    fullWidthInput: {
      width: "100%",
    },
  };
};

export default styles;
