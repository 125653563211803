import i18n from "../../../locales";
import { FILTER_TYPES, TYPE_LABELS } from "../../../constants/report";
import { EMPTY_GROUP_VALUE } from "./constants";
import { CONTRACT_TYPES, hasContractAccessToFeature, USER_ACCESS } from "@skyportal/auth-web-client/dist";

export const getSelectTypeOptions = (t) =>
  FILTER_TYPES.map((value) => ({
    value,
    label: t(`common:${TYPE_LABELS[value]}`),
  }));

export const addAllOption = (list) => [
  { value: EMPTY_GROUP_VALUE, label: i18n.t("All", { ns: "iaasCommon" }) },
  ...list,
];

export const makeAgreementOptions = (groups, userProfile, feature) =>
  groups
    .filter((group) => group.agreement)
    .filter((group) =>
      hasContractAccessToFeature(userProfile, group.agreement, CONTRACT_TYPES.IA, feature, [USER_ACCESS.VIEW])
    )
    .map((group) => ({
      value: group.agreement,
      label: `${group.displayname || group.name} (${group.agreement})`,
    }))
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

export const handleDateWrapperClick = (event) => {
  event.preventDefault();

  const dateInput = event.currentTarget.querySelector("input");

  if (dateInput) {
    dateInput.focus();
  }
};
