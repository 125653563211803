import { directoryClient } from "./web";

const regionApiUrl = "/v1/region";

/**
 * @return {Promise<AxiosResponse<RegionApiModel[]>>}
 */
export const getRegions = () =>
  directoryClient.request({
    method: "GET",
    url: regionApiUrl,
  });

/**
 * @param {object} region
 * @param {string} region.id
 * @param {string} region.code
 * @param {string} region.name
 * @return {Promise<AxiosResponse<RegionApiModel>>}
 */
export const updateRegion = (region) =>
  directoryClient.request({
    method: "PUT",
    url: `${regionApiUrl}/${region.id}`,
    data: {
      code: region.code,
      name: region.name,
    },
  });

/**
 * @param region
 * @return {Promise<AxiosResponse<RegionApiModel>>}
 */
export const createNewRegion = (region) =>
  directoryClient.request({
    method: "POST",
    url: regionApiUrl,
    data: {
      name: region.name,
      code: region.code,
    },
  });

/**
 * @param {object} region
 * @param {string} region.id
 * @return {Promise<AxiosResponse<void>>}
 */
export const deleteRegion = (region) =>
  directoryClient.request({
    method: "DELETE",
    url: `${regionApiUrl}/${region.id}`,
  });
