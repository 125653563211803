import * as React from "react";
import Pagination from "@material-ui/lab/Pagination";
import cn from "classnames";
import PaginationPerSizeSelect from "../../components/PaginationPerSizeSelect";

import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {function(page: number)} onPerPageChange
 * @param {function(page: number)} onChangePage
 * @param {number} perPage
 * @param {number[]} [steps]
 * @param {number} currentPage
 * @param {number} pageCount
 * @param {string} [className]
 * @param {object} [rest]
 * @return {JSX.Element}
 * @constructor
 */
const PaginationBox = ({
  onPerPageChange,
  perPage,
  currentPage,
  pageCount,
  onChangePage,
  steps,
  className,
  ...rest
}) => {
  const classes = useStyles();

  const handlePageChange = (_, page) => onChangePage(page);

  return (
    <div className={cn(classes.root, className)} {...rest}>
      <PaginationPerSizeSelect steps={steps} value={perPage} onChange={onPerPageChange} />
      <Pagination page={currentPage} count={pageCount} onChange={handlePageChange} variant="outlined" shape="rounded" />
    </div>
  );
};

export default PaginationBox;
