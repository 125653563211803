import {
  SET_VM_EC_NAME,
  SET_VM_EC_GROUP,
  SET_VM_EC_TIER,
  SET_VM_EC_SIZE,
  SET_VM_EC_INPUT_SIZE,
  GET_VM_EC_TEMPLATES,
} from "../types";
import { getTemplates } from "../../api/imageService";

export const setVmEcNameAct = (payload) => (dispatch) => dispatch({ type: SET_VM_EC_NAME, payload });
export const setVmEcGroupAct = (payload) => (dispatch) => dispatch({ type: SET_VM_EC_GROUP, payload });
export const setVmEcTierAct = (payload) => (dispatch) =>
  dispatch({
    type: SET_VM_EC_TIER,
    payload,
  });
export const setVmEcSizeAct = (payload) => (dispatch) => dispatch({ type: SET_VM_EC_SIZE, payload });
export const setVmEcInputSizeAct = (payload) => (dispatch) => dispatch({ type: SET_VM_EC_INPUT_SIZE, payload });

export const setTemplatesAC = (payload) => ({
  type: GET_VM_EC_TEMPLATES,
  payload,
});

export const getTemplatesAct =
  (datacenters = []) =>
  async (dispatch) => {
    const promises = await Promise.allSettled(datacenters.map((dc) => getTemplates(dc.api.url)));

    const templates = promises.reduce((acc, response, index) => {
      if (response.status === "rejected") {
        return acc;
      }
      return [
        ...acc,
        ...response.value.data.map((template) => ({
          ...template,
          dcOwner: datacenters[index],
        })),
      ];
    }, []);

    dispatch(setTemplatesAC(templates));
  };
