import i18n from "../locales";
import { makeUniversalSort } from "../pages/helpers";
import { DATACENTER_EVENT_HISTORY_PAGE_INCLUDE_MAP, getStoredObj, setObjectToStore } from "./storage";

/**
 * @param {ActionHistory} item
 */
export const nameNormalizer = (item) => item.name;
/**
 * @param {ActionHistory} item
 */
export const startTimeNormalizer = (item) => item.stime;
/**
 * @param {ActionHistory} item
 */
export const groupNormalizer = (item) => item.gname;
/**
 * @param {ActionHistory} item
 */
export const groupDisplayNameNormalizer = (item) => item.gdisplayname;
/**
 * @param {ActionHistory} item
 */
export const actionNormalizer = (item) => item.action_name;
/**
 * @param {ActionHistory} item
 */
export const statusNormalizer = (item) => item.reason;
/**
 * @param {ActionHistory} item
 */
export const typeNormalizer = (item) => item.type;
/**
 * @param {ActionHistory} item
 */
export const usernameNormalizer = (item) => item.username;
/**
 * Set of sort handlers for ActionHistory
 * */
export const sortHandler = {
  name: makeUniversalSort(nameNormalizer),
  type: makeUniversalSort(typeNormalizer),
  username: makeUniversalSort(usernameNormalizer),
  stime: makeUniversalSort(startTimeNormalizer),
  gname: makeUniversalSort(groupNormalizer),
  gdisplayname: makeUniversalSort(groupDisplayNameNormalizer),
  action_name: makeUniversalSort(actionNormalizer),
  reason: makeUniversalSort(statusNormalizer),
};

/**
 * @type {EventHistorySortFields[]}
 */
export const FILTER_KEY_LIST = ["name", "username", "gdisplayname", "action_name", "type", "reason"];
/**
 * @type {EventHistorySortFields[]}
 */
export const FREE_TEXT_SEARCH_LIST = ["name", "username"];
export const DEFAULT_USER_NAME = "System";

/**
 * @param {ActionHistory} item
 */
export const keyGen = (item) =>
  item._ID || [item.stime, item.action_name, item.name, item.type, item.gname, item.gdisplayname].join("");
/**
 * @param {ActionHistory} action
 * @return {boolean}
 */
export const isActionCompeted = (action) => [0, null].includes(action.reason);

/**
 * @param {ActionHistory} action
 * @return {string}
 */
export const getActionUsername = (action) =>
  action.username === DEFAULT_USER_NAME ? i18n.t("System", { ns: "iaasCommon" }) : action.username;

/**
 * @param {ActionHistory} action
 * @return {ActionHistory}
 */
export const transformApiEventHistory = (action) => ({
  ...action,
  _ID: String(Math.random()).slice(2),
  username: action.username || DEFAULT_USER_NAME,
  reason: action.reason || 0,
});

/**
 * @param {ActionHistory} action
 * @return {string}
 */
export const composeEventStatusLabel = (action) =>
  isActionCompeted(action) ? i18n.t("Completed", { ns: "iaasCommon" }) : i18n.t("Failed", { ns: "iaasCommon" });

const filterOptionFormattersMap = {
  reason: (reason) => composeEventStatusLabel({ reason }),
};

/**
 * @param {EventHistorySortFields} key
 * @return {function(value: any): any}
 */
export const getFieldFormatter = (key) => {
  const formatter = filterOptionFormattersMap[key];
  return typeof formatter === "function" ? formatter : (value) => value;
};

/**
 *
 * @param {ActionHistory[]} list
 * @param {EventHistorySortFields} keyIndex
 * @return {string[]}
 */
export const composeEventHistoryFilterOptionList = (list, keyIndex) =>
  [...new Set(list.map((item) => item[keyIndex]))].map((item) => getFieldFormatter(keyIndex)(item));

/**
 * @param {EventHistoryFilters} filters
 * @return {boolean}
 */
export const isAnyFilterExists = (filters) =>
  Object.keys(filters)
    .map((key) => filters[key])
    .some((filter) => filter.length);
/**
 * @param {string} item
 * @return {boolean}
 */
export const isFreeTextSearch = (item) =>
  Boolean(typeof item === "string" && item.startsWith('"') && item.endsWith('"'));
/**
 * @param {string} text
 * @return {string}
 */
export const composeFreeTextSearch = (text) => (text ? `"${text}"` : "");
/**
 * @param {string} text
 * @return {string}
 */
export const parseFreeTextSearch = (text) => (isFreeTextSearch(text) ? text.slice(1, text.length - 1) : "");

/**
 * @param {ActionHistory} event
 * @param {EventHistoryFilters} keyIndex
 * @param {string[]} freeTextValues
 * @param {function} [formatter]
 * @return {boolean}
 */
export const filterEventByFreeSearchValues = (event, keyIndex, freeTextValues, formatter) =>
  freeTextValues.some((searchText) =>
    (formatter ? formatter(event[keyIndex]) : event[keyIndex]).toLowerCase().includes(searchText)
  );

export const isResponseWithExceededLimit = (response = {}) => {
  const { data = [], _meta: { total } = { total: 0 } } = response;
  return total > data.length;
};

export const storeDatacenterIncludeMap = (map, prefix = "") =>
  setObjectToStore(DATACENTER_EVENT_HISTORY_PAGE_INCLUDE_MAP, map, prefix);

export const getDatacenterIncludeMapFromStorage = (prefix = "") =>
  getStoredObj(DATACENTER_EVENT_HISTORY_PAGE_INCLUDE_MAP, prefix) || {};
