const styles = (theme) => ({
  vmStatusItem: {
    padding: "15px 0",
    position: "relative",
    minHeight: "30px",
    borderBottom: "1px solid #F4F4F4",

    "&:last-child": {
      borderBottom: "none",
    },
  },

  labelContainer: {
    flexGrow: 1,
  },
  variantLabel: {
    color: "#8D8D8D",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    marginBottom: "6px",
    textTransform: "uppercase",
  },
  label: {
    color: theme.colors.black,
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "24px",
  },
  percentageLabel: {
    fontSize: "12px",
  },
  linkText: {
    textDecoration: "none",
    color: "inherit",

    "&:hover": {
      fontWeight: 600,
    },
  },
  total: {
    color: "#44d9fc",
    "@global": {
      ".MuiCircularProgress-root": {
        color: "#44d9fc !important",
      },
    },
  },
  running: {
    color: "#4ef09d",
    "@global": {
      ".MuiCircularProgress-root": {
        color: "#4ef09d !important",
      },
    },
  },
  pending: {
    color: "#E59A7F",
    "@global": {
      ".MuiCircularProgress-root": {
        color: "#E59A7F !important",
      },
    },
  },
  stopped: {
    color: "#ff5a5a",
    "@global": {
      ".MuiCircularProgress-root": {
        color: "#ff5a5a !important",
      },
    },
  },
  iconBox: {
    paddingRight: "16px",
  },
  circleBox: {
    paddingLeft: "8px",
  },
});

export default styles;
