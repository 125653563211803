import { SET_GROUPS } from "../../types";

/**
 * @type {GroupSlice}
 */
export const initState = {
  list: [],
};

export default function groupReducer(state = initState, action) {
  switch (action.type) {
    case SET_GROUPS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    default:
      return state;
  }
}
