import i18n from "../../locales";
import { getDataCenterList } from "../selectors/dcSelectors";
import { fetchEventHistory } from "../../graphql/dashboard";
import { reduceActionHistoryList } from "../../utils/dashboard";
import {
  EVENT_HISTORY_REQUEST,
  EVENT_HISTORY_REQUEST_FAILED,
  EVENT_HISTORY_REQUEST_SUCCESS,
  EVENT_HISTORY_RESET_FILTERS,
  EVENT_HISTORY_SET_DATE_RANGE,
  EVENT_HISTORY_SET_DC_INCLUDE,
  EVENT_HISTORY_SET_FILTERS,
  EVENT_HISTORY_SET_PAGE,
  EVENT_HISTORY_SET_PER_PAGE,
  EVENT_HISTORY_SET_SORT_FIELD,
} from "../types";
import { getDateRange } from "../selectors/eventHistorySelectors";
import { formatDate } from "../../utils/dates";
import { notificationAC } from "./notificationActions";
import { isResponseWithExceededLimit } from "../../utils/eventHistory";
import { getUserProfile } from "../selectors/userSelectors";
import { storeDatacenterIncludeMap } from "../../utils/eventHistory";

/**
 * @return {{type: "EVENT_HISTORY_REQUEST"}}
 */
export const getEventHistoryRequestAC = () => ({
  type: EVENT_HISTORY_REQUEST,
});

/**
 * @return {{type: "EVENT_HISTORY_REQUEST_FAILED"}}
 */
export const getEventHistoryRequestFailureAC = () => ({
  type: EVENT_HISTORY_REQUEST_FAILED,
});

/**
 * @param {ActionHistory[]} payload
 * @return {{type: "EVENT_HISTORY_REQUEST_SUCCESS", payload: ActionHistory[]}}
 */
export const getEventHistoryRequestSuccessAC = (payload) => ({
  type: EVENT_HISTORY_REQUEST_SUCCESS,
  payload,
});

export const fetchEventHistoryMetrics = () => async (dispatch, getState) => {
  const dcList = getDataCenterList(getState());
  const { start, end } = getDateRange(getState());

  try {
    dispatch(getEventHistoryRequestAC());

    const promises = await Promise.allSettled(
      dcList.map((dc) => fetchEventHistory({ dcUrl: dc.api.url, start: formatDate(start), end: formatDate(end) }))
    );

    const dcMap = new Map();

    const responseDataList = promises
      .map((result, index) => {
        if (result.status !== "rejected") {
          dcMap.set(result.value.data.actionHistory.data, dcList[index]);
        }
        return result;
      })
      .filter((result) => result.status !== "rejected")
      .map((result) => result.value.data.actionHistory);

    const actionHistory = responseDataList
      .map(({ data = [] }) => data.map((item) => ({ ...item, dcOwner: dcMap.get(data) })))
      .reduce(reduceActionHistoryList, []);

    dispatch(getEventHistoryRequestSuccessAC(actionHistory));

    if (responseDataList.some(isResponseWithExceededLimit)) {
      dispatch(notificationAC(i18n.t("eventHistory.notification.fetchLimitExceeded")));
    }
  } catch (error) {
    dispatch(getEventHistoryRequestFailureAC());
  }
};

/**
 * @param {EventHistorySortFields} fieldName
 * @return {{payload: EventHistorySortFields, type: "EVENT_HISTORY_SET_SORT_FIELD"}}
 */
export const setEventHistorySortFieldAC = (fieldName) => ({
  type: EVENT_HISTORY_SET_SORT_FIELD,
  payload: fieldName,
});

/**
 * @param {number} payload
 * @return {{payload: number, type: "EVENT_HISTORY_SET_PER_PAGE"}}
 */
export const setEvenHistoryPerPageAC = (payload) => ({
  type: EVENT_HISTORY_SET_PER_PAGE,
  payload,
});
/**
 * @param {number} payload
 * @return {{payload: number, type: "EVENT_HISTORY_SET_PAGE"}}
 */
export const setEventHistoryPageAC = (payload) => ({
  type: EVENT_HISTORY_SET_PAGE,
  payload,
});

/**
 * @param {EventHistoryFilters} payload
 * @return {{payload: EventHistoryFilters, type: "EVENT_HISTORY_SET_FILTERS"}}
 */
export const setEventHistoryFiltersAC = (payload) => ({
  type: EVENT_HISTORY_SET_FILTERS,
  payload,
});

/**
 * @return {{type: "EVENT_HISTORY_RESET_FILTERS"}}
 */
export const resetEventHistoryFiltersAC = () => ({
  type: EVENT_HISTORY_RESET_FILTERS,
});

/**
 * @param {EventHistoryDateRange} payload - can be partial (start or end)
 * @return {{payload: EventHistoryDateRange, type: "EVENT_HISTORY_SET_DATE_RANGE"}}
 */
export const setDateRange = (payload) => ({
  type: EVENT_HISTORY_SET_DATE_RANGE,
  payload,
});
/**
 * @param {object} payload
 * @return {{payload: object, type: string}}
 */
export const setEventHistoryDcIncludeAC = (payload) => ({
  type: EVENT_HISTORY_SET_DC_INCLUDE,
  payload,
});

export const setEventHistoryDcIncludeMa = (dcIncludeMap) => (dispatch, getState) => {
  const { id, email } = getUserProfile(getState()) || {};

  if (email) {
    storeDatacenterIncludeMap(dcIncludeMap, String(id));
  }

  dispatch(setEventHistoryDcIncludeAC(dcIncludeMap));
};
