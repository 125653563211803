import React, { useEffect } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Table, Text, Tag } from "@skyportal/ui-kit";
import { useDispatch, useSelector } from "react-redux";
import { getStorageDetailedReportTableRegionsWithTotalRow } from "../../../store/selectors/reportSelectors";
import { makeStyles } from "@material-ui/core/styles";
import { fetchStorageDetailsReport, sortReportsAct } from "../../../store/actions/reportsActions";
import { REQUEST_UNCALLED } from "../../../constants/request";
import { formatSize } from "../../../utils/size";
import * as reportSelectors from "../../../store/selectors/reportSelectors";
import TablesPagination from "./TablesPagination";

import styles from "./styles";

const useStyles = makeStyles(styles);

const checkIcon =
  "data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPgo8dGl0bGU+Y2hlY2s8L3RpdGxlPgo8cGF0aCBkPSJNMjMuNTMgOS43OWwtOC40MSA4LjcxLTAuMjggMS40NGgtMS41bC0wLjI4LTEuNDQtNC41OS00LjcxYy0wLjA4MC0wLjA4MC0wLjItMC4wODAtMC4yOCAwbC0xLjEzIDEuMTNjLTAuMDgwIDAuMDgwLTAuMDgwIDAuMiAwIDAuMjhsNi44OSA3LjAxMGMwLjA4MCAwLjA4MCAwLjIgMC4wODAgMC4yOCAwbDEwLjcxLTExLjAxMGMwLjA4MC0wLjA4MCAwLjA4MC0wLjIgMC0wLjI4bC0xLjEzLTEuMTNjLTAuMDgwLTAuMDgwLTAuMi0wLjA4MC0wLjI4IDB6Ij48L3BhdGg+Cjwvc3ZnPgo=";

const StorageDetailsTable = () => {
  const dispatch = useDispatch();
  const storageDetailSReportRequest = useSelector(reportSelectors.getStorageDetailsReportRequest);
  const sortField = useSelector(reportSelectors.getSorterValue);
  const sortWay = useSelector(reportSelectors.getSortWay);
  const { t } = useTranslation("usageReportsPage");
  const classes = useStyles();
  const { list, pageCount, totalAllocatedStorage, tiersTotals, totalAttached, total } = useSelector(
    getStorageDetailedReportTableRegionsWithTotalRow
  );

  const makeSortBtnClickHandler = (value) => dispatch(sortReportsAct(value));

  useEffect(() => {
    if (storageDetailSReportRequest === REQUEST_UNCALLED) {
      dispatch(fetchStorageDetailsReport());
    }
  }, [storageDetailSReportRequest, dispatch]);

  const columns = [
    {
      title: t("iaasCommon:Disk Name"),
      key: "name",
      dataIndex: "name",
      render: (name, data) => {
        if (data._id === "total") {
          return (
            <Text>
              {t("iaasCommon:Total")}: {total}
            </Text>
          );
        }
        return <Text>{name}</Text>;
      },
    },
    {
      title: t("iaasCommon:Group"),
      key: "_groupWithAgreementID",
      dataIndex: "_groupWithAgreementID",
      sortDisabled: true,
    },
    {
      title: t("iaasCommon:Attached to VM"),
      key: "vms",
      dataIndex: "vms",
      render: (vms, { _id }) => {
        if (_id === "total") {
          return <Text>{totalAttached}</Text>;
        }
        return (
          <Text>{vms.length > 0 ? <img src={checkIcon} alt="check-icon" className={classes.checkIcon} /> : null}</Text>
        );
      },
    },
    {
      title: t("iaasCommon:Tier"),
      key: "tier",
      dataIndex: "tier",
      render: (tier, data) => {
        if (data._id === "total") {
          return tiersTotals.map(({ name, tierTotal }) => {
            return (
              <Tag icon="server" key={name} className={classes.tag}>
                {name === "null" ? t("iaasCommon:noTier") : name} - {formatSize(tierTotal, true)}
              </Tag>
            );
          });
        }
        return <Text>{tier && tier !== "null" ? tier : null}</Text>;
      },
    },
    {
      title: t("iaasCommon:Allocated Storage"),
      key: "size",
      dataIndex: "size",
      render: (size, { _id }) => {
        return <Text>{formatSize(_id === "total" ? totalAllocatedStorage : size, true)}</Text>;
      },
    },
  ];

  return (
    <>
      <div className={classes.tableContainer} data-testid="report_storage_detailed_table">
        <div className={cn(classes.table, classes.tableWithTotalRow)}>
          <Table
            sortField={sortField}
            sortOrder={sortWay}
            size="medium"
            columns={columns}
            dataSource={list}
            rowKey="_id"
            onSortFieldChange={makeSortBtnClickHandler}
          />
        </div>
      </div>
      <TablesPagination pageCount={pageCount} />
    </>
  );
};

export default StorageDetailsTable;
