import {
  SET_VMS,
  FILTER_VMS,
  SORT_VMS,
  SET_VM_FILTER_VALUE,
  SET_CURRENT_VM_PAGE,
  TOGGLE_VM_COLUMN,
  SELECT_VM,
  DELETE_VM,
  SET_TO_RUNNING,
  SET_TO_STOPPED,
  SET_TO_PENDING,
  TOGGLE_SELECT_ALL_VMS,
  DELETE_SNAPSHOT,
  CREATE_SNAPSHOT,
  SAVE_VM,
  EDIT_VM,
  GET_SNAPSHOTS,
  SET_SNAPSHOT_DISABLE,
  UNSET_SNAPSHOT_DISABLE,
  VM_LIST_REQUEST,
  VM_LIST_REQUEST_FAILURE,
  VM_VNC_URL_REQUEST,
  VM_VNC_URL_REQUEST_SUCCESS,
  VM_VNC_URL_REQUEST_FAILURE,
  VM_VNC_URL_RESET,
  SET_VM_PER_PAGE,
  SET_VM_DC_INCLUDE,
  SET_USER_PROFILE,
  SET_REFRESH_TIMER_VALUE,
} from "../../types";
import { REQUEST_FAILED, REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_UNCALLED } from "../../../constants/request";
import { PAGINATION_STEPS } from "../../../constants/pagination";
import { DEFAULT_VM_UPDATE_INTERVAL_SEC } from "pages/constants";
import {
  getDatacenterIncludeMapFromStorage,
  getVmListColumnsFromStorage,
  getVmsPerPageFromStorage,
} from "../../../utils/vm";

/**
 * @typedef {object} VmSelectedColumn
 * @property {string} name
 * @property {boolean} selected
 */

/**
 * @typedef {object} VmAttributes
 * @property {string|null} hostname
 * @property {string|null} vc_policy
 * @property {string|null} password
 * @property {string} guest_os
 * @property {string} guest_os_logo
 */

/**
 * @typedef {object} VmStateModel
 * @property {string} _id
 * @property {string} name
 * @property {number} id
 * @property {VmSnapshot[]} snapshots
 * @property {VmAttributes} attributes
 * @property {VmHardware} hardware
 * @property {number} group
 * @property {VmStatus} status
 * @property {DataCenterApiModel} dcOwner
 *
 * @property {string} [__groupName] - field prepared by selectors
 * @property {string[]} [__networkNameList] - field prepared by selectors
 * @property {string[]} [__networkIpList] - field prepared by selectors (network ips ["192.167.0.1"])
 * @property {string[]} [__networkMacList] - field prepared by selectors (network ips ["02:00:ac:10:64:02"])
 */

/**
 * @typedef {object} VmState
 * @property {VmStateModel[]} vms
 * @property {RequestStatus} vmListRequest
 * @property {string[]} selectedVMs
 * @property {VmSnapshot[]} snapshots
 * @property {string} sorterValue
 * @property {string} sortWay
 * @property {string} selectedFilter
 * @property {string} filterValue
 * @property {number} currentVMPage
 * @property {number} refreshTimerValue
 * @property {string|null} vncUrl
 * @property {RequestStatus} vncUrlRequest
 * @property {VmSelectedColumn[]} selectedCols
 * @property {string[]} selectedVMs
 * @property {{name: string, key: string}[]} snapshotCols
 * @property {PaginationStep} perPage
 * @property {object} datacenterIncludeMap
 */

/**
 * @type {VmState}
 */
export const vmInitialState = {
  vmListRequest: REQUEST_UNCALLED,
  vms: [],
  selectedCols: [
    { name: "status", label: "Status", selected: true },
    { name: "group", label: "Group", selected: true },
    { name: "datacenter", label: "Datacenter", selected: false },
    { name: "os", label: "OS", selected: true },
    { name: "name", label: "Name", selected: true },
    { name: "network", label: "Network", selected: false },
    { name: "ip", label: "IP", selected: false },
    { name: "mac", label: "MAC", selected: false },
    { name: "cpu", label: "Cpu", selected: true },
    { name: "memory", label: "Memory", selected: true },
    { name: "size", label: "AllocatedSize", selected: true },
  ],
  snapshotCols: [
    { name: "id", label: "Id", key: "index" },
    { name: "name", label: "Name", key: "name" },
    { name: "timestamp", label: "Timestamp", key: "timestamp" },
    { name: "", key: "actions" },
  ],
  datacenterIncludeMap: {},
  snapshots: [],
  sorterValue: "",
  selectedFilter: "",
  filterValue: "",
  currentVMPage: 1,
  refreshTimerValue: DEFAULT_VM_UPDATE_INTERVAL_SEC,
  sortWay: "asc",
  selectedVMs: [],
  vncUrl: null,
  vncUrlRequest: REQUEST_UNCALLED,
  perPage: PAGINATION_STEPS[0],
};

const resetVMHelper = (state, payload) =>
  state.vms.map((vmFromState) => {
    const match = payload.find((vmFromPayload) => vmFromPayload._id === vmFromState._id);
    if (match) return match;
    return vmFromState;
  });

/**
 * @param {VmState} state
 * @param {{type: string, payload: *}} action
 * @return {VmState}
 */
const userReducer = (state = vmInitialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case VM_LIST_REQUEST: {
      return {
        ...state,
        vmListRequest: REQUEST_PENDING,
      };
    }
    case VM_LIST_REQUEST_FAILURE: {
      return {
        ...state,
        vmListRequest: REQUEST_FAILED,
      };
    }
    case SET_VMS: {
      return {
        ...state,
        vmListRequest: REQUEST_SUCCESS,
        vms: payload,
      };
    }
    case SORT_VMS: {
      return {
        ...state,
        sorterValue: payload,
        sortWay: state.sorterValue === payload && state.sortWay === "desc" ? "asc" : "desc",
      };
    }
    case FILTER_VMS: {
      return {
        ...state,
        selectedFilter: payload,
        currentVMPage: 1,
        selectedVMs: [],
      };
    }
    case SET_VM_FILTER_VALUE: {
      return {
        ...state,
        filterValue: payload,
        currentVMPage: 1,
        selectedVMs: [],
      };
    }
    case SET_VM_PER_PAGE: {
      return {
        ...state,
        perPage: payload,
        currentVMPage: 1,
        selectedVMs: [],
      };
    }
    case SET_CURRENT_VM_PAGE: {
      return {
        ...state,
        currentVMPage: payload,
      };
    }
    case TOGGLE_VM_COLUMN: {
      return {
        ...state,
        selectedCols: payload,
      };
    }
    case SELECT_VM: {
      return {
        ...state,
        selectedVMs: state.selectedVMs.includes(payload)
          ? state.selectedVMs.filter((vm) => vm !== payload)
          : [...state.selectedVMs, payload],
      };
    }
    case TOGGLE_SELECT_ALL_VMS: {
      return {
        ...state,
        selectedVMs: payload,
      };
    }
    case DELETE_VM: {
      const result = state.vms.filter((vm) => vm._id !== payload._id);

      return {
        ...state,
        vms: result,
        selectedVMs: [],
      };
    }
    case SET_TO_RUNNING:
    case SET_TO_STOPPED: {
      return {
        ...state,
        vms: resetVMHelper(state, payload),
      };
    }
    case SET_TO_PENDING: {
      return {
        ...state,
        vms: state.vms.map((vm) => (payload.includes(vm.id) ? { ...vm, status: "pending" } : vm)),
      };
    }
    case SET_REFRESH_TIMER_VALUE: {
      return {
        ...state,
        refreshTimerValue: payload,
      };
    }
    case DELETE_SNAPSHOT: {
      return {
        ...state,
        vms: state.vms.map((vm) => {
          if (vm._id === payload.vmId) {
            return {
              ...vm,
              snapshots: vm.snapshots.filter((snapshot) => snapshot.SNAPSHOT_ID !== payload.snapshotId),
            };
          }
          return vm;
        }),
      };
    }
    case CREATE_SNAPSHOT: {
      const result = state.vms.map((vm) => {
        if (vm.id === payload.vmId) {
          const newSnapshots = [
            ...vm.snapshots,
            {
              _id: `5edbee149299eb377026ac7e-${vm.snapshots.length}`,
              index: vm.snapshots.length,
              name: payload.name,
              timestamp: payload.timestamp,
            },
          ];
          return {
            ...vm,
            snapshots: newSnapshots,
          };
        }
        return vm;
      });

      return {
        ...state,
        vms: result,
      };
    }
    case SET_SNAPSHOT_DISABLE: {
      const updatedVM = state.vms.map((vm) => {
        if (vm._id === payload.vmId) {
          const updatedSnapshots = vm.snapshots.map((snap) => {
            return { ...snap, isDisabled: true };
          });

          return { ...vm, snapshots: updatedSnapshots };
        }

        return vm;
      });

      return {
        ...state,
        vms: updatedVM,
      };
    }
    case UNSET_SNAPSHOT_DISABLE: {
      const updatedVM = state.vms.map((vm) => {
        if (vm._id === payload.vmId) {
          const updatedSnapshots = vm.snapshots.map((snap) => {
            return { ...snap, isDisabled: false };
          });

          return { ...vm, snapshots: updatedSnapshots };
        }

        return vm;
      });

      return {
        ...state,
        vms: updatedVM,
      };
    }
    case GET_SNAPSHOTS: {
      const updatedVM = state.vms.map((vm) => {
        if (vm._id === payload._id) {
          return { ...vm, snapshots: payload.snapshots };
        }

        return vm;
      });

      return {
        ...state,
        vms: updatedVM,
      };
    }

    case SAVE_VM: {
      const diskSize = payload.disk.length ? payload.disk.reduce((accum, current) => accum + current.size, 0) : 0;
      return {
        ...state,
        vms: [
          {
            id: state.vms.length + 1,
            status: "pending",
            hypervisor: "alpha",
            snapshots: [],
            size: diskSize,
            ...payload,
          },
          ...state.vms,
        ],
      };
    }
    case EDIT_VM: {
      const updated = state.vms.map((vm) => {
        if (vm.id === payload.vm._id) {
          return { ...vm, ...payload.newData };
        }
        return vm;
      });

      return {
        ...state,
        vms: updated,
      };
    }
    case VM_VNC_URL_REQUEST:
      return {
        ...state,
        vncUrlRequest: REQUEST_PENDING,
      };
    case VM_VNC_URL_RESET:
      return {
        ...state,
        vncUrl: null,
      };
    case VM_VNC_URL_REQUEST_SUCCESS:
      return {
        ...state,
        vncUrlRequest: REQUEST_SUCCESS,
        vncUrl: payload,
      };
    case VM_VNC_URL_REQUEST_FAILURE:
      return {
        ...state,
        vncUrlRequest: REQUEST_FAILED,
      };
    case SET_VM_DC_INCLUDE: {
      return {
        ...state,
        datacenterIncludeMap: action.payload,
      };
    }
    case SET_USER_PROFILE: {
      return {
        ...state,
        selectedCols: action.payload ? getVmListColumnsFromStorage(action.payload.id) : state.selectedCols,
        datacenterIncludeMap: action.payload
          ? getDatacenterIncludeMapFromStorage(action.payload.id)
          : state.datacenterIncludeMap,
        perPage: action.payload ? getVmsPerPageFromStorage(action.payload.id) : state.perPage,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
