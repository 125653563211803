import { getPaginationSlice, makeFindObjectValueMatch } from "../../pages/helpers";
import { sortHandler } from "../../utils/eventHistory";

/**
 * @param {RootState} state
 * @return {DashboardState}
 */
export const getSlice = (state) => state.dashboard;
export const getConsumption = (state) => state.dashboard.consumption;
export const getConsumptionRequest = (state) => state.dashboard.consumptionRequest;
export const getStored = (state) => state.dashboard.stored;
export const getStoredRequest = (state) => state.dashboard.storedRequest;
export const getAllMetricsRequest = (state) => state.dashboard.allMetricsRequest;
export const getTopMemoryVmList = (state) => state.dashboard.topMemory;
export const getTopUsedDataVmList = (state) => state.dashboard.topUsedData;
export const getVmTotals = (state) => state.dashboard.vmTotals;
export const getUsageStatistic = (state) => state.dashboard.usageStatistic;
export const getActionHistory = (state) => state.dashboard.actionHistory;
/**
 *
 * @param {RootState} state
 * @return {EventTableSubState}
 */
export const getEventTable = (state) => state.dashboard.eventTable;

/**
 * @param {EventHistorySortFields} sortField
 * @param {SortOrderEnum} sortOrder
 * @param {string} searchText
 * @param {number} currentPage
 * @param {ActionHistory[]} events
 * @param {number} [perPage]
 * @return {{total: number, pageCount: number, list: ActionHistory[]}}
 */
const selectComposer = ({ sortField, searchText = "", sortOrder, currentPage, events, perPage }) => {
  let _list = [...events];

  if (searchText) {
    _list = _list.filter(makeFindObjectValueMatch(searchText, ["id"]));
  }

  if (sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getActionHistoryTable = (state) => {
  const events = getActionHistory(state);
  const { currentPage, perPage, sortField, sortOrder } = getEventTable(state);

  return selectComposer({ currentPage, perPage, sortField, sortOrder, events });
};
