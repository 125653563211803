import { axiosClient } from "./web";

/**
 * @typedef {object} VmSnapshot
 * @property {string} NAME
 * @property {string} HYPERVISOR_ID
 * @property {string} SNAPSHOT_ID
 * @property {string} TIME
 */

/**
 * @typedef {object} VmStatus
 * @property {number} state
 * @property {VmStatusName} name
 * @property {number} substate
 * @property {number} poll
 * @property {number} start
 * @property {string} color
 */

/**
 * @typedef {object} VmHardwareImageInfo
 * @property {number} created - timestamp
 * @property {number} group - OpenNebula group id
 * @property {number} id - OpenNebula id
 * @property {number} _id - Mongo id
 * @property {string} name
 * @property {boolean} persistent
 * @property {number} size
 * @property {string} tier
 * @property {object} attributes
 * @property {object} snapshot
 */

/**
 * @typedef {object} VmHardwareDiscItem
 * @property {number} disk_id - OpenNebula id (0,1,2...)
 * @property {number} image_id - OpenNebula id (0,1,2...)
 */

/**
 * @typedef {object} VmHardwareNetworkItem
 * @property {string} ip - Opennebula network VM IP
 * @property {string} mac - Opennebula network VM MAC
 * @property {number} network_id - Opennebula network id. (relation: network->id)
 * @property {number} nic_id - unique id of network for vm. Always starts from 0 per VM.
 * @property {number} nic_name
 */

/**
 * @typedef {object} VmHardwareValidations
 * @property {string|null} cpu - example "O|range||1..16|2"
 * @property {string|null} vcpu - example "O|range||1..16|2"
 * @property {string|null} memory - example "M|range||1048576..2097152|1536000"
 */

/**
 * @typedef {object} VmHardware
 * @property {string} _id
 * @property {VmHardwareDiscItem[]} disks
 * @property {VmHardwareValidations} validations
 * @property {string|null} hypervisor
 * @property {string} cpu - number - could integer or float "1","1.5"....
 * @property {string} vcpu - integer only "1","2","3","4"...
 * @property {string} memory - integer in MB like "1024"
 * @property {VmHardwareNetworkItem[]} networks
 * @property {VmHardwareImageInfo[]} images_info
 */

/**
 * @typedef {object} VmApiModel
 * @property {string} _id
 * @property {string} name
 * @property {number} id
 * @property {VmSnapshot[]} snapshots
 * @property {object} attributes
 * @property {VmHardware} hardware
 * @property {number} group
 * @property {VmStatus} status
 */

/**
 * @param {string} dcUrl - IaaS URL
 */
export const getVMList = (dcUrl) =>
  axiosClient.request({
    baseURL: dcUrl,
    method: "GET",
    url: "/v1/vm",
  });

export const getSnapshots = (vmId, dcUrl) => axiosClient.get(`${dcUrl}/v1/vm/${vmId}/snap`);

export const createSnapshot = ({ vmId, name, dcUrl }) => axiosClient.post(`${dcUrl}/v1/vm/${vmId}/snap`, { name });

export const deleteSnapshot = ({ vmId, snapshotId, dcUrl }) =>
  axiosClient.delete(`${dcUrl}/v1/vm/${vmId}/snap/${snapshotId}`);

export const rollbackSnapshot = ({ vmId, snapshotId, dcUrl }) =>
  axiosClient.post(`${dcUrl}/v1/vm/${vmId}/snap/${snapshotId}/revert`);

export const deleteVM = async (vm, disksIds) =>
  axiosClient.delete(`${vm.dcOwner.api.url}/v1/vm/${vm._id}`, { data: { imageIds: disksIds } });

/**
 * @param {string} baseURL - IaaS URL
 * @param {string} vmId
 */
export const runSingleVM = (baseURL, vmId) =>
  axiosClient.request({
    method: "POST",
    baseURL,
    url: `/v1/vm/${vmId}/on`,
  });

/**
 * @param {string} baseURL - IaaS URL
 * @param {string} vmId
 * @param {boolean} [hard]
 */
export const stopSingleVM = (baseURL, vmId, hard = false) =>
  axiosClient.request({
    method: "POST",
    baseURL,
    url: `/v1/vm/${vmId}/off`,
    params: { hard },
  });

/** @typedef {object} VmCreatePayload
 * @property {object} attributes
 * @property {string|null} attributes.hostname
 * @property {string|null} attributes.vc_policy
 * @property {object} hardware
 * @property {number} hardware.vcpu
 * @property {number} hardware.memory
 * @property {number|null} hardware.networks - network id OpenNebula
 * @property {string|null} hardware.password - VM password in OpenNebula
 * @property {string} name
 * @property {string|null} group - group id OpenNebula
 * @property {number} template_id - id OpenNebula
 */

/**
 * @param {VmCreatePayload} data
 * @param {string} baseURL - dataCenter url
 */
export const saveVm = ({ data, baseURL }) =>
  axiosClient.request({
    baseURL,
    method: "POST",
    url: "/v1/vm",
    data,
  });

/** @typedef {object} VmUpdatePayload
 * @property {object} attributes
 * @property {string|null} attributes.hostname
 * @property {string|null} attributes.vc_policy
 * @property {object} hardware
 * @property {number} hardware.vcpu
 * @property {number} hardware.memory
 * @property {VmHardwareDiscItem[]} hardware.disks
 * @property {VmHardwareNetworkItem[]} hardware.networks - network id OpenNebula
 * @property {string} name
 * @property {string|null} group - group id OpenNebula
 */

/**
 * @param {string} baseURL - dataCenter url
 * @param {string} vmId - vm id Mongo
 * @param {VmUpdatePayload} data
 */
export const editVm = (baseURL, vmId, data) =>
  axiosClient.request({
    baseURL,
    method: "PUT",
    url: `/v1/vm/${vmId}`,
    data,
  });

/**
 * @typedef {object} GetVncUrlResponse
 * @property {string} url
 */

/**
 * @param {string} baseURL - dataCenter url
 * @param {string} vmId - vm id Mongo
 */
export const getVncUrl = (baseURL, vmId) =>
  axiosClient.request({
    baseURL,
    method: "GET",
    url: `/v1/vnc/${vmId}/wsproxy`,
  });

/**
 * @param {string} baseURL - dataCenter url
 * @param {string} vmId - vm id Mongo
 * @param {number} networkId - network id OpenNebula
 */
export const attachNetwork = (baseURL, vmId, networkId) =>
  axiosClient.request({
    method: "PUT",
    baseURL,
    url: `/v1/vm/${vmId}/network/${networkId}`,
  });

/**
 * @param {string} baseURL - dataCenter url
 * @param {string} vmId - vm id Mongo
 * @param {number} networkId - network id OpenNebula
 */
export const detachNetwork = (baseURL, vmId, networkId) =>
  axiosClient.request({
    method: "DELETE",
    baseURL,
    url: `/v1/vm/${vmId}/network/${networkId}`,
  });

/**
 * @param {string} baseURL - dataCenter url
 * @param {string} vmId - vm id Mongo
 * @param {number} diskId - disk id OpenNebula
 */
export const attachDisk = (baseURL, vmId, diskId) =>
  axiosClient.request({
    method: "PUT",
    baseURL,
    url: `/v1/vm/${vmId}/disk/${diskId}`,
  });

/**
 * @param {string} baseURL - dataCenter url
 * @param {string} vmId - vm id Mongo
 * @param {number} diskId - disk id OpenNebula
 */
export const detachDisk = (baseURL, vmId, diskId) =>
  axiosClient.request({
    method: "DELETE",
    baseURL,
    url: `/v1/vm/${vmId}/disk/${diskId}`,
  });
