import { formatSize } from "../../../../../utils/size";

/**
 * @see <https://api.highcharts.com/highcharts/tooltip.formatter>
 * @return {string}
 */
function tooltipFormatter() {
  return `<b>${this.series.name}: ${formatSize(this.y)}</b>`;
}

export default tooltipFormatter;
