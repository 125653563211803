import { NOTIFICATION } from "../../types";

const initialGeneralState = {
  notification: {
    message: null,
    description: null,
    id: 0,
  },
};

const generalReducer = (state = initialGeneralState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATION: {
      return {
        ...state,
        notification: {
          message: payload.message,
          description: payload.description,
          id: state.notification.id + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default generalReducer;
