import { getPaginationSlice, makeFindObjectValueMatch, makeUniversalSort } from "../../pages/helpers";

/**
 * @param {RootState} state
 * @return {DataCenterState}
 */
export const dcSel = (state) => state.dc;

/**
 * @param {RootState} state
 * @return {DataCenterApiModel[]}
 */
export const getDataCenterList = (state) => state.dc.datacenters;
/**
 * @param {RootState} state
 * @return {RequestStatus}
 */
export const getDataCenterRequest = (state) => dcSel(state).datacentersRequest;
/**
 * @param {RootState} state
 * @return {RequestStatus}
 */
export const getCreateRequestStatus = (state) => state.dc.createRequest;
/**
 * @param {RootState} state
 * @return {RequestStatus}
 */
export const getUpdateRequestStatus = (state) => state.dc.updateRequest;
/**
 * @param {RootState} state
 * @return {RequestStatus}
 */
export const getDeleteRequestStatus = (state) => state.dc.deleteRequest;
/**
 * @param {RootState} state
 * @return {RequestStatus}
 */
export const getUpdateSettingsRequestStatus = (state) => state.dc.updateSettingsRequest;
/**
 * @param {RootState} state
 * @return {object}
 */
export const getCreateRequestErrors = (state) => state.dc.createRequestErrors;

const makeNameSortHandler = makeUniversalSort((item) => item.name.trim().toLowerCase());
const makeApiSortHandler = makeUniversalSort((item) => {
  return item.api.url.toLowerCase();
});
const makeDescriptionSortHandler = makeUniversalSort((item) => item.description.toLowerCase());
const makeRegionSortHandler = makeUniversalSort((item) => item.region.name.toLowerCase());

/**
 * @param {DatacenterSortField} sortField
 * @param {"asc"|"desc"} sortOrder
 * @param {string} searchText
 * @param {number} currentPage
 * @param {DataCenterApiModel[]} datacenters
 * @param {number} [perPage]
 * @return {{total: number, pageCount: number, list: DataCenterApiModel[]}}
 */
const datacenterSelectComposer = ({ sortField, sortOrder, searchText, currentPage, allDatacenters, perPage }) => {
  let _list = [...allDatacenters];

  if (searchText) {
    _list = _list.filter(makeFindObjectValueMatch(searchText, ["id"], ["api.url", "region.name"]));
  }

  const sortHandler = {
    name: makeNameSortHandler(sortOrder),
    "api.url": makeApiSortHandler(sortOrder),
    description: makeDescriptionSortHandler(sortOrder),
    region: makeRegionSortHandler(sortOrder),
  }[sortField];

  if (sortHandler) {
    _list.sort(sortHandler);
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getTableDatacenters = (state) => {
  const { sortField, sortOrder, searchText, currentPage, allDatacenters, perPage } = dcSel(state);
  return datacenterSelectComposer({ sortField, sortOrder, searchText, currentPage, allDatacenters, perPage });
};
