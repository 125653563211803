import { getPaginationSlice } from "../../pages/helpers";
import {
  filterEventByFreeSearchValues,
  getFieldFormatter,
  isFreeTextSearch,
  parseFreeTextSearch,
  sortHandler,
} from "../../utils/eventHistory";

/**
 * @param {RootState} state
 * @return {EventHistoryState}
 */
export const getEventHistorySlice = (state) => state.eventHistory;
/**
 * @param {RootState} state
 * @return {RequestStatus}
 */
export const getEventHistoryGetRequest = (state) => getEventHistorySlice(state).getRequestStatus;
/**
 * @param {RootState} state
 * @return {EventHistoryFilters}
 */
export const getEventHistoryFilters = (state) => getEventHistorySlice(state).filters;

/**
 * @param {RootState} state
 * @return {EventHistoryDateRange}
 */
export const getDateRange = (state) => getEventHistorySlice(state).dateRange;
/**
 * @param {RootState} state
 * @return {object}
 */
export const getEventDcIncludeListMap = (state) => getEventHistorySlice(state).datacenterIncludeMap;

/**
 * @param {EventHistorySortFields} sortField
 * @param {SortOrderEnum} sortOrder
 * @param {number} currentPage
 * @param {ActionHistory[]} events
 * @param {number} [perPage]
 * @param {EventHistoryFilters} filters
 * @param {object} [datacenterIncludeMap]
 * @return {{total: number, pageCount: number, list: ActionHistory[]}}
 */
const selectComposer = ({ sortField, sortOrder, currentPage, events, perPage, filters, datacenterIncludeMap }) => {
  let _list = [...events];

  if (datacenterIncludeMap && Object.values(datacenterIncludeMap).some(Boolean)) {
    _list = _list.filter((item) => (item?.dcOwner?.id ? datacenterIncludeMap[item?.dcOwner?.id] : true));
  }

  if (filters) {
    Object.keys(filters).forEach((key) => {
      const formatter = getFieldFormatter(key);
      const includeValues = filters[key].filter((item) => !isFreeTextSearch(item));
      const freeTextSearchValues = filters[key]
        .filter(isFreeTextSearch)
        .map((item) => parseFreeTextSearch(item).toLowerCase());

      if (includeValues && includeValues.length) {
        _list = _list.filter((event) => includeValues.includes(formatter(event[key])));
      }

      if (freeTextSearchValues && freeTextSearchValues.length) {
        _list = _list.filter((event) => filterEventByFreeSearchValues(event, key, freeTextSearchValues, formatter));
      }
    });
  }

  if (sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getEventHistoryTable = (state) => {
  const { events, currentPage, sortField, sortOrder, perPage, filters, datacenterIncludeMap } =
    getEventHistorySlice(state);
  return selectComposer({ events, currentPage, sortField, sortOrder, perPage, filters, datacenterIncludeMap });
};
