import { VM_STATUS_STRING_PENDING, VM_STATUS_STRING_RUNNING, VM_STATUS_STRING_STOPPED } from "../constants/vm";
import { NEW_CREATED_VM_ID_LIST } from "../constants/storage";
import {
  DATACENTER_VM_PAGE_INCLUDE_MAP,
  getStoredObj,
  setObjectToStore,
  VMS_PER_PAGE,
  VM_LIST_COLUMNS_MAP,
} from "./storage";
import { sortString } from "./sort";
import { PAGINATION_STEPS } from "constants/pagination";
import { vmInitialState } from "store/reducers/VM/vmReducer";

/**
 * @param {VmStateModel} vm
 * @return {VmStatusName}
 */
export const getVmStatusLabel = (vm) => vm.status.name;
/**
 * @param {VmStateModel} vm
 * @return {boolean}
 */
export const isVmRunning = (vm) => vm.status.name === VM_STATUS_STRING_RUNNING;
/**
 * @param {VmStateModel} vm
 * @return {boolean}
 */
export const isVmPending = (vm) => vm.status.name === VM_STATUS_STRING_PENDING;
/**
 * @param {VmStateModel} vm
 * @return {boolean}
 */
export const isVmStopped = (vm) => vm.status.name === VM_STATUS_STRING_STOPPED;

/**
 * @param {VmStateModel[]} list
 * @return {Option}
 */
export const composeVmOptions = (list) =>
  list
    .map((item) => ({
      label: item.name,
      value: item._id,
    }))
    .sort((a, b) => sortString(a.label, b.label));

/**
 * @param {VmStateModel} vm
 * @return {number} - ms timestamp
 */
export const getVmStartTime = (vm) => vm.status.start * 1000;

export const getPersistNewCreatedVmIdList = () => {
  try {
    return JSON.parse(localStorage.getItem(NEW_CREATED_VM_ID_LIST) || "[]");
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return [];
  }
};

/**
 * @param {string} vmId
 */
export const saveInLSNewCreatedVmId = (vmId) => {
  try {
    localStorage.setItem(NEW_CREATED_VM_ID_LIST, JSON.stringify([vmId, ...getPersistNewCreatedVmIdList()]));
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }
};

export const removeNewCreateVm = () => {
  try {
    localStorage.removeItem(NEW_CREATED_VM_ID_LIST);
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }
};

/**
 * @param {VmStateModel} vm
 * @return {boolean}
 */
export const isNewCreatedVm = (vm) => getPersistNewCreatedVmIdList().includes(vm._id);
/**
 * @param {VmStateModel} vm
 * @return {string}
 */
export const getVmOsLogoUrl = (vm) => `${vm.dcOwner.api.url}/v1/logos/${vm.attributes.guest_os_logo}`;
/**
 * @param {VmStateModel} vm
 * @param {NetworkStateModel} network
 * @return {boolean}
 */
export const isNetworkAttachedToVm = (vm, network) =>
  vm.hardware.networks.some((item) => item.network_id === network.id);
/**
 * @param {VmStateModel} vm
 * @param {DiskStateModel} disk
 * @return {boolean}
 */
export const isDiskAttachedToVm = (vm, disk) => vm.hardware.disks.some((item) => item.image_id === disk.id);
/**
 * @param {VmStateModel} vm
 * @return {number[]}
 */
export const getNetworkIdList = (vm) => vm.hardware.networks.map((network) => network.network_id);
/**
 * @param {VmApiModel} vm
 * @return {boolean}
 */
export const isDetachNetworkAllowed = (vm) => isVmStopped(vm);

/**
 * @param {"info.main"|"secondary.main"|"success.main"} vmStatusColor
 * @return {"infoMain"|"secondaryMain"|"successMain"}
 */
export const vmStatusToClassName = (vmStatusColor) => {
  switch (vmStatusColor) {
    case "info.main":
      return "infoMain";
    case "secondary.main":
      return "secondaryMain";
    case "success.main":
      return "successMain";
    default:
      return "infoMain";
  }
};

/**
 * @param {VmApiModel} vm
 * @return {BadgeType}
 */
export const getBadgeType = (vm) => {
  switch (vm.status.name) {
    case "running":
      return "success";
    case "pending":
      return "info";
    case "stopped":
      return "danger";
    default:
      return "info";
  }
};

export const storeDatacenterIncludeMap = (map, prefix = "") =>
  setObjectToStore(DATACENTER_VM_PAGE_INCLUDE_MAP, map, prefix);
export const getDatacenterIncludeMapFromStorage = (prefix = "") =>
  getStoredObj(DATACENTER_VM_PAGE_INCLUDE_MAP, prefix) || {};

export const storeVmsPerPageValue = (map, prefix = "") => setObjectToStore(VMS_PER_PAGE, map, prefix);
export const getVmsPerPageFromStorage = (prefix = "") => getStoredObj(VMS_PER_PAGE, prefix) || PAGINATION_STEPS[0];

export const validateStoredColumnsConfig = (initialConfig, storedConfig) => {
  const validatedColumnNames = initialConfig.map((col) => col.name);

  if (storedConfig) {
    const storedColumnNames = storedConfig.map((col) => col.name);
    return (
      storedColumnNames.length === validatedColumnNames.length &&
      storedColumnNames.every((name) => validatedColumnNames.includes(name))
    );
  }
  return false;
};

export const storeVmListColumnsMap = (map, prefix = "") => setObjectToStore(VM_LIST_COLUMNS_MAP, map, prefix);
export const getVmListColumnsFromStorage = (prefix = "") => {
  const storedColumnConfig = getStoredObj(VM_LIST_COLUMNS_MAP, prefix);

  return validateStoredColumnsConfig(vmInitialState.selectedCols, storedColumnConfig)
    ? storedColumnConfig
    : vmInitialState.selectedCols;
};
