import { pricelistClient } from "./web";

/**
 * @return {Promise<AxiosResponse<DataCenterApiModel[]>>}
 */
export const getContractPricelist = (contract) =>
  pricelistClient.request({
    method: "GET",
    url: `/v1/contract/IA${contract}/price-list`,
  });
