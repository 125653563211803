import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReportsTableHeadColumnControls from "../../../pages/Reports/ReportsContent/ReportsTableComponents/ReportsTableHeadColumnControls";
import ReportsTableRow from "../../../pages/Reports/ReportsContent/ReportsTableComponents/ReportsTableRow";
import { useDispatch, useSelector } from "react-redux";
import { getDetailedReportTableRegions, reportSel } from "../../../store/selectors/reportSelectors";
import { makeStyles } from "@material-ui/core/styles";
import { getDetailedReportRequest } from "../../../store/actions/reportsActions";
import { REQUEST_UNCALLED } from "../../../constants/request";
import * as reportSelectors from "../../../store/selectors/reportSelectors";
import DetailedTotalRow from "../../../pages/Reports/ReportsContent/ReportsTableComponents/DetailedTotalRow";
import TablesPagination from "./TablesPagination";

import styles from "./styles";

const useStyles = makeStyles(styles);

const DetailedTable = () => {
  const dispatch = useDispatch();
  const detailedReportRequest = useSelector(reportSelectors.getDetailedReportRequest);
  const { t } = useTranslation("usageReportsPage");
  const classes = useStyles();
  const { selectedDetailedCols } = useSelector(reportSel);
  const { list, pageCount } = useSelector(getDetailedReportTableRegions);

  const detailedReportColumns = selectedDetailedCols.map((item) => ({
    ...item,
    name: t(`iaasCommon:${item.name}`),
  }));

  useEffect(() => {
    if (detailedReportRequest === REQUEST_UNCALLED) {
      dispatch(getDetailedReportRequest());
    }
  }, [detailedReportRequest, dispatch]);

  return (
    <>
      <div className={classes.tableContainer} data-testid="report_detailed_table">
        <div className={classes.table}>
          <ReportsTableHeadColumnControls columns={detailedReportColumns} />
          <div className={classes.tableBody}>
            {list.map((item) => (
              <ReportsTableRow key={item._id} data={item} columns={detailedReportColumns} />
            ))}
          </div>
          <DetailedTotalRow />
        </div>
      </div>
      <TablesPagination pageCount={pageCount} />
    </>
  );
};

export default DetailedTable;
