const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "5px 15px",
  },
  text: {
    color: theme.colors.roadGrey,
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "1.1",
  },
});

export default styles;
