import {
  ALL_METRICS_REQUEST,
  ALL_METRICS_REQUEST_FAILED,
  ALL_METRICS_REQUEST_SUCCESS,
  CONSUMPTION_REQUEST,
  CONSUMPTION_REQUEST_FAILED,
  CONSUMPTION_REQUEST_SUCCESS,
  DASHBOARD_EVENT_TABLE_SET_SORT_FIELD,
  STORED_REQUEST,
  STORED_REQUEST_FAILED,
  STORED_REQUEST_SUCCESS,
} from "../types";
import { getDataCenterList } from "../selectors/dcSelectors";
import { fetchConsumption, fetchStored, fetchAllMetrics } from "../../graphql/dashboard";
import {
  reduceActionHistoryList,
  reduceConsumptionMetricList,
  reduceStoredMetricList,
  reduceTopCpuMetricList,
  reduceTopMemoryMetricList,
  reduceTopUsedDataVmList,
  reduceUsageStatisticList,
  reduceVmTotalsList,
} from "../../utils/dashboard";
import { formatDate, getPastDay } from "../../utils/dates";
import { initUsageStatistic, initVmTotal } from "../reducers/Dashboard/dashboardReducer";

export const consumptionRequestAC = () => ({
  type: CONSUMPTION_REQUEST,
});

export const consumptionRequestSuccessAC = (payload) => ({
  type: CONSUMPTION_REQUEST_SUCCESS,
  payload,
});

export const consumptionRequestFailureAC = () => ({
  type: CONSUMPTION_REQUEST_FAILED,
});

/**
 * @param {Date} start
 * @param {Date} end
 * @param {"DAILY"|"MONTHLY"|"YEARLY"} typeVM
 */
export const fetchConsumptionMetrics = (start, end, typeVM) => async (dispatch, getState) => {
  const dcList = getDataCenterList(getState());

  try {
    dispatch(consumptionRequestAC());
    const resultList = await Promise.allSettled(
      dcList.map((dc) => fetchConsumption(dc.api.url, formatDate(start), formatDate(end), typeVM))
    );

    const mergedMetrics = resultList
      .filter((result) => result.status !== "rejected")
      .map((item) => item.value.data.consumption || [])
      .reduce(reduceConsumptionMetricList, []);

    dispatch(consumptionRequestSuccessAC(mergedMetrics));
  } catch (error) {
    dispatch(consumptionRequestFailureAC());
  }
};

export const storedRequestAC = () => ({
  type: STORED_REQUEST,
});

export const storedRequestSuccessAC = (payload) => ({
  type: STORED_REQUEST_SUCCESS,
  payload,
});

export const storedRequestFailureAC = () => ({
  type: STORED_REQUEST_FAILED,
});

/**
 * @param {Date} start
 * @param {Date} end
 */
export const fetchStoredMetrics = (start, end) => async (dispatch, getState) => {
  const dcList = getDataCenterList(getState());

  try {
    dispatch(storedRequestAC());
    const resultList = await Promise.allSettled(
      dcList.map((dc) => fetchStored(dc.api.url, formatDate(start), formatDate(end)))
    );

    const mergedMetrics = resultList
      .filter((result) => result.status !== "rejected")
      .map((item) => item.value.data.stored || [])
      .reduce(reduceStoredMetricList, []);

    dispatch(storedRequestSuccessAC(mergedMetrics));
  } catch (error) {
    dispatch(storedRequestFailureAC());
  }
};

export const allMetricsRequestAC = () => ({
  type: ALL_METRICS_REQUEST,
});

export const allMetricsRequestFailedAC = () => ({
  type: ALL_METRICS_REQUEST_FAILED,
});

/**
 * @param {string} payload
 * @return {{payload: string, type: "DASHBOARD_EVENT_TABLE_SET_SORT_FIELD"}}
 */
export const setDashboardEventTableSortFieldAC = (payload) => ({
  type: DASHBOARD_EVENT_TABLE_SET_SORT_FIELD,
  payload,
});

/**
 * @param {AllDashboardMetrics} payload
 * @return {{type: string, payload: AllDashboardMetrics }}
 */
export const allMetricsRequestSuccessAC = (payload) => ({
  type: ALL_METRICS_REQUEST_SUCCESS,
  payload,
});

/**
 * @param {DataCenterApiModel} dc
 * @return {{stored: {start: string, end: string}, consumption: {start: string, end: string}, dcUrl: string}}
 */
const composeAllMetricParams = (dc) => ({
  dcUrl: dc.api.url,
  stored: {
    start: formatDate(getPastDay(30)),
    end: formatDate(new Date()),
  },
  consumption: {
    start: formatDate(getPastDay(7)),
    end: formatDate(new Date()),
  },
});

export const fetchMetrics = () => async (dispatch, getState) => {
  const dcList = getDataCenterList(getState());

  try {
    dispatch(allMetricsRequestAC());
    const promises = await Promise.allSettled(dcList.map(composeAllMetricParams).map(fetchAllMetrics));

    /**
     * @type {AllDashboardMetrics[]}
     */
    const dsMetricsList = promises.filter((result) => result.status !== "rejected").map((result) => result.value.data);

    dispatch(
      allMetricsRequestSuccessAC({
        stored: dsMetricsList.map((item) => item.stored || []).reduce(reduceStoredMetricList, []),
        consumption: dsMetricsList.map((item) => item.consumption || []).reduce(reduceConsumptionMetricList, []),
        topMemory: dsMetricsList.map((item) => item.topMemory || []).reduce(reduceTopMemoryMetricList, []),
        topCpu: dsMetricsList.map((item) => item.topCpu || []).reduce(reduceTopCpuMetricList, []),
        vmTotals: dsMetricsList.map((item) => item.vmTotals || {}).reduce(reduceVmTotalsList, initVmTotal),
        topUsedData: dsMetricsList.map((item) => item.topUsedData || []).reduce(reduceTopUsedDataVmList, []),
        usageStatistic: dsMetricsList
          .map((item) => item.usageStatistic || {})
          .reduce(reduceUsageStatisticList, initUsageStatistic),
        actionHistory: dsMetricsList.map((item) => item.actionHistory.data || []).reduce(reduceActionHistoryList, []),
      })
    );
  } catch (error) {
    dispatch(allMetricsRequestFailedAC());
  }
};
