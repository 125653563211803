import Highcharts from "highcharts";
import i18n from "../locales";

const DECIMAL_SEPARATOR = ".";

/**
 * @param {number} value
 * @return {string}
 */
export const decimalNormalizer = (value) => {
  const formatted = Highcharts.numberFormat(value, 1, DECIMAL_SEPARATOR, " ");

  if (formatted.endsWith(`${DECIMAL_SEPARATOR}0`)) {
    return formatted.replace(`${DECIMAL_SEPARATOR}0`, "");
  }

  return formatted;
};

const BYTES_FRACTION = 1024;
/**
 * @param {number} gb
 * @return {number}
 */
export const gb2Mb = (gb) => gb * BYTES_FRACTION;
/**
 * @param {number} mb
 * @return {number}
 */
export const mb2gb = (mb) => mb / BYTES_FRACTION;
/**
 * @param {number} mb
 * @return {number}
 */
export const mb2tb = (mb) => mb / BYTES_FRACTION / BYTES_FRACTION;
/**
 * @param {number} size
 * @param {boolean} [inGB]
 * @return {string} or 2.1 GB
 */
export const formatSize = (size, inGB = false) => {
  if (inGB || mb2gb(size) < BYTES_FRACTION) {
    return `${decimalNormalizer(Number(mb2gb(size)))} ${i18n.t("GB", { ns: "iaasCommon" })}`;
  }

  return `${decimalNormalizer(Number(mb2tb(size)))} ${i18n.t("TB", { ns: "iaasCommon" })}`;
};
