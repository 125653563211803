import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles } from "@material-ui/core/styles";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";

import styles from "./styles";

const useStyles = makeStyles(styles);

const getOptionValue = (option) => option.value || option;
const getOptionLabel = (option) => option.label || option;
const getOptionSelected = (option, item) => item && getOptionValue(option) === getOptionValue(item);

const Autocomplete = ({
  testId,
  options,
  label,
  variant,
  onChange,
  value,
  multiple,
  placeholder,
  className,
  ...rest
}) => {
  const { t } = useTranslation("iaasCommon");
  const classes = useStyles();

  const handleChange = (event, item) => onChange(item);

  return (
    <MuiAutocomplete
      popupIcon={<KeyboardArrowDownIcon />}
      data-testid={testId}
      className={cn(classes.root, className)}
      options={options}
      onChange={handleChange}
      classes={{
        option: classes.option,
        noOptions: cn(classes.option, classes.noOptions),
      }}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderInput={(params) => <TextField {...params} variant={variant} placeholder={placeholder} />}
      noOptionsText={t("noOptions")}
      clearText={t("clearText")}
      multiple={multiple}
      {...rest}
    />
  );
};

export default Autocomplete;
