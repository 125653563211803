/**
 * @type {Storage}
 */
const storage = window.localStorage;

export const DATACENTER_VM_PAGE_INCLUDE_MAP = "DC_VM_INCLUDE_MAP";
export const DATACENTER_DISK_PAGE_INCLUDE_MAP = "DC_DISK_INCLUDE_MAP";
export const DATACENTER_EVENT_HISTORY_PAGE_INCLUDE_MAP = "DC_EVENT_HIST_INCLUDE_MAP";

export const VMS_PER_PAGE = "VMS_PER_PAGE";
export const DISKS_PER_PAGE = "DISKS_PER_PAGE";
export const VM_LIST_COLUMNS_MAP = "VM_LIST_COLUMNS_MAP";
export const DISK_LIST_COLUMNS_MAP = "DISK_LIST_COLUMNS_MAP";

export const generateLSKeyName = (prefix, key) => [prefix, key].join("/");

export const setObjectToStore = (key, obj, prefix) => {
  try {
    storage.setItem(generateLSKeyName(prefix, key), JSON.stringify(obj));
  } catch {
    // skip error handle
  }
};

export const getStoredObj = (key, prefix) => {
  try {
    return JSON.parse(storage.getItem(generateLSKeyName(prefix, key)));
  } catch {
    return null;
  }
};

export default storage;
