import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cn from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, NavLink, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { Button } from "@skyportal/ui-kit";
import ReportChart from "../../../pages/Reports/ReportsChart/ReportsChart";
import { reportSel } from "store/selectors/reportSelectors";
import SummaryTable from "./SummaryTable";
import DetailedTable from "./DetailedTable";
import ReportsSpinner from "./ReportsSpinner";
import StorageDetailsTable from "./StorageDetailsTable";
import ExportModal from "../modals/ExportModal";
import SendModal from "../modals/SendModal";
import { composeDetailedReportParams, composeSummaryReportParams } from "store/actions/reportsActions";
import { hasUserAccessToFeature, FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";
import { reportPagesUrls, reportTypes, USAGE_REPORT_SECTION } from "pages/constants";

import styles from "./styles";

const useStyles = makeStyles(styles);

const ReportsContent = ({ userProfile }) => {
  const { t } = useTranslation("usageReportsPage");
  const history = useHistory();
  const { pathname } = useLocation();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const {
    reports,
    detailedReports,
    storageDetailsReportList,
    reportFilterType,
    selectionStartDate,
    selectionEndDate,
    reportGroupId,
    summaryReportFilterStatuses,
    selectionDetailedDate,
    reportDatacenterId,
  } = useSelector(reportSel);
  const isValidReportsPath = useMemo(() => Object.values(reportPagesUrls).includes(pathname), [pathname]);

  const classes = useStyles();

  useEffect(() => {
    if (pathname[pathname.length - 1] === "/") {
      const pathWithoutSlash = pathname.slice(0, -1);
      history.replace(pathWithoutSlash);
    }
  }, [history, pathname]);

  const isUsageReportTabDisabled = !hasUserAccessToFeature(userProfile, FEATURES.IAAS_USAGE_REPORT, [USER_ACCESS.VIEW]);
  const isUsageReportDetailedTabDisabled = !hasUserAccessToFeature(userProfile, FEATURES.IAAS_USAGE_REPORT_DETAILED, [
    USER_ACCESS.VIEW,
  ]);

  const handleExportBtnClick = () => setIsExportModalOpen(true);
  const handleExportModalClose = () => setIsExportModalOpen(false);
  const handleSendBtnClick = () => setIsSendModalOpen(true);
  const handleSendModalClose = () => setIsSendModalOpen(false);

  const relevantReports = {
    [reportPagesUrls.summary]: reports,
    [reportPagesUrls.detailed]: detailedReports,
    [reportPagesUrls.storage]: storageDetailsReportList,
  };

  const relevantReportParams = {
    [reportPagesUrls.summary]: useMemo(
      () =>
        composeSummaryReportParams({
          selectionStartDate,
          selectionEndDate,
          reportFilterType,
          reportGroupId,
          summaryReportFilterStatuses,
        }),
      [reportGroupId, reportFilterType, selectionEndDate, selectionStartDate, summaryReportFilterStatuses]
    ),
    [reportPagesUrls.detailed]: useMemo(
      () =>
        composeDetailedReportParams({
          date: selectionDetailedDate,
          datacenter: reportDatacenterId,
          group: reportGroupId,
        }),
      [reportDatacenterId, reportGroupId, selectionDetailedDate]
    ),
    [reportPagesUrls.storage]: useMemo(
      () =>
        composeDetailedReportParams({
          date: selectionDetailedDate,
          datacenter: reportDatacenterId,
          group: reportGroupId,
        }),
      [reportDatacenterId, reportGroupId, selectionDetailedDate]
    ),
  };

  const usageReportTabWrapperClasses = cn(classes.tabItemWrapper, {
    [classes.cursorNotAllowed]: isUsageReportTabDisabled,
  });
  const usageReportTabClasses = cn(classes.tabItem, { [classes.disabledTabItem]: isUsageReportTabDisabled });
  const usageReportDetailedTabWrapperClasses = cn(classes.tabItemWrapper, {
    [classes.cursorNotAllowed]: isUsageReportDetailedTabDisabled,
  });
  const usageReportDetailedTabClasses = cn(classes.tabItem, {
    [classes.disabledTabItem]: isUsageReportDetailedTabDisabled,
  });

  const SummaryTabContent = () => (
    <>
      <ReportChart />
      <SummaryTable />
    </>
  );

  return (
    <div className={classes.content}>
      <ReportsSpinner />
      <h1 className={classes.title}>{t("reportTitle")}</h1>
      {isValidReportsPath && relevantReports[pathname].length > 0 && (
        <div className={classes.exportControlsContainer}>
          <Button onClick={handleExportBtnClick} className={classes.reportBtn}>
            <span>{t("iaasCommon:Export")}</span>
          </Button>
          <Button onClick={handleSendBtnClick} className={classes.reportBtn}>
            <span>{t("iaasCommon:SendTo")}</span>
          </Button>
        </div>
      )}
      <div className={classes.tabContainer}>
        <span className={usageReportTabWrapperClasses}>
          <NavLink
            to="/infra/reports/summary"
            exact
            className={usageReportTabClasses}
            activeClassName={classes.tabItemActive}
          >
            {t("summaryTab")}
          </NavLink>
        </span>
        <span className={usageReportDetailedTabWrapperClasses}>
          <NavLink
            to="/infra/reports/detailed"
            className={usageReportDetailedTabClasses}
            activeClassName={classes.tabItemActive}
          >
            {t("vmDetailsTab")}
          </NavLink>
        </span>
        <span className={usageReportDetailedTabWrapperClasses}>
          <NavLink
            to="/infra/reports/storage-details"
            className={usageReportDetailedTabClasses}
            activeClassName={classes.tabItemActive}
          >
            {t("storageDetailsTab")}
          </NavLink>
        </span>
        {isValidReportsPath && (
          <>
            <ExportModal
              section={USAGE_REPORT_SECTION}
              reportType={reportTypes[pathname]}
              onClose={handleExportModalClose}
              open={isExportModalOpen}
              reportParams={relevantReportParams[pathname]}
            />
            <SendModal
              reportType={reportTypes[pathname]}
              onClose={handleSendModalClose}
              open={isSendModalOpen}
              reportParams={relevantReportParams[pathname]}
            />
          </>
        )}
      </div>

      <Switch>
        {hasUserAccessToFeature(userProfile, FEATURES.IAAS_USAGE_REPORT, [USER_ACCESS.VIEW]) && (
          <Route path="/infra/reports/summary" exact component={SummaryTabContent} />
        )}
        {hasUserAccessToFeature(userProfile, FEATURES.IAAS_USAGE_REPORT_DETAILED, [USER_ACCESS.VIEW]) && (
          <Route path="/infra/reports/detailed" exact component={DetailedTable} />
        )}
        {hasUserAccessToFeature(userProfile, FEATURES.IAAS_USAGE_REPORT_DETAILED, [USER_ACCESS.VIEW]) && (
          <Route path="/infra/reports/storage-details" exact component={StorageDetailsTable} />
        )}
        <Route path="*">
          <Redirect to="/infra/reports" />
        </Route>
      </Switch>
    </div>
  );
};

export default ReportsContent;
