import i18n from "i18next";

/**
 * @param {BreadCrumbsConfigItem} config
 * @return {string}
 */
export const makeTitleFromBreadCrumbs = (config) =>
  [
    config.label,
    ...(config.crumbs || [])
      .filter((item) => item.label !== i18n.t("breadcrumbs:Start"))
      .map((item) => item.label)
      .reverse(),
    "Skyportal",
  ].join(" - ");
