const styles = (theme) => ({
  cbContainer: {
    ...theme.classes.elems.cbContainer,
    width: "18px",
    borderRadius: "3px",
  },
  cbInput: theme.classes.elems.cbInput,
  cbCheckmark: theme.classes.elems.cbCheckmark,
  cbOption: {
    display: "flex",
    alignItems: "center",
  },
  cbText: {
    color: "black",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
    width: "84px",
    height: "40px",
    textTransform: "capitalize",
  },
  cbChecked: {
    backgroundColor: "black",
  },
});

export default styles;
