import {
  SET_VM_EC_NAME,
  SET_VM_EC_GROUP,
  SET_VM_EC_TIER,
  SET_VM_EC_SIZE,
  SET_VM_EC_INPUT_SIZE,
  GET_VM_EC_TEMPLATES,
  VM_UPDATE_PENDING,
  VM_CREATE_PENDING,
} from "../../types";

/**
 * @type {EditCreateVmState}
 */
const initialEditCreateVmState = {
  name: "",
  group: "",
  tier: "",
  size: 1,
  inputSizeValue: "1",
  templates: [],
  createPending: false,
  updatePending: false,
};

const editCreateVmReducer = (state = initialEditCreateVmState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_VM_EC_NAME: {
      return {
        ...state,
        name: payload,
      };
    }
    case SET_VM_EC_GROUP: {
      return {
        ...state,
        group: payload,
      };
    }
    case SET_VM_EC_TIER: {
      return {
        ...state,
        tier: payload,
      };
    }
    case SET_VM_EC_SIZE: {
      return {
        ...state,
        size: payload,
      };
    }
    case SET_VM_EC_INPUT_SIZE: {
      return {
        ...state,
        inputSizeValue: payload,
      };
    }
    case GET_VM_EC_TEMPLATES: {
      return {
        ...state,
        templates: payload,
      };
    }
    case VM_CREATE_PENDING: {
      return {
        ...state,
        createPending: action.payload,
      };
    }
    case VM_UPDATE_PENDING: {
      return {
        ...state,
        updatePending: action.payload,
      };
    }
    default:
      return state;
  }
};

export default editCreateVmReducer;
