import { makeFindObjectValueMatch, makeUniversalSort } from "../../pages/helpers";
import { DISKS_MODAL_PER_PAGE } from "../../constants/pagination";
import { getDcIdFromEntity, getGroupMapByDcId, getGroupNameFromListById } from "../../utils/groups";
import * as groupSelectors from "../../store/selectors/groupSelectors";
import { isDiskAttachedToSomeVM } from "../../utils/disks";
import { hasContractAccessToFeature, CONTRACT_TYPES, FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";

/**
 * @param {RootState} state
 * @return {ImagesState}
 */
export const imageSel = (state) => state.img;
/**
 * @param {RootState} state
 * @return {Object}
 */
export const getDisksDatacenterIncludeMap = (state) => imageSel(state).datacenterIncludeMap;
export const getSelectedColsSel = (state) => imageSel(state).selectedCols;

const makeNameSortHandler = makeUniversalSort((item) => item.name.trim().toLowerCase());
const makeSizeSortHandler = makeUniversalSort((item) => item.size);
const makeDatacenterSortHandler = makeUniversalSort((item) => (item.dcOwner.name || "").toLowerCase());
const makeCreatedSortHandler = makeUniversalSort((item) => item.created);
const makeTierSortHandler = makeUniversalSort((item) => (item.tier || "").trim().toLowerCase());
const makeGroupSortHandler = makeUniversalSort((item) => item.group);
const makeVmsSortHandler = makeUniversalSort((item) => (item.vms || []).map((vm) => vm.ID).join(""));

/**
 * @param {ImageSortField} sortField
 * @param {"asc"|"desc"} sortOrder
 * @param {string} searchText
 * @param {number} currentPage
 * @param {DiskStateModel[]} images
 * @param {number} [perPage]
 * @param {object} [datacenterIncludeMap]
 * @param {RootState} state
 * @return {{total: number, pageCount: number, list: DiskStateModel[]}}
 */
const imageSelectComposer = ({
  sortField,
  sortOrder,
  searchText,
  currentPage,
  images,
  perPage,
  state,
  datacenterIncludeMap,
}) => {
  const groupMapByDcId = getGroupMapByDcId(groupSelectors.getGroups(state));
  let _list = [...images];

  if (datacenterIncludeMap && Object.values(datacenterIncludeMap).some(Boolean)) {
    _list = _list.filter((item) => datacenterIncludeMap[item.dcOwner.id]);
  }

  _list = _list.map((image) => ({
    ...image,
    __groupName: getGroupNameFromListById(groupMapByDcId[image.dcOwner.id], image.group, ""),
  }));

  if (searchText) {
    _list = _list.filter(makeFindObjectValueMatch(searchText, ["_id", "snapshot"], ["__groupName"]));
  }

  const sortHandler = {
    name: makeNameSortHandler(sortOrder),
    size: makeSizeSortHandler(sortOrder),
    datacenter: makeDatacenterSortHandler(sortOrder),
    created: makeCreatedSortHandler(sortOrder),
    tier: makeTierSortHandler(sortOrder),
    group: makeGroupSortHandler(sortOrder),
    vms: makeVmsSortHandler(sortOrder),
  }[sortField];

  if (sortHandler) {
    _list.sort(sortHandler);
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: _list.slice((currentPage - 1) * perPage, currentPage * perPage),
  };
};

/**
 * @param {VmStateModel} vm
 * @param {UserInfoModel} userProfile
 */
export const getModalImages = (vm, userProfile) => (state) => {
  const { sortField, sortOrder, currentPage, searchText, images } = imageSel(state);

  const dcFilter = (disk) => getDcIdFromEntity(disk) === getDcIdFromEntity(vm);
  const attachedFilter = (disk) => !isDiskAttachedToSomeVM(disk);
  const accessFilter = (disk) =>
    hasContractAccessToFeature(userProfile, disk.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_DISKS, [
      USER_ACCESS.UPDATE,
    ]);

  const filteredImages = images.filter(dcFilter).filter(attachedFilter).filter(accessFilter);

  return imageSelectComposer({
    sortField,
    sortOrder,
    currentPage,
    searchText,
    images: filteredImages,
    perPage: DISKS_MODAL_PER_PAGE,
    state,
  });
};

/**
 * @param {UserInfoModel} userProfile
 */
export const getTableImages = (userProfile) => (state) => {
  const { sortField, sortOrder, searchText, currentPage, images, perPage, datacenterIncludeMap } = imageSel(state);
  const accessFilter = (disk) =>
    hasContractAccessToFeature(userProfile, disk.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_DISKS, [USER_ACCESS.VIEW]);
  const filteredImages = images.filter(accessFilter);

  return imageSelectComposer({
    sortField,
    sortOrder,
    searchText,
    currentPage,
    images: filteredImages,
    perPage,
    datacenterIncludeMap,
    state,
  });
};
