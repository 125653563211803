import React from "react";
import { Card, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatSize } from "../../../../utils/size";
import { getUsageStatistic } from "../../../../store/selectors/dashboardSelectors";
import UsageStatisticCard from "./UsageStatisticCard";
import { VM_VARIANT, CPU_VARIANT, DATA_VARIANT, MEMORY_VARIANT } from "./constants";
import { hasUserAccessToFeature, FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";
import { getUserProfile } from "store/selectors/userSelectors";
import styles from "../../../../pages/Dashboard/styles";

const useStyles = makeStyles(styles);

const UsageStatisticCards = () => {
  const { t } = useTranslation("usageStatisticBlock");
  const classes = useStyles();
  /**
   * @type {UsageStatistic}
   */
  const usageStatistic = useSelector(getUsageStatistic);
  const userProfile = useSelector(getUserProfile);

  const userAccessToVms = hasUserAccessToFeature(userProfile, FEATURES.IAAS_VIRTUAL_MACHINES, [USER_ACCESS.VIEW]);
  const userAccessToDisks = hasUserAccessToFeature(userProfile, FEATURES.IAAS_DISKS, [USER_ACCESS.VIEW]);
  const isAnyAccessGranted = [userAccessToVms, userAccessToDisks].some((access) => access);

  return (
    <div className={classes.usageBoxContainer}>
      <div className={classes.usageBoxItem}>
        <Card data-testid="usageStatisticVm">
          <UsageStatisticCard
            userAccessToBtn={userAccessToVms}
            isAnyAccessGranted={isAnyAccessGranted}
            variant={VM_VARIANT}
            label={`${usageStatistic.vmTotal} ${t("vm.prefix")}`}
            labelHint={t("vm.hint")}
            linkTo="/infra/vm"
          />
        </Card>
      </div>
      <div className={classes.usageBoxItem}>
        <Card data-testid="usageStatisticVCPU">
          <UsageStatisticCard
            userAccessToBtn={userAccessToVms}
            isAnyAccessGranted={isAnyAccessGranted}
            variant={CPU_VARIANT}
            label={`${usageStatistic.vcpuTotal} ${t("cpu.prefix")}`}
            labelHint={t("cpu.hint")}
            linkTo="/infra/vm"
          />
        </Card>
      </div>
      <div className={classes.usageBoxItem}>
        <Card data-testid="usageStatisticData">
          <UsageStatisticCard
            userAccessToBtn={userAccessToDisks}
            isAnyAccessGranted={isAnyAccessGranted}
            label={formatSize(usageStatistic.dataStored).toUpperCase()}
            variant={DATA_VARIANT}
            labelHint={t("data.hint")}
            linkTo="/infra/disks"
          />
        </Card>
      </div>
      <div className={classes.usageBoxItem}>
        <Card data-testid="usageStatisticMemory">
          <UsageStatisticCard
            userAccessToBtn={userAccessToVms}
            isAnyAccessGranted={isAnyAccessGranted}
            label={formatSize(usageStatistic.memoryTotal).toUpperCase()}
            variant={MEMORY_VARIANT}
            labelHint={t("memory.hint")}
            linkTo="/infra/vm"
          />
        </Card>
      </div>
    </div>
  );
};

export default React.memo(UsageStatisticCards);
