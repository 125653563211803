import React, { useEffect, useState } from "react";
import Input from "../../../../ui-kit/Input";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { DEFAULT_CONFIRMATION_TEXT } from "../../../../constants/texts";
import { detachNetworkRequest, setAllVmsAct } from "../../../../store/actions/virtualMachinesActions";

import styles from "../styles";

const useStyles = makeStyles(styles);

/**
 * @param {string | null} detachNetworkId
 * @param {VmStateModel} vm
 * @param {function} onClose
 * @return {JSX.Element}
 * @constructor
 */
const DetachNetworkModal = ({ detachNetworkId, vm, onClose }) => {
  const classes = useStyles();
  const [isDetaching, setIsDetaching] = useState(false);
  const [confirmationText, setConfirmationText] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation("vmPage");
  const confirmWord = t("confirmDetachNetworkModal.confirmWord") || DEFAULT_CONFIRMATION_TEXT;

  const handleConfirmDetach = async () => {
    try {
      setIsDetaching(true);
      await dispatch(detachNetworkRequest(vm, detachNetworkId));
      await dispatch(setAllVmsAct());
      onClose();
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
    setIsDetaching(false);
  };

  useEffect(() => {
    setConfirmationText("");
  }, [detachNetworkId]);

  return (
    <ConfirmationModal
      isRequesting={isDetaching}
      title={t("confirmDetachNetworkModal.title")}
      open={detachNetworkId !== null}
      onClose={onClose}
      onConfirmed={handleConfirmDetach}
      onCancel={onClose}
      isDisabled={confirmWord !== confirmationText.toLowerCase()}
    >
      <p>{t("confirmDetachNetworkModal.confirmQuestion", { text: confirmWord })}</p>
      <Input
        className={classes.fullWidthInput}
        placeholder={t("confirmDetachNetworkModal.placeholder", { text: confirmWord })}
        type="text"
        value={confirmationText}
        onChange={(e) => setConfirmationText(e.target.value)}
        disabled={isDetaching}
      />
    </ConfirmationModal>
  );
};

export default React.memo(DetachNetworkModal);
