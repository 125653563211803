import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { setAllDatacentersAct } from "../../store/actions/datacenterActions";
import DatacentersContent from "./DatacentersContent/DatacentersContent";
import PageHeader from "../shareableComponents/PageHeader";

import styles from "./styles";

const useStyles = makeStyles(styles);

const Datacenters = () => {
  const { t } = useTranslation("datacentersPage");
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAllDatacentersAct());
  }, [dispatch]);

  return (
    <div className={classes.page}>
      <PageHeader title={t("title")} />
      <DatacentersContent />
    </div>
  );
};

export default Datacenters;
