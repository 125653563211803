import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "../../../../ui-kit/Checkbox";
import Tooltip from "../../../../ui-kit/Tooltip";
import { ClickAwayListener } from "@material-ui/core";
import cn from "classnames";
import { getSnapshotsAct, selectVM } from "../../../../store/actions/virtualMachinesActions";
import { Button, Icon, Tag, Tooltip as UiKitTooltip } from "@skyportal/ui-kit";
import { getSelectedColsSel, vmSel } from "../../../../store/selectors/vmSelectors";
import {
  getVmStatusLabel,
  isVmRunning,
  vmStatusToClassName,
  isNewCreatedVm,
  getVmOsLogoUrl,
} from "../../../../utils/vm";
import { formatSize } from "../../../../utils/size";
import { composeDisksLabel } from "../../../helpers";
import expansionPanelStyle from "./ExpansionPanelStyle";
import SnapshotPanel from "./SnapshotPanel";
import { ExpansionPanel, PanelSummary, PanelDetails } from "./ExpansionPanel";
import VmDetailsPopup from "./VmDetailsPopup";

import styles from "./styles";

const useStyles = makeStyles(styles);
const useExpansionPanelStyles = makeStyles(expansionPanelStyle);

/**
 * @param {VmStateModel} source
 * @param {string} colName
 * @param {object} classes
 * @return {JSX.Element|string}
 */
const fieldParser = (source, colName, classes) => {
  if (!source || !source.hardware || !source.status) return "";

  switch (colName) {
    case "status":
      return (
        <div
          classes={{ label: classes.badgeLabel }}
          className={cn(classes.btn, classes[vmStatusToClassName(source.status.color)], classes.badge)}
        >
          {getVmStatusLabel(source)}
        </div>
      );
    case "name":
      return (
        <span key={colName} className={cn(classes.cellData, { [classes.cellRightPad]: isNewCreatedVm(source) })}>
          {source.name}
          {isNewCreatedVm(source) && (
            <Icon icon="star-black" color="popBlue" height={10} className={classes.starIcon} />
          )}
        </span>
      );
    case "disk":
      return (
        <span key={colName} className={classes.cellData}>
          {composeDisksLabel(source)}
        </span>
      );
    case "cpu":
      return (
        <span key={colName} className={classes.cellData}>
          {source.hardware.vcpu}
        </span>
      );
    case "memory":
      return (
        <span key={colName} className={classes.cellData}>
          {formatSize(source.hardware.memory)}
        </span>
      );
    case "size":
      return (
        <span key={colName} className={classes.cellData}>
          {formatSize(source.hardware[colName])}
        </span>
      );
    case "group":
      return (
        <span key={colName} className={classes.cellData}>
          {source.__groupName || " "}
        </span>
      );
    case "datacenter":
      return (
        <span key={colName} className={classes.cellData}>
          {source.dcOwner.name || " "}
        </span>
      );
    case "os": {
      const LogoElement = () => (
        <span key={colName} className={classes.cellData} data-testid="VMTableRow__os-logo">
          <span className={classes.cellOsImg} style={{ backgroundImage: `url("${getVmOsLogoUrl(source)}")` }} />
        </span>
      );
      if (source.attributes.guest_os) {
        return (
          <UiKitTooltip title={source.attributes.guest_os}>
            <span>
              <LogoElement />
            </span>
          </UiKitTooltip>
        );
      }
      return <LogoElement />;
    }
    case "network":
      return (
        <span key={colName} className={classes.cellData}>
          {source.__networkNameList.map((item, index) => (
            <div key={String(index)}>
              <Tag>
                <UiKitTooltip title={item}>
                  <span>{item}</span>
                </UiKitTooltip>
              </Tag>
            </div>
          ))}
        </span>
      );
    case "ip":
    case "mac":
      return (
        <span key={colName} className={classes.cellData}>
          {(source.hardware.networks || [])
            .filter((item) => item[colName])
            .map((item, index) => (
              <div key={String(index)}>
                <Tag>
                  <UiKitTooltip title={item[colName]}>
                    <span>{item[colName]}</span>
                  </UiKitTooltip>
                </Tag>
              </div>
            ))}
          {(source.hardware.networks || []).length === 0 && <span>-</span>}
        </span>
      );
    default:
      return (
        <span key={colName} className={classes.cellData}>
          {source[colName]}
        </span>
      );
  }
};

const VMTableRow = ({ vm }) => {
  const dispatch = useDispatch();
  const selectedCols = useSelector(getSelectedColsSel);
  const { selectedVMs } = useSelector(vmSel);
  const snapshots = useMemo(() => vm.snapshots || [], [vm]);
  const dcUrl = (vm.dcOwner && vm.dcOwner.api && vm.dcOwner.api.url) || "";

  const [isExpanded, setExpanded] = useState(false);
  const [isVmDetailsPopupOpen, setIsVmDetailsPopupOpen] = useState(false);
  const handleSelectChange = (event) => {
    event.stopPropagation();
    dispatch(selectVM(vm._id));
  };

  const positionRef = React.useRef({ x: 0, y: 0 });
  const scrollYOnVmClick = React.useRef(0);
  const areaRef = React.useRef(null);

  const countDetailedPopupPosition = () => {
    // count position of popup depending on position of click
    const scrollDelta = scrollYOnVmClick.current - window.scrollY;

    return new DOMRect(
      positionRef.current.x,
      areaRef.current.getBoundingClientRect().y +
        (positionRef.current.y - areaRef.current.getBoundingClientRect().top + scrollDelta),
      0,
      0
    );
  };

  const handleVmDetailsPopupOpen = (event) => {
    if (!isVmDetailsPopupOpen) {
      positionRef.current = { x: event.clientX, y: event.clientY };
      scrollYOnVmClick.current = window.scrollY;
      setIsVmDetailsPopupOpen(true);
    } else {
      setIsVmDetailsPopupOpen(false);
    }
  };
  const handleVmDetailsPopupClose = () => setIsVmDetailsPopupOpen(false);

  const panelStyles = useExpansionPanelStyles();
  const classes = useStyles();

  const handleChange = (event) => {
    event.stopPropagation();
    if (!isExpanded && !vm.snapshots) {
      dispatch(getSnapshotsAct(vm._id, dcUrl));
    }

    if (isVmRunning(vm)) {
      setExpanded(!isExpanded);
    }
  };

  const values = useMemo(
    () =>
      selectedCols
        .filter((col) => col.selected)
        .map((col) => (
          <span key={col.name} className={classes[col.name]}>
            {fieldParser(vm, col.name, classes)}
          </span>
        )),
    [vm]
  );

  return (
    <ClickAwayListener onClickAway={handleVmDetailsPopupClose}>
      <div>
        <Tooltip
          arrow
          position="bottom"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          open={isVmDetailsPopupOpen}
          onClose={handleVmDetailsPopupClose}
          title={<VmDetailsPopup vm={vm} onClose={handleVmDetailsPopupClose} />}
          PopperProps={{
            anchorEl: { getBoundingClientRect: countDetailedPopupPosition },
          }}
          classes={{
            arrow: classes.tooltipArrow,
            popper: classes.popper,
            tooltip: classes.tooltip,
          }}
        >
          <div data-testid="VMTableRow" onClick={handleVmDetailsPopupOpen} ref={areaRef}>
            <ExpansionPanel className={panelStyles.root} square expanded={isExpanded}>
              <PanelSummary
                classes={{
                  root: panelStyles.summaryRoot,
                  content: panelStyles.summaryContent,
                  expandIcon: classes.snapShotShell,
                }}
                IconButtonProps={{
                  disableRipple: true,
                  onClick: handleChange,
                }}
                expandIcon={
                  isVmRunning(vm) ? (
                    <UiKitTooltip title="Snapshot">
                      <Button leftIcon="image"></Button>
                    </UiKitTooltip>
                  ) : (
                    <div />
                  )
                }
              >
                <div className={classes.muiCell}>
                  <div className={classes.dataRow}>
                    <div className={classes.vmData}>
                      <span className={classes.checkboxShell} onClick={handleSelectChange}>
                        <Checkbox testId="VMTableRow_checkbox" isChecked={selectedVMs.includes(vm._id)} />
                      </span>
                      {values}
                    </div>
                  </div>
                </div>
              </PanelSummary>
              {isVmRunning(vm) && (
                <PanelDetails>
                  <SnapshotPanel snapshots={snapshots} vmId={vm._id} dcUrl={dcUrl} />
                </PanelDetails>
              )}
            </ExpansionPanel>
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default VMTableRow;
