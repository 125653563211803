import React from "react";
import cn from "classnames";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @typedef {object} DropdownOption
 * @property {string} label
 * @property {string} value
 * @property {string|number} [key]
 */

/**
 * @param {function(step: number)} onChange
 * @param {number} value
 * @param {DropdownOption[]} options
 * @param {string} [testId]
 * @param {object} [selectClasses]
 * @param {object} [optionClasses]
 * @param {object} [rest]
 * @return {JSX.Element}
 * @constructor
 */
const Dropdown = ({ onChange, value, options, selectClasses = {}, optionClasses = {}, testId, ...rest }) => {
  const classes = useStyles();

  return (
    <Select
      data-testid={testId}
      classes={{
        ...selectClasses,
        select: cn(classes.muiSelectRoot, selectClasses.select),
        icon: cn(classes.muiSelectIcon, selectClasses.icon),
      }}
      IconComponent={KeyboardArrowDownIcon}
      disableUnderline
      onChange={onChange}
      value={value}
      {...rest}
    >
      {options.map((option) => (
        <MenuItem
          key={option.key || option.value}
          value={option.value}
          classes={{ ...optionClasses, root: cn(classes.muiMenuItem, optionClasses.root) }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Dropdown;
