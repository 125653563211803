import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Slider } from "@material-ui/core";
import Input from "../../../../ui-kit/Input";
import { isResizingVmHardwareAllowed } from "../../../helpers";
import { decimalNormalizer, gb2Mb, mb2gb } from "../../../../utils/size";
import {
  attributeParser,
  enhanceLimits,
  TEMPLATE_VALIDATION_TYPE_ANY,
  TEMPLATE_VALIDATION_TYPE_FIXED,
  TEMPLATE_VALIDATION_TYPE_LIST,
  TEMPLATE_VALIDATION_TYPE_RANGE,
} from "../constantsNHelpers";

import { formStyles } from "../styles";

const useStyles = makeStyles(formStyles);

const DEFAULT_SLIDER_STEP = 0.05;
const DEFAULT_MEMORY_MARK_MIN_VALUE = 0.1;
const DEFAULT_MEMORY_MARK_MAX_VALUE = 8;
const DEFAULT_MEMORY_MARKS = [DEFAULT_MEMORY_MARK_MIN_VALUE, DEFAULT_MEMORY_MARK_MAX_VALUE];
const DEFAULT_MEMORY_MARK_OPTIONS = DEFAULT_MEMORY_MARKS.map((mark) => ({ value: mark, label: String(mark) }));
const DEFAULT_MEMORY_ATTRIBUTES = {};

const composeMarksByValidationRules = (attributes) => {
  switch (attributes.type) {
    case TEMPLATE_VALIDATION_TYPE_LIST:
    case TEMPLATE_VALIDATION_TYPE_RANGE:
      return attributes.options.map((option) => ({
        value: Number(decimalNormalizer(mb2gb(Number(option)))),
        label: String(decimalNormalizer(mb2gb(Number(option)))),
      }));
    case TEMPLATE_VALIDATION_TYPE_FIXED:
      return [
        DEFAULT_MEMORY_MARK_OPTIONS[0],
        {
          value: mb2gb(Number(attributes.defaultValue)),
          label: String(mb2gb(Number(attributes.defaultValue))),
        },
      ];
    default:
      return DEFAULT_MEMORY_MARK_OPTIONS;
  }
};

const MemorySlider = ({
  VmMemorySliderValue,
  setVmMemorySlider,
  handleMemoryChange,
  selectedTemplate,
  VmMemoryInputValue,
  setVmMemoryInput,
  isEditVMPage,
  memoryRef,
  currentVm,
}) => {
  const { t } = useTranslation("vmPage");
  const classes = useStyles();
  const [memoryAttributes, setMemoryAttributes] = useState(DEFAULT_MEMORY_ATTRIBUTES);

  const [memoryMarks, setMemoryMarks] = useState(DEFAULT_MEMORY_MARK_OPTIONS);

  const setSliderAndInputValue = (value) => {
    setVmMemorySlider(Number(value));
    setVmMemoryInput(String(value));
  };

  const updateSliderMarks = (value) => {
    if (!memoryAttributes.type || memoryAttributes.type === TEMPLATE_VALIDATION_TYPE_ANY) {
      enhanceLimits(memoryMarks, setMemoryMarks, value || VmMemorySliderValue);
    }
  };

  const onInputSizeBlur = (event) => {
    const maxMemory = memoryMarks[memoryMarks.length - 1].value;
    const value = Number(event.target.value);
    const mbValue = gb2Mb(value);

    if (!Number.isNaN(mbValue)) {
      if (memoryAttributes.type === TEMPLATE_VALIDATION_TYPE_RANGE) {
        if (mbValue > Number(memoryAttributes.options[1]) || mbValue < Number(memoryAttributes.options[0])) {
          setSliderAndInputValue(mb2gb(Number(memoryAttributes.defaultValue)));
          return;
        }
      }
      if (memoryAttributes.type === TEMPLATE_VALIDATION_TYPE_LIST) {
        if (memoryAttributes.options.includes(String(mbValue))) {
          setSliderAndInputValue(value);
          return;
        }
        setSliderAndInputValue(mb2gb(Number(memoryAttributes.defaultValue)));
        return;
      }
      if (value >= maxMemory) {
        setSliderAndInputValue(value);

        const interOpMarks = [...memoryMarks];
        interOpMarks[interOpMarks.length - 1].value = value;
        interOpMarks[interOpMarks.length - 1].label = value.toString();

        setMemoryMarks(interOpMarks);
      } else if (value <= DEFAULT_MEMORY_MARK_MIN_VALUE) {
        setSliderAndInputValue(DEFAULT_MEMORY_MARK_MIN_VALUE);
      } else {
        setSliderAndInputValue(value);
      }
      updateSliderMarks(value);
    }
  };

  useEffect(() => {
    if (!selectedTemplate) {
      return;
    }

    const { memory } = selectedTemplate.hardware.validations;

    if (typeof memory !== "string") {
      setMemoryMarks(DEFAULT_MEMORY_MARK_OPTIONS);
      setMemoryAttributes(DEFAULT_MEMORY_ATTRIBUTES);
      const _gbValue = selectedTemplate.hardware.memory
        ? mb2gb(Number(selectedTemplate.hardware.memory))
        : DEFAULT_MEMORY_MARK_MIN_VALUE;
      setSliderAndInputValue(_gbValue);
      return;
    }

    const attributes = attributeParser(memory);
    const gbValue = mb2gb(Number(attributes.defaultValue));
    setMemoryAttributes(attributes);
    if (!Number.isNaN(gbValue)) {
      setSliderAndInputValue(mb2gb(Number(attributes.defaultValue)));
    }
    setMemoryMarks(composeMarksByValidationRules(attributes));
  }, [selectedTemplate]);

  useEffect(() => {
    const handleMouseUp = () => updateSliderMarks();

    if (memoryRef.current) {
      memoryRef.current.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      if (memoryRef.current) {
        memoryRef.current.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [memoryAttributes, memoryMarks, setMemoryMarks, VmMemorySliderValue]);

  const areInputsDisabled =
    (!isEditVMPage && !selectedTemplate) ||
    memoryAttributes.type === TEMPLATE_VALIDATION_TYPE_FIXED ||
    (isEditVMPage && !isResizingVmHardwareAllowed(currentVm));

  return (
    <>
      <div className={classes.fieldLabel}>{t("createOrEdit.fields.memory.label")}</div>
      <div className={classes.slideWrapper}>
        <div className={classes.sliderInner} data-testid="MemorySlider__slide-wrapper">
          <Slider
            classes={{
              markLabel: classes.sliderMark,
            }}
            className={classes.slider}
            value={VmMemorySliderValue || DEFAULT_MEMORY_MARK_MIN_VALUE}
            onChange={handleMemoryChange}
            min={memoryMarks[0].value}
            max={memoryMarks[memoryMarks.length - 1].value}
            step={memoryAttributes.type === TEMPLATE_VALIDATION_TYPE_LIST ? null : DEFAULT_SLIDER_STEP}
            disabled={areInputsDisabled}
            marks={memoryMarks}
          />
        </div>
        <div className={classes.slideInputWrapper}>
          <Input
            data-testid="MemorySlider__input"
            className={classes.numberInputField}
            onChange={(event) => setVmMemoryInput(event.target.value)}
            disabled={areInputsDisabled}
            value={VmMemoryInputValue}
            onBlur={onInputSizeBlur}
          />
          <span className={classes.slideInputDescription}>{t("iaasCommon:GB")}</span>
        </div>
      </div>
    </>
  );
};

export default MemorySlider;
