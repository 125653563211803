import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@skyportal/ui-kit";
import { Grid, ButtonGroup, Button as MUIButton } from "@material-ui/core";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { isVmRunning } from "../../../utils/vm";
import { vmPowerOff } from "../../../store/actions/virtualMachinesActions";
import { SHUTDOWN_SIGNAL, REBOOT_SIGNAL, RESET_SIGNAL, CTR_ALT_DEL_SIGNAL } from "./constants";
import { sendRFBSignal, getSignalTexts } from "./utils";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {VmStateModel} [vm]
 * @param {{current: RFB|null}} rfbRef
 * @param {boolean} isConnected
 * @param {null,{power: boolean}} capabilities
 * @param {null,{power: boolean}} viewRef
 * @return {JSX.Element}
 * @constructor
 */
const VncHeader = ({ vm, rfbRef, viewRef, isConnected, capabilities }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const params = useParams();
  const { t } = useTranslation("vnc");
  const [signalToConfirm, setSignalToConfirm] = useState(null);
  const [stopConfirmModalShow, setStopConfirmModalShow] = useState(false);
  const hasPowerCapability = Boolean(capabilities && capabilities.power);

  const handleStopVMConfirm = () => dispatch(vmPowerOff({ isHard: false, vmIds: [params.id] }));
  const handleStopVmBtnClick = () => setStopConfirmModalShow(true);
  const handleStopVMConfirmModalClose = () => setStopConfirmModalShow(false);

  const handleConfirm = () => {
    sendRFBSignal(rfbRef.current, signalToConfirm);
    setSignalToConfirm(null);
  };
  const handleConfirmModalClose = () => setSignalToConfirm(null);
  const makeModalControlClick = (modal) => () => setSignalToConfirm(modal);
  const handleFullScreenBtnClick = async () => {
    if (viewRef.current) {
      await viewRef.current.requestFullscreen();
    }
    if (rfbRef.current) {
      rfbRef.current.focus();
    }
  };
  const handlePasteBtnClick = async () => {
    if (rfbRef.current) {
      const text = await navigator.clipboard.readText();

      for (let i = 0, len = text.length; i < len; i += 1) {
        rfbRef.current.sendKey(text.charCodeAt(i), null, true);
      }
    }
  };

  return (
    <>
      <Grid
        data-testid="VncHeader"
        container
        className={classes.controlContainer}
        justifyContent="flex-end"
        alignItems="center"
        spacing={1}
      >
        <Grid item className={classes.statusBox}>
          {vm ? vm.name : ""}
        </Grid>
        <Grid item>
          <ButtonGroup size="small">
            <MUIButton
              data-testid="VncHeader_stop-control"
              disabled={!isVmRunning(vm)}
              className={classes.btn}
              onClick={handleStopVmBtnClick}
            >
              {t("iaasCommon:Stop")}
            </MUIButton>
          </ButtonGroup>
        </Grid>
        {hasPowerCapability && (
          <Grid item>
            <ButtonGroup size="small">
              <MUIButton
                data-testid="VncHeader_reboot-control"
                className={classes.btn}
                onClick={makeModalControlClick(REBOOT_SIGNAL)}
              >
                {t("iaasCommon:Reboot")}
              </MUIButton>
              <MUIButton
                data-testid="VncHeader_reset-control"
                className={classes.btn}
                onClick={makeModalControlClick(RESET_SIGNAL)}
              >
                {t("iaasCommon:Reset")}
              </MUIButton>
              <MUIButton
                data-testid="VncHeader_shutdown-control"
                className={classes.btn}
                onClick={makeModalControlClick(SHUTDOWN_SIGNAL)}
              >
                {t("iaasCommon:Shutdown")}
              </MUIButton>
            </ButtonGroup>
          </Grid>
        )}

        <Grid item>
          <ButtonGroup size="small">
            <MUIButton
              data-testid="VncHeader_CAD-control"
              className={classes.btn}
              disabled={!isConnected}
              onClick={makeModalControlClick(CTR_ALT_DEL_SIGNAL)}
            >
              {t("sendCrtAltDelBnt")}
            </MUIButton>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <ButtonGroup>
            <MUIButton data-testid="VncHeader_paste-control" className={classes.iconBtn} onClick={handlePasteBtnClick}>
              <Icon type="fontawesome" icon="paste" />
            </MUIButton>
            <MUIButton
              data-testid="VncHeader_fullscreen-control"
              className={classes.iconBtn}
              onClick={handleFullScreenBtnClick}
            >
              <Icon type="fontawesome" icon="expand-arrows-alt" />
            </MUIButton>
          </ButtonGroup>
        </Grid>
      </Grid>
      <ConfirmationModal
        open={Boolean(signalToConfirm)}
        onClose={handleConfirmModalClose}
        title={getSignalTexts(signalToConfirm, t).title}
        hint={getSignalTexts(signalToConfirm, t).hint}
        onCancel={handleConfirmModalClose}
        onConfirmed={handleConfirm}
      />
      <ConfirmationModal
        open={stopConfirmModalShow}
        onClose={handleStopVMConfirmModalClose}
        title={t("stopVMModal.title")}
        hint={t("stopVMModal.hint")}
        onCancel={handleStopVMConfirmModalClose}
        onConfirmed={handleStopVMConfirm}
      />
    </>
  );
};

export default VncHeader;
