import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { Button, Spinner } from "@skyportal/ui-kit";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { vmSel } from "../../../../store/selectors/vmSelectors";
import { imageSel } from "../../../../store/selectors/imgSelectors";
import { getGroups } from "../../../../store/selectors/groupSelectors";
import { deleteVMAct } from "../../../../store/actions/virtualMachinesActions";
import Input from "../../../../ui-kit/Input";
import Checkbox from "../../../../ui-kit/Checkbox";
import { filterListByDcId, getDcIdFromEntity, getGroupNameFromListById } from "utils/groups";
import { isDiskAttachedToVm } from "utils/vm";
import { formatSize } from "utils/size";
import IconTooltip from "components/IconTooltip";
import { CONTRACT_TYPES, FEATURES, hasContractAccessToFeature, USER_ACCESS } from "@skyportal/auth-web-client/dist";

import styles from "./styles";

const useStyles = makeStyles(styles);

const CONFIRM_TEXT = "yes";

const DeleteVmModal = ({ isModalOpen, onClose, userProfile }) => {
  const { t } = useTranslation("deleteVmModal");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirmText, setConfirmText] = useState("");
  const [isSpinnerShown, setIsSpinnerShown] = useState(false);
  const [selectedDisks, setSelectedDisks] = useState([]);
  const { selectedVMs, vms } = useSelector(vmSel);
  const { images } = useSelector(imageSel);
  const groups = useSelector(getGroups);
  const [selectedVmId] = selectedVMs;
  const [vm] = vms.filter((item) => item._id === selectedVmId);

  const dcDisks = filterListByDcId(images, getDcIdFromEntity(vm));
  const attachedDisks = dcDisks.filter((disk) => isDiskAttachedToVm(vm, disk));

  const toggleDiskSelected = (disk) => {
    selectedDisks.find((selectedDisk) => disk._id === selectedDisk._id)
      ? setSelectedDisks(selectedDisks.filter((selectedDisk) => selectedDisk._id !== disk._id))
      : setSelectedDisks([...selectedDisks, disk]);
  };

  const handleConfirmInputChange = (event) => setConfirmText(event.target.value);

  const handleConfirmDeleteBtnClick = async () => {
    setIsSpinnerShown(true);
    if (vm) {
      try {
        const selectedDisksIds = selectedDisks.map((disk) => disk.id);
        const selectedDisksStringIds = selectedDisks.map((disk) => disk._id);

        await dispatch(deleteVMAct(vm, selectedDisksIds, selectedDisksStringIds));
        onClose();
      } catch (e) {
        console.error(e);
      }
    }
    setIsSpinnerShown(false);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setConfirmText("");
      setSelectedDisks([]);
    }
  }, [isModalOpen]);

  return (
    <Dialog open={isModalOpen} onClose={onClose}>
      <Spinner show={isSpinnerShown} />
      <div className={classes.deleteVmBodySpace}>
        <div className={classes.modalForm}>
          <div className={classes.modalHeader}>{t("title")}</div>
          <div className={classes.modalCard}>
            <div className={classes.cardRow}>
              <p>
                <span>{t("confirmQuestionStart")}</span>
                {vm && vm.name && <span className={classes.delVmName}>{` ${vm.name} `}</span>}
                <span>{t("confirmQuestionEnd")}</span>
              </p>
              {attachedDisks
                .filter(Boolean)
                .filter((img) =>
                  hasContractAccessToFeature(userProfile, img.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_DISKS, [
                    USER_ACCESS.DELETE,
                  ])
                ).length > 0 && (
                <>
                  <p>{t("disksAreAttachedText")}</p>
                  <div className={classes.selectedImgBar}>
                    <div className={cn(classes.selImgName, classes.selImgNameTableHead)}>{t("iaasCommon:Name")}</div>
                    <div className={classes.selImgMiddle}>{t("iaasCommon:Group")}</div>
                    <div className={classes.selImgMiddle}>{t("iaasCommon:Tier")}</div>
                    <div className={classes.selImgMiddle}>{t("iaasCommon:Size")}</div>
                  </div>
                  {attachedDisks
                    .filter(Boolean)
                    .filter((img) =>
                      hasContractAccessToFeature(userProfile, img.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_DISKS, [
                        USER_ACCESS.DELETE,
                      ])
                    )
                    .map((img) => (
                      <div key={img._id} className={classes.selectedImgRow}>
                        {img.vms.length > 1 ? (
                          <IconTooltip title={t("tooltipText")} />
                        ) : (
                          <Checkbox
                            isChecked={selectedDisks.find((disk) => img._id === disk._id)}
                            checkHandler={() => toggleDiskSelected(img)}
                          />
                        )}
                        <div className={classes.selImgName}>{img.name}</div>
                        <div className={classes.selImgMiddle}>{getGroupNameFromListById(groups, img.group, "-")}</div>
                        <div className={classes.selImgMiddle}>{img.tier || "-"}</div>
                        <div className={classes.selImgMiddle}>{formatSize(img.size)}</div>
                      </div>
                    ))}
                </>
              )}
            </div>
            <div className={classes.cardRow}>
              <p>{t("hint", { word: CONFIRM_TEXT })}</p>
            </div>
            <div className={classes.cardRow}>
              <Input
                className={classes.cardRowInput}
                placeholder={t("placeholder", { word: CONFIRM_TEXT })}
                value={confirmText}
                onChange={handleConfirmInputChange}
              />
            </div>
            <div className={classes.vmModalFooter}>
              <Button disabled={confirmText !== CONFIRM_TEXT} onClick={handleConfirmDeleteBtnClick}>
                {t("iaasCommon:Remove")}
              </Button>
              <Button type="secondary" onClick={onClose}>
                {t("iaasCommon:Cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteVmModal;
