const styles = (theme) => ({
  dropdown: {
    height: "32px",
    color: "white",
    backgroundColor: theme.colors.black,
    borderRadius: "2px",
    transition: ".3s",

    "&:hover": {
      opacity: "0.75",
      cursor: "pointer",
    },
  },
  mainBar: {
    display: "flex",
    height: "100%",
    padding: "0 10px",
    boxSizing: "border-box",
  },
  label: {
    marginRight: "5px",
  },
  iconShell: {
    ...theme.classes.centralized,
    flexGrow: 1,
    margin: "0 3px",
  },
  elements: {
    position: "relative",
    zIndex: 2,
    transformOrigin: "top",
    display: "none",
    pointerEvents: "none",
    margin: "0px !important",
  },
  isExpanded: {
    display: "block",
    pointerEvents: "unset",
  },
});

export default styles;
