import { createSelector } from "reselect";
import { makeUniversalSort } from "../../pages/helpers";

export const reportSel = (state) => state.reports;
export const getReports = (state) => state.reports.reports;
export const getCurrentReportPage = (state) => state.reports.currentReportPage;
export const getPerPage = (state) => state.reports.perPage;
export const getEmailReportRequest = (state) => state.reports.sendEmailReportRequest;
export const getReportRequest = (state) => state.reports.reportRequest;
export const getDetailedReportRequest = (state) => state.reports.detailedReportsRequest;
export const getStorageDetailsReportRequest = (state) => state.reports.storageDetailsReportRequest;
export const getSorterValue = (state) => state.reports.sorterValue;
export const getSortWay = (state) => state.reports.sortWay;
export const getSummaryReportFilterStatuses = (state) => state.reports.summaryReportFilterStatuses;
export const getSelectionDetailedDate = (state) => state.reports.selectionDetailedDate;

/**
 * @param {RootState} state
 * @return {StorageDetailsReportItem[]}
 */
export const getStorageDetailsReportList = (state) => state.reports.storageDetailsReportList;
/**
 * @param {RootState} state
 * @return {DetailedReport[]}
 */
export const getDetailedReports = (state) => state.reports.detailedReports;
/**
 * @param {RootState} state
 * @return {{count: number, vcpu: number, memory: number, storage: number, tiers: {name: string, storage: number}[]}}
 */
export const getDetailedReportTotals = (state) =>
  getDetailedReports(state).reduce(
    (acc, item) => {
      acc.count += 1;
      acc.vcpu += item.vcpu_count;
      acc.memory += item.memory_size;
      acc.storage += item.image_size;
      acc.tiers = Array.from(
        new Set([...acc.tiers.map((_item) => _item.name), ...item.tiers.map((_item) => _item.name)])
      ).map((_name) => ({
        name: _name,
        storage:
          (acc.tiers.find((_item) => _item.name === _name)?.storage || 0) +
          (item.tiers.find((_item) => _item.name === _name)?.image_size || 0),
      }));
      return acc;
    },
    { count: 0, vcpu: 0, memory: 0, storage: 0, tiers: [] }
  );

const SORT_HANDLER_MAKER_MAP = {
  _id: makeUniversalSort((item) => new Date(item._id).getTime()),
  vm_count: makeUniversalSort((item) => item.vm_count),
  vcpu_count: makeUniversalSort((item) => item.vcpu_count),
  memory_size: makeUniversalSort((item) => item.memory_size),
  image_count: makeUniversalSort((item) => item.image_count),
  image_size: makeUniversalSort((item) => item.image_size),
  name: makeUniversalSort((item) => item.name),
  status: makeUniversalSort((item) => item.status),
  size: makeUniversalSort((item) => item.size),
  _groupWithAgreementID: makeUniversalSort((item) => item._groupWithAgreementID),
  vms: makeUniversalSort((item) => item.vms.length),
  tier: makeUniversalSort((item) => (item.tier !== "null" ? item.tier : "")),
};

/**
 * @param {SummaryReportSortField|DetailedReportSortField} sortField
 * @param {"asc"|"desc"} sortOrder
 * @param {number} currentPage
 * @param {SummaryReportApiModel[]|DetailedReportSortField[]} reports
 * @param {number} [perPage]
 * @return {{total: number, pageCount: number, list: SummaryReportApiModel[]|DetailedReportSortField[]}}
 */
const reportsSelectComposer = ({ sortField, sortOrder, currentPage, reports, perPage }) => {
  const _list = [...reports];

  if (SORT_HANDLER_MAKER_MAP[sortField]) {
    _list.sort(SORT_HANDLER_MAKER_MAP[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: _list.slice((currentPage - 1) * perPage, currentPage * perPage),
  };
};

export const getSummaryReportTableRegions = (state) => {
  const { sorterValue, sortWay, currentReportPage, reports, perPage } = reportSel(state);
  return reportsSelectComposer({
    sortField: sorterValue,
    sortOrder: sortWay,
    currentPage: currentReportPage,
    reports,
    perPage,
  });
};

export const getDetailedReportTableRegions = (state) => {
  const { sorterValue, sortWay, currentReportPage, detailedReports, perPage } = reportSel(state);
  return reportsSelectComposer({
    sortField: sorterValue,
    sortOrder: sortWay,
    currentPage: currentReportPage,
    reports: detailedReports,
    perPage,
  });
};

export const getStorageDetailedReportTableRegions = createSelector(
  getSorterValue,
  getSortWay,
  getCurrentReportPage,
  getPerPage,
  getStorageDetailsReportList,
  (sortField, sortOrder, currentPage, perPage, reports) =>
    reportsSelectComposer({
      sortField,
      sortOrder,
      currentPage,
      reports,
      perPage,
    })
);

export const getStorageDetailedReportTableRegionsWithTotalRow = createSelector(
  getStorageDetailedReportTableRegions,
  getStorageDetailsReportList,
  (rest, list) => ({
    ...rest,
    totalAttached: list.reduce((acc, item) => [...acc, ...item.vms], []).length,
    tiersTotals: Object.entries(
      list.reduce((acc, item) => {
        acc[item.tier] = (acc[item.tier] || 0) + item.size;
        return acc;
      }, {})
    ).map(([name, tierTotal]) => ({ name, tierTotal })),
    totalAllocatedStorage: list.reduce((acc, item) => acc + item.size, 0),
    list: [
      ...rest.list,
      {
        _id: "total",
      },
    ],
  })
);
