const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "8px 16px",

    "@media(max-width: 767px) ": {
      flexWrap: "wrap",
    },
  },
});

export default styles;
