export const TODAY_LABEL = "Today";
export const WEEK_LABEL = "Week";
export const MONTH_LABEL = "Month";
export const THREE_MONTH_LABEL = "3 Month";
export const SIX_MONTH_LABEL = "6 Month";
export const YEAR = "Year";

export const DATE_CONTROL_LABELS = Object.freeze([
  TODAY_LABEL,
  WEEK_LABEL,
  MONTH_LABEL,
  THREE_MONTH_LABEL,
  SIX_MONTH_LABEL,
  YEAR,
]);
export const DAY_OFFSET = Object.freeze({
  [TODAY_LABEL]: 1,
  [WEEK_LABEL]: 7,
  [MONTH_LABEL]: 30,
  [THREE_MONTH_LABEL]: 90,
  [SIX_MONTH_LABEL]: 180,
  [YEAR]: 365,
});

export const ALL_KEY = "All";
export const NO_TIER_KEY = "noTier";
export const CHART_COLOR_LIST = [
  "#44d9fc",
  "#b4b4b4",
  "#c48df7",
  "#e59a7f",
  "#1f603f",
  "#19495e",
  "#6d000b",
  "#5c3e33",
  "#2e2256",
  "#4f4f4f",
];
