const styles = (theme) => ({
  sorterBar: {
    display: "flex",
    padding: "0 24px",
    borderBottom: "3px solid #f4f4f4",
  },
  cell: {
    ...theme.classes.table.cell,
    padding: "18px 8px 8px 8px",
    cursor: "pointer",
    display: "flex",
    flexGrow: 1,
    whiteSpace: "nowrap",
    boxSizing: "border-box",

    "&:first-child": {
      width: "200px",
      flexGrow: 2,
    },
    "&:nth-child(2)": {
      width: "100px",
    },
    "&:nth-child(3)": {
      width: "150px",
    },
    "&:nth-child(4)": {
      width: "100px",
    },
    "&:nth-child(5)": {
      width: "100px",
    },
    "&:nth-child(6)": {
      width: "100px",
    },
    "&:nth-child(7)": {
      width: "150px",
    },
    "&:last-child": {
      width: "100px",
      flexGrow: 0,
    },
  },
  sorterCell: {
    borderBottom: "none",
    width: "150px",
  },
  sorterCellIcon: {
    ...theme.classes.table.sorterCellIcon,
    color: theme.colors.inactiveGrey,
    marginRight: "6px",
  },
  sorterCellText: {
    ...theme.classes.table.sorterCellText,
    textTransform: "capitalize",
    fontSize: theme.fonts.smaller,
  },
  header: {
    ...theme.classes.centralized,
    minHeight: "55px",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    fontSize: theme.fonts.bigger,
    paddingBottom: "16px",
  },
  headerContent: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  content: {
    flexGrow: 10,
    display: "flex",
    alignItems: "center",
  },
  titleWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",

    "& button": {
      marginRight: "16px",
    },
  },
  headerText: {
    fontSize: "28px",
    lineHeight: "32px",
    textTransform: "capitalize",
    margin: "0",

    "@media(max-width: 767px)": {
      textAlign: "center",
    },
  },
  notificationCard: {
    padding: "6px 16px",
    borderRadius: "4px",
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
    maxWidth: "400px",
    minWidth: "292px",
  },
  notificationCardTitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "8px 0px",
    opacity: "0.7",
  },
  notificationCardIcon: {
    fontSize: "20px",
    marginInlineEnd: "8px",
  },
  notificationCardDescr: {
    paddingBottom: "8px",
    fontSize: "0.85rem",
  },
  pricelistModal: {
    "& header": {
      fontSize: "20px",
      fontWeight: "700",
    },
    "& footer": {
      border: "none",
    },
    "& footer button": {
      fontSize: "14px",
    },
    "& footer>div": {
      display: "block",
    },
  },
  pricelistSubtitle: {
    fontWeight: "700",
    marginBottom: "8px",
  },
  pricelistTable: {
    "& tr": {
      height: "40px",
    },
  },
  pricelistFooter: {
    display: "flex",
    width: "100%",
  },
});

export default styles;
