import React from "react";
import cn from "classnames";
import { Card, CardContent, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner } from "@skyportal/ui-kit";
import { getVmTotals } from "../../../../store/selectors/dashboardSelectors";
import useAllMetricsRequestPending from "../../../../hooks/useAllMetricsRequestPending";
import { PENDING_VARIANT, RUNNING_VARIANT, STOPPED_VARIANT, TOTAL_VARIANT } from "../VmStatusItem/constants";
import styles from "../../../../pages/Dashboard/styles";
import VmStatusItem from "../VmStatusItem/VmStatusItem";
import { getPercentInteger } from "./utils";

const useStyles = makeStyles(styles);

const VmTotalsCard = () => {
  const { t } = useTranslation("iaasCommon");
  const classes = useStyles();
  /**
   * @type {VmTotals}
   */
  const vmTotals = useSelector(getVmTotals);
  const composeLabel = (key) => `${t("vmWithCount", { count: vmTotals[key] })} ${t(key)}`;

  return (
    <Card className={cn(classes.smallCard, classes.basicHeader)}>
      <Spinner show={useAllMetricsRequestPending()} />
      <CardContent classes={{ root: classes.cardContentPadding }}>
        <VmStatusItem value={100} headLabel={t("total")} label={composeLabel("total")} variant={TOTAL_VARIANT} />
        <VmStatusItem
          value={getPercentInteger(vmTotals.total, vmTotals.running)}
          label={composeLabel("running")}
          variant={RUNNING_VARIANT}
          headLabel={t("running")}
        />
        <VmStatusItem
          value={getPercentInteger(vmTotals.total, vmTotals.pending)}
          label={composeLabel("pending")}
          variant={PENDING_VARIANT}
          headLabel={t("pending")}
        />
        <VmStatusItem
          value={getPercentInteger(vmTotals.total, vmTotals.stopped)}
          label={composeLabel("stopped")}
          variant={STOPPED_VARIANT}
          headLabel={t("stopped")}
        />
      </CardContent>
    </Card>
  );
};

export default VmTotalsCard;
