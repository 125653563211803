import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { formStyles } from "../styles";

const useStyles = makeStyles(formStyles);

/**
 * @param {string} [label]
 * @param {React,ReactNode} children
 * @return {JSX.Element}
 * @constructor
 */
export const Control = ({ label, children }) => {
  const classes = useStyles();

  return (
    <>
      {label && (
        <Grid item>
          <div className={classes.fieldLabel}>{label}</div>
        </Grid>
      )}
      <Grid item>{children}</Grid>
    </>
  );
};

export default Control;
