import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Button } from "@skyportal/ui-kit";
import Input from "../../../ui-kit/Input";
import { REQUEST_PENDING, REQUEST_SUCCESS } from "../../../constants/request";
import { CSV_REPORT_ACCEPT_TYPE, PDF_REPORT_ACCEPT_TYPE } from "../../constants";
import { isValidEmail } from "../../../utils/validation";
import { sendReportEmailRequest } from "../../../store/actions/reportsActions";
import { getEmailReportRequest } from "../../../store/selectors/reportSelectors";
import Spinner from "../../../pages/shareableComponents/Spinner/Spinner";

import styles from "./styles";

const useStyles = makeStyles(styles);
/**
 * @type {ReportAcceptFileType[]}
 */
const EXPORT_TYPES = [PDF_REPORT_ACCEPT_TYPE, CSV_REPORT_ACCEPT_TYPE];

/**
 * @param {boolean} open
 * @param {function} onClose
 * @param {ReportQueryParams|{date: string}} reportParams
 * @param {ReportType} reportType
 * @return {JSX.Element}
 * @constructor
 */
const SendModal = ({ open, onClose, reportParams, reportType }) => {
  const onCloseRef = useRef(onClose);
  const { t } = useTranslation("sendReportModal");
  const mailReportRequestState = useSelector(getEmailReportRequest);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailValidMsg, setEmailValidMsg] = useState("");
  const [acceptType, setAcceptType] = useState(PDF_REPORT_ACCEPT_TYPE);
  const classes = useStyles();
  const typeOptions = EXPORT_TYPES.map((type) => ({
    label: t(type),
    value: type,
  }));

  const handleEmailChange = (event) => setEmail(event.target.value);

  const handleSendBtnClick = () => {
    if (!isValidEmail(email)) {
      setEmailValidMsg(t("invalidEmail"));
      return;
    }

    dispatch(
      sendReportEmailRequest(reportType, {
        ...reportParams,
        accept: acceptType,
        email,
      })
    );
  };

  useEffect(() => {
    if (mailReportRequestState === REQUEST_SUCCESS) {
      onCloseRef.current();
    }
  }, [mailReportRequestState]);

  useEffect(() => {
    setEmailValidMsg("");
  }, [email]);

  return (
    <Modal open={open} onClose={onClose} data-testid="Reports_SendModal">
      <div className={classes.modalContainer}>
        <Spinner show={mailReportRequestState === REQUEST_PENDING} />
        <div className={classes.modalHeader}>{t("title")}</div>
        <div className={classes.modalBody}>
          <label className={classes.controlRow}>
            <span className={classes.controlLabel}>{t("Email")}</span>
            <Input
              name="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="example@gmail.com"
              className={classes.input}
            />
            <div className={classes.errMsg}>{emailValidMsg}</div>
          </label>
          <div className={classes.controlRow}>
            <span className={classes.controlLabel}>{t("Format")}</span>
            <Select
              IconComponent={KeyboardArrowDownIcon}
              disableUnderline
              value={acceptType}
              onChange={(event) => setAcceptType(event.target.value)}
              classes={{ root: classes.muiSelectRoot }}
            >
              {typeOptions.map(({ value, label }) => (
                <MenuItem key={value} value={value} className={classes.optionItem}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div className={classes.cardFooter}>
          <Button type="secondary" onClick={onClose}>
            {t("iaasCommon:Cancel")}
          </Button>
          <Button onClick={handleSendBtnClick}>
            <span>{t("iaasCommon:Send")}</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SendModal;
