import {
  CREATE_IMAGE,
  TOGGLE_IMAGE_MODAL_STATE,
  SET_IMAGE_FOR_EDIT,
  UPDATE_IMAGE,
  CLOSE_IMAGE_MODAL,
  DELETE_IMAGE,
  SET_MAX_IMAGE_SIZE,
  SET_IMAGES,
  SET_IMAGE_CURRENT_PAGE,
  SET_IMAGE_SORT_ORDER_PAGE,
  SET_IMAGE_SORT_FIELD_PAGE,
  SET_SEARCH_TEXT_PAGE,
  RESET_IMAGE_SORT,
  SET_IMAGE_PER_PAGE,
  IMAGE_TIER_LIST_REQUEST,
  IMAGE_TIER_LIST_REQUEST_SUCCESS,
  SET_IMAGE_DC_INCLUDE,
  DISK_LIST_PENDING,
  CREATE_DISK_PENDING,
  UPDATE_DISK_PENDING,
  TOGGLE_IMAGE_COLUMN,
} from "../types";

import { createImage, updateImage, deleteImage, getImages, getImageTierList } from "../../api/imageService";
import { editVm } from "../../api/vmService";
import { getDataCenterList } from "../selectors/dcSelectors";
import { notificationAC, notificationErrorAC } from "./notificationActions";
import { parseApiErrorMessage } from "../../api/utils";
import i18n from "../../locales";
import { vmSel } from "../selectors/vmSelectors";
import { getUserProfile } from "../selectors/userSelectors";
import { getSelectedColsSel } from "store/selectors/imgSelectors";
import { storeDatacenterIncludeMap, storeDiskListColumnsMap, storeDisksPerPageValue } from "../../utils/disks";

/**
 * @param {number} payload
 * @return {{payload: number, type: "SET_IMAGE_CURRENT_PAGE"}}
 */
export const setCurrentPageAC = (payload) => ({
  type: SET_IMAGE_CURRENT_PAGE,
  payload,
});

/**
 * @param {PaginationStep} payload
 * @return {{payload: PaginationStep, type: "SET_IMAGE_PER_PAGE"}}
 */
export const setPerPageAC = (payload) => ({
  type: SET_IMAGE_PER_PAGE,
  payload,
});

export const setPerPageValue = (value) => (dispatch, getState) => {
  const { id, email } = getUserProfile(getState()) || {};

  if (email) {
    storeDisksPerPageValue(value, String(id));
  }

  dispatch(setPerPageAC(value));
};

/**
 * @param {"asc"|"desc"} payload
 */
export const setSortOrderAC = (payload) => ({
  type: SET_IMAGE_SORT_ORDER_PAGE,
  payload,
});
/**
 * @param {ImageSortField} payload
 */
export const setSortFieldAC = (payload) => ({
  type: SET_IMAGE_SORT_FIELD_PAGE,
  payload,
});
/**
 * @param {string} payload
 */
export const setSearchTextAC = (payload) => ({
  type: SET_SEARCH_TEXT_PAGE,
  payload,
});

/**
 * @param {boolean} payload
 * @return {{type: string}}
 */
export const diskListPendingAC = (payload) => ({
  type: DISK_LIST_PENDING,
  payload,
});

export const resetImageSortAC = () => ({
  type: RESET_IMAGE_SORT,
});

export const getAllImagesAct = () => async (dispatch, getState) => {
  const dcList = getDataCenterList(getState());
  dispatch(diskListPendingAC(true));
  const promises = await Promise.allSettled(dcList.map((dc) => getImages(dc.api.url)));

  const payload = promises.reduce((acc, result, index) => {
    if (result.status !== "rejected") {
      result.value.data.forEach((image) => {
        acc.push({
          ...image,
          dcOwner: dcList[index],
        });
      });
    }

    return acc;
  }, []);

  dispatch({ type: SET_IMAGES, payload });
};

export const createImageRequestSuccessAC = (payload) => ({
  type: CREATE_IMAGE,
  payload,
});

/**
 * @param {boolean} payload
 * @return {{payload: boolean, type: string}}
 */
const createDiskPendingAC = (payload) => ({
  type: CREATE_DISK_PENDING,
  payload,
});

/**
 * @param {boolean} payload
 * @return {{payload: boolean, type: string}}
 */
const updateDiskPendingAC = (payload) => ({
  type: UPDATE_DISK_PENDING,
  payload,
});

/**
 * @param {object} dc - datacenter entity
 * @param {CreateImagePayload} payload
 */
export const createImageAct = (dc, payload) => async (dispatch) => {
  try {
    dispatch(createDiskPendingAC(true));
    const response = await createImage(dc.api.url, payload);
    const createdImage = {
      ...response.data,
      dcOwner: dc,
    };

    dispatch(createImageRequestSuccessAC(createdImage));
    dispatch(notificationAC(i18n.t("notification:diskCreationSuccess")));
    dispatch(createDiskPendingAC(false));
    return response.data;
  } catch (error) {
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:diskCreationFailed"),
        description: parseApiErrorMessage(error),
      })
    );
    dispatch(createDiskPendingAC(false));
    throw error;
  }
};

/**
 * @param {DataCenterApiModel} dc
 * @param {DiskStateModel} disk
 * @param {string} vmId
 * @return {function(*, *): Promise<void>}
 */
export const attachDiskToVm = (dc, disk, vmId) => async (dispatch, getState) => {
  try {
    const { vms } = vmSel(getState());
    const vm = vms.find((item) => item._id === vmId);

    if (vm) {
      /**
       * @type {VmUpdatePayload}
       */
      const vmEditPayload = {
        group: vm.group,
        name: vm.name,
        attributes: vm.attributes,
        hardware: {
          ...vm.hardware,
          disks: [...vm.hardware.disks, { image_id: disk.id }],
        },
      };

      await editVm(dc.api.url, vm._id, vmEditPayload);
      dispatch(notificationAC(i18n.t("notification:diskAttachedToVmSuccess")));
    } else {
      // eslint-disable-next-line
      console.error(`Virtual Machine id -"${vmId}" not found.`);
    }
  } catch (error) {
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:diskAttachingToVmFailed"),
        description: parseApiErrorMessage(error),
      })
    );
    throw error;
  }
};

/**
 * @param {DataCenterApiModel} dc
 * @param {object} payload
 * @param {string} vmId
 * @return {function(*, *): Promise<void>}
 */
export const createImageAndAttachToVm = (dc, payload, vmId) => async (dispatch) => {
  const createdDisk = await dispatch(createImageAct(dc, payload));
  await dispatch(attachDiskToVm(dc, createdDisk, vmId));
};

export const toggleImageModalAct = () => async (dispatch) =>
  dispatch({
    type: TOGGLE_IMAGE_MODAL_STATE,
  });

export const selectImageForEditAct = (img) => ({
  type: SET_IMAGE_FOR_EDIT,
  payload: img,
});

/**
 *
 * @param {object} dc - datacenter
 * @param {string} imageId - image id Mongo
 * @param {PatchImagePayload} payload
 */
export const updateImageAct = (dc, imageId, payload) => async (dispatch) => {
  try {
    dispatch(updateDiskPendingAC(true));
    const response = await updateImage(dc.api.url, imageId, payload);
    dispatch({
      type: UPDATE_IMAGE,
      payload: {
        ...response.data,
        dcOwner: dc,
      },
    });
    dispatch(updateDiskPendingAC(false));
    dispatch(notificationAC(i18n.t("notification:diskUpdatingSuccess")));
  } catch (error) {
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:diskUpdatingFailed"),
        description: parseApiErrorMessage(error),
      })
    );
    dispatch(updateDiskPendingAC(false));
    throw error;
  }
};

export const closeImageModalAct = () => async (dispatch) => dispatch({ type: CLOSE_IMAGE_MODAL });

/**
 * @param {string} dcUrl
 * @param {string} imageId
 */
export const deleteImageAct = (dcUrl, imageId) => async (dispatch) => {
  try {
    await deleteImage(dcUrl, imageId);
    dispatch({ type: DELETE_IMAGE, payload: imageId });
    dispatch(notificationAC(i18n.t("notification:diskDeletingSuccess")));
  } catch (error) {
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:diskDeletingFailed"),
        description: parseApiErrorMessage(error),
      })
    );
    throw error;
  }
};

export const setImageSizeAct = (payload) => (dispatch) => dispatch({ type: SET_MAX_IMAGE_SIZE, payload });

export const imageTierListRequestAC = () => ({
  type: IMAGE_TIER_LIST_REQUEST,
});

export const imageTierListRequestSuccessAC = (payload) => ({
  type: IMAGE_TIER_LIST_REQUEST_SUCCESS,
  payload,
});

export const fetchImageTiers = () => async (dispatch, getState) => {
  dispatch(imageTierListRequestAC());

  const dcList = getDataCenterList(getState());
  const promises = await Promise.allSettled(dcList.map((dc) => getImageTierList(dc.api.url)));

  const payload = promises.reduce((acc, result, index) => {
    if (result.status !== "rejected") {
      result.value.data.forEach((item) => {
        acc.push({
          ...item,
          dcOwner: dcList[index],
        });
      });
    }

    return acc;
  }, []);

  dispatch(imageTierListRequestSuccessAC(payload));
};

/**
 * @param {object} payload
 * @return {{payload: object, type: string}}
 */
export const setImageDcIncludeAC = (payload) => ({
  type: SET_IMAGE_DC_INCLUDE,
  payload,
});

/**
 * @param {object} dcIncludeMap
 */
export const setImageDcIncludeMap = (dcIncludeMap) => (dispatch, getState) => {
  const { id, email } = getUserProfile(getState()) || {};

  if (email) {
    storeDatacenterIncludeMap(dcIncludeMap, String(id));
  }

  dispatch(setImageDcIncludeAC(dcIncludeMap));
};

export const selectColumnAct = (payload) => ({
  type: TOGGLE_IMAGE_COLUMN,
  payload,
});

export const toggleDiskColumnView = (value) => (dispatch, getState) => {
  const { id, email } = getUserProfile(getState()) || {};
  const selectedCols = getSelectedColsSel(getState()) || {};
  const reselectedColumns = selectedCols.map((col) => {
    if (col.name === value) {
      return {
        ...col,
        selected: !col.selected,
      };
    }
    return col;
  });

  if (email) {
    storeDiskListColumnsMap(reselectedColumns, String(id));
  }

  dispatch(selectColumnAct(reselectedColumns));
};
