import React, { useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { makeChartOptions } from "./utils";

/**
 * @param {StoredMetric[]} data
 * @return {JSX.Element}
 * @constructor
 */
const StoredDataChart = ({ data = [] }) => {
  const chartOptions = useMemo(() => makeChartOptions({ data }), [data]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default StoredDataChart;
