const styles = (theme) => ({
  cbContainer: theme.classes.elems.cbContainer,
  cbCheckmark: {
    ...theme.classes.elems.cbCheckmark,
    transition: "0.2s",
  },
  checked: {
    transform: "translate(-50%, -50%) scale(1)",
  },
});

export default styles;
