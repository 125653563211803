import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "@skyportal/ui-kit";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { VNC_FEATURE } from "../../../constants/features";
import { refreshTimerOptions, VMS_REPORT_SECTION } from "pages/constants";
import {
  setVmFilterValueAct,
  vmPowerOn,
  vmPowerOff,
  setDcIncludeMap,
  setRefreshTimerValue,
} from "../../../store/actions/virtualMachinesActions";
import { getDcIncludeMap, vmSel } from "../../../store/selectors/vmSelectors";
import { getUserProfile } from "store/selectors/userSelectors";
import {
  hasUserAccessToFeature,
  CONTRACT_TYPES,
  FEATURES,
  USER_ACCESS,
  hasContractAccessToFeature,
} from "@skyportal/auth-web-client";
import Input from "../../../ui-kit/Input";
import Dropdown from "ui-kit/Dropdown";
import { isVmRunning } from "../../../utils/vm";
import VmRefreshButton from "../../../pages/VMs/VmRefreshButton";
import IconicDropdown from "../../../ui-kit/IconicDropdown";
import DatacenterFilter from "../../../components/DatacenterFilter";
import DeleteVmModal from "./VMsTableComponents/DeleteVmModal";
import ColumnSwitchCheckbox from "../../../components/ColumnSwitchCheckbox";
import ExportModal from "pages/Reports/modals/ExportModal";

import styles from "./styles";

const useStyles = makeStyles(styles);

const VMsHeadControls = () => {
  const { t } = useTranslation("vmPage");
  const classes = useStyles();
  const dispatch = useDispatch();
  const dcIncludeMap = useSelector(getDcIncludeMap);
  const { filterValue, vms, selectedCols, selectedVMs, refreshTimerValue } = useSelector(vmSel);
  const userProfile = useSelector(getUserProfile);
  const [isDeleteVmModalOpen, setIsDeleteVmModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const history = useHistory();
  /**
   * @type {VmStateModel}
   */
  const selectedVm = vms.find((item) => item._id === selectedVMs[0]);

  const searchVM = (value) => dispatch(setVmFilterValueAct(value));

  const handleCreateVm = () => history.push(`/infra/vm/create`);
  const handleEditVm = () => history.push(`/infra/vm/edit/${selectedVm._id}`);
  const handleVncBtnClick = () => history.push(`/infra/vm/vnc/${selectedVm._id}`);

  const toggleDeleteVmModal = () => setIsDeleteVmModalOpen(!isDeleteVmModalOpen);

  const handlePowerOnBtnClick = () => dispatch(vmPowerOn());
  const handlePowerOffBtnClick = () => dispatch(vmPowerOff({ isHard: false }));
  const handlePowerOffHardBtnClick = () => dispatch(vmPowerOff({ isHard: true }));

  const handleRefreshTimerChange = (event) => dispatch(setRefreshTimerValue(event.target.value));

  const handleExportBtnClick = () => setIsExportModalOpen(true);
  const handleExportModalClose = () => setIsExportModalOpen(false);

  const notOneVmSelected = selectedVMs.length !== 1;
  const isDeleteVmBtnDisabled =
    notOneVmSelected ||
    !hasContractAccessToFeature(userProfile, selectedVm.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_VIRTUAL_MACHINES, [
      USER_ACCESS.DELETE,
    ]);
  const isEditVmBtnDisabled =
    notOneVmSelected ||
    !hasContractAccessToFeature(userProfile, selectedVm.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_VIRTUAL_MACHINES, [
      USER_ACCESS.UPDATE,
    ]);
  const isVncBtnDisabled =
    notOneVmSelected ||
    !selectedVm ||
    !isVmRunning(selectedVm) ||
    !hasContractAccessToFeature(userProfile, selectedVm.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_VNC_CONSOLE, [
      USER_ACCESS.VIEW,
    ]);

  return (
    <>
      <div className={classes.vmsHeadControlsBar}>
        <div className={classes.vmsRightControls}>
          <Tooltip title={t("iaasCommon:Create")}>
            <div>
              <Button
                data-testid="create_control"
                leftIcon="plus"
                disabled={!hasUserAccessToFeature(userProfile, FEATURES.IAAS_VIRTUAL_MACHINES, [USER_ACCESS.CREATE])}
                className={classes.addVMs}
                onClick={handleCreateVm}
              ></Button>
            </div>
          </Tooltip>
          <Tooltip title={t("iaasCommon:Delete")}>
            <div>
              <Button
                data-testid="remove_control"
                leftIcon="trash-bin"
                disabled={isDeleteVmBtnDisabled}
                className={classes.deleteVMs}
                onClick={toggleDeleteVmModal}
              ></Button>
            </div>
          </Tooltip>
          <Tooltip title={t("iaasCommon:Edit")}>
            <div>
              <Button
                data-testid="edit_control"
                leftIcon="pen"
                disabled={isEditVmBtnDisabled}
                className={classes.editVMs}
                onClick={handleEditVm}
              ></Button>
            </div>
          </Tooltip>

          {VNC_FEATURE && (
            <Tooltip title={t("iaasCommon:OpenConsole")}>
              <div>
                <Button
                  data-testid="vnc_control"
                  disabled={isVncBtnDisabled}
                  onClick={handleVncBtnClick}
                  leftIcon="code"
                />
              </div>
            </Tooltip>
          )}
          <div>
            <IconicDropdown
              hideOnClick
              tooltipTitle={t("iaasCommon:PowerState")}
              mainIcon="idea"
              data-testid="power_control"
            >
              <div className={classes.vmActionList}>
                <div type="button" className={classes.vmActionBtn} onClick={handlePowerOnBtnClick}>
                  {t("iaasCommon:PowerOn")}
                </div>
                <div type="button" className={classes.vmActionBtn} onClick={handlePowerOffBtnClick}>
                  {t("iaasCommon:PowerOff")}
                </div>
                <div type="button" className={classes.vmActionBtn} onClick={handlePowerOffHardBtnClick}>
                  {t("iaasCommon:PowerOffHard")}
                </div>
              </div>
            </IconicDropdown>
          </div>
          <Tooltip title={t("iaasCommon:Refresh")}>
            <div>
              <VmRefreshButton />
            </div>
          </Tooltip>
          <Tooltip title={t("iaasCommon:SetRefreshFrequency")} position="right">
            <div>
              <Dropdown
                value={refreshTimerValue}
                onChange={handleRefreshTimerChange}
                options={refreshTimerOptions}
                className={classes.timerDropdown}
              />
            </div>
          </Tooltip>
        </div>
        <div className={classes.vmsLeftControls}>
          <div>
            <Input
              data-testid="VmSearchInput"
              value={filterValue}
              onChange={(e) => searchVM(e.target.value)}
              placeholder={t("searchPlaceholder")}
            />
          </div>
          <div className={classes.datacenterFilterWrap}>
            <DatacenterFilter
              onChangeAction={setDcIncludeMap}
              dcIncludeMap={dcIncludeMap}
              placeholder={t("datacenterFilter")}
            />
          </div>
          <div>
            <IconicDropdown mainIcon="list" data-testid="VMColumnSwitcher">
              <div className={classes.cbList}>
                {selectedCols.map((cb) => (
                  <ColumnSwitchCheckbox key={cb.name} column={cb} variant="vmsList" />
                ))}
              </div>
            </IconicDropdown>
          </div>
          <div>
            <Button onClick={handleExportBtnClick}>{t("iaasCommon:Export")}</Button>
          </div>
        </div>
      </div>
      <DeleteVmModal isModalOpen={isDeleteVmModalOpen} onClose={toggleDeleteVmModal} userProfile={userProfile} />
      <ExportModal section={VMS_REPORT_SECTION} onClose={handleExportModalClose} open={isExportModalOpen} />
    </>
  );
};
export default VMsHeadControls;
