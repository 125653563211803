const btn = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "40px",
  borderRadius: "2px",
  boxSizing: "border-box",
  padding: "12px 40px 14px",
  border: "none",
  outline: "none",
  outlineOffset: "none",
  "&:hover": {
    cursor: "pointer",
    opacity: "0.75",
  },
};

const control = {
  lineHeight: "16px",
  padding: "4px 8px",
};

const styles = (theme) => ({
  btn: {
    ...btn,
  },
  confirm: {
    ...btn,
    backgroundColor: theme.colors.grassGreen,
    color: theme.colors.darkGreen,
    padding: "7px 24px",
    height: "32px",
  },
  edit: {
    ...btn,
    ...control,
    fontSize: theme.fonts.small,
    backgroundColor: theme.colors.skyBlue,
    color: theme.colors.navyBlue,
    width: "70px",
    height: "24px",
    display: "flex",
    justifyContent: "space-between",
    fill: theme.colors.navyBlue,
  },
  danger: {
    ...btn,
    ...control,
    width: "54px",
    height: "24px",
    fontSize: theme.fonts.small,
    backgroundColor: theme.colors.lightRed,
    color: theme.colors.ruby,
  },
  controls: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "8px 10px",
    width: "25%",
    height: "32px",
    paddingRight: "17px",
  },
  newRegionCell: {
    width: "100%",
  },
  codeCell: {
    padding: "8px 10px",
    width: "25%",
  },
  nameCell: {
    padding: "8px 10px",
    width: "25%",
  },
  saveBtn: {
    marginLeft: "8px",
  },
  newRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "32px",
    padding: "8px 0",
    fontSize: "14px",
    borderBottom: `1px solid #f4f4f4`,
  },
});
export default styles;
