import { sortString } from "./sort";
import { filterListByDcId } from "./groups";
/**
 * @typedef {object} DatacenterOptionItem
 * @property {string} value - id
 * @property {string} label - name
 */
/**
 * @param {DataCenterApiModel[]} list
 * @return {DatacenterOptionItem[]}
 */
export const composeDatacenterOptions = (list) =>
  list
    .map((item) => ({
      label: item.name,
      value: item.id,
    }))
    .sort((a, b) => sortString(a.label, b.label));
/**
 * @param {DatacenterOptionItem[]} list
 * @return {object}
 */
export const makeDcIdMapFromOptions = (list) =>
  Object.values(list).reduce((acc, option) => {
    acc[option.value] = true;
    return acc;
  }, {});

export const mapApiToStateModel = ({ _id, ...rest }) => ({ id: _id, ...rest });

export const getDatacentersWithGroups = (datacenters, groups) =>
  datacenters.filter((dc) => filterListByDcId(groups, dc.id).length);
