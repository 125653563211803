import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import { Icon, Spinner } from "@skyportal/ui-kit";
import useAllMetricsRequestPending from "../../../../hooks/useAllMetricsRequestPending";
import ActionHistoryTable from "./ActionHistoryTable";
import { EVENT_HISTORY_PAGE } from "../../../../constants/features";

import styles from "../../../../pages/Dashboard/styles";

const useStyles = makeStyles(styles);

const ActionHistoryCard = () => {
  const { t } = useTranslation("dashboardHistory");
  const classes = useStyles();

  return (
    <Card className={classes.smallCard}>
      <Spinner show={useAllMetricsRequestPending()} />
      <CardHeader
        title={t("title")}
        className={classes.basicHeader}
        classes={{
          title: classes.headerTitle,
          subheader: classes.subHeader,
        }}
        action={
          EVENT_HISTORY_PAGE ? (
            <Link to="/infra/event-history" className={classes.headerLink}>
              <Icon icon="time-ongoing" height={16} /> {t("allEventPageLink")}
            </Link>
          ) : undefined
        }
      />

      <CardContent classes={{ root: classes.cardContentPadding }}>
        <ActionHistoryTable />
      </CardContent>
    </Card>
  );
};

export default ActionHistoryCard;
