import { SET_GROUPS } from "../types";
import { getGroupList } from "../../api/groupsService";
import { getDataCenterList } from "../selectors/dcSelectors";

export const getAllGroupsAct = () => async (dispatch, getState) => {
  const dcList = getDataCenterList(getState());
  const promises = await Promise.allSettled(dcList.map((dc) => getGroupList(dc.api.url)));

  const payload = promises.reduce((acc, result, index) => {
    if (result.status !== "rejected") {
      result.value.data.forEach((group) => {
        acc.push({
          ...group,
          dcOwner: dcList[index],
        });
      });
    }

    return acc;
  }, []);

  dispatch({
    type: SET_GROUPS,
    payload,
  });
};
