import axios from "axios";
import store from "../store";
import { checkUserAuthState, setUserIsLoggedInAc } from "../store/actions/authActions";
import { notificationErrorAC } from "../store/actions/notificationActions";
import { isUnauthorizedError, parseApiErrorMessage } from "./utils";
import * as redirects from "../utils/redirect";

export const directoryAuthCheckInterceptor = (error) => {
  if (isUnauthorizedError(error)) {
    store.dispatch(setUserIsLoggedInAc(false));
    redirects.toAuthApp();
  }

  return Promise.reject(error);
};

export const authCheckInterceptor = (error) => {
  if (isUnauthorizedError(error)) {
    store.dispatch(checkUserAuthState());
  }

  return Promise.reject(error);
};

export const failedResponseNotificationInterceptor = (error) => {
  const apiErrorMessage = parseApiErrorMessage(error);

  if (apiErrorMessage) {
    store.dispatch(notificationErrorAC({ message: apiErrorMessage }));
  }

  return Promise.reject(error);
};

const cancelTokenMap = new Map();

/**
 * @param {AxiosRequestConfig} config
 */
export const cancelPrevDuplicatedRequestInterceptor = (config) => {
  if (config.method.toLowerCase() === "get") {
    const key = `${config.baseURL}${config.url}`;

    cancelTokenMap.get(key)?.cancel();

    const cancelToken = axios.CancelToken.source();
    // eslint-disable-next-line no-param-reassign
    config.cancelToken = cancelToken.token;
    cancelTokenMap.set(key, cancelToken);
  }

  return Promise.resolve(config);
};
