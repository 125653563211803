import { Buffer } from "buffer";
import { attributeParser } from "pages/VMs/VMs_create_edit/constantsNHelpers";
import { DEFAULT_CONFIRMATION_TEXT } from "../../../../constants/texts";
import { DELETE_SNAPSHOT, REVERT_SNAPSHOT } from "./constants";

export const getRevertModalTexts = (modal, t) => {
  const confirmWord =
    {
      [REVERT_SNAPSHOT]: t("snapshotRevertModal.confirmWord"),
      [DELETE_SNAPSHOT]: t("snapshotDeleteModal.confirmWord"),
    }[modal] || DEFAULT_CONFIRMATION_TEXT;

  return {
    title:
      {
        [REVERT_SNAPSHOT]: t("snapshotRevertModal.title"),
        [DELETE_SNAPSHOT]: t("snapshotDeleteModal.title"),
      }[modal] || "",
    confirmQuestion:
      {
        [REVERT_SNAPSHOT]: t("snapshotRevertModal.confirmQuestion", { text: confirmWord }),
        [DELETE_SNAPSHOT]: t("snapshotDeleteModal.confirmQuestion", { text: confirmWord }),
      }[modal] || "",

    placeholder:
      {
        [REVERT_SNAPSHOT]: t("snapshotRevertModal.placeholder", { text: confirmWord }),
        [DELETE_SNAPSHOT]: t("snapshotDeleteModal.placeholder", { text: confirmWord }),
      }[modal] || "",
    confirmWord,
  };
};

export const formatVmAttributesValues = (type, value) => {
  if (type === "boolean") {
    return value === "true" ? "Yes" : "No";
  } else if (type === "password") {
    return "•".repeat(value.length);
  } else if (type === "list-multiple") {
    return value.replace(",", ", ");
  } else if (type === "text64") {
    return Buffer.from(value, "base64").toString();
  }

  return value;
};

export const getVmAttributesList = (vm) => {
  const parsedAttributes = {};
  for (let attribute in vm.attributeInputs) {
    if (vm.attributeInputs[attribute]) {
      parsedAttributes[attribute] = attributeParser(vm.attributeInputs[attribute]);
    }
  }

  const order = vm?.attributeOrder?.length
    ? vm.attributeOrder
        .replace(" ", "")
        .split(",")
        .filter((attributeName) => parsedAttributes[attributeName])
    : [];

  const list = order.map((attribute) => ({
    name: parsedAttributes[attribute]?.title,
    value: formatVmAttributesValues(parsedAttributes[attribute]?.type, vm.attributeValues[attribute]),
  }));

  return list;
};
