import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Button } from "@skyportal/ui-kit";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../../../ui-kit/Input";
import Modal from "@material-ui/core/Modal";
import { getSnapshotColsSel } from "../../../../store/selectors/vmSelectors";
import {
  creteSnapshotRequestAct,
  deleteSnapshotRequestAct,
  rollbackSnapshotRequestAct,
} from "../../../../store/actions/virtualMachinesActions";
import SnapshotRow from "../../../../pages/VMs/VMsContent/VMsTableComponents/SnapshotRow";
import { MAX_SNAPSHOT_COUNT } from "../../../../constants/vm";

import styles, { snapshotPanelStyles } from "./styles";

const useStyles = makeStyles(snapshotPanelStyles);
const useModalStylesClasses = makeStyles(styles);

const SnapshotPanel = ({ snapshots, vmId, dcUrl }) => {
  const { t } = useTranslation("vmPage");
  const hasSnapShots = snapshots.length > 0;
  const hasMaxSnapShotsCount = snapshots.length >= MAX_SNAPSHOT_COUNT;
  const snapshotCols = useSelector(getSnapshotColsSel);
  const classes = useStyles();
  const modalStyles = useModalStylesClasses();
  const dispatch = useDispatch();
  const [isSnapshotModalOpen, toggleSnapshotModal] = useState(false);
  const [newSnapShotName, setNewSnapShotName] = useState("");

  const handleRollback = (snapshotId) => dispatch(rollbackSnapshotRequestAct({ snapshotId, vmId, dcUrl }));

  const handleDeleteSnapshot = (snapshotId) => dispatch(deleteSnapshotRequestAct({ snapshotId, vmId, dcUrl }));

  const toggleSnapshotNameModal = (e) => {
    e.stopPropagation();
    toggleSnapshotModal(!isSnapshotModalOpen);
  };

  const cancelSnapshotCreation = () => {
    toggleSnapshotModal(false);
  };

  const handleCreateSnapshot = async () => {
    await dispatch(creteSnapshotRequestAct({ dcUrl, vmId, name: newSnapShotName }));
    toggleSnapshotModal(false);
  };

  useEffect(() => {
    if (!isSnapshotModalOpen && newSnapShotName) {
      setNewSnapShotName("");
    }
  }, [isSnapshotModalOpen, newSnapShotName]);

  const onSnapshotNameChange = (event) => setNewSnapShotName(event.target.value);

  const setSnapshotNameModalBody = (
    <div>
      <div className={modalStyles.snapshotNameModal}>
        <div className={modalStyles.snapshotNameModalCard}>
          <div className={modalStyles.snapshotNameModalHeader}>{t("snapshot.createModal.title")}</div>
          <div className={modalStyles.snapshotNameModalInputShell}>
            <Input
              className={modalStyles.snapShotInput}
              placeholder={t("snapshot.namePlaceholder")}
              value={newSnapShotName}
              onChange={onSnapshotNameChange}
            />
          </div>
          <div className={modalStyles.snapshotNameModalFooter}>
            <Button onClick={handleCreateSnapshot}>{t("snapshot.createModal.saveBtn")}</Button>
            <Button type="secondary" onClick={cancelSnapshotCreation}>
              {t("iaasCommon:Cancel")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Grid
        container
        classes={{
          root: classes.snapshotContainer,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Grid container item justifyContent="flex-end" alignItems="flex-end" className={classes.panelHeaderContainer}>
          <Typography variant="h6" className={classes.panelHeaderTitle}>
            {hasSnapShots && t("snapshot.title")}
          </Typography>
          <div className={classes.snapshotBtnWrapper}>
            <Button disabled={hasMaxSnapShotsCount} onClick={toggleSnapshotNameModal}>
              {t("snapshot.createBtn")}
            </Button>
          </div>
        </Grid>
        {hasSnapShots && (
          <TableContainer
            classes={{
              root: classes.tableContainer,
            }}
          >
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  {snapshotCols.map((col) => (
                    <TableCell
                      key={col.key}
                      classes={{
                        root: classes.tableHeaderCell,
                        stickyHeader: classes.stickyHeader,
                      }}
                    >
                      <span className={classes.tableHeaderText}>{col.label && t(`iaasCommon:${col.label}`)}</span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {snapshots.map((snapshot) => (
                  <SnapshotRow
                    key={snapshot.SNAPSHOT_ID}
                    columnList={snapshotCols}
                    snapshot={snapshot}
                    onRollback={handleRollback}
                    onDelete={handleDeleteSnapshot}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Modal open={isSnapshotModalOpen} onClose={toggleSnapshotNameModal} onClick={(e) => e.stopPropagation()}>
        {setSnapshotNameModalBody}
      </Modal>
    </>
  );
};

export default SnapshotPanel;
