import * as React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

const PageContent = ({ children, className }) => {
  const classes = useStyles();

  return <div className={cn(classes.page, className)}>{children}</div>;
};

export default PageContent;
