import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import ImageSorterCell from "./ImageSorterCell";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {string} cellClassName
 * @param {ColumnConfigItem[]} selectedCols
 * @return {JSX.Element}
 * @constructor
 */
const ImageSorterBar = ({ cellClassName, selectedCols }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.sorterBar} data-testid="ImageSorterBar">
      {selectedCols
        .filter((col) => col.selected)
        .map(({ name, label }) => (
          <ImageSorterCell key={name} className={cellClassName} field={name} label={t(`iaasCommon:${label}`)} />
        ))}
      <div className={classnames(classes.cell, classes.sorterCell, cellClassName)} />
    </div>
  );
};

export default ImageSorterBar;
