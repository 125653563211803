import { PAGINATION_STEPS } from "constants/pagination";
import { initialImagesState } from "store/reducers/Images/imgReducer";
import {
  DATACENTER_DISK_PAGE_INCLUDE_MAP,
  DISKS_PER_PAGE,
  DISK_LIST_COLUMNS_MAP,
  getStoredObj,
  setObjectToStore,
} from "./storage";
import { validateStoredColumnsConfig } from "./vm";

/**
 * @param {DiskStateModel} disk
 * @param {VmStateModel[]} vms
 * @return {VmStateModel[]}
 */
export const getVmsFromDisk = (disk, vms) => {
  const attachedVmIds = disk.vms.map((vm) => Number(vm.ID));

  return vms.filter((vm) => vm.dcOwner.id === disk.dcOwner.id).filter((vm) => attachedVmIds.includes(vm.id));
};

/**
 * @param {DiskStateModel} disk
 * @return {{ID: string}[]}
 */
export const getDiskVmList = (disk) => (Array.isArray(disk.vms) ? disk.vms : []);

/**
 * @param {DiskStateModel} disk
 * @return {boolean}
 */
export const isDiskAttachedToSomeVM = (disk) => getDiskVmList(disk).length > 0;

export const composeTierOption = (list) =>
  list
    .filter((item) => item.name !== null)
    .map((item) => ({
      value: item.name,
      label: item.name,
    }));

export const storeDatacenterIncludeMap = (map, prefix = "") =>
  setObjectToStore(DATACENTER_DISK_PAGE_INCLUDE_MAP, map, prefix);
export const getDatacenterIncludeMapFromStorage = (prefix = "") =>
  getStoredObj(DATACENTER_DISK_PAGE_INCLUDE_MAP, prefix) || {};

export const storeDisksPerPageValue = (map, prefix = "") => setObjectToStore(DISKS_PER_PAGE, map, prefix);
export const getDisksPerPageFromStorage = (prefix = "") => getStoredObj(DISKS_PER_PAGE, prefix) || PAGINATION_STEPS[0];

export const storeDiskListColumnsMap = (map, prefix = "") => setObjectToStore(DISK_LIST_COLUMNS_MAP, map, prefix);
export const getDiskListColumnsFromStorage = (prefix = "") => {
  const storedColumnConfig = getStoredObj(DISK_LIST_COLUMNS_MAP, prefix);

  return validateStoredColumnsConfig(initialImagesState.selectedCols, storedColumnConfig)
    ? storedColumnConfig
    : initialImagesState.selectedCols;
};
