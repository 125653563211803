const styles = (theme) => ({
  pageBox: {
    ...theme.classes.pageBox,
    padding: "16px",
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto",
  },

  table: {
    minHeight: "100px",
    boxSizing: "border-box",

    "@media(max-width: 767px)": {
      width: "767px",
    },
  },
  tableHeadControls: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    margin: "-8px",

    "@media(max-width: 767px)": {
      "&> *": {
        minWidth: "250px",
        flexGrow: 1,
      },
    },

    "&> *": {
      padding: "8px",
    },
  },
  tableSearchGroup: {
    width: "300px",

    "& > input": {
      width: "100%",
    },
  },

  btnContainer: {
    "& > button": {
      width: "100%",
    },
  },
  tableSearchLabel: theme.classes.table.tableSearchLabel,
  headerRowCell: {
    marginBottom: "16px",
  },
  sorterCell: {
    ...theme.classes.table.sorterCell,
    width: "20%",
    flexShrink: 0,
    boxSizing: "border-box",
  },
  sorterCellIcon: {
    ...theme.classes.table.sorterCellIcon,
    color: theme.colors.inactiveGrey,
  },
  sorterCellText: {
    ...theme.classes.table.sorterCellText,
    fontSize: theme.fonts.smaller,
  },
  pagingContainer: theme.classes.table.pagingContainer,
  cell: {
    ...theme.classes.table.cell,
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
  },
  smallerCell: {
    width: "15%",
  },
  newRegionBar: {
    borderBottom: `3px solid ${theme.colors.lightGrey}`,
  },
  columnRow: {
    display: "flex",
    margin: "0 16px",
  },
  lastEmptyCell: {
    minWidth: "30%",
    borderBottom: `3px solid ${theme.colors.lightGrey}`,
  },
});

export default styles;
