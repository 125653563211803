const style = (theme) => ({
  page: theme.classes.page,
  pageBox: {
    ...theme.classes.pageBox,
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    position: "relative",
  },
  controlContainer: {
    marginBottom: "15px",
  },
  statusBox: {
    flexGrow: "1",
    color: theme.colors.black,
    fontSize: theme.fonts.bigger,
  },
  btn: {
    textTransform: "none",
  },
  iconBtn: {
    height: "33px",
  },
  formContainer: {
    ...theme.classes.pageBox,
    position: "relative",
    padding: "32px 96px",
    marginBottom: "15px",

    "@media(max-width: 767px)": {
      padding: "16px",
    },
  },
  vncUiRoot: {
    width: "100%",
    minHeight: "500px",
    backgroundColor: theme.colors.black,
  },
});

export default style;
