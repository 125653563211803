export const MONTHLY_TYPE = "monthly";
export const DAILY_TYPE = "daily";
export const YEARLY_TYPE = "yearly";

export const FILTER_TYPES = [DAILY_TYPE, MONTHLY_TYPE, YEARLY_TYPE];

export const TYPE_LABELS = {
  [MONTHLY_TYPE]: "Monthly",
  [DAILY_TYPE]: "Daily",
  [YEARLY_TYPE]: "Yearly",
};
