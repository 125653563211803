import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Icon } from "@skyportal/ui-kit";
import { useSelector } from "react-redux";
import { getUserProfile } from "store/selectors/userSelectors";
import { CONTRACT_TYPES, FEATURES, hasContractAccessToFeature, USER_ACCESS } from "@skyportal/auth-web-client/dist";
import { useTranslation } from "react-i18next";
import { formatSize } from "utils/size";
import { getVmAttributesList } from "./utils";

import styles from "./styles";

const useStyles = makeStyles(styles);

const VmDetailsPopup = ({ vm, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation("vmPage");

  const userProfile = useSelector(getUserProfile);
  const attributesList = getVmAttributesList(vm);

  const isEditVmBtnDisabled = !hasContractAccessToFeature(
    userProfile,
    vm.agreement,
    CONTRACT_TYPES.IA,
    FEATURES.IAAS_VIRTUAL_MACHINES,
    [USER_ACCESS.UPDATE]
  );
  const handleEditVm = () => history.push(`/infra/vm/edit/${vm._id}`);

  return (
    <>
      <span onClick={onClose}>
        <Icon icon="close" className={classes.crossIcon} />
      </span>
      <div className={classes.vmDetailsTitle}>{vm.name}</div>

      <div className={classes.vmDetailsRow}>
        {t("iaasCommon:Datacenter")}: {vm.dcOwner.name || " "}
      </div>
      <div className={classes.vmDetailsRow}>
        {t("iaasCommon:Group")}: {vm.__groupName || " "}
      </div>
      <div className={classes.vmDetailsRow}>
        {t("iaasCommon:Cpu")}: {vm.hardware.vcpu}
      </div>
      <div className={classes.vmDetailsRow}>
        {t("iaasCommon:Memory")}: {formatSize(vm.hardware.memory)}
      </div>

      {attributesList.map((attribute) => (
        <div className={classes.vmDetailsRow}>
          {attribute.name}: {attribute.value}
        </div>
      ))}

      {vm.hardware.images_info.length ? (
        <div className={classes.vmDetailsRow}>
          {t("iaasCommon:Disk", { count: vm.hardware.images_info.length })}:{" "}
          {vm.hardware.images_info.length === 1
            ? [
                vm.hardware.images_info[0].name,
                vm.hardware.images_info[0].tier,
                formatSize(vm.hardware.images_info[0].size),
              ]
                .filter(Boolean)
                .join(" / ")
            : vm.hardware.images_info.map((image) => image.name).join(", ")}
        </div>
      ) : null}

      {vm.__networkNameList.length ? (
        <div className={classes.vmDetailsRow}>
          {t("iaasCommon:Network", { count: vm.__networkNameList.length })}: {vm.__networkNameList.join(", ")}
        </div>
      ) : null}

      <div className={classes.vmDetailsFooter}>
        <Button
          data-testid="edit_control"
          type="tertiary"
          leftIcon="pen"
          disabled={isEditVmBtnDisabled}
          onClick={handleEditVm}
        />
      </div>
    </>
  );
};

export default VmDetailsPopup;
