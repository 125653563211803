import { REQUEST_FAILED, REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_UNCALLED } from "../../../constants/request";
import { PAGINATION_STEPS } from "../../../constants/pagination";
import {
  CREATE_DATACENTER_REQUEST,
  CREATE_DATACENTER_REQUEST_FAILED,
  CREATE_DATACENTER_REQUEST_SUCCESS,
  DELETE_DATACENTER_REQUEST,
  DELETE_DATACENTER_REQUEST_FAILED,
  DELETE_DATACENTER_REQUEST_SUCCESS,
  NEW_DC_TOGGLE_MODAL,
  SET_DATACENTER_CURRENT_PAGE,
  SET_DATACENTER_PER_PAGE,
  SET_DATACENTER_SEARCH_TEXT,
  SET_DATACENTER_SORT_FIELD,
  GET_DATACENTERS_REQUEST,
  GET_ENABLED_DATACENTERS_REQUEST_SUCCESS,
  GET_ALL_DATACENTERS_REQUEST_SUCCESS,
  GET_DATACENTERS_REQUEST_FAILURE,
  GET_DATACENTER_SETTINGS_REQUEST,
  GET_DATACENTER_SETTINGS_REQUEST_SUCCESS,
  GET_DATACENTER_SETTINGS_REQUEST_FAILURE,
  SET_DATACENTER_SETTINGS_REQUEST,
  SET_DATACENTER_SETTINGS_REQUEST_SUCCESS,
  SET_DATACENTER_SETTINGS_REQUEST_FAILURE,
  UPDATE_DATACENTER_REQUEST,
  UPDATE_DATACENTER_REQUEST_SUCCESS,
  UPDATE_DATACENTER_REQUEST_FAILED,
} from "../../types";

/**
 * @typedef {""|"name"|"description"|"region"|"api.url"} DatacenterSortField
 */

/**
 * @typedef {object} DataCenterState
 * @property {DataCenterApiModel[]} datacenters
 * @property {DataCenterApiModel[]} allDatacenters
 * @property {RequestStatus} datacentersRequest
 * @property {boolean} isNewDCModalOpen
 * @property {RequestStatus} createRequest
 * @property {RequestStatus} deleteRequest
 * @property {number} currentPage - Datacenter page pagination number
 * @property {string} searchText - search input value
 * @property {SortOrderEnum} sortOrder
 * @property {DatacenterSortField} sortField
 * @property {PaginationStep} perPage
 */

/**
 * @type {DataCenterState}
 */
const initialDatacenterState = {
  datacenters: [],
  allDatacenters: [],
  datacentersRequest: REQUEST_UNCALLED,
  createRequest: REQUEST_UNCALLED,
  updateRequest: REQUEST_UNCALLED,
  deleteRequest: REQUEST_UNCALLED,
  updateSettingsRequest: REQUEST_UNCALLED,
  isNewDCModalOpen: false,
  currentPage: 1,
  searchText: "",
  sortOrder: "asc",
  sortField: "",
  perPage: PAGINATION_STEPS[0],
};

const datacenterReducer = (state = initialDatacenterState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DATACENTERS_REQUEST: {
      return {
        ...state,
        datacentersRequest: REQUEST_PENDING,
      };
    }
    case GET_ENABLED_DATACENTERS_REQUEST_SUCCESS: {
      return {
        ...state,
        datacenters: payload,
        datacentersRequest: REQUEST_SUCCESS,
      };
    }
    case GET_ALL_DATACENTERS_REQUEST_SUCCESS: {
      const datacentersWithSettings = payload.map((dc) => ({
        ...dc,
        settings: {
          requestStatus: REQUEST_UNCALLED,
        },
      }));

      return {
        ...state,
        allDatacenters: datacentersWithSettings,
        datacentersRequest: REQUEST_SUCCESS,
      };
    }
    case GET_DATACENTERS_REQUEST_FAILURE: {
      return {
        ...state,
        datacentersRequest: REQUEST_FAILED,
      };
    }
    case CREATE_DATACENTER_REQUEST: {
      return {
        ...state,
        createRequest: REQUEST_PENDING,
      };
    }
    case CREATE_DATACENTER_REQUEST_SUCCESS: {
      return {
        ...state,
        allDatacenters: [
          {
            ...payload,
            settings: {
              requestStatus: REQUEST_UNCALLED,
            },
          },
          ...state.allDatacenters,
        ],
        datacenters: [payload, ...state.datacenters],
        createRequest: REQUEST_SUCCESS,
      };
    }
    case CREATE_DATACENTER_REQUEST_FAILED: {
      return {
        ...state,
        createRequest: REQUEST_FAILED,
      };
    }

    case UPDATE_DATACENTER_REQUEST: {
      return {
        ...state,
        updateRequest: REQUEST_PENDING,
      };
    }
    case UPDATE_DATACENTER_REQUEST_SUCCESS: {
      return {
        ...state,
        updateRequest: REQUEST_SUCCESS,
      };
    }
    case UPDATE_DATACENTER_REQUEST_FAILED: {
      return {
        ...state,
        updateRequest: REQUEST_FAILED,
      };
    }

    case DELETE_DATACENTER_REQUEST: {
      return {
        ...state,
        deleteRequest: REQUEST_PENDING,
      };
    }
    case DELETE_DATACENTER_REQUEST_FAILED: {
      return {
        ...state,
        deleteRequest: REQUEST_FAILED,
      };
    }
    case DELETE_DATACENTER_REQUEST_SUCCESS: {
      const updatedAllDcList = state.allDatacenters.filter((dc) => dc.id !== payload.id);
      const updatedDcList = state.datacenters.filter((dc) => dc.id !== payload.id);

      return {
        ...state,
        allDatacenters: updatedAllDcList,
        datacenters: updatedDcList,
        deleteRequest: REQUEST_SUCCESS,
      };
    }
    case NEW_DC_TOGGLE_MODAL: {
      return {
        ...state,
        isNewDCModalOpen: !state.isNewDCModalOpen,
      };
    }
    case SET_DATACENTER_SORT_FIELD: {
      return {
        ...state,
        sortField: payload,
        sortOrder: state.sortField === payload && state.sortOrder === "desc" ? "asc" : "desc",
      };
    }
    case SET_DATACENTER_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload,
      };
    }
    case SET_DATACENTER_SEARCH_TEXT: {
      return {
        ...state,
        searchText: payload,
        currentPage: 1,
      };
    }
    case SET_DATACENTER_PER_PAGE: {
      return {
        ...state,
        perPage: payload,
        currentPage: 1,
      };
    }
    case GET_DATACENTER_SETTINGS_REQUEST: {
      return {
        ...state,
        allDatacenters: state.allDatacenters.map((dc) => {
          if (dc.id === payload) {
            dc.settings.requestStatus = REQUEST_PENDING;
          }
          return dc;
        }),
      };
    }
    case GET_DATACENTER_SETTINGS_REQUEST_SUCCESS: {
      return {
        ...state,
        allDatacenters: state.allDatacenters.map((dc) => {
          if (dc.id === payload.id) {
            const { id, ...rest } = payload;
            dc.settings = rest;
            dc.settings.requestStatus = REQUEST_SUCCESS;
          }
          return dc;
        }),
      };
    }
    case GET_DATACENTER_SETTINGS_REQUEST_FAILURE: {
      return {
        ...state,
        allDatacenters: state.allDatacenters.map((dc) => {
          if (dc.id === payload) {
            dc.settings.requestStatus = REQUEST_FAILED;
          }
          return dc;
        }),
      };
    }
    case SET_DATACENTER_SETTINGS_REQUEST: {
      return {
        ...state,
        updateSettingsRequest: REQUEST_PENDING,
      };
    }
    case SET_DATACENTER_SETTINGS_REQUEST_SUCCESS: {
      return {
        ...state,
        updateSettingsRequest: REQUEST_SUCCESS,
      };
    }
    case SET_DATACENTER_SETTINGS_REQUEST_FAILURE: {
      return {
        ...state,
        updateSettingsRequest: REQUEST_FAILED,
      };
    }
    default:
      return { ...state };
  }
};

export default datacenterReducer;
