import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Checkbox from "../../ui-kit/Checkbox";
import { toggleVmColumnView } from "../../store/actions/virtualMachinesActions";
import { toggleDiskColumnView } from "../../store/actions/imagesActions";

import styles from "./styles";

const useStyles = makeStyles(styles);

const ColumnSwitchCheckbox = ({ column, variant }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isChecked, setCheck] = useState(column.selected);

  const onCheckboxClick = useCallback(() => {
    setCheck(!isChecked);

    if (variant === "vmsList") {
      dispatch(toggleVmColumnView(column.name));
    } else if (variant === "disksList") {
      dispatch(toggleDiskColumnView(column.name));
    }
  }, [column]);

  return (
    <div className={classes.cbOption} onClick={(e) => e.stopPropagation()}>
      <Checkbox isChecked={isChecked} checkHandler={onCheckboxClick} />
      <span className={classes.cbText}>{t(`iaasCommon:${column.label}`)}</span>
    </div>
  );
};

export default ColumnSwitchCheckbox;
