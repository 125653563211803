import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SortableTable from "../../../../ui-kit/SortableTable/SortableTable";
import { keyGen } from "../../../../utils/eventHistory";
import { getActionHistoryTable, getEventTable } from "../../../../store/selectors/dashboardSelectors";
import { setDashboardEventTableSortFieldAC } from "../../../../store/actions/dashboardActions";
import useEventHistoryColumnConfig from "../../../../hooks/useEventHistoryColumnConfig";

const ActionHistoryTable = () => {
  const dispatch = useDispatch();
  const { sortField, sortOrder } = useSelector(getEventTable);
  const { list } = useSelector(getActionHistoryTable);
  const columnsConfig = useEventHistoryColumnConfig();
  const handleSortFieldChange = useCallback(
    (sortFiled) => dispatch(setDashboardEventTableSortFieldAC(sortFiled || "")),
    []
  );

  return (
    <SortableTable
      testId="EventHistory_table"
      data={list}
      onSortFieldChange={handleSortFieldChange}
      columnsConfig={columnsConfig}
      sortField={sortField}
      sortOrder={sortOrder}
      scrollAreaHeight="300px"
      keyGen={keyGen}
    />
  );
};

export default ActionHistoryTable;
