import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const AbsolutCenteredBox = ({ children }) => {
  return (
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Box>
  );
};

const CircleLabel = ({ labelProps, ...props }) => {
  return (
    <Box position="relative" display="inline-flex">
      <AbsolutCenteredBox>
        <CircularProgress variant="determinate" {...props} value={100} style={{ opacity: "0.1" }} />
      </AbsolutCenteredBox>

      <CircularProgress variant="determinate" {...props} />
      <AbsolutCenteredBox>
        <span {...labelProps}>{props.value}%</span>
      </AbsolutCenteredBox>
    </Box>
  );
};

export default CircleLabel;
