export const DISKS_PER_PAGE = 9;
export const DISKS_MODAL_PER_PAGE = 5;
export const REGIONS_PER_PAGE = 5;

/**
 * @typedef {number} PaginationStep - 10|20|50|100
 */

/**
 * @type {number[]}
 */
export const PAGINATION_STEPS = [10, 20, 50, 100];
