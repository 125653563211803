import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "@skyportal/ui-kit";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {boolean} open
 * @param {function} onClose
 * @param {function} onCancel
 * @param {function} onConfirmed
 * @param {string} title
 * @param {string} hint
 * @param {boolean} [isRequesting]
 * @param {boolean} [isDisabled]
 * @param {React.ReactNode} [children]
 * @return {JSX.Element}
 * @constructor
 */
const ConfirmationModal = ({
  open,
  onClose,
  onCancel,
  onConfirmed,
  title,
  hint,
  isDisabled = false,
  children = null,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      data-testid="ConfirmationModal"
      open={open}
      onClose={onClose}
      title={title}
      onClick={(e) => e.stopPropagation()}
      controls={
        <>
          <Button disabled={isDisabled} onClick={onConfirmed}>
            {t("iaasCommon:Yes")}
          </Button>
          <Button type="secondary" onClick={onCancel}>
            {t("iaasCommon:No")}
          </Button>
        </>
      }
    >
      <div className={classes.modalCard}>
        <div className={classes.text}>{hint}</div>
        <div className={classes.contentContainer}>{children}</div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
