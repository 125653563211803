import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import { formStyles } from "../styles";

const useStyles = makeStyles(formStyles);

const disksMode = "disks";
const vmsMode = "VMs";

/**
 * @param {boolean} [disabled]
 * @param {string} [placeholder]
 * @param {string} value
 * @param {string} prefixValue
 * @param {boolean} hasVmNameMatchingPrefix
 * @param {string} mode
 * @param {function(value: string): void} onChange
 * @return {JSX.Element}
 * @constructor
 */
export const NameControl = ({
  value,
  onChange,
  disabled = false,
  placeholder,
  prefixValue,
  hasNameMatchingPrefix = true,
  mode,
}) => {
  const classes = useStyles();
  const { t: tVms } = useTranslation("vmPage");
  const { t: tImages } = useTranslation("imagesPage");

  const warnings = {
    [disksMode]: tImages("editOrCreateModal.nameWithoutPrefixNote"),
    [vmsMode]: tVms("createOrEdit.fieldset.nameWithoutPrefixNote"),
  };

  const handleChange = (event) => onChange(event.target.value);

  return (
    <TextField
      name="name"
      data-testid={mode === disksMode && "diskName"}
      className={cn(classes.input, classes.inputField, {
        [classes.warningOutline]: !hasNameMatchingPrefix,
        [classes.inputForDisks]: mode === disksMode,
      })}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      helperText={!hasNameMatchingPrefix ? warnings[mode] : ""}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" className={classes.inputAdornment}>
            {prefixValue}
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
};

export default React.memo(NameControl);
