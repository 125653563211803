const styles = (theme) => ({
  input: {
    boxSizing: "border-box",
    padding: "7px 16px",
    fontSize: theme.fonts.smaller,
    fontFamily: theme.typography.fontFamily,
    "&::placeholder": {
      color: theme.colors.inactiveGrey,
    },
    backgroundColor: theme.colors.grey,
    "&::-ms-input-placeholder": {
      color: theme.colors.inactiveGrey,
    },
    border: "none",
    borderRadius: "2px",
    minWidth: "145px",
    lineHeight: "18px",
    textOverflow: "ellipsis",
    "&:active": {
      border: "none",
      outline: `1px solid ${theme.colors.focusedGrey}`,
      padding: "7px 16px",
    },
    "&:focus": {
      border: "none",
      outline: `1px solid ${theme.colors.focusedGrey}`,
      padding: "7px 16px",
    },
  },
  // other classType ...
});

export default styles;
