import { SET_USER_IS_LOGGED_IN } from "../../types";

/**
 * @typedef {object} AuthState
 * @property {boolean} isLoggedIn
 */

/**
 * @type {AuthState}
 */
export const initState = {
  isLoggedIn: false,
};

/**
 * @param {AuthState} state
 * @param {object} action
 * @return {AuthState}
 */
export default function authReducer(state = initState, action) {
  switch (action.type) {
    case SET_USER_IS_LOGGED_IN: {
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    }
    default:
      return state;
  }
}
