import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "@skyportal/ui-kit";
import { Pagination } from "@material-ui/lab";
import {
  resetPricelistDataAC,
  setPricelistCurrentPageAC,
  setPricelistSortFieldAC,
} from "store/actions/pricelistActions";
import { getPricelistTable, pricelistSel } from "store/selectors/pricelistSelectors";
import { REQUEST_SUCCESS } from "constants/request";
import SortableTable from "ui-kit/SortableTable/SortableTable";

import styles from "./styles";

const useStyles = makeStyles(styles);

const PricelistModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { contractId, requestStatus, sortField, sortOrder, currentPage } = useSelector(pricelistSel);
  const { list, pageCount } = useSelector(getPricelistTable);

  const columnConfig = useMemo(
    () => [
      { key: "productId", label: t("pricelist:productId"), width: "100px" },
      { key: "description", label: t("pricelist:description") },
      {
        key: "unitPrice",
        label: t("pricelist:unitPrice"),
        width: "100px",
        render: (item) => `${Number(item.unitPrice).toFixed(2)} ${item.currency}`,
      },
    ],
    [t]
  );

  const handleCloseBtn = () => dispatch(resetPricelistDataAC());
  const handleSortFieldChange = (field) => dispatch(setPricelistSortFieldAC(field));
  const onChangePage = (_, page) => dispatch(setPricelistCurrentPageAC(page));

  return (
    <Modal
      title={t("iaasCommon:priceList")}
      open={requestStatus === REQUEST_SUCCESS}
      className={classes.pricelistModal}
      controls={
        <div className={classes.pricelistFooter}>
          <Pagination
            page={currentPage}
            count={pageCount}
            onChange={onChangePage}
            className={classes.pricelistFooter}
            variant="outlined"
            shape="rounded"
          />
          <Button type="secondary" onClick={handleCloseBtn}>
            Close
          </Button>
        </div>
      }
    >
      <div className={classes.pricelistSubtitle}>
        {t("iaasCommon:Infrastructure")} - IA{contractId}
      </div>
      <SortableTable
        testId="PricelistModal_table"
        data={list}
        onSortFieldChange={handleSortFieldChange}
        columnsConfig={columnConfig}
        sortField={sortField}
        sortOrder={sortOrder}
        className={classes.pricelistTable}
      />
    </Modal>
  );
};

export default PricelistModal;
