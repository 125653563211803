import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { REQUEST_PENDING, REQUEST_UNCALLED } from "../../../constants/request";
import { setCurrentVmPageAct, setPerPageValue } from "../../../store/actions/virtualMachinesActions";
import { Spinner } from "@skyportal/ui-kit";
import { getTableVms, getVmListRequest, vmSel } from "../../../store/selectors/vmSelectors";
import { getUserProfile } from "store/selectors/userSelectors";
import PaginationPerSizeSelect from "../../../components/PaginationPerSizeSelect";
import VMTableRow from "./VMsTableComponents/VMTableRow";
import VmTotalsRow from "./VMsTableComponents/VmTotalsRow";
import VMsTableHead from "./VMsTableHead";
import VMsTableHeadControls from "./VMsTableHeadControls";
import { hasContractAccessToFeature, CONTRACT_TYPES, FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";
import { getVmsTotalHardware } from "../../../pages/helpers";

import styles from "./styles";

const useStyles = makeStyles(styles);

const VMsContent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { pageCount, list } = useSelector(getTableVms);
  const { currentVMPage, perPage } = useSelector(vmSel);
  const isMmListRequesting = [REQUEST_PENDING, REQUEST_UNCALLED].includes(useSelector(getVmListRequest));
  const userProfile = useSelector(getUserProfile);

  const handlePageChange = (_, page) => dispatch(setCurrentVmPageAct(page));
  const handlePerPageChange = (step) => dispatch(setPerPageValue(step));

  const filteredVmsList = list.filter((vm) =>
    hasContractAccessToFeature(userProfile, vm.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_VIRTUAL_MACHINES, [
      USER_ACCESS.VIEW,
    ])
  );
  const totalFilteredCpu = useMemo(() => getVmsTotalHardware(filteredVmsList, "vcpu"), [filteredVmsList]);
  const totalFilteredMemory = useMemo(() => getVmsTotalHardware(filteredVmsList, "memory"), [filteredVmsList]);
  const totalFilteredAllocatedSize = useMemo(() => getVmsTotalHardware(filteredVmsList, "size"), [filteredVmsList]);

  return (
    <div className={classes.pageBox}>
      <Spinner show={isMmListRequesting} />
      <div data-testid="VMsTableHeadControls">
        <VMsTableHeadControls />
      </div>
      <div className={classes.tableContainer}>
        <div className={classes.table} id="table-shell">
          <VMsTableHead />
          <div className={classes.tableBody}>
            {filteredVmsList.map((vm) => (
              <VMTableRow key={vm._id} vm={vm} />
            ))}
            {filteredVmsList.length > 0 && (
              <VmTotalsRow memory={totalFilteredMemory} cpu={totalFilteredCpu} size={totalFilteredAllocatedSize} />
            )}
          </div>
        </div>
      </div>
      <div className={classes.pagingContainer}>
        <PaginationPerSizeSelect value={perPage} onChange={handlePerPageChange} />
        <Pagination
          count={pageCount}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          page={currentVMPage}
        />
      </div>
    </div>
  );
};

export default VMsContent;
