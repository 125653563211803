import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { getNetworkList } from "../../../../store/selectors/networkSelectors";
import { filterListByDcId, getDcIdFromEntity } from "../../../../utils/groups";
import { getGroups } from "../../../../store/selectors/groupSelectors";
import { isNetworkAttachedToVm, isDetachNetworkAllowed } from "../../../../utils/vm";
import VmNetworkListItem from "./VmNetworkListItem";
import DetachNetworkModal from "./DetachNetworkModal";

import styles from "../styles";

const useStyles = makeStyles(styles);

/**
 * @param {VmStateModel} vm
 * @constructor
 */
const ListVmNetworks = ({ vm }) => {
  const classes = useStyles();
  const { t } = useTranslation("vmPage");
  const [detachNetworkId, setDetachNetworkId] = useState(null);
  /**
   * @type {NetworkStateModel[]}
   */
  const networks = useSelector(getNetworkList);
  /**
   * @type {NetworkStateModel[]}
   */
  const dcNetworks = filterListByDcId(networks, getDcIdFromEntity(vm));
  /**
   * @type {NetworkStateModel[]}
   */
  const attachedNetworks = dcNetworks.filter((item) => isNetworkAttachedToVm(vm, item));
  const groups = useSelector(getGroups);
  const dcGroups = filterListByDcId(groups, getDcIdFromEntity(vm));

  const handleDetachClick = (networkId) => setDetachNetworkId(networkId);
  const handleClose = useCallback(() => setDetachNetworkId(null), []);

  if (!attachedNetworks.length) {
    return null;
  }

  return (
    <>
      <div data-testid="ListVmNetworks" className={classes.scrollContainer}>
        <div className={classes.listTable}>
          <div className={classes.selectedImgBar}>
            <div className={classes.selImgName}>{t("iaasCommon:Name")}</div>
            <div className={classes.selImgMiddle}>{t("iaasCommon:Group")}</div>
            <div className={classes.selImgMiddle} />
            <div className={classes.selImgMiddle} />
            <div className={classes.selImgCross} />
          </div>
          {attachedNetworks.map((item) => (
            <VmNetworkListItem
              detachAllowed={isDetachNetworkAllowed(vm)}
              key={item.id}
              network={item}
              groups={dcGroups}
              onDetachClick={handleDetachClick}
            />
          ))}
        </div>
      </div>
      <DetachNetworkModal vm={vm} detachNetworkId={detachNetworkId} onClose={handleClose} />
    </>
  );
};

export default React.memo(ListVmNetworks);
