import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import Autocomplete from "../../ui-kit/Autocomplete";
import useDatacenterOptions from "../../hooks/useDatacenterOptions";
import { makeDcIdMapFromOptions } from "../../utils/datacenter";

/**
 * @param {object} dcIncludeMap
 * @param {function} onChangeAction
 * @param {string} [placeholder]
 * @param {object} rest
 * @return {JSX.Element}
 * @constructor
 */
const DatacenterFilter = ({ dcIncludeMap, onChangeAction, placeholder, ...rest }) => {
  const dispatch = useDispatch();
  const dcOptions = useDatacenterOptions();
  const values = useMemo(() => dcOptions.filter((item) => dcIncludeMap[item.value]), [dcOptions, dcIncludeMap]);
  const filterOptions = useCallback((options) => options.filter((item) => !dcIncludeMap[item.value]), [dcIncludeMap]);
  const handleChange = useCallback(
    (list) => dispatch(onChangeAction(makeDcIdMapFromOptions(list))),
    [dispatch, onChangeAction]
  );

  return (
    <Autocomplete
      testId="datacenterFilter"
      multiple
      placeholder={!values.length ? placeholder : ""}
      disableClearable
      value={values}
      options={dcOptions}
      filterOptions={filterOptions}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default React.memo(DatacenterFilter);
