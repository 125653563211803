import { REQUEST_FAILED, REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_UNCALLED } from "../../../constants/request";
import {
  EVENT_HISTORY_REQUEST,
  EVENT_HISTORY_REQUEST_FAILED,
  EVENT_HISTORY_REQUEST_SUCCESS,
  EVENT_HISTORY_RESET_FILTERS,
  EVENT_HISTORY_SET_DATE_RANGE,
  EVENT_HISTORY_SET_DC_INCLUDE,
  EVENT_HISTORY_SET_FILTERS,
  EVENT_HISTORY_SET_PAGE,
  EVENT_HISTORY_SET_PER_PAGE,
  EVENT_HISTORY_SET_SORT_FIELD,
  SET_USER_PROFILE,
} from "../../types";
import { getPastDay } from "../../../utils/dates";
import { getDatacenterIncludeMapFromStorage } from "../../../utils/eventHistory";

/**
 * @typedef {"name"|"type"|"username"|"stime"|"gname"|"gdisplayname"|"action_name"|"reason"|""} EventHistorySortFields
 */

/**
 * @typedef {object} EventHistoryFilters
 * @property {string[]} name
 * @property {string[]} type
 * @property {string[]} username
 * @property {string[]} gname
 * @property {string[]} gdisplayname
 * @property {string[]} action_name
 * @property {string[]} reason
 */

/**
 * @typedef {object} EventHistoryDateRange
 * @property {Date} start
 * @property {Date} end
 */

/**
 * @typedef {object} EventHistoryState
 * @property {ActionHistory[]} events
 * @property {RequestStatus} getRequestStatus
 * @property {number} currentPage
 * @property {SortOrderEnum} sortOrder
 * @property {EventHistorySortFields} sortField
 * @property {PaginationStep} perPage
 * @property {EventHistoryFilters} filters
 * @property {EventHistoryDateRange} dateRange
 * @property {object} datacenterIncludeMap
 */

/**
 * @type {EventHistoryState}
 */
export const initState = {
  events: [],
  getRequestStatus: REQUEST_UNCALLED,

  sortOrder: "desc",
  sortField: "stime",
  perPage: 50,
  currentPage: 1,
  filters: {
    reason: [],
    username: [],
    action_name: [],
    name: [],
    type: [],
    gname: [],
    gdisplayname: [],
  },

  dateRange: { start: getPastDay(30), end: new Date() },
  datacenterIncludeMap: {},
};

/**
 * @param {EventHistoryState} state
 * @param {object} action
 * @return {EventHistoryState}
 */
export default function authReducer(state = initState, action = {}) {
  switch (action.type) {
    case EVENT_HISTORY_REQUEST: {
      return {
        ...state,
        getRequestStatus: REQUEST_PENDING,
      };
    }
    case EVENT_HISTORY_REQUEST_FAILED: {
      return {
        ...state,
        getRequestStatus: REQUEST_FAILED,
      };
    }
    case EVENT_HISTORY_REQUEST_SUCCESS: {
      return {
        ...state,
        getRequestStatus: REQUEST_SUCCESS,
        events: action.payload,
      };
    }
    case EVENT_HISTORY_SET_SORT_FIELD: {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
      };
    }
    case EVENT_HISTORY_SET_PER_PAGE: {
      return {
        ...state,
        perPage: action.payload,
        currentPage: 1,
      };
    }
    case EVENT_HISTORY_SET_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case EVENT_HISTORY_SET_FILTERS: {
      return {
        ...state,
        filters: action.payload,
        currentPage: 1,
      };
    }
    case EVENT_HISTORY_RESET_FILTERS: {
      return {
        ...state,
        filters: {
          reason: [],
          username: [],
          action_name: [],
          name: [],
          type: [],
          gname: [],
          gdisplayname: [],
        },
        currentPage: 1,
      };
    }
    case EVENT_HISTORY_SET_DATE_RANGE: {
      return {
        ...state,
        dateRange: {
          start: action.payload.start || state.dateRange.start,
          end: action.payload.end || state.dateRange.end,
        },
      };
    }
    case EVENT_HISTORY_SET_DC_INCLUDE: {
      return {
        ...state,
        datacenterIncludeMap: action.payload,
      };
    }
    case SET_USER_PROFILE: {
      return {
        ...state,
        datacenterIncludeMap: action.payload
          ? getDatacenterIncludeMapFromStorage(action.payload.id)
          : state.datacenterIncludeMap,
      };
    }
    default:
      return state;
  }
}
