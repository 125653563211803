import { useReducer } from "react";
import { mb2gb } from "../../../utils/size";
import { attributeParser } from "./constantsNHelpers";

const types = {
  SET_DC_ID: "SET_DC_ID",
  SET_TEMPLATE: "SET_TEMPLATE",
  SET_CURRENT_VM: "SET_CURRENT_VM",
  SET_NAME: "SET_NAME",
  SET_ATTRIBUTE: "SET_ATTRIBUTE",
  SET_GROUP_ID: "SET_GROUP_ID",
  SET_NETWORK_ID: "SET_NETWORK_ID",
  SET_V_CPU: "SET_V_CPU",
  SET_V_CPU_ALT: "SET_V_CPU_ALT",
  SET_MEMORY: "SET_MEMORY",
  SET_MEMORY_ALT: "SET_MEMORY_ALT",
};

export const actions = {
  setDatacenterId: (payload) => ({ type: types.SET_DC_ID, payload }),
  setAttribute: (payload) => ({ type: types.SET_ATTRIBUTE, payload }),
  setTemplate: (payload) => ({ type: types.SET_TEMPLATE, payload }),
  setCurrentVm: (payload) => ({ type: types.SET_CURRENT_VM, payload }),
  setName: (payload) => ({ type: types.SET_NAME, payload }),
  setGroupId: (payload) => ({ type: types.SET_GROUP_ID, payload }),
  setNetworkId: (payload) => ({ type: types.SET_NETWORK_ID, payload }),
  setVCpu: (payload) => ({ type: types.SET_V_CPU, payload }),
  setVCpuAlt: (payload) => ({ type: types.SET_V_CPU_ALT, payload }),
  setMemory: (payload) => ({ type: types.SET_MEMORY, payload }),
  setMemoryAlt: (payload) => ({ type: types.SET_MEMORY_ALT, payload }),
};
/**
 * @typedef {object} TemplateModel
 * @property {string} _id
 * @property {number} id
 * @property {DataCenterApiModel} dcOwner
 */

/**
 * @typedef {object} VmFormState
 * @property {string} datacenterId
 * @property {string} name
 * @property {number} vCPU
 * @property {string} vCPUAlt
 * @property {number} memory
 * @property {string} memoryAlt
 * @property {object} attributes
 * @property {number|null} groupId
 * @property {number|null} networkId
 * @property {TemplateModel|null} template
 * @property {VmStateModel|null} currentVm
 */
/**
 * @type {VmFormState}
 */
const initState = {
  datacenterId: "",
  name: "",
  currentVm: null,
  template: null,
  groupId: null,
  networkId: null,
  vCPU: 0,
  vCPUAlt: "",
  memory: 0,
  memoryAlt: "",
  attributes: {},
};

/**
 * @param {VmFormState} state
 * @param {object} action
 * @param {string} action.type
 * @param {*} action.payload
 * @return {VmFormState}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_DC_ID:
      return {
        ...state,
        datacenterId: action.payload,
        template: null,
        name: "",
        groupId: null,
        networkId: null,
        attributes: null,
      };

    case types.SET_TEMPLATE: {
      const {
        attributes,
        hardware: { vcpu, memory },
      } = action.payload;

      const defaultAttributesValues = {};
      for (let attribute in attributes) {
        if (attributes[attribute]) {
          const parsedAttribute = attributeParser(attributes[attribute]);
          defaultAttributesValues[attribute] = parsedAttribute?.defaultValue || "";

          if (parsedAttribute.type === "boolean" && parsedAttribute.defaultValue !== "true") {
            defaultAttributesValues[attribute] = "false";
          }
        } else {
          defaultAttributesValues[attribute] = null;
        }
      }
      const memoryInGb = mb2gb(Number(memory));
      return {
        ...state,
        attributes: defaultAttributesValues,
        template: action.payload,
        vCPU: Number(vcpu),
        vCPUAlt: vcpu,
        memory: memoryInGb,
        memoryAlt: String(memoryInGb),
      };
    }
    case types.SET_CURRENT_VM: {
      /**
       * @type {VmStateModel}
       */
      const { currentVm, currentVmGroup } = action.payload;
      const {
        attributeValues,
        attributeInputs,
        hardware: { vcpu, memory },
      } = currentVm;

      for (let attribute in attributeInputs) {
        if (!attributeValues[attribute]) {
          const parsedAttribute = attributeParser(attributeInputs[attribute]);
          attributeValues[attribute] = parsedAttribute?.defaultValue || "";
          if (parsedAttribute.type === "boolean" && parsedAttribute.defaultValue !== "true") {
            attributeValues[attribute] = "false";
          }
        }
      }

      let vmName = currentVm.name;
      let hasVmNameMatchingPrefix = false;
      if (currentVmGroup?.prefix) {
        hasVmNameMatchingPrefix = currentVm.name.includes(currentVmGroup.prefix);
        if (hasVmNameMatchingPrefix) {
          vmName = vmName.replace(`${currentVmGroup.prefix}-`, "");
        }
      } else if (currentVmGroup?.name) {
        hasVmNameMatchingPrefix = currentVm.name.includes(currentVmGroup.name);
        if (hasVmNameMatchingPrefix) {
          vmName = vmName.replace(`${currentVmGroup.name}-`, "");
        }
      }

      const memoryInGb = mb2gb(Number(memory));
      return {
        ...state,
        datacenterId: currentVm.dcOwner.id,
        currentVm,
        name: vmName,
        groupId: currentVm.group,
        vCPU: Number(vcpu),
        vCPUAlt: String(vcpu),
        memory: memoryInGb,
        memoryAlt: String(memoryInGb),
        attributes: attributeValues,
        hasVmNameMatchingPrefix,
      };
    }
    case types.SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case types.SET_GROUP_ID:
      return {
        ...state,
        groupId: action.payload,
      };
    case types.SET_NETWORK_ID:
      return {
        ...state,
        networkId: action.payload,
      };
    case types.SET_ATTRIBUTE:
      const { _name: name, _value: value } = action.payload;
      return {
        ...state,
        attributes: {
          ...state.attributes,
          [name]: value,
        },
      };
    case types.SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case types.SET_V_CPU:
      return {
        ...state,
        vCPU: action.payload,
        vCPUAlt: String(action.payload),
      };
    case types.SET_V_CPU_ALT:
      return {
        ...state,
        vCPUAlt: action.payload,
      };
    case types.SET_MEMORY:
      return {
        ...state,
        memory: action.payload,
        memoryAlt: String(action.payload),
      };
    case types.SET_MEMORY_ALT:
      return {
        ...state,
        memoryAlt: action.payload,
      };
    default:
      return state;
  }
};

/**
 *
 * @return {[VmFormState, function, object]}
 */
const useVmFormState = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  return [state, dispatch, actions];
};

export default useVmFormState;
