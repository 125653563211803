import { makeFindObjectValueMatch, makeUniversalSort } from "../../pages/helpers";
import { REGIONS_PER_PAGE } from "../../constants/pagination";

/**
 * @param {RootState} state
 * @return {RegionState}
 */
export const regionSel = (state) => state.region;
/**
 * @param {RootState} state
 * @return {RequestStatus}
 */
export const getRegionRequest = (state) => regionSel(state).regionRequest;

const makeNameSortHandler = makeUniversalSort((item) => item.name.trim().toLowerCase());
const makeCodeSortHandler = makeUniversalSort((item) => item.code.trim().toLowerCase());
const makeDatacentersSortHandler = makeUniversalSort((item) => item.datacenters.length);

/**
 * @param {RegionSortField} sortField
 * @param {"asc"|"desc"} sortOrder
 * @param {string} searchText
 * @param {number} currentPage
 * @param {RegionStateModel[]} regions
 * @param {number} [perPage]
 * @return {{total: number, pageCount: number, list: RegionStateModel[]}}
 */
const regionSelectComposer = ({
  sortField,
  sortOrder,
  searchText,
  currentPage,
  regions,
  perPage = REGIONS_PER_PAGE,
}) => {
  let _list = [...regions];

  if (searchText) {
    _list = _list.filter(makeFindObjectValueMatch(searchText, ["id"]));
  }

  const sortHandler = {
    name: makeNameSortHandler(sortOrder),
    code: makeCodeSortHandler(sortOrder),
    datacenters: makeDatacentersSortHandler(sortOrder),
  }[sortField];

  if (sortHandler) {
    _list.sort(sortHandler);
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: _list.slice((currentPage - 1) * perPage, currentPage * perPage),
  };
};

export const getTableRegions = (state) => {
  const { sortField, sortOrder, searchText, currentPage, regions, perPage } = regionSel(state);
  return regionSelectComposer({ sortField, sortOrder, searchText, currentPage, regions, perPage });
};
