import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Dropdown from "../../../../ui-kit/Dropdown";
import { setReportGroup } from "../../../../store/actions/reportsActions";
import { addAllOption, makeAgreementOptions } from "../utils";
import { reportSel } from "../../../../store/selectors/reportSelectors";
import { EMPTY_GROUP_VALUE } from "../constants";
import { getGroups } from "../../../../store/selectors/groupSelectors";
import { getUserProfile } from "store/selectors/userSelectors";
import { FEATURES } from "@skyportal/auth-web-client";

import styles from "../styles";

const useStyles = makeStyles(styles);

const DetailedReportGroupSelect = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { reportGroupId, reportDatacenterId } = useSelector(reportSel);
  const userProfile = useSelector(getUserProfile);
  const handleChange = (event) => dispatch(setReportGroup(event.target.value));
  const groups = useSelector(getGroups);

  const agreementOptions = useMemo(() => {
    const datacenterGroups =
      reportDatacenterId && reportDatacenterId !== EMPTY_GROUP_VALUE
        ? groups.filter((item) => item.dcOwner.id === reportDatacenterId)
        : groups;
    return addAllOption(makeAgreementOptions(datacenterGroups, userProfile, FEATURES.IAAS_USAGE_REPORT_DETAILED));
  }, [reportDatacenterId, groups, userProfile]);

  return (
    <Dropdown
      id="agreementSelect"
      onChange={handleChange}
      value={reportGroupId}
      options={agreementOptions}
      disabled={agreementOptions.length === 0}
      classes={{ select: classes.muiSelectRoot, icon: classes.selectIcon }}
    />
  );
};

export default DetailedReportGroupSelect;
