import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getEventHistoryFilters, getEventHistorySlice } from "../../../store/selectors/eventHistorySelectors";
import {
  composeEventHistoryFilterOptionList,
  composeFreeTextSearch,
  FREE_TEXT_SEARCH_LIST,
} from "../../../utils/eventHistory";
import Autocomplete from "../../../ui-kit/Autocomplete";

/**
 * @param {EventHistorySortFields} keyIndex
 * @param {function(key: EventHistorySortFields, filterValues: string|number[])} onChange
 * @return {JSX.Element}
 * @constructor
 */
const EventHistoryFilter = ({ keyIndex, onChange }) => {
  const onChangeRef = useRef(onChange);
  const storeFilters = useSelector((state) => getEventHistoryFilters(state)[keyIndex]);
  const [value, setValue] = useState(storeFilters);
  const [inputValue, setInputValue] = useState("");
  const { events } = useSelector(getEventHistorySlice);

  const optionsList = useMemo(() => composeEventHistoryFilterOptionList(events, keyIndex), [events, keyIndex]);
  const options = [
    FREE_TEXT_SEARCH_LIST.includes(keyIndex) && composeFreeTextSearch(inputValue),
    ...optionsList.filter((item) => !value.includes(item)),
  ].filter(Boolean);

  /**
   * @param {string[]} selectedOptionList
   */
  const handleChange = (selectedOptionList) => {
    setValue(selectedOptionList);
    onChange(keyIndex, selectedOptionList);
  };

  onChangeRef.current = onChange;
  useEffect(() => {
    setValue(storeFilters);
    onChangeRef.current(keyIndex, storeFilters);
  }, [storeFilters]);

  const handleInputChange = (event, inputValue) => setInputValue(inputValue);

  return (
    <Autocomplete
      multiple
      disableClearable
      value={value}
      testId={keyIndex}
      options={options}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  );
};

export default EventHistoryFilter;
