import { axiosClient } from "./web";

export const getDcSettings = (dcUrl) => axiosClient.get(`${dcUrl}/v1/settings`);

export const setDcSettings = (dcUrl, data) =>
  axiosClient.request({
    baseURL: dcUrl,
    method: "PUT",
    url: "/v1/settings",
    data,
  });
