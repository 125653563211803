import {
  getAllDatacenters,
  getEnabledDatacenters,
  updateDatacenter,
  createNewDatacenter,
  deleteDatacenter,
} from "../../api/datacenterService";
import i18n from "../../locales";
import { notificationAC, notificationErrorAC } from "./notificationActions";
import { getTranslatedApiErrorMessage, showDirectoryApiErrorNotification } from "../../api/utils";
import { mapApiToStateModel } from "../../utils/datacenter";
import {
  GET_ENABLED_DATACENTERS_REQUEST_SUCCESS,
  NEW_DC_TOGGLE_MODAL,
  DELETE_DATACENTER_REQUEST_SUCCESS,
  CREATE_DATACENTER_REQUEST_SUCCESS,
  CREATE_DATACENTER_REQUEST,
  CREATE_DATACENTER_REQUEST_FAILED,
  UPDATE_DATACENTER_REQUEST,
  UPDATE_DATACENTER_REQUEST_SUCCESS,
  UPDATE_DATACENTER_REQUEST_FAILED,
  DELETE_DATACENTER_REQUEST,
  DELETE_DATACENTER_REQUEST_FAILED,
  SET_DATACENTER_PER_PAGE,
  SET_DATACENTER_SEARCH_TEXT,
  SET_DATACENTER_CURRENT_PAGE,
  SET_DATACENTER_SORT_FIELD,
  GET_DATACENTERS_REQUEST,
  GET_DATACENTERS_REQUEST_FAILURE,
  GET_DATACENTER_SETTINGS_REQUEST,
  GET_DATACENTER_SETTINGS_REQUEST_SUCCESS,
  GET_DATACENTER_SETTINGS_REQUEST_FAILURE,
  SET_DATACENTER_SETTINGS_REQUEST,
  SET_DATACENTER_SETTINGS_REQUEST_SUCCESS,
  SET_DATACENTER_SETTINGS_REQUEST_FAILURE,
  GET_ALL_DATACENTERS_REQUEST_SUCCESS,
} from "../types";
import { getDcSettings, setDcSettings } from "api/settingsService";

/**
 * @param {DataCenterApiModel[]} payload
 * @return {{payload: DataCenterApiModel[], type: "SET_DATACENTERS"}}
 */
const setEnabledDatacentersAC = (payload) => ({
  type: GET_ENABLED_DATACENTERS_REQUEST_SUCCESS,
  payload,
});

const setAllDatacentersAC = (payload) => ({
  type: GET_ALL_DATACENTERS_REQUEST_SUCCESS,
  payload,
});

const datacentersRequestAC = () => ({ type: GET_DATACENTERS_REQUEST });
const datacentersRequestFailureAC = () => ({ type: GET_DATACENTERS_REQUEST_FAILURE });

export const setEnabledDatacentersAct = () => async (dispatch) => {
  try {
    dispatch(datacentersRequestAC());
    const { data } = await getEnabledDatacenters();
    dispatch(setEnabledDatacentersAC(data.map(mapApiToStateModel)));
  } catch (error) {
    showDirectoryApiErrorNotification(error, dispatch);
    dispatch(datacentersRequestFailureAC());
  }
};

export const setAllDatacentersAct = () => async (dispatch) => {
  try {
    dispatch(datacentersRequestAC());
    const { data } = await getAllDatacenters();
    dispatch(setAllDatacentersAC(data.map(mapApiToStateModel)));
  } catch (error) {
    showDirectoryApiErrorNotification(error, dispatch);
    dispatch(datacentersRequestFailureAC());
  }
};

export const updateDcRequestAC = () => ({
  type: UPDATE_DATACENTER_REQUEST,
});

export const updateDcRequestSuccessAC = (payload) => ({
  type: UPDATE_DATACENTER_REQUEST_SUCCESS,
  payload,
});
export const updateDcRequestFailedAC = () => ({
  type: UPDATE_DATACENTER_REQUEST_FAILED,
});
export const updateDatacenterAct = (payload, closeModal) => async (dispatch) => {
  dispatch(updateDcRequestAC());

  try {
    await updateDatacenter(payload);
    await dispatch(setAllDatacentersAct());
    await dispatch(setEnabledDatacentersAct());
    dispatch(updateDcRequestSuccessAC());
    closeModal();
    dispatch(notificationAC(i18n.t("notification:dcSuccessfullyUpdated")));
  } catch (error) {
    const translatedApiErrorMessage = getTranslatedApiErrorMessage(error);
    dispatch(
      notificationErrorAC({ message: i18n.t("notification:dcUpdatingFailed"), description: translatedApiErrorMessage })
    );
    dispatch(updateDcRequestFailedAC());
  }
};

export const toggleNewDCModal = () => ({
  type: NEW_DC_TOGGLE_MODAL,
});

export const createDcRequestAC = () => ({
  type: CREATE_DATACENTER_REQUEST,
});

export const createDcRequestSuccessAC = (payload) => ({
  type: CREATE_DATACENTER_REQUEST_SUCCESS,
  payload,
});
export const createDcRequestFailedAC = () => ({
  type: CREATE_DATACENTER_REQUEST_FAILED,
});

export const createDCAct = (payload, closeModal) => async (dispatch) => {
  dispatch(createDcRequestAC());

  try {
    const response = await createNewDatacenter(payload);
    dispatch(
      createDcRequestSuccessAC({
        id: response.data._id,
        ...payload,
      })
    );
    closeModal();
    dispatch(notificationAC(i18n.t("notification:dcSuccessfullyCreated")));
  } catch (error) {
    const translatedApiErrorMessage = getTranslatedApiErrorMessage(error);
    dispatch(
      notificationErrorAC({ message: i18n.t("notification:dcCreatingFailed"), description: translatedApiErrorMessage })
    );
    dispatch(createDcRequestFailedAC());
  }
};

const deleteRequestSuccessAC = (dc) => ({
  type: DELETE_DATACENTER_REQUEST_SUCCESS,
  payload: dc,
});

const deleteRequestFailureAC = () => ({
  type: DELETE_DATACENTER_REQUEST_FAILED,
});

const deleteRequestAC = () => ({
  type: DELETE_DATACENTER_REQUEST,
});

export const deleteDCAct = (dc) => async (dispatch) => {
  try {
    dispatch(deleteRequestAC());
    await deleteDatacenter(dc);
    dispatch(deleteRequestSuccessAC(dc));
    dispatch(notificationAC(i18n.t("notification:dcSuccessfullyDeleted")));
  } catch (error) {
    dispatch(deleteRequestFailureAC());
    const translatedApiErrorMessage = getTranslatedApiErrorMessage(error);
    dispatch(
      notificationErrorAC({ message: i18n.t("notification:dcDeletingFailed"), description: translatedApiErrorMessage })
    );
  }
};

/**
 * @param {PaginationStep} payload
 * @return {{payload: PaginationStep, type: "SET_DATACENTER_PER_PAGE"}}
 */
export const setPerPageAC = (payload) => ({
  type: SET_DATACENTER_PER_PAGE,
  payload,
});

/**
 * @param {string} payload
 * @return {{payload: string, type: "SET_DATACENTER_SEARCH_TEXT"}}
 */
export const setSearchTextAC = (payload) => ({
  type: SET_DATACENTER_SEARCH_TEXT,
  payload,
});

/**
 * @param {number} payload
 * @return {{payload: number, type: "SET_DATACENTER_CURRENT_PAGE"}}
 */
export const setCurrentPageAC = (payload) => ({
  type: SET_DATACENTER_CURRENT_PAGE,
  payload,
});

/**
 * @param {DatacenterSortField} payload
 * @return {{payload: DatacenterSortField, type: "SET_DATACENTER_SORT_FIELD"}}
 */
export const setSortFieldAC = (payload) => ({
  type: SET_DATACENTER_SORT_FIELD,
  payload,
});

export const getDcSettingsRequestAC = (dcId) => ({
  type: GET_DATACENTER_SETTINGS_REQUEST,
  payload: dcId,
});

export const getDcSettingsSuccessAC = (dcId, payload) => ({
  type: GET_DATACENTER_SETTINGS_REQUEST_SUCCESS,
  payload: { id: dcId, ...payload },
});

export const getDcSettingsRequestFailureAC = (dcId) => ({
  type: GET_DATACENTER_SETTINGS_REQUEST_FAILURE,
  payload: dcId,
});

export const getDcSettingsAct = (datacenter) => async (dispatch) => {
  try {
    dispatch(getDcSettingsRequestAC(datacenter.id));
    const { data } = await getDcSettings(datacenter.api.url);
    dispatch(getDcSettingsSuccessAC(datacenter.id, data));
  } catch (error) {
    dispatch(getDcSettingsRequestFailureAC(datacenter.id));
    const translatedApiErrorMessage = getTranslatedApiErrorMessage(error);
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:dcSettingsFetchingFailed"),
        description: translatedApiErrorMessage,
      })
    );
  }
};

export const setDcSettingsRequestAC = () => ({
  type: SET_DATACENTER_SETTINGS_REQUEST,
});

export const setDcSettingsRequestSuccessAC = () => ({
  type: SET_DATACENTER_SETTINGS_REQUEST_SUCCESS,
});

export const setDcSettingsRequestFailureAC = () => ({
  type: SET_DATACENTER_SETTINGS_REQUEST_FAILURE,
});

export const setDcSettingsAct = (datacenter, data, setSettingsModalOpen) => async (dispatch) => {
  try {
    dispatch(setDcSettingsRequestAC());
    await setDcSettings(datacenter.api.url, data);
    dispatch(setDcSettingsRequestSuccessAC());
    dispatch(notificationAC(i18n.t("notification:dcSettingsUpdateSuccess")));
    setSettingsModalOpen(false);
  } catch (error) {
    dispatch(setDcSettingsRequestFailureAC(datacenter.id));
    const translatedApiErrorMessage = getTranslatedApiErrorMessage(error);
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:dcSettingsUpdateFailed"),
        description: translatedApiErrorMessage,
      })
    );
  }
};
