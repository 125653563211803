import { getContractPricelist } from "api/pricelistService";
import { parseApiErrorMessage } from "api/utils";
import i18n from "../../locales";
import {
  CONTRACT_PRICELIST_REQUEST,
  CONTRACT_PRICELIST_REQUEST_SUCCESS,
  CONTRACT_PRICELIST_REQUEST_FAILURE,
  RESET_PRICELIST_DATA,
  SET_PRICELIST_SORT_FIELD,
  SET_PRICELIST_CURRENT_PAGE,
} from "../types";
import { notificationErrorAC } from "./notificationActions";

const contractPricelistRequestAC = () => ({ type: CONTRACT_PRICELIST_REQUEST });
const contractPricelistRequestSuccessAC = (payload) => ({ type: CONTRACT_PRICELIST_REQUEST_SUCCESS, payload });
const contractPricelistRequestFailureAC = () => ({ type: CONTRACT_PRICELIST_REQUEST_FAILURE });

export const getContractPricelistAct = (contractId) => async (dispatch) => {
  try {
    dispatch(contractPricelistRequestAC(contractId));
    const { data } = await getContractPricelist(contractId);
    dispatch(contractPricelistRequestSuccessAC({ contractId, list: data.products }));
  } catch (error) {
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:pricelistFetchFailed"),
        description: parseApiErrorMessage(error),
      })
    );
    dispatch(contractPricelistRequestFailureAC());
  }
};

export const resetPricelistDataAC = () => ({ type: RESET_PRICELIST_DATA });

export const setPricelistSortFieldAC = (payload) => ({
  type: SET_PRICELIST_SORT_FIELD,
  payload,
});

export const setPricelistCurrentPageAC = (payload) => ({
  type: SET_PRICELIST_CURRENT_PAGE,
  payload,
});
