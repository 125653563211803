export default () => ({
  root: {
    borderTop: "4px solid #B4B4B4",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    backgroundColor: "#fff",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
    marginBottom: "16px",
  },
  filterContainer: {
    borderTop: "1px solid #F4F4F4",
  },
  form: {
    display: "block",
    width: "100%",
  },
  panelLabel: {
    color: "#000000",
    fontSize: "20px",
    lineHeight: "24px",
  },
  panelStateHint: {
    color: "#000000",
    fontSize: "14px",
    lineHeight: "18px",
  },
  expandIcon: {
    color: "#000000",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  label: {
    color: "#4F4F4F",
    fontSize: "12px",
    lineHeight: "16px",
    marginBottom: "6px",
  },
  controlWrapper: {
    padding: "0 6px 6px",
  },
  muiSelectRoot: {
    fontSize: "14px",
    boxSizing: "border-box",
    width: "100%",
    height: "32px",
    minWidth: "120px",
    margin: 0,
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    paddingLeft: "16px",
    paddingRight: "16px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F0F0F0",
    color: "#000",
  },
  selectIcon: {
    color: "#000",
  },
  inputMaskValue: {
    color: "#000",
    fontSize: "14px",
    lineHeight: "18px",
    flexGrow: 1,
    marginLeft: "16px",
  },
  controlContainer: {
    display: "flex",
    margin: "-8px",
    flexWrap: "wrap",
  },
  controlBoxItem: {
    margin: "8px",
    minWidth: "180px",
    flexGrow: 1,

    "& > *": {
      width: "100%",
    },
  },
  controlButtonBoxItem: {
    alignSelf: "flex-end",
  },
  pricelistLink: {
    margin: "0 16px 16px 16px",
    fontSize: "14px !important",
  },
});
