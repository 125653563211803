import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { Icon } from "@skyportal/ui-kit";
import { iconResolver } from "../../helpers";
import { setSortFieldAC } from "../../../store/actions/datacenterActions";
import * as dcSelectors from "../../../store/selectors/dcSelectors";
import ModalBody from "./DatacenterRow/ModalBody";

import styles from "./styles";

const useStyles = makeStyles(styles);

const DatacentersTableHead = ({ toggleCreateNewRow }) => {
  const { t } = useTranslation("datacentersPage");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isNewDCModalOpen, sortOrder, sortField } = useSelector(dcSelectors.dcSel);

  /**
   * @param {DatacenterSortField} key
   * @return {function(): *}
   */
  const makeHandleSortClick = (key) => () => dispatch(setSortFieldAC(key));

  return (
    <>
      <div className={classes.columnRow}>
        <div
          className={classnames(classes.cell, classes.sorterCell, classes.newRegionBar)}
          onClick={makeHandleSortClick("name")}
        >
          <span className={classes.sorterCellIcon}>
            <Icon type="fontawesome" icon={iconResolver(sortOrder, sortField, "name")} />
          </span>
          <span className={classes.sorterCellText}>{t("tableColumns.name")}</span>
        </div>
        <div
          className={classnames(classes.cell, classes.smallerCell, classes.sorterCell, classes.newRegionBar)}
          onClick={makeHandleSortClick("description")}
        >
          <span className={classes.sorterCellIcon}>
            <Icon type="fontawesome" icon={iconResolver(sortOrder, sortField, "description")} />
          </span>
          <span className={classes.sorterCellText}>{t("tableColumns.description")}</span>
        </div>
        <div
          className={classnames(classes.cell, classes.smallerCell, classes.sorterCell, classes.newRegionBar)}
          onClick={makeHandleSortClick("region")}
        >
          <span className={classes.sorterCellIcon}>
            <Icon type="fontawesome" icon={iconResolver(sortOrder, sortField, "region")} />
          </span>
          <span className={classes.sorterCellText}>{t("tableColumns.region")}</span>
        </div>
        <div
          className={classnames(classes.cell, classes.sorterCell, classes.newRegionBar)}
          onClick={makeHandleSortClick("api.url")}
        >
          <span className={classes.sorterCellIcon}>
            <Icon type="fontawesome" icon={iconResolver(sortOrder, sortField, "api")} />
          </span>
          <span className={classes.sorterCellText}>{t("tableColumns.apiUrl")}</span>
        </div>
        <div className={classes.lastEmptyCell} />
      </div>
      <Modal open={isNewDCModalOpen} onClose={toggleCreateNewRow}>
        <div>
          <ModalBody setEdit={toggleCreateNewRow} closeModal={toggleCreateNewRow} />
        </div>
      </Modal>
    </>
  );
};

export default DatacentersTableHead;
