const modalFooterBtn = {
  width: "93px",
  height: "32px",
  padding: "7px 24px",
  // fontSize: theme.fonts.smaller,
};

const btn = {
  width: "80px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "$4f4f4f",
  },
};

const styles = (theme) => ({
  row: {
    display: "flex",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  greyedOutRow: {
    "& span": {
      opacity: "0.6",
    },
    "& button span": {
      opacity: "1",
    },
  },
  cell: {
    ...theme.classes.table.cell,
    height: "50px",
    width: "20%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    flexShrink: 0,
  },
  smallerCell: {
    width: "15%",
  },
  actionsCell: {
    width: "30%",
  },
  controls: {
    ...theme.classes.centralized,
    height: "32px",
  },
  cellShell: {
    ...theme.classes.table.cellShell,
    fontSize: theme.fonts.smaller,
    marginLeft: 0,
    display: "flex",
    alignItems: "center",
  },
  inCell: theme.classes.table.inCell,
  modalBody: {
    width: "563px",
    maxWidth: "90%",
    maxHeight: "90vh",
    backgroundColor: "white",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    boxSizing: "border-box",
    outline: "none",
  },
  modalHeader: {
    ...theme.classes.elems.modalHeader,
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
  },
  confirmModalHeader: {
    padding: "24px",
    fontSize: "28px",
    textAlign: "center",
  },
  confirmModalBody: {
    position: "absolute",
    width: "360px",
    maxWidth: "90%",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90vh",
    backgroundColor: "white",
    overflowY: "auto",
    boxSizing: "border-box",

    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  card: theme.classes.elems.card,
  modalCard: {
    padding: "0 16px",
  },
  cardRow: {
    marginTop: "16px",
    display: "flex",
    flexWrap: "wrap",
    "@global": {
      ".MuiInputBase-root": {
        width: "100%",
      },
    },
  },
  cardRowLabel: {
    color: theme.colors.darkGrey,
    fontSize: theme.fonts.small,
    padding: "0 0 8px 0",
  },
  invalid: {
    color: theme.colors.lightRed,
  },
  cardRowInput: {
    width: "100%",
  },
  cardFooter: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "8px",
    paddingTop: "16px",
    paddingBottom: "16px",

    "& > *": {
      marginLeft: "16px",
    },

    "@media(max-width: 767px)": {
      "& > *": {
        flexGrow: 1,
      },
      "& > * > button": {
        width: "100%",
        minWidth: "150px",
      },
    },
  },
  confirmCardFooter: {
    display: "flex",
    alignItems: "center",
    padding: "24px",
    justifyContent: "center",
  },
  saveFooterBtn: {
    ...modalFooterBtn,
    fontSize: theme.fonts.smaller,
    textAlign: "center",
  },
  hiddenCell: {
    display: "inline-block",
    position: "absolute",
    padding: "15px",
    maxWidth: "200px",
    borderRadius: "3px",
    backgroundColor: theme.colors.lightGrey,
    zIndex: "100",
    opacity: "1 !important",
  },
  dcRowControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
  },
  settingsBtn: {
    width: "80px",
  },
  dcSettingsModal: {
    "& header": {
      fontSize: "20px",
    },
  },
  leftMargin: {
    marginLeft: "8px",
  },
  settingRow: {
    display: "flex",
    alignItems: "center",
    lineHeight: "36px",
    "& :hover": {
      cursor: "pointer",
    },
  },
  checkboxLabel: {
    marginLeft: "8px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  muiSelectRoot: {
    ...theme.classes.dropDown.muiSelectRoot,
    backgroundColor: theme.colors.lightGrey,
    fontSize: theme.fonts.smaller,
    width: "100%",
  },
  muiSelectIcon: {
    ...theme.classes.dropDown.muiSelectIcon,
  },
  muiMenuItem: {
    ...theme.classes.dropDown.muiMenuItem,
    fontSize: theme.fonts.smaller,
  },
  cancelDeleteBtn: {
    ...btn,
    marginRight: "16px",
    backgroundColor: "black",
    color: "white",
  },
  confirmDeleteBtn: {
    ...btn,
    backgroundColor: "#d3d3d3",

    "&:disabled": {
      opacity: "0.5",
      cursor: "not-allowed",
    },
  },
});

export default styles;
