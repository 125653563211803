import { getPaginationSlice, makeFindObjectValueMatch, makeUniversalSort } from "../../pages/helpers";
import { getDcIdFromEntity, getGroupMapByDcId, getGroupNameFromListById } from "../../utils/groups";
import * as groupSelectors from "../../store/selectors/groupSelectors";
import { isNetworkAttachedToVm } from "../../utils/vm";

/**
 * @param {RootState} state
 * @return {NetworkState}
 */
export const getNetworkSlice = (state) => state.network;
/**
 * @param {RootState} state
 * @return {NetworkStateModel[]}
 */
export const getNetworkList = (state) => getNetworkSlice(state).list;

/**
 * @param {NetworkStateModel} item
 */
const nameNormalizer = (item) => item.name;
/**
 * @param {NetworkStateModel} item
 */
const groupNormalizer = (item) => item.group;

const sortHandler = {
  name: makeUniversalSort(nameNormalizer),
  group: makeUniversalSort(groupNormalizer),
};

/**
 * @param {string} sortField
 * @param {"asc"|"desc"} sortOrder
 * @param {string} searchText
 * @param {number} currentPage
 * @param {NetworkStateModel[]} networks
 * @param {number} [perPage]
 * @param {RootState} state
 * @return {{total: number, pageCount: number, list: NetworkStateModel[]}}
 */
const selectComposer = ({
  sortField = "name",
  searchText = "",
  sortOrder = "asc",
  currentPage = 1,
  networks = [],
  perPage = 5,
  state,
}) => {
  const groupMapByDcId = getGroupMapByDcId(groupSelectors.getGroups(state));
  let _list = [...networks].map((network) => ({
    ...network,
    __groupName: getGroupNameFromListById(groupMapByDcId[network.dcOwner.id], network.group, ""),
  }));

  if (searchText) {
    _list = _list.filter(makeFindObjectValueMatch(searchText, ["id", "_id"], ["__groupName"]));
  }

  if (sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const makeNetworkToAttachList = (vm) => (state) => {
  const { list, sortField, currentPage, perPage, searchText, sortOrder } = getNetworkSlice(state);

  const dcFilter = (network) => getDcIdFromEntity(network) === getDcIdFromEntity(vm);
  const attachedFilter = (network) => !isNetworkAttachedToVm(vm, network);

  const networks = list.filter(dcFilter).filter(attachedFilter);

  return selectComposer({ networks, sortField, currentPage, perPage, searchText, sortOrder, state });
};
