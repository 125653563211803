const styles = () => ({
  spinner: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.1)",
    color: "#000",
    zIndex: 1,
    "& > *": {
      zIndex: 2,
    },
  },
});

export default styles;
