export const maxInputLength = 50;
export const amountPerPage = 9;

/**
 * @typedef {object} ColumnConfigItem
 * @property {string} key - unique, mostly props of data model
 * @property {string} label - label with will be pass into i18n
 * @property {boolean} [isSortable] - flag to allow sort
 */

export const minImageSize = 1;
export const maxImageSizeGB = Number(window.IAAS_DISK_MAX_GB) || 250;
export const maxMemorySizeGB = Number(window.IAAS_MEMORY_MAX_GB) || 64;
export const maxVCPUCount = Number(window.IAAS_MAX_VCPU_COUNT) || 16;
export const tariffs = ["dashboard", "backup", "disusterRecovery", "storage", "infra", "other"];
export const DEFAULT_VM_UPDATE_INTERVAL_SEC = 30;
export const refreshTimerOptions = [
  { value: 0, label: "Off" },
  { value: 5, label: "5s" },
  { value: 10, label: "10s" },
  { value: 30, label: "30s" },
  { value: 60, label: "1m" },
  { value: 300, label: "5m" },
];

/**
 * @typedef {"usage"|"vms"|"images"} ReportSection
 */
/**
 * @type {ReportSection}
 */
export const USAGE_REPORT_SECTION = "usage";
/**
 * @type {ReportSection}
 */
export const VMS_REPORT_SECTION = "vms";
/**
 * @type {ReportSection}
 */
export const IMAGES_REPORT_SECTION = "images";
/**
 * @typedef {"summary"|"detailed"|"detailed-storage"} ReportType
 */
/**
 * @type {ReportType}
 */
export const SUMMARY_REPORT_TYPE = "summary";
/**
 * @type {ReportType}
 */
export const DETAILED_REPORT_TYPE = "detailed";
/**
 * @type {ReportType}
 */
export const DETAILED_STORAGE_REPORT_TYPE = "detailed-storage";
/**
 * @typedef {"pdf"|"csv"} ReportAcceptFileType
 */
/**
 * @type {ReportAcceptFileType}
 */
export const PDF_REPORT_ACCEPT_TYPE = "pdf";
/**
 * @type {ReportAcceptFileType}
 */
export const CSV_REPORT_ACCEPT_TYPE = "csv";

export const reportPagesUrls = {
  summary: "/infra/reports/summary",
  detailed: "/infra/reports/detailed",
  storage: "/infra/reports/storage-details",
};

export const reportTypes = {
  [reportPagesUrls.summary]: SUMMARY_REPORT_TYPE,
  [reportPagesUrls.detailed]: DETAILED_REPORT_TYPE,
  [reportPagesUrls.storage]: DETAILED_STORAGE_REPORT_TYPE,
};
