import { useEffect, useRef } from "react";

const useUnmount = (cb) => {
  const callBackRef = useRef();

  callBackRef.current = cb;

  useEffect(() => {
    return () => {
      if (typeof callBackRef.current === "function") {
        callBackRef.current();
      }
    };
  }, []);
};

export default useUnmount;
