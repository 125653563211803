import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { regionSel } from "../../../store/selectors/regionSelectors";
import { setSortFieldAC } from "../../../store/actions/regionActions";
import { iconResolver } from "../../helpers";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Icon } from "@skyportal/ui-kit";

import styles from "./styles";

const useStyles = makeStyles(styles);

const RegionsTableHead = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("regionPage");
  const { sortField, sortOrder } = useSelector(regionSel);
  const classes = useStyles();
  const changeSortBy = (sortFiled) => dispatch(setSortFieldAC(sortFiled));

  return (
    <div>
      <div className={classes.sorterBar}>
        <div
          className={classnames(classes.cell, classes.sorterCell, classes.newRegionBar)}
          onClick={() => changeSortBy("code")}
        >
          <span className={classes.sorterCellIcon}>
            <Icon type="fontawesome" icon={iconResolver(sortOrder, sortField, "code")} />
          </span>
          <span className={classes.sorterCellText}>{t("tableColumns.code")}</span>
        </div>
        <div className={classnames(classes.cell, classes.sorterCell)} onClick={() => changeSortBy("name")}>
          <span className={classes.sorterCellIcon}>
            <Icon type="fontawesome" icon={iconResolver(sortOrder, sortField, "name")} />
          </span>
          <span className={classes.sorterCellText}>{t("tableColumns.name")}</span>
        </div>
        <div
          className={classnames(classes.cell, classes.sorterCell)}
          data-sorter="datacenter"
          onClick={() => changeSortBy("datacenters")}
        >
          <span className={classes.sorterCellIcon}>
            <Icon type="fontawesome" icon={iconResolver(sortOrder, sortField, "datacenters")} />
          </span>
          <span className={classes.sorterCellText}>{t("tableColumns.datacenters")}</span>
        </div>
        <div className={classnames(classes.cell, classes.sorterCell)} />
      </div>
    </div>
  );
};

export default RegionsTableHead;
