import { withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

const LinearProgressBar = withStyles(() => ({
  root: {
    height: 8,
    borderRadius: 4,
  },
  colorPrimary: {
    backgroundColor: "#FAEBE5",
  },
  bar: {
    borderRadius: 4,
    backgroundColor: "#E59A7F",
  },
}))(LinearProgress);

export default LinearProgressBar;
