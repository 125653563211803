export const NOTIFICATION = "NOTIFICATION";

export const GET_REGIONS_REQUEST = "GET_REGIONS_REQUEST";
export const GET_REGIONS_REQUEST_SUCCESS = "GET_REGIONS_REQUEST_SUCCESS";
export const GET_REGIONS_REQUEST_FAILURE = "GET_REGIONS_REQUEST_FAILURE";
export const CREATE_REGION = "CREATE_REGION";
export const UPDATE_REGION = "UPDATE_REGION";
export const DELETE_REGION = "DELETE_REGION";

export const SET_REGION_SORT_FIELD = "SET_REGION_SORT_FIELD";
export const SET_REGION_CURRENT_PAGE = "SET_REGION_CURRENT_PAGE";
export const SET_REGION_SEARCH_TEXT = "SET_REGION_SEARCH_TEXT";
export const SET_REGION_PER_PAGE = "SET_REGION_PER_PAGE";

export const GET_DATACENTERS_REQUEST = "GET_DATACENTERS_REQUEST";
export const GET_ENABLED_DATACENTERS_REQUEST_SUCCESS = "GET_ENABLED_DATACENTERS_REQUEST_SUCCESS";
export const GET_ALL_DATACENTERS_REQUEST_SUCCESS = "GET_ALL_DATACENTERS_REQUEST_SUCCESS";
export const GET_DATACENTERS_REQUEST_FAILURE = "GET_DATACENTERS_REQUEST_FAILURE";
export const CREATE_DATACENTER_REQUEST = "CREATE_DATACENTER_REQUEST";
export const CREATE_DATACENTER_REQUEST_SUCCESS = "CREATE_DATACENTER_REQUEST_SUCCESS";
export const CREATE_DATACENTER_REQUEST_FAILED = "CREATE_DATACENTER_REQUEST_FAILED";
export const UPDATE_DATACENTER_REQUEST = "UPDATE_DATACENTER_REQUEST";
export const UPDATE_DATACENTER_REQUEST_SUCCESS = "UPDATE_DATACENTER_REQUEST_SUCCESS";
export const UPDATE_DATACENTER_REQUEST_FAILED = "UPDATE_DATACENTER_REQUEST_FAILED";
export const GET_DATACENTER_SETTINGS_REQUEST = "GET_DATACENTER_SETTINGS_REQUEST";
export const GET_DATACENTER_SETTINGS_REQUEST_SUCCESS = "GET_DATACENTER_SETTINGS_REQUEST_SUCCESS";
export const GET_DATACENTER_SETTINGS_REQUEST_FAILURE = "GET_DATACENTER_SETTINGS_REQUEST_FAILURE";
export const SET_DATACENTER_SETTINGS_REQUEST = "SET_DATACENTER_SETTINGS_REQUEST";
export const SET_DATACENTER_SETTINGS_REQUEST_SUCCESS = "SET_DATACENTER_SETTINGS_REQUEST_SUCCESS";
export const SET_DATACENTER_SETTINGS_REQUEST_FAILURE = "SET_DATACENTER_SETTINGS_REQUEST_FAILURE";

export const SET_DATACENTER_SORT_FIELD = "SET_DATACENTER_SORT_FIELD";
export const SET_DATACENTER_CURRENT_PAGE = "SET_DATACENTER_CURRENT_PAGE";
export const SET_DATACENTER_SEARCH_TEXT = "SET_DATACENTER_SEARCH_TEXT";
export const SET_DATACENTER_PER_PAGE = "SET_DATACENTER_PER_PAGE";

export const DELETE_DATACENTER_REQUEST = "DELETE_DATACENTER_REQUEST";
export const DELETE_DATACENTER_REQUEST_SUCCESS = "DELETE_DATACENTER_REQUEST_SUCCESS";
export const DELETE_DATACENTER_REQUEST_FAILED = "DELETE_DATACENTER_REQUEST_FAILED";
export const NEW_DC_TOGGLE_MODAL = "NEW_DC_TOGGLE_MODAL";
export const UPDATE_IMAGES_ERROR = "UPDATE_IMAGES_ERROR";

export const VM_CREATE_PENDING = "VM_CREATE_PENDING";
export const VM_UPDATE_PENDING = "VM_UPDATE_PENDING";
export const SET_VMS = "SET_VMS";
export const VM_LIST_REQUEST = "VM_LIST_REQUEST";
export const VM_LIST_REQUEST_FAILURE = "VM_LIST_REQUEST_FAILURE";
export const FILTER_VMS = "FILTER_VMS";
export const SORT_VMS = "SORT_VMS";
export const SET_VM_FILTER_VALUE = "SET_VM_FILTER_VALUE";
export const SET_CURRENT_VM_PAGE = "SET_CURRENT_VM_PAGE";
export const SET_VM_PER_PAGE = "SET_VM_PER_PAGE";
export const SET_VM_DC_INCLUDE = "SET_VM_DC_INCLUDE";
export const TOGGLE_VM_COLUMN = "TOGGLE_VM_COLUMN";
export const SELECT_VM = "SELECT_VM";
export const DELETE_VM = "DELETE_VM";
export const SET_TO_RUNNING = "SET_TO_RUNNING";
export const SET_TO_STOPPED = "SET_TO_STOPPED";
export const SET_TO_PENDING = "SET_TO_PENDING";
export const SET_REFRESH_TIMER_VALUE = "SET_REFRESH_TIMER_VALUE";
export const TOGGLE_SELECT_ALL_VMS = "TOGGLE_SELECT_ALL_VMS";
export const DELETE_SNAPSHOT = "DELETE_SNAPSHOT";
export const CREATE_SNAPSHOT = "CREATE_SNAPSHOT";
export const SET_SNAPSHOT_DISABLE = "SET_SNAPSHOT_DISABLE";
export const UNSET_SNAPSHOT_DISABLE = "UNSET_SNAPSHOT_DISABLE";
export const GET_SNAPSHOTS = "GET_SNAPSHOTS";
export const SAVE_VM = "SAVE_VM";
export const EDIT_VM = "EDIT_VM";
export const SET_IMAGES = "SET_IMAGES";
export const SET_GROUPS = "SET_GROUPS";
export const ERROR_ON_SET_GROUPS = "ERROR_ON_SET_GROUPS";
export const CREATE_IMAGE = "CREATE_IMAGE";
export const CREATE_DISK_PENDING = "CREATE_DISK_PENDING";
export const UPDATE_DISK_PENDING = "UPDATE_DISK_PENDING";
export const TOGGLE_IMAGE_MODAL_STATE = "TOGGLE_IMAGE_MODAL_STATE";
export const SET_IMAGE_FOR_EDIT = "SET_IMAGE_FOR_EDIT";
export const IMAGE_TIER_LIST_REQUEST = "IMAGE_TIER_LIST_REQUEST";
export const IMAGE_TIER_LIST_REQUEST_SUCCESS = "IMAGE_TIER_LIST_REQUEST_SUCCESS";

export const SET_IMAGE_CURRENT_PAGE = "SET_IMAGE_CURRENT_PAGE";
export const SET_IMAGE_PER_PAGE = "SET_IMAGE_PER_PAGE";
export const SET_SEARCH_TEXT_PAGE = "SET_SEARCH_TEXT_PAGE";
export const SET_IMAGE_SORT_ORDER_PAGE = "SET_IMAGE_SORT_ORDER_PAGE";
export const SET_IMAGE_SORT_FIELD_PAGE = "SET_IMAGE_SORT_FIELD_PAGE";
export const RESET_IMAGE_SORT = "RESET_IMAGE_SORT";
export const DISK_LIST_PENDING = "DISK_LIST_PENDING";

export const UPDATE_IMAGE = "UPDATE_IMAGE";
export const UPDATE_IMAGES = "UPDATE_IMAGES";
export const CLOSE_IMAGE_MODAL = "CLOSE_IMAGE_MODAL";
export const DELETE_IMAGE = "DELETE_IMAGE";
export const TOGGLE_IMAGE_COLUMN = "TOGGLE_IMAGE_COLUMN";
export const SET_MAX_IMAGE_SIZE = "SET_MAX_IMAGE_SIZE";
export const SET_IMAGE_DC_INCLUDE = "SET_IMAGE_DC_INCLUDE";

export const SET_VM_EC_NAME = "SET_VM_EC_NAME";
export const SET_VM_EC_GROUP = "SET_VM_EC_GROUP";
export const SET_VM_EC_TIER = "SET_VM_EC_TIER";
export const SET_VM_EC_SIZE = "SET_VM_EC_SIZE";
export const SET_VM_EC_INPUT_SIZE = "SET_VM_EC_INPUT_SIZE";
export const GET_VM_EC_TEMPLATES = "GET_VM_EC_TEMPLATES";

export const VM_VNC_URL_REQUEST = "VM_VNC_URL_REQUEST";
export const VM_VNC_URL_REQUEST_SUCCESS = "VM_VNC_URL_REQUEST_SUCCESS";
export const VM_VNC_URL_REQUEST_FAILURE = "VM_VNC_URL_REQUEST_FAILURE";
export const VM_VNC_URL_RESET = "VM_VNC_URL_RESET";

export const NETWORK_LIST_REQUEST_SUCCESS = "NETWORK_LIST_REQUEST_SUCCESS";
export const SET_NETWORK_MODAL_SEARCH_TEXT = "SET_NETWORK_MODAL_SEARCH_TEXT";
export const SET_NETWORK_MODAL_CURRENT_PAGE = "SET_NETWORK_MODAL_CURRENT_PAGE";
export const SET_NETWORK_MODAL_SORT_FIELD = "SET_NETWORK_MODAL_SORT_FIELD";

export const SET_REPORTS = "SET_REPORTS";
export const REPORTS_REQUEST = "REPORTS/REPORTS_REQUEST";
export const SET_DETAILED_REPORTS = "REPORTS/SET_DETAILED_REPORTS";
export const DETAILED_REPORTS_REQUEST = "REPORTS/DETAILED_REPORTS_REQUEST";
export const REPORTS_ERROR = "REPORTS_ERROR";
export const FILTER_REPORTS = "FILTER_REPORTS";

export const SORT_REPORTS = "SORT_REPORTS";
export const SET_REPORT_FILTER_VALUE = "SET_REPORT_FILTER_VALUE";
export const SET_REPORTS_PER_PAGE = "SET_REPORTS_PER_PAGE";
export const RESET_REPORTS_SORT_STATE = "RESET_REPORTS_SORT_STATE";
export const SET_CURRENT_REPORT_PAGE = "SET_CURRENT_REPORT_PAGE";
export const SET_REPORT_FILTER_TYPE = "REPORTS/SET_REPORT_FILTER_TYPE";
export const SET_REPORT_GROUP = "REPORTS/SET_REPORT_GROUP";
export const SET_REPORT_FILTER_STATUSES = "REPORTS/SET_REPORT_FILTER_STATUSES";
export const SET_REPORT_START_DATE = "REPORTS/SET_REPORT_START_DATE";
export const SET_REPORT_DATACENTER = "REPORTS/SET_REPORT_DATACENTER";
export const STORAGE_DETAILS_REPORTS_REQUEST = "REPORTS/STORAGE_DETAILS_REPORTS_REQUEST";
export const STORAGE_DETAILS_REPORTS_REQUEST_SUCCESS = "REPORTS/STORAGE_DETAILS_REPORTS_REQUEST_SUCCESS";
export const STORAGE_DETAILS_REPORTS_REQUEST_FAILURE = "REPORTS/STORAGE_DETAILS_REPORTS_REQUEST_FAILURE";
export const SET_REPORT_END_DATE = "REPORTS/SET_REPORT_END_DATE";
export const SET_DETAILED_DATE = "REPORTS/SET_DETAILED_DATE";
export const SEND_EMAIL_REPORT_REQUEST = "SEND_EMAIL_REPORT_REQUEST";
export const SEND_EMAIL_REPORT_REQUEST_SUCCESS = "SEND_EMAIL_REPORT_REQUEST_SUCCESS";
export const SEND_EMAIL_REPORT_REQUEST_FAILED = "SEND_EMAIL_REPORT_REQUEST_FAILED";

export const SET_USER_IS_LOGGED_IN = "SET_USER_IS_LOGGED_IN";
export const SET_USER_PROFILE = "SET_USER_PROFILE";

export const CONSUMPTION_REQUEST = "CONSUMPTION_REQUEST";
export const CONSUMPTION_REQUEST_SUCCESS = "CONSUMPTION_REQUEST_SUCCESS";
export const CONSUMPTION_REQUEST_FAILED = "CONSUMPTION_REQUEST_FAILED";

export const STORED_REQUEST = "STORED_REQUEST";
export const STORED_REQUEST_SUCCESS = "STORED_REQUEST_SUCCESS";
export const STORED_REQUEST_FAILED = "STORED_REQUEST_FAILED";

export const ALL_METRICS_REQUEST = "ALL_METRICS_REQUEST";
export const ALL_METRICS_REQUEST_SUCCESS = "ALL_METRICS_REQUEST_SUCCESS";
export const ALL_METRICS_REQUEST_FAILED = "ALL_METRICS_REQUEST_FAILED";

export const DASHBOARD_EVENT_TABLE_SET_SORT_FIELD = "DASHBOARD_EVENT_TABLE_SET_SORT_FIELD";

export const EVENT_HISTORY_REQUEST = "EVENT_HISTORY_REQUEST";
export const EVENT_HISTORY_REQUEST_SUCCESS = "EVENT_HISTORY_REQUEST_SUCCESS";
export const EVENT_HISTORY_REQUEST_FAILED = "EVENT_HISTORY_REQUEST_FAILED";
export const EVENT_HISTORY_SET_SORT_FIELD = "EVENT_HISTORY_SET_SORT_FIELD";
export const EVENT_HISTORY_SET_PER_PAGE = "EVENT_HISTORY_SET_PER_PAGE";
export const EVENT_HISTORY_SET_PAGE = "EVENT_HISTORY_SET_PAGE";
export const EVENT_HISTORY_SET_FILTERS = "EVENT_HISTORY_SET_FILTERS";
export const EVENT_HISTORY_RESET_FILTERS = "EVENT_HISTORY_RESET_FILTERS";
export const EVENT_HISTORY_SET_DATE_RANGE = "EVENT_HISTORY_SET_DATE_RANGE";
export const EVENT_HISTORY_SET_DC_INCLUDE = "EVENT_HISTORY_SET_DC_INCLUDE";

export const CONTRACT_PRICELIST_REQUEST = "CONTRACT_PRICELIST_REQUEST";
export const CONTRACT_PRICELIST_REQUEST_SUCCESS = "CONTRACT_PRICELIST_REQUEST_SUCCESS";
export const CONTRACT_PRICELIST_REQUEST_FAILURE = "CONTRACT_PRICELIST_REQUEST_FAILURE";
export const RESET_PRICELIST_DATA = "RESET_PRICELIST_DATA";
export const SET_PRICELIST_SORT_FIELD = "SET_PRICELIST_SORT_FIELD";
export const SET_PRICELIST_CURRENT_PAGE = "SET_PRICELIST_CURRENT_PAGE";
