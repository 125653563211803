import React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";
import { composeEventStatusLabel, isActionCompeted } from "../../utils/eventHistory";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {ActionHistory} event
 * @return {JSX.Element}
 * @constructor
 */
const ActionStatusBadge = ({ event }) => {
  const classes = useStyles();
  const isCompeted = isActionCompeted(event);

  return (
    <span
      className={cn(classes.badge, {
        [classes.success]: isCompeted,
        [classes.failed]: !isCompeted,
      })}
    >
      {composeEventStatusLabel(event)}
    </span>
  );
};

export default ActionStatusBadge;
