import React, { useMemo } from "react";
import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner } from "@skyportal/ui-kit";
import { getTopMemoryVmList } from "../../../../store/selectors/dashboardSelectors";
import useAllMetricsRequestPending from "../../../../hooks/useAllMetricsRequestPending";
import styles from "../../../../pages/Dashboard/styles";
import { composeChartData } from "./utils";
import UsedMemoryPie from "./UsedMemoryPie";

const useStyles = makeStyles(styles);

const TopUsedMemoryCard = () => {
  const { t } = useTranslation("topUsedMemoryBlock");
  const classes = useStyles();
  const topMemoryVmList = useSelector(getTopMemoryVmList);
  const chartData = useMemo(() => composeChartData(topMemoryVmList), [topMemoryVmList]);

  return (
    <Card className={classes.smallCard}>
      <Spinner show={useAllMetricsRequestPending()} />
      <CardHeader
        title={t("title")}
        subheader={t("hint")}
        className={classes.basicHeader}
        classes={{
          title: classes.headerTitle,
          subheader: classes.subHeader,
        }}
      />

      <CardContent classes={{ root: classes.cardContent }}>
        <UsedMemoryPie data={chartData} />
      </CardContent>
    </Card>
  );
};

export default TopUsedMemoryCard;
