import { getRegions, updateRegion, createNewRegion, deleteRegion } from "../../api/regionService";
import {
  GET_REGIONS_REQUEST_SUCCESS,
  UPDATE_REGION,
  CREATE_REGION,
  DELETE_REGION,
  SET_REGION_SORT_FIELD,
  SET_REGION_CURRENT_PAGE,
  SET_REGION_SEARCH_TEXT,
  SET_REGION_PER_PAGE,
  GET_REGIONS_REQUEST,
  GET_REGIONS_REQUEST_FAILURE,
} from "../types";
import { showDirectoryApiErrorNotification } from "../../api/utils";
import { notificationAC } from "./notificationActions";
import i18n from "../../locales";

/**
 * @param {RegionSortField} payload
 */
export const setSortFieldAC = (payload) => ({
  type: SET_REGION_SORT_FIELD,
  payload,
});

/**
 * @param {number} payload
 */
export const setCurrentPageAC = (payload) => ({
  type: SET_REGION_CURRENT_PAGE,
  payload,
});

/**
 * @param {string} payload
 * @return {{payload: string, type: "SET_REGION_SEARCH_TEXT"}}
 */
export const setSearchTextAC = (payload) => ({
  type: SET_REGION_SEARCH_TEXT,
  payload,
});

/**
 * @param {PaginationStep} payload
 * @return {{payload: PaginationStep, type: "SET_REGION_PER_PAGE"}}
 */
export const setPerPageAC = (payload) => ({
  type: SET_REGION_PER_PAGE,
  payload,
});

const formatOnSuccess = (response) =>
  response.data.map((r) => {
    const formatted = { ...r };
    delete formatted._id;
    formatted.id = r._id;
    return formatted;
  });

const getRegionsRequestAC = () => ({ type: GET_REGIONS_REQUEST });
const getRegionsRequestFailureAC = () => ({ type: GET_REGIONS_REQUEST_FAILURE });

/**
 * @param {RegionStateModel[]} regions
 * @return {{payload: RegionStateModel[], type: string}}
 */
const getRegionsRequestSuccessAC = (regions) => ({
  type: GET_REGIONS_REQUEST_SUCCESS,
  payload: regions,
});

export const getRegionsAct = () => async (dispatch) => {
  try {
    dispatch(getRegionsRequestAC());
    dispatch(getRegionsRequestSuccessAC(formatOnSuccess(await getRegions())));
  } catch (error) {
    showDirectoryApiErrorNotification(error, dispatch);
    dispatch(getRegionsRequestFailureAC());
  }
};

export const updateRegionAct = (payload) => async (dispatch) => {
  try {
    await updateRegion(payload);
    dispatch({ type: UPDATE_REGION, payload });
    dispatch(notificationAC(i18n.t("notification:regionUpdateSuccess")));
  } catch (error) {
    showDirectoryApiErrorNotification(error, dispatch);
    throw error;
  }
};

export const createNewRegionAct = (payload) => async (dispatch) => {
  try {
    const response = await createNewRegion(payload);
    dispatch({
      type: CREATE_REGION,
      payload: {
        ...payload,
        id: response.data._id,
      },
    });
    dispatch(notificationAC(i18n.t("notification:regionCreateSuccess")));
  } catch (error) {
    showDirectoryApiErrorNotification(error, dispatch);
    throw error;
  }
};

export const deleteRegionAct = (region) => async (dispatch) => {
  try {
    await deleteRegion(region);
    dispatch({ type: DELETE_REGION, payload: region });
    dispatch(notificationAC(i18n.t("notification:regionDeleteSuccess")));
  } catch (error) {
    showDirectoryApiErrorNotification(error, dispatch);
    throw error;
  }
};
