const style = (theme) => ({
  root: {
    padding: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  navBtn: {
    display: "block",
    width: "40px",
    height: "40px",
    outline: "none",
    border: 0,

    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.1) !important",
    },
  },
  icon: {
    width: "15px",
    height: "15px",
    color: theme.colors.black,
  },
  label: {
    textAlign: "center",
    fontSize: theme.fonts.smaller,
    color: theme.colors.black,
    flexGrow: 1,
  },
  calendarIcon: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "10px",
  },

  inputMask: {
    position: "relative",
    height: "32px",
    minWidth: "120px",
    backgroundColor: theme.colors.grey,
    "&:hover": {
      cursor: "pointer",
    },
    "@global": {
      ".DayPickerInput": {
        height: "100%",
        backgroundColor: theme.colors.grey,
        flexGrow: 1,
      },
      ".DayPicker-Caption": {
        display: "none !important",
      },
      ".DayPicker-Month": {
        margin: 0,
      },
      ".DayPicker-Weekday": {
        color: theme.colors.black,
        fontSize: "12px",
        lineHeight: "16px",
      },
      ".DayPicker-wrapper": {
        backgroundColor: theme.colors.grey,
        borderRadius: "0",
        fontFamily: theme.typography.fontFamily,
        paddingBottom: 0,
        width: "280px",
      },
      ".DayPicker-Day--outside": {
        color: "#8D8D8D !important",
      },
      ".DayPicker-Day": {
        width: "40px",
        height: "40px",
        backgroundColor: theme.colors.grey,
        padding: 0,
        color: theme.colors.black,
        fontSize: "12px",
        lineHeight: "16px",
        borderRadius: "2px",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.1) !important",
          color: "#000000 !important",
        },
      },
      ".DayPicker-Day--selected": {
        backgroundColor: "rgba(0, 0, 0, 0.1) !important",
        color: "#000000 !important",
      },
      ".DayPicker-Day--today": {
        fontWeight: "600",
      },
      ".DayPickerInput > input": {
        border: "0",
        height: "100%",
        backgroundColor: "transparent",
        outline: "none",
        paddingLeft: "30px",
        fontSize: "14px",
      },
    },
  },
});

export default style;
