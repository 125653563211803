import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Dustin Sans",
    fontSize: 16,
  },
  palette: {
    primary: {
      main: "#44d9fc",
    },
  },
  colors: {
    grey: "#f0f0f0",
    inactiveGrey: "#8d8d8d",
    focusedGrey: "#d8d8d8",
    lightGrey: "#f4f4f4",
    darkGrey: "#4f4f4f",
    roadGrey: "#7b7b7b",
    black: "#222",
    blackighter: "#2e2e2e",
    lightGreen: "#4ef09d",
    grassGreen: "#4ef09d",
    shadowedGreen: "#3fbf7b",
    darkGreen: "#1f603f",
    shadowedBlue: "#35accd",
    skyBlue: "#44d9fc",
    skyBlueAlt: "#41D7FC",
    navyBlue: "#19495e",
    lightRed: "#ff5a5a",
    shadowedRed: "#ca4548",
    ruby: "#6d000b",
    pink: "#ffd0d0",
    lightBrown: "#e59a7f",
    brown: "#5c3e33",
    shadowedBrown: "#b57862",
    notificationRed: "#d32f2f",
  },
  classes: {
    centralized: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    page: {
      boxSizing: "border-box",
      padding: "0 15px 10px",
    },
    pageBox: {
      backgroundColor: "#fff",
      borderTop: "4px solid #B4B4B4",
      borderRadius: "4px",
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
      paddingTop: "15px",
    },
    header: {
      height: "55px",
      justifyContent: "space-between",
    },
    table: {
      controls: {
        // need to add centralized
        justifyContent: "space-between",
        flexDirection: "row",
        padding: "4px 10px",
        // need to add fontSize: theme.fonts.smaller,
      },
      tableSearchLabel: {
        marginRight: "16px",
      },
      headerRowCell: {
        padding: "4px 10px",
        borderBottom: "none",
      },
      sorterCell: {
        borderBottom: `3px solid #f4f4f4`,
        "&:hover": {
          cursor: "pointer",
          opacity: "0.75",
        },
      },
      sorterCellIcon: {
        marginRight: "10px",
        // need to add color: theme.colors.inactiveGrey,
      },
      sorterCellText: {
        fontWeight: "bold",
      },
      pagingContainer: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: "white",
        padding: "16px 10px",
      },
      cell: {
        padding: "8px 8px",
        borderBottom: `1px solid #f4f4f4`,
      },
      cellShell: {
        width: "100%",
        maxHeight: "50px",
        overflow: "hidden",
        marginLeft: "25px",
      },
      inCell: {
        overflow: "hidden",
        display: "inline-block",
        maxHeight: "50px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "95%",
        position: "relative",
      },
    },
    elems: {
      cbContainer: {
        display: "inline-block",
        position: "relative",
        cursor: "pointer",
        height: "16px",
        width: "16px",
        border: "1px solid black",
      },
      cbInput: {
        position: "absolute",
        opacity: "0",
        cursor: "pointer",
        height: "16px",
        width: "16px",
      },
      cbCheckmark: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%) scale(0)",
        height: "70%",
        width: "70%",
        backgroundColor: "#000",
      },
      modalHeader: {
        padding: "16px",
        fontSize: "18px",
        fontWeight: "700",
      },
      card: {
        display: "flex",
        flexDirection: "column",
      },
      cardRow: {
        minHeight: "80px",
        display: "flex",
        flexDirection: "column",
      },
    },
    modal: {
      modalBody: {
        position: "absolute",
        width: "360px",
        left: "50%",
        marginLeft: "-180px",
        top: "50%",
        marginTop: "-105px",
        height: "210px",
        backgroundColor: "white",
        "&:focus": {
          border: "none",
          outline: "none",
        },
      },
      modalHeader: {
        padding: "16px",
        fontSize: "28px",
        fontWeight: "700",
        textAlign: "center",
      },
      cardFooter: {
        display: "flex",
        alignItems: "center",
        padding: "24px",
        justifyContent: "center",
      },
      cancelDeleteBtn: {
        marginRight: "16px",
        backgroundColor: "black",
        color: "white",
      },
    },
    dropDown: {
      muiSelectRoot: {
        paddingLeft: "16px",
        ".MuiList-root.MuiMenu-list.MuiList-padding": {
          padding: "0",
        },
      },
      muiSelectIcon: {
        top: "50%",
        transform: "translateY(-50%)",

        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#b4b4b4",
          color: "white",
          fill: "white",
        },
      },
      muiMenuItem: {
        height: "30px",
        fontFamily: `"Dustin Sans", serif`,

        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#b4b4b4",
          fill: "white",
        },
      },
    },
    card: {
      basic: {
        body: {
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
        },
        header: {
          borderTop: "4px solid #B4B4B4",
          borderBottom: "1px solid #F4F4F4",
          boxSizing: "border-box",
        },
      },
      small: {
        width: "100%",
        minHeight: "325px",
      },
      big: {
        width: "100%",
        minHeight: "325px",
      },
    },
  },

  fonts: {
    small: "12px",
    smaller: "14px",
    normal: "16px",
    bigger: "24px",
  },
});

export default theme;
