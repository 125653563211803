const styles = (theme) => ({
  page: theme.classes.page,
  modalBody: {
    backgroundColor: "white",
    position: "absolute",
    width: "90%",
    maxWidth: "1000px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  modalHeader: {
    ...theme.classes.elems.modalHeader,
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
    display: "flex",

    "@media(max-width: 767px)": {
      flexWrap: "wrap",

      "& > h2": {
        marginBottom: "8px",
      },

      "& > *": {
        flexGrow: 1,
      },
    },
  },
  modalTitle: {
    flexGrow: 1,
    fontSize: "20px",
    lineHeight: "24px",
    margin: 0,
  },
  headCell: {
    width: "15% !important",

    "&:nth-child(1)": {
      width: "30% !important",
    },

    "&:nth-child(5)": {
      width: "20% !important",
    },
    "&:nth-child(6)": {
      width: "0% !important",
    },
  },
  modalImageRow: {
    display: "flex",
  },
  tableBody: {
    padding: "15px",
  },
  table: {
    fontSize: "14px",
  },
  cell: {
    ...theme.classes.table.cell,
    height: "50px",
    width: "100%",
    display: "flex",
    fontSize: theme.fonts.smaller,
    padding: "0 10px",
    borderBottom: "none",
  },
  cellShell: {
    ...theme.classes.table.cellShell,
    marginLeft: "0",
    display: "flex",
    alignItems: "center",
    width: "20%",
    flexShrink: 0,
  },
  inCell: theme.classes.table.inCell,
  name: {
    width: "30%",
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
  },
  middle: {
    width: "15%",
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
  },
  created: {
    width: "20%",
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
  },
  checkboxCell: {
    textAlign: "center",

    "& span": {
      color: "black !important",
    },
  },
  pagingContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: "32px 24px 24px",
    justifyContent: "space-between",

    "@media(max-width: 767px)": {
      padding: "16px",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,

    "@media(max-width: 600px)": {
      marginBottom: "8px",
      order: -1,
    },
  },
  imageModalActions: {
    display: "flex",
    margin: "-8px",

    "& > button": {
      flexShrink: 0,
      margin: "8px",
      marginRight: 0,
    },

    "@media(max-width: 767px)": {
      flexWrap: "wrap",
      flexGrow: 1,
      paddingBottom: "8px",

      "& > button": {
        minWidth: "180px",
        flexGrow: 1,
      },
    },
  },
  scrollContainer: {
    width: "100%",

    "@media(max-width: 767px)": {
      overflow: "auto",
    },
  },
  listTable: {
    "@media(max-width: 767px)": {
      width: "600px",
    },
  },
  selectedImgBar: {
    width: "100%",
    boxSizing: "border-box",
    padding: "24px 8px 8px",
    display: "flex",
    fontSize: "14px",
    borderBottom: `3px solid ${theme.colors.lightGrey}`,
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  selectedImgRow: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    height: "50px",
    padding: "0 8px",
    fontSize: "14px",
  },
  selImgName: {
    width: "40%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",
    overflow: "hidden",
  },
  selImgMiddle: {
    width: "18%",
  },
  selImgCross: {
    display: "flex",
    justifyContent: "flex-end",
    width: "30px",
    margin: "4px",
  },
  templateSelect: {
    width: "100%",
  },
  templateShell: {
    display: "flex",
    width: "100%",
    backgroundColor: "white",
    alignItems: "center",
    fontSize: theme.fonts.smaller,
    padding: "6px 16px",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#F0F0F0",
    },
  },
  imgBox: {
    width: "60px",
    flexShrink: 0,
    flexGrow: 0,
    overflow: "hidden",
    marginRight: "15px",
  },
  templateImage: {
    width: "50px",
    height: "50px",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  templateInfoContainer: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 0,
    flexWrap: "wrap",
    alignItems: "center",
  },
  infoText: {
    margin: "3px",
    flexGrow: 0,
    flexShrink: 0,
    fontSize: "12px",
  },
  templateHeader: {
    fontWeight: "600",
    width: "100%",
    flexGrow: 0,
    flexShrink: 0,
    fontSize: "16px",
  },
  fullWidthInput: {
    width: "100%",
  },
});

export default styles;

export const formStyles = (theme) => ({
  page: {
    ...theme.classes.page,
  },
  titleWrap: {
    display: "inline-flex",
    alignItems: "center",
  },
  title: {
    marginRight: "10px",
  },
  formContainer: {
    ...theme.classes.pageBox,
    position: "relative",
    padding: "32px 96px",
    marginBottom: "15px",

    "@media(max-width: 767px)": {
      padding: "16px",
    },
  },
  rowContainer: {
    display: "flex",
    flexWrap: "nowrap !important",
    marginBottom: "32px",
    position: "relative",

    "@media(max-width: 767px)": {
      flexWrap: "wrap",
    },
  },
  rowLabelContainer: {
    width: "220px",
    marginRight: "24px !important",
  },
  rowFieldContainer: {
    flexDirection: "column",
  },
  rowTitle: {
    fontSize: "20px",
    lineHeight: "24px",
    margin: "0",
    marginBottom: "8px",
  },
  iconTooltip: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  rowTagline: {
    fontSize: "14px",
    lineHeight: "18px",
    color: "#8D8D8D",
  },
  fieldContainer: {
    marginBottom: "8px !important",
  },
  fieldLabel: {
    color: "#4F4F4F",
    fontSize: "12px",
    lineHeight: "16px",
    marginBottom: "8px",
    marginTop: "8px",
  },
  input: {
    boxSizing: "border-box",
    border: "none",
    fontSize: theme.fonts.smaller,
    backgroundColor: theme.colors.grey,
    "&::placeholder": {
      color: theme.colors.inactiveGrey,
    },
    "&::-ms-input-placeholder": {
      color: theme.colors.inactiveGrey,
    },
    borderRadius: "2px",
    lineHeight: "18px",
    textOverflow: "ellipsis",
    "& .MuiInput-root": {
      border: `1px solid white`,
      borderRadius: "2px",
    },
    "&  .MuiInput-root.Mui-focused": {
      border: `1px solid ${theme.colors.focusedGrey} !important`,
    },
    "& .Mui-disabled": {
      cursor: "not-allowed",
    },
    "& .MuiInputBase-input": {
      padding: "7px 0px",
    },
    "& .MuiFormHelperText-root": {
      color: theme.colors.lightBrown,
      backgroundColor: "white",
      margin: "0px",
    },
  },
  warningOutline: {
    "&  .MuiInput-root.Mui-focused": {
      border: `1px solid ${theme.colors.lightBrown} !important`,
    },
  },
  inputForDisks: {
    "& .MuiInputBase-input": {
      fontSize: "14px",
    },
    "& .MuiTypography-root": {
      fontSize: "14px",
      paddingLeft: "15px",
    },
  },
  inputField: {
    width: "100%",
    "&:disabled": {
      opacity: 0.6,
      outline: "none",
    },
  },
  inputAdornment: {
    marginRight: "0px",
  },
  checkboxBlock: {
    display: "flex",
    alignItems: "center",
    margin: "16px 0px 8px -5px",
  },
  checkboxLabel: {
    paddingLeft: "4px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  numberInputField: {
    width: "66px",
    marginLeft: "16px",
    padding: "6px",
    textAlign: "center",

    "&:focus": {
      padding: "6px",
    },
  },
  inputSuffixOffset: {
    marginRight: "30px",
  },
  slideWrapper: {
    display: "flex",
  },
  slideInputWrapper: {
    display: "flex",
    alignItems: "center",
  },
  slideInputDescription: {
    fonstSize: "12px",
    lineHeight: "16px",
    color: "#4F4F4F",
    marginLeft: "8px",
  },
  fieldButtonWrapper: {
    display: "flex",
    flexWrap: "wrap",
    margin: "-8px",
    padding: "8px 0",

    "& > *": {
      margin: "8px",

      "@media(max-width: 767px)": {
        flexGrow: 1,
      },
    },
  },
  buttonRowContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "-8px",

    "@media(max-width: 767px)": {
      flexWrap: "wrap",
    },

    "& > *": {
      margin: "8px",

      "@media(max-width: 767px)": {
        flexGrow: 1,
        minWidth: "150px",
      },
    },
  },
  addImageButton: {
    borderRadius: "2px",
    fontSize: theme.fonts.smaller,
    color: "white",
    backgroundColor: "black",

    "@media(max-width: 767px)": {
      flexGrow: 1,
      minWidth: "200px",
    },

    "&:disabled": {
      opacity: "0.5",
      cursor: "not-allowed",
    },
  },
  createImageButton: {
    borderRadius: "2px",
    fontSize: theme.fonts.smaller,
    color: theme.colors.darkGreen,
    backgroundColor: theme.colors.grassGreen,
  },
  sliderInner: {
    width: "100%",
    paddingRight: "20px",
  },
  slider: {
    width: "100%",
    color: "#8D8D8D",
  },
  sliderMarksWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  muiTemplateRoot: {
    minHeight: "32px",
    backgroundColor: theme.colors.lightGrey,
    fontSize: theme.fonts.smaller,
    width: "100%",
    margin: "0",
    padding: "0",
  },
  datacenterSelect: {
    width: "100%",
  },
  muiSelectRoot: {
    width: "100%",
    height: "100%",
    padding: "0 !important",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.colors.lightGrey,

    "&.Mui-disabled": {
      opacity: "0.5",
      cursor: "not-allowed",
    },
  },
  listItemRoot: {
    backgroundColor: "white",
    padding: "0",
    "&:hover": {
      opacity: "0.8",
    },
  },
  muiSelectIcon: {
    ...theme.classes.dropDown.muiSelectIcon,
    right: "6px",
  },
  groupsListRoot: {
    width: "100%",
    height: "32px",
  },
  networkListItem: {
    padding: "8px",
  },
  selectMenu: {
    maxHeight: "520px",
  },
  menuPaper: {
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px #c2c2c2",
      webkitBoxShadow: "inset 0 0 6px #c2c2c2",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,1)",
      borderRadius: "4px",
      outline: "1px solid slategrey",
    },
  },
  pricelistBtn: {
    marginBottom: "24px",
  },
});
