import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ActionStartTimeCell from "../components/ActionStartTimeCell";
import { getActionUsername } from "../utils/eventHistory";
import ActionStatusBadge from "../components/ActionStatusBadge";
import { getGroups } from "../store/selectors/groupSelectors";

/**
 * @return {ColumnConfig[]}
 */
const useEventHistoryColumnConfig = () => {
  const groups = useSelector(getGroups);
  const { t } = useTranslation("eventHistory");

  return useMemo(
    () =>
      [
        {
          key: "stime",
          label: t("tableColumns.time"),
          width: "20%",
          render: (data) => <ActionStartTimeCell event={data} />,
        },
        {
          key: "action_name",
          width: "20%",
          label: t("tableColumns.action"),
        },
        {
          key: "type",
          width: "10%",
          label: t("tableColumns.type"),
        },
        {
          key: "name",
          width: "15%",
          label: t("tableColumns.name"),
        },
        {
          key: "username",
          label: t("tableColumns.username"),
          width: "10%",
          render: getActionUsername,
        },
        groups.length > 1 && {
          key: "gdisplayname",
          width: "10%",
          label: t("tableColumns.group"),
        },
        {
          key: "reason",
          width: "10%",
          label: t("tableColumns.status"),
          render: (data) => <ActionStatusBadge event={data} />,
        },
      ].filter(Boolean),
    [t, groups]
  );
};

export default useEventHistoryColumnConfig;
