import React from "react";
import Select from "@material-ui/core/Select";
import { Slider } from "@material-ui/core";
import cn from "classnames";
import { Buffer } from "buffer";
import Autocomplete from "../../../ui-kit/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Input from "../../../ui-kit/Input";
import { Checkbox } from "@skyportal/ui-kit";
import { makeStyles } from "@material-ui/core/styles";

import { formStyles } from "./styles";

const useStyles = makeStyles(formStyles);

const textInputTypes = ["text", "text64", "password", "number", "float", "fixed"];
const htmlInputType = {
  text: "text",
  text64: "text",
  password: "password",
  number: "number",
  float: "number",
  fixed: "text",
};
const componentMapper = {
  list: ({ attributeConfig, value, classes, handleChange }) => (
    <Select
      id={attributeConfig.title}
      IconComponent={KeyboardArrowDownIcon}
      disableUnderline
      value={value}
      displayEmpty
      classes={{
        select: classes.muiSelectRoot,
        icon: classes.muiSelectIcon,
      }}
      className={classes.groupsListRoot}
      onChange={handleChange}
    >
      {attributeConfig.options?.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  ),
  "list-multiple": ({ attributeConfig, value, handleMultipleSelectChange }) => (
    <Autocomplete
      multiple
      disableClearable
      filterSelectedOptions
      value={value !== "" ? value.split(",") : []}
      options={attributeConfig.options}
      onChange={handleMultipleSelectChange}
    />
  ),
  boolean: ({ attributeConfig, classes, value, handleCheckboxClick }) => (
    <div className={classes.checkboxBlock}>
      <Checkbox size="small" checked={value === "true"} onChange={handleCheckboxClick} />
      <span className={classes.checkboxLabel} onClick={handleCheckboxClick}>
        {attributeConfig.title}
      </span>
    </div>
  ),
  range: ({ attributeConfig, value, classes, handleChange, handleSliderValueChange, handleSliderInputBlur }) => (
    <div className={classes.slideWrapper}>
      <div className={classes.sliderInner}>
        <Slider
          className={classes.slider}
          value={Number(value)}
          onChange={handleSliderValueChange}
          min={Number(attributeConfig.options[0]) || 0}
          max={Number(attributeConfig.options[1]) || 16}
          step={1}
          marks={[
            { value: attributeConfig.options[0] || 0, label: attributeConfig.options[0] || 0 },
            { value: attributeConfig.options[1] || 16, label: attributeConfig.options[1] || 16 },
          ]}
        />
      </div>
      <div className={classes.slideInputWrapper}>
        <Input
          className={cn(classes.numberInputField, classes.inputSuffixOffset)}
          value={value}
          type="number"
          onChange={handleChange}
          step={1}
          onBlur={handleSliderInputBlur}
        />
      </div>
    </div>
  ),
  "range-float": ({
    attributeConfig,
    value,
    classes,
    handleChange,
    handleSliderValueChange,
    handleSliderInputBlur,
  }) => (
    <div className={classes.slideWrapper}>
      <div className={classes.sliderInner}>
        <Slider
          className={classes.slider}
          value={Number(value)}
          onChange={handleSliderValueChange}
          min={Number(attributeConfig.options[0]) || 0}
          max={Number(attributeConfig.options[1]) || 16}
          step={0.01}
          marks={[
            { value: attributeConfig.options[0] || 0, label: attributeConfig.options[0] || 0 },
            { value: attributeConfig.options[1] || 16, label: attributeConfig.options[1] || 16 },
          ]}
        />
      </div>
      <div className={classes.slideInputWrapper}>
        <Input
          className={cn(classes.numberInputField, classes.inputSuffixOffset)}
          value={value}
          type="number"
          onChange={handleChange}
          step={0.01}
          onBlur={handleSliderInputBlur}
        />
      </div>
    </div>
  ),
};

/**
 * @param {object} [attribute]
 * @param {object} [value]
 * @param {function(value: string): void} onChange
 * @return {JSX.Element}
 * @constructor
 */
export const ControlForInputTypes = ({ attribute, value, onChange }) => {
  const [attributeName, attributeConfig] = attribute;
  const classes = useStyles();

  const handleChange = (event) => onChange(attributeName, event.target.value);
  const handleBase64InputChange = (event) => {
    const encodedString = Buffer.from(event.target.value).toString("base64");
    return onChange(attributeName, encodedString);
  };
  const handleMultipleSelectChange = (list) => onChange(attributeName, list.join());
  const handleCheckboxClick = () => onChange(attributeName, value === "true" ? "false" : "true");
  const handleSliderValueChange = (_, value) => onChange(attributeName, value.toString());
  const getValueInRange = (value) => {
    if (Number(value) < Number(attributeConfig.options[0])) {
      return attributeConfig.options[0];
    } else if (Number(value) > Number(attributeConfig.options[1])) {
      return attributeConfig.options[1];
    } else {
      return value;
    }
  };
  const handleSliderInputBlur = (event) => {
    const { value } = event.target;
    return onChange(
      attributeName,
      attributeConfig.type === "range-float" ? getValueInRange(value) : String(Math.round(getValueInRange(value)))
    );
  };

  if (componentMapper[attributeConfig.type]) {
    return componentMapper[attributeConfig.type]({
      attributeConfig,
      value,
      classes,
      handleChange,
      handleMultipleSelectChange,
      handleCheckboxClick,
      handleSliderValueChange,
      handleSliderInputBlur,
    });
  } else if (textInputTypes.includes(attributeConfig.type)) {
    return (
      <Input
        name={attributeName}
        className={classes.inputField}
        type={htmlInputType[attributeConfig.type]}
        step={attributeConfig.type === "float" ? "0.01" : null}
        disabled={attributeConfig.type === "fixed"}
        value={attributeConfig.type === "text64" ? Buffer.from(value, "base64").toString() : value}
        onChange={attributeConfig.type === "text64" ? handleBase64InputChange : handleChange}
      />
    );
  }

  return null;
};

export default React.memo(ControlForInputTypes);
