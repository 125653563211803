import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Layout from "../../layouts";
import useDatacenterOnLoad from "../../hooks/useDatacenterOnLoad";
import { getAllImagesAct } from "../../store/actions/imagesActions";
import { setAllVmsAct } from "../../store/actions/virtualMachinesActions";
import DisksContent from "./DisksContent/DisksContent";
import PageHeader from "../shareableComponents/PageHeader";

const Disks = () => {
  const { t } = useTranslation("imagesPage");
  const dispatch = useDispatch();

  useDatacenterOnLoad(() => {
    dispatch(getAllImagesAct());
    dispatch(setAllVmsAct());
  });

  return (
    <Layout.PageContent>
      <PageHeader title={t("title")} />
      <DisksContent />
    </Layout.PageContent>
  );
};

export default Disks;
