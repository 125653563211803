import React, { useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { makeChartOptions } from "./utils";

const UsedMemoryPie = ({ data }) => {
  const chartOptions = useMemo(() => makeChartOptions({ data }), [data]);

  if (!data.length) return null;

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default UsedMemoryPie;
