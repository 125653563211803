import Highcharts from "highcharts";
import { sortString } from "../../../../utils/sort";
import tooltipFormatter from "./formatters/tooltipFormatter";
import axisLabelFormatter from "./formatters/axisLabelFormatter";
import { ALL_KEY, CHART_COLOR_LIST, NO_TIER_KEY } from "./constants";

/**
 * @param {StoredMetric[]} storedMetrics
 * @return {object} key - tier label or All
 */
export const groupMetricsByTiers = (storedMetrics) =>
  storedMetrics.reduce(
    (acc, item) => {
      const timeStamp = Number(item._id);

      acc[ALL_KEY].push([timeStamp, item.image_size]);

      (item.tiers || []).forEach((tierItem) => {
        const tierName = tierItem.name && tierItem.name !== "null" ? tierItem.name : NO_TIER_KEY;

        if (!acc[tierName]) {
          acc[tierName] = [];
        }
        acc[tierName].push([timeStamp, tierItem.image_size]);
      });

      return acc;
    },
    { [ALL_KEY]: [] }
  );

/**
 * @param {StoredMetric[]} storedMetrics
 * @param {function} t - i18n.t
 */
export const composeStoredChartData = (storedMetrics, t) => {
  const metricsByTiers = groupMetricsByTiers(storedMetrics);

  return Object.keys(metricsByTiers)
    .map((name, index) => ({
      name,
      data: metricsByTiers[name],
      color: CHART_COLOR_LIST[index] || CHART_COLOR_LIST[0],
      visible: name === ALL_KEY,
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [
            0,
            Highcharts.Color(CHART_COLOR_LIST[index] || CHART_COLOR_LIST[0])
              .setOpacity(0.7)
              .get("rgba"),
          ],
          [
            1,
            Highcharts.Color(CHART_COLOR_LIST[index] || CHART_COLOR_LIST[0])
              .setOpacity(0)
              .get("rgba"),
          ],
        ],
      },
    }))
    .sort((a, b) => sortString(a.name, b.name))
    .sort((a) => (a.name === ALL_KEY ? -1 : 0))
    .map((item) => ({
      ...item,
      name: [ALL_KEY, NO_TIER_KEY].includes(item.name) ? t(`${item.name}`, { ns: "iaasCommon" }) : item.name,
    }));
};

export const makeChartOptions = ({ data }) => ({
  chart: {
    height: 246,
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "areaspline",
  },
  plotOptions: {
    areaspline: {
      marker: {
        enabled: false,
      },
    },
  },
  tooltip: {
    formatter: tooltipFormatter,
  },
  yAxis: {
    title: null,
    gridLineWidth: 0,
    gridLineColor: "#C4C4C4",
    labels: {
      formatter: axisLabelFormatter,
    },
  },
  xAxis: {
    title: null,
    type: "datetime",
    gridLineWidth: 1,
  },
  title: {
    text: null,
  },
  dataLabels: {
    enabled: false,
  },
  series: data,
  credits: {
    enabled: false,
  },
  legend: {
    align: "left",
    verticalAlign: "bottom",
  },
});
