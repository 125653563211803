const btn = {
  width: "92px",
  padding: "7px 24px",
  borderRadius: "2px",
};

const styles = (theme) => ({
  page: {
    ...theme.classes.pageBox,
    paddingLeft: "10px",
    paddingRight: "10px",
    position: "relative",
    minHeight: 400,
  },
  tableHeadConrols: {
    ...theme.classes.centralized,
    ...theme.classes.table.controls,
    fontSize: theme.fonts.smaller,
  },
  tableSearchLabel: theme.classes.table.tableSearchLabel,
  tableBody: {
    fontSize: theme.fonts.smaller,
  },
  headerRowCell: theme.classes.table.headerRowCell,
  sorterCell: theme.classes.table.sorterCell,
  sorterCellIcon: {
    ...theme.classes.table.sorterCellIcon,
    color: theme.colors.inactiveGrey,
    marginRight: "6px",
  },
  sorterCellText: {
    ...theme.classes.table.sorterCellText,
    textTransform: "capitalize",
    fontSize: theme.fonts.smaller,
  },
  pagingContainer: theme.classes.table.pagingContainer,
  cell: {
    ...theme.classes.table.cell,
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
    width: "16%",
  },
  cellBottomBorder: {
    borderBottom: `3px solid ${theme.colors.lightGrey}`,
  },
  modalBody: {
    position: "absolute",
    width: "calc(100% - 30px)",
    maxWidth: "600px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    maxHeight: "90vh",
    backgroundColor: "white",
    outline: "none",
    overflowY: "auto",
  },
  modalHeader: {
    ...theme.classes.elems.modalHeader,
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
  },
  datacenterLabel: {
    marginTop: 0,
    marginBottom: 0,
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  modalCard: {
    padding: "16px",
    display: "flex",
    flexWrap: "wrap",
  },
  sorterBar: {
    display: "flex",
  },
  card: theme.classes.elems.card,
  cardRow: {
    ...theme.classes.elems.cardRow,
    width: "100%",
    flexShrink: 0,
    padding: "0 10px",
    boxSizing: "border-box",
  },
  box50: {
    flexGrow: 1,
    minWidth: "250px",
  },
  cardRowLabel: {
    color: theme.colors.darkGrey,
    fontSize: theme.fonts.small,
    paddingTop: "24px",
    paddingBottom: "8px",
  },
  cardFooter: {
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: "16px",
    width: "100%",
    margin: "-8px",

    "& > button": {
      margin: "16px 0 8px 16px",

      "@media(max-width: 767px)": {
        flexGrow: 1,
        minWidth: "160px",
      },
    },
  },
  imageModalSlider: {
    display: "flex",
    alignItems: "center",
  },
  sliderBlock: {
    padding: "0",
    flexGrow: "1",
  },
  unableToIncreaseSizeText: {
    fontSize: "14px",
  },
  warningIconBlock: {
    margin: "6px -14px 0px 24px",
    color: theme.colors.lightBrown,
  },
  numberInputBlock: {
    marginLeft: "30px",
    minWidth: "96px",
  },
  sizeTextInputField: {
    width: "66px",
    marginRight: "8px",
    paddingRight: "10px",
    paddingLeft: "10px",

    "&:focus": {
      paddingRight: "10px",
      paddingLeft: "10px",
    },
  },
  sizeTextInputSpan: {
    color: theme.colors.darkGrey,
  },
  rail: {
    backgroundColor: theme.colors.grey,
  },
  blackBg: {
    backgroundColor: "black",
  },
  disabledBg: {
    backgroundColor: theme.colors.focusedGrey,
  },
  createBtn: {
    ...btn,
    fontSize: theme.fonts.smaller,
    color: theme.colors.darkGreen,
    backgroundColor: theme.colors.grassGreen,
    marginRight: "16px",
  },
  saveBtn: {
    ...btn,
    fontSize: theme.fonts.smaller,
    color: theme.colors.navyBlue,
    backgroundColor: theme.colors.skyBlue,
    marginRight: "16px",
  },
  cancelBtn: {
    ...btn,
    fontSize: theme.fonts.smaller,
    color: theme.colors.ruby,
    backgroundColor: theme.colors.lightRed,
  },
  muiSelectRoot: {
    ...theme.classes.dropDown.muiSelectRoot,
    backgroundColor: theme.colors.lightGrey,
    fontSize: theme.fonts.smaller,
    marginRight: "30px",
  },
  muiSelectIcon: theme.classes.dropDown.muiSelectIcon,
  muiMenuItem: {
    ...theme.classes.dropDown.muiMenuItem,
    fontSize: theme.fonts.smaller,
  },
  selectAligner: {
    marginRight: "10px",
  },
  tableHeadControls: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    margin: "-8px",

    "& > *": {
      margin: "8px",

      "& > * > *": {
        width: "100%",
      },
    },

    "@media(max-width: 767px)": {
      "& > *": {
        flexGrow: 1,
      },
    },
  },
  tableSearchGroup: {
    "@global": {
      input: {
        width: "100%",
        minWidth: "170px",
      },
    },
    flexGrow: 1,
  },
  datacenterFilterWrap: { minWidth: "200px", flexGrow: 3 },
  tableContainer: {
    width: "100%",
    marginTop: "8px",
    "&::-webkit-scrollbar": {
      width: "3px",
      height: "3px",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "3px",
      backgroundColor: "rgba(0,0,0,.8)",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "3px",
      backgroundColor: "rgba(0,0,0,.1)",
    },

    "@media(max-width: 767px)": {
      overflowX: "auto",
    },
  },

  table: {
    minHeight: "100px",
    boxSizing: "border-box",

    "@media(max-width: 767px) ": {
      width: "767px",
    },
  },
  tooltip: {
    marginLeft: "4px",
  },
  disabledSlider: {
    cursor: "not-allowed",

    "input&:active": {
      padding: "7px 10px",
      outline: "none",
    },
  },
  cbList: {
    position: "absolute",
    zIndex: "100",
    width: "110px",
    backgroundColor: theme.colors.lightGrey,
    padding: "15px 10px",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.36)",
    borderRadius: "2px",
    top: "40px",
    right: "8px",
  },
});

export default styles;
