import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import HighchartsReact from "highcharts-react-official";
import { makeStyles } from "@material-ui/core/styles";
import Highcharts from "highcharts";
import { formatDate } from "../../../utils/dates";
import { mb2gb } from "../../../utils/size";
import { getReports } from "../../../store/selectors/reportSelectors";
import axisLabelFormatter from "./formatters/axisLabelFormatter";

import styles from "./styles";

const useStyles = makeStyles(styles);

const composeCartOptions = (data, categories, t) => ({
  chart: {
    height: 300,
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
  },
  plotOptions: {
    column: {
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
    },
    series: {
      groupPadding: 0.3,
      pointWidth: 8,
      states: {
        hover: {
          enabled: false,
        },
        inactive: {
          enabled: false,
        },
      },
    },
  },
  yAxis: [
    {
      allowDecimals: false,
      labels: {
        format: "{value}",
      },
      title: {
        text: t("iaasCommon:Vms"),
      },
      opposite: true,
    },
    {
      gridLineWidth: 0,
      title: {
        text: "Size",
      },
      labels: {
        formatter: axisLabelFormatter,
      },
    },
  ],
  xAxis: { categories },
  title: {
    text: null,
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#C5F5FA", "#41D7FC", "#FF5A5A"],
  series: data,
  credits: {
    enabled: false,
  },
  legend: {
    align: "left",
    verticalAlign: "bottom",
  },
});

const ReportChart = () => {
  const classes = useStyles();
  const reports = useSelector(getReports);
  const { t } = useTranslation("usageReportsPage");

  const chartOptions = useMemo(() => {
    const xAxisCategories = reports.map((item) => formatDate(item._id));
    const data = [
      {
        name: t("iaasCommon:Cpu"),
        type: "column",
        yAxis: 0,
        data: reports.map((item) => item.vcpu_count),
        marker: {
          enabled: false,
        },
        color: "#c48df7",
      },
      {
        name: t("iaasCommon:Memory"),
        type: "spline",
        yAxis: 1,
        data: reports.map((item) => mb2gb(item.memory_size)),
        marker: {
          enabled: false,
        },
        tooltip: {
          valueDecimals: 1,
          valueSuffix: ` ${t("iaasCommon:GB")}`,
        },
        color: "#e59a7f",
      },
      {
        name: t("iaasCommon:Vms"),
        yAxis: 0,
        type: "column",
        data: reports.map((item) => item.vm_count),
        marker: {
          enabled: false,
        },
        color: "#44d9fc",
      },
    ];
    return composeCartOptions(data, xAxisCategories, t);
  }, [reports, t]);

  return (
    <div className={classes.root}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default React.memo(ReportChart);
