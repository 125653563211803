import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { generateReportUrl } from "../ReportsContent/utils";
import { CSV_REPORT_ACCEPT_TYPE, PDF_REPORT_ACCEPT_TYPE } from "../../constants";
import { Button } from "@skyportal/ui-kit";

import styles from "./styles";

const useStyles = makeStyles(styles);
/**
 * @type {ReportAcceptFileType[]}
 */
const EXPORT_TYPES = [PDF_REPORT_ACCEPT_TYPE, CSV_REPORT_ACCEPT_TYPE];

/**
 * @param {boolean} open
 * @param {function} onClose
 * @param {ReportSection} section
 * @param {ReportQueryParams|{date: string}} reportParams
 * @param {ReportType | undefined} reportType
 * @return {JSX.Element}
 * @constructor
 */
const ExportModal = ({ open, onClose, section, reportType, reportParams }) => {
  const { t } = useTranslation("exportReportModal");
  const [acceptType, setAcceptType] = useState(PDF_REPORT_ACCEPT_TYPE);
  const classes = useStyles();
  const typeOptions = EXPORT_TYPES.map((type) => ({
    label: t(type),
    value: type,
  }));

  const handleLinkClick = () => onClose();

  return (
    <Modal open={open} onClose={onClose} data-testid="Reports_ExportModal">
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>{t("title")}</div>
        <div className={classes.modalBody}>
          <Select
            IconComponent={KeyboardArrowDownIcon}
            disableUnderline
            value={acceptType}
            onChange={(event) => setAcceptType(event.target.value)}
            classes={{ root: classes.muiSelectRoot }}
          >
            {typeOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value} className={classes.optionItem}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.cardFooter}>
          <Button type="secondary" onClick={onClose} className={classes.cancelBtn}>
            {t("iaasCommon:Cancel")}
          </Button>
          <a download href={generateReportUrl(section, reportType, acceptType, reportParams)}>
            <Button onClick={handleLinkClick}>
              <span>{t("iaasCommon:Export")}</span>
            </Button>
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ExportModal;
