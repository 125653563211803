import get from "lodash/get";
import { formatDate, formatTime } from "utils/dates";
import {
  VM_STATUS_CODE_RUNNING,
  VM_STATUS_CODE_STOPPED,
  VM_STATUS_CODE_SUSPENDED,
  VM_STATUS_CODE_DONE,
  VM_STATUS_CODE_POWER_OFF,
  VM_STATUS_CODE_UNDEPLOYED,
} from "../constants/vm";
import { getVmStatusLabel } from "../utils/vm";

export const formatTimestampToFullDate = (value) => {
  return `${formatDate(value * 1000)} ${formatTime(value * 1000)}`;
};

export const iconResolver = (way, by, field) => {
  return `${way === "desc" && by === field ? "sort-amount-down" : "sort-amount-down-alt"}`;
};

/**
 * @param {VmApiModel} vm
 * @return {boolean}
 */
export const isValidVm = (vm) =>
  Boolean(
    vm &&
      typeof vm === "object" &&
      typeof vm.status === "object" &&
      vm.status &&
      typeof vm.status.state === "number" &&
      typeof vm.status.substate === "number" &&
      typeof vm.hardware === "object" &&
      vm.hardware &&
      typeof vm.name === "string" &&
      typeof vm.group === "number" &&
      typeof vm._id === "string"
  );

/**
 * @param {VmApiModel} vm
 * @return {boolean}
 */
export const isAttachedDiskToVmAllowed = (vm) => {
  if (!isValidVm(vm)) {
    return false;
  }

  return (
    (vm.status.state === VM_STATUS_CODE_RUNNING && vm.status.substate === 3) ||
    [
      VM_STATUS_CODE_STOPPED,
      VM_STATUS_CODE_SUSPENDED,
      VM_STATUS_CODE_DONE,
      VM_STATUS_CODE_POWER_OFF,
      VM_STATUS_CODE_UNDEPLOYED,
    ].includes(vm.status.state)
  );
};

/**
 * @param {VmApiModel} vm
 * @return {boolean}
 */
export const isAttachedNetworkToVmAllowed = (vm) => {
  if (!isValidVm(vm)) {
    return false;
  }

  return (
    (vm.status.state === VM_STATUS_CODE_RUNNING && vm.status.substate === 3) ||
    [
      VM_STATUS_CODE_STOPPED,
      VM_STATUS_CODE_SUSPENDED,
      VM_STATUS_CODE_DONE,
      VM_STATUS_CODE_POWER_OFF,
      VM_STATUS_CODE_UNDEPLOYED,
    ].includes(vm.status.state)
  );
};

/**
 * @param {VmApiModel} vm
 * @return {boolean}
 */
export const isResizingVmHardwareAllowed = (vm) => {
  if (!isValidVm(vm)) {
    return false;
  }
  return [
    VM_STATUS_CODE_STOPPED,
    VM_STATUS_CODE_SUSPENDED,
    VM_STATUS_CODE_DONE,
    VM_STATUS_CODE_POWER_OFF,
    VM_STATUS_CODE_UNDEPLOYED,
  ].includes(vm.status.state);
};

/**
 * @param {VmApiModel} vm
 * @return {string} - "Disk-1, Disk-2"
 */
export const composeDisksLabel = (vm) => {
  try {
    return vm.hardware.images_info.map((diskItem) => diskItem.name).join(", ");
  } catch (error) {
    return "-";
  }
};

export const makeUniversalSort = (normalizer) => (sortWay) => (a, b) => {
  const flag = sortWay === "asc" ? -1 : 1;
  try {
    return normalizer(a) >= normalizer(b) ? flag * -1 : flag;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return 0;
  }
};

export const makeSortByName = makeUniversalSort((item) => item.name.toLowerCase());
export const makeSortByDisks = makeUniversalSort((item) => composeDisksLabel(item).toLowerCase());
export const makeSortByStatus = makeUniversalSort((item) => getVmStatusLabel(item).toLowerCase());
export const makeSortByGroup = makeUniversalSort((item) => item.group);
export const makeSortByDatecenter = makeUniversalSort((item) => (item.dcOwner.name || "").toLowerCase());
export const makeSortByOs = makeUniversalSort((item) => (item.attributes.guest_os || "").toLowerCase());
export const makeSortByHostname = makeUniversalSort((item) => (item.attributes.hostname || "").toLowerCase());
export const makeSortByHardwareKey = (hardwareKey) =>
  makeUniversalSort((item) => Number(item.hardware[hardwareKey]) || 0);

/**
 * @param {VmStateModel} vm
 * @return {string}
 */
export const networkSortNormalizer = (vm) => vm.__networkNameList[0] || "";
/**
 * @param {VmStateModel} vm
 * @return {string}
 */
export const networkIpSortNormalizer = (vm) => vm.__networkIpList[0] || "";
/**
 * @param {VmStateModel} vm
 * @return {string}
 */
export const networkMacSortNormalizer = (vm) => vm.__networkMacList[0] || "";

export const EXCLUDE_SEARCH_KEYS = ["__v"];

const isString = (value) => typeof value === "string";
const isStringArray = (value) => Array.isArray(value) && value.every(isString);

export const stringOrStringArray = (value) => isString(value) || isStringArray(value);

/**
 * @param {string} searchText
 * @param {string[]} [excludeKeys]
 * @param {string[]} [includeKeys] = 'key'|'key.subKey'|'key.subKey.deepKey'...
 * @return {function(*=): boolean}
 */
export const makeFindObjectValueMatch =
  (searchText, excludeKeys = [], includeKeys = []) =>
  (item) => {
    const _search = searchText.toLowerCase();
    return [...new Set([...Object.keys(item), ...includeKeys])]
      .filter((key) => stringOrStringArray(get(item, key)) && ![...EXCLUDE_SEARCH_KEYS, ...excludeKeys].includes(key))
      .map((key) => get(item, key))
      .some((value) =>
        isString(value)
          ? value.toLowerCase().includes(_search)
          : value.some((listValue) => listValue.toLowerCase().includes(_search))
      );
  };

/**
 *
 * @param {VmStateModel[]} vmList
 * @param {string} key
 * @return {number}
 */
export const getVmsTotalHardware = (vmList, key) =>
  vmList.reduce((acc, item) => acc + Number(item.hardware[key]) || 0, 0);

/**
 * @param {[]} list
 * @param {number} page - >= 1
 * @param {number} perPage - >= 1
 * @return {[]}
 */
export const getPaginationSlice = (list, page, perPage) => list.slice((page - 1) * perPage, page * perPage);
