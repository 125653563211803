import { SET_USER_PROFILE } from "../types";
/**
 * @typedef {object} SetUserProfileAction
 * @property {"SET_USER_PROFILE"} type
 * @property {UserInfoModel} payload
 */

/**
 * @param {UserInfoModel} user
 * @return {SetUserProfileAction}
 */
export const setUserProfile = (user) => ({
  type: SET_USER_PROFILE,
  payload: user,
});
