import * as React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserProfile } from "store/selectors/userSelectors";
import { hasUserAccessToFeature } from "@skyportal/auth-web-client";

const RoleCheckLink = ({ to, feature, accessTypes, ...props }) => {
  const userProfile = useSelector(getUserProfile);

  return !hasUserAccessToFeature(userProfile, feature, accessTypes) ? <span {...props} /> : <Link to={to} {...props} />;
};

export default React.memo(RoleCheckLink);
