import React from "react";
import cn from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {boolean} show
 * @param {number} [size]
 * @param {string} [className]
 * @param {object} [rest]
 * @return {JSX.Element|null}
 * @constructor
 */
const Spinner = ({ show, size = 50, className, ...rest }) => {
  const classes = useStyles();

  if (!show) {
    return null;
  }

  return (
    <div className={cn(classes.spinner, className)}>
      <CircularProgress {...rest} size={size} color="inherit" disableShrink />
    </div>
  );
};

export default Spinner;
