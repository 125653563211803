import labelFormatter from "./labelFormatter";

export const getData = (data) =>
  data.map((item) => ({
    name: item.name,
    y: item.value,
    selected: true,
  }));

export const getSeries = (data) => [
  {
    name: "Used",
    colorByPoint: true,
    size: "100%",
    innerSize: "80%",
    dataLabels: {
      enabled: false,
    },
    data: getData(data),
  },
];

export const makeChartOptions = ({ data }) => ({
  chart: {
    type: "pie",
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    height: 246,
  },
  title: {
    text: null,
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#FF5A5A", "#C48DF7", "#b4b4b4", "#E59A7F", "#44d9fc"],
  series: getSeries(data),
  plotOptions: {
    pie: {
      showInLegend: true,
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    itemDistance: 30,
    align: "right",
    verticalAlign: "top",
    layout: "vertical",
    width: "136px",
    height: "100%",
    x: 0,
    y: 0,
    useHTML: true,
    labelFormatter,
  },
});

/**
 * @param {TopMemoryVM[]} topVmByMemoryList
 * @return {{name: string, value: number}[]}
 */
export const composeChartData = (topVmByMemoryList) =>
  topVmByMemoryList.map((item) => ({
    name: item.name,
    value: item.hardware.memory,
  }));
