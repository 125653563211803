import React from "react";
import { useDispatch } from "react-redux";
import { getDetailedReportRequest } from "../../../store/actions/reportsActions";
import { makeStyles } from "@material-ui/core/styles";
import DetailsControls from "./DetailsControls";

import styles from "./styles";

const useStyles = makeStyles(styles);

const VMDetailedFilterForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(getDetailedReportRequest());
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit} noValidate data-testid="detailed_filter_form">
      <DetailsControls handleSubmit={handleSubmit} />
    </form>
  );
};

export default VMDetailedFilterForm;
