export const RFB_DEFAULT_OPTIONS = {
  wsProtocols: ["binary", "base64"],
};

export const CONNECT_EVENT = "connect";
export const DISCONNECT_EVENT = "disconnect";
export const CAPABILITIES_EVENT = "capabilities";
/**
 * @typedef {"SHUTDOWN_SIGNAL"|"RESET_SIGNAL"|"REBOOT_SIGNAL"|"CTR_ALT_DEL_SIGNAL"} MachineSignals
 */
/**
 * @type {MachineSignals}
 */
export const SHUTDOWN_SIGNAL = "SHUTDOWN_SIGNAL";
/**
 * @type {MachineSignals}
 */
export const RESET_SIGNAL = "RESET_SIGNAL";
/**
 * @type {MachineSignals}
 */
export const REBOOT_SIGNAL = "REBOOT_SIGNAL";
/**
 * @type {MachineSignals}
 */
export const CTR_ALT_DEL_SIGNAL = "CTR_ALT_DEL_SIGNAL";
