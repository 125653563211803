import { formatSize } from "../../../../../utils/size";

/**
 * @see <https://api.highcharts.com/highcharts/tooltip.formatter>
 * @return {function}
 */
const makeTooltipFormatter = (t) =>
  function tooltipFormatter() {
    switch (this.colorIndex) {
      case 0:
        return `<b>${t("cpuAxisTitle")}: ${this.y}</b>`;
      case 1:
        return `<b>${t("sizeAxisTitle")}: ${formatSize(this.y)}</b>`;
      default:
        return "";
    }
  };

export default makeTooltipFormatter;
