import { gb2Mb } from "../../../utils/size";
import { maxMemorySizeGB, maxVCPUCount } from "../../constants";

export const isAttributeRequired = (parsedAttributes, key) => {
  try {
    return parsedAttributes[key].editable === "M";
  } catch {
    return false;
  }
};

const NAME_REQUIRED_VALIDATION_KEY = "name.required";
const GROUP_REQUIRED_VALIDATION_KEY = "group.required";
const V_CPU_REQUIRED_VALIDATION_KEY = "vcpu.required";
const V_CPU_MAX_LIMIT_VALIDATION_KEY = "vcpu.maxLimit";
const MEMORY_REQUIRED_VALIDATION_KEY = "memory.required";
const MEMORY_MAX_LIMIT_VALIDATION_KEY = "memory.maxLimit";
const ATTRIBUTE_REQUIRED_VALIDATION_KEY = "attribute.required";

const composeHardwareVCpuPayload = (vCpu) => (typeof vCpu === "number" && vCpu > 0 ? vCpu : 0);
const composeHardwareMemoryPayload = (memory) => Math.round(gb2Mb(Number(memory)));
const composeHardwareNetworkPayload = (networkId) => (typeof networkId === "number" ? [{ network_id: networkId }] : []);

/**
 * @param {string} name
 * @param {TemplateModel} template
 * @param {number|null} groupId
 * @param {number|null} networkId
 * @param {number} vCPU
 * @param {number} memory
 * @param {string} vcPolicy
 * @param {string} hostname
 * @param {string} password
 * @param {object} attributes
 * @return {VmCreatePayload}
 */
export const composeCreateVmPayload = ({ name, groupId, vCPU, memory, networkId, template, attributes }) => ({
  group: groupId,
  name,
  template_id: template.id,
  hardware: {
    vcpu: composeHardwareVCpuPayload(vCPU),
    memory: composeHardwareMemoryPayload(memory),
    networks: composeHardwareNetworkPayload(networkId),
  },
  attributeValues: attributes,
});
/**
 * @param {string} name
 * @param {number|null} groupId
 * @param {number} vCPU
 * @param {number} memory
 * @param {string} vcPolicy
 * @param {string} hostname
 * @param {string} password
 * @return {VmUpdatePayload}
 */
export const composeEditVmPayload = ({ groupId, name, vCPU, memory, attributes }) => {
  const { guest_os, guest_os_logo, ...restAttributes } = attributes;
  return {
    group: groupId,
    name,
    hardware: {
      vcpu: composeHardwareVCpuPayload(vCPU),
      memory: composeHardwareMemoryPayload(memory),
    },
    attributeValues: restAttributes,
  };
};

/**
 * @param {VmCreatePayload|VmUpdatePayload} payload
 * @param {TemplateModel} [template]
 * @return {string[]} - validation i18n keys
 */
export const validateVmPayload = (payload, template, parsedAttributes) => {
  const errors = [];
  const fieldsWithError = [];
  if (!payload.name || payload.name.trim() === "") {
    errors.push(NAME_REQUIRED_VALIDATION_KEY);
  }
  if (payload.hardware.vcpu === 0) {
    errors.push(V_CPU_REQUIRED_VALIDATION_KEY);
  }
  if (payload.group === null || payload.group === "") {
    errors.push(GROUP_REQUIRED_VALIDATION_KEY);
  }
  if (payload.hardware.vcpu > maxVCPUCount) {
    errors.push(V_CPU_MAX_LIMIT_VALIDATION_KEY);
  }
  if (payload.hardware.memory === 0) {
    errors.push(MEMORY_REQUIRED_VALIDATION_KEY);
  }
  if (payload.hardware.memory > composeHardwareMemoryPayload(maxMemorySizeGB)) {
    errors.push(MEMORY_MAX_LIMIT_VALIDATION_KEY);
  }

  Object.entries(payload?.attributeValues).forEach((attribute) => {
    const [attributeName] = attribute;
    if (template && isAttributeRequired(parsedAttributes, attributeName) && !payload.attributeValues[attributeName]) {
      const attributeTitle = parsedAttributes[attributeName].title;
      fieldsWithError.push(attributeTitle);
      errors.push(ATTRIBUTE_REQUIRED_VALIDATION_KEY);
    }
  });

  return { errors, fieldsWithError };
};

/**
 * @param {string} type
 * @return {[string, {field: string}]|[string]|*[]}
 */
export const validationI18nMapper = (type, fieldName) => {
  switch (type) {
    case NAME_REQUIRED_VALIDATION_KEY:
      return ["createOrEdit.fields.name.requiredMsg"];
    case GROUP_REQUIRED_VALIDATION_KEY:
      return ["createOrEdit.fields.groups.requiredMsg"];
    case V_CPU_REQUIRED_VALIDATION_KEY:
      return ["createOrEdit.fields.vCpu.requiredMsg"];
    case V_CPU_MAX_LIMIT_VALIDATION_KEY:
      return ["createOrEdit.fields.vCpu.maxLimitMsg", { max: `${maxVCPUCount}GB` }];
    case MEMORY_REQUIRED_VALIDATION_KEY:
      return ["createOrEdit.fields.memory.requiredMsg"];
    case MEMORY_MAX_LIMIT_VALIDATION_KEY:
      return ["createOrEdit.fields.memory.maxLimitMsg", { max: `${maxMemorySizeGB}GB` }];
    case ATTRIBUTE_REQUIRED_VALIDATION_KEY:
      return ["createOrEdit.requiredFiled", { field: fieldName }];
    default:
      return [];
  }
};

/**
 * @param {object} groups
 * @param {number | string | null} groupId
 * @return {string}
 */
export const getPrefixValue = (groups, groupId) => {
  if (groups.length && typeof groupId === "number") {
    const selectedGroup = groups.find((group) => group.id === groupId);

    return `${selectedGroup?.prefix || selectedGroup?.name}-`;
  } else if (groups.length && typeof groupId === "string" && groupId.length > 0) {
    const selectedGroup = groups.find((group) => group._id === groupId);

    return `${selectedGroup?.prefix || selectedGroup?.name}-`;
  }
  return "";
};
