import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @typedef {"button"|"a"|"span"|"div"} ButtonTag
 */

const TAGS = ["button", "a", "span", "div"];
const DEFAULT_ATTR = {
  button: {
    type: "button",
  },
};
/**
 * @param {"regular"|"confirm"|"edit"|"danger"} classType
 * @param {React.ReactNode} children
 * @param {ButtonTag} tag
 * @param {boolean} [medium]
 * @param {string} [className]
 * @param {object} rest
 * @return {React.ReactNode}
 * @constructor
 */
const Button = React.forwardRef(({ classType, disabled, children, className, tag = TAGS[0], medium, ...rest }, ref) => {
  const classes = useStyles();

  return React.createElement(
    TAGS.includes(tag) ? tag : TAGS[0],
    {
      className: classnames(classes.btn, classes[classType], className, {
        [classes.medium]: medium,
        [classes.disabled]: disabled,
      }),
      ...DEFAULT_ATTR[tag],
      disabled,
      ...rest,
      ref,
    },
    children
  );
});

export default Button;
