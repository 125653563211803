/**
 * @param {string} sortWay
 */
export const makeSortSummaryReportsById = (sortWay) => (a, b) => {
  const flag = sortWay === "asc" ? -1 : 1;
  return new Date(a._id).getTime() <= new Date(b._id).getTime() ? -1 * flag : flag;
};

/**
 * @param {ReportType} type
 * @param {ReportAcceptFileType} acceptType
 * @param {ReportQueryParams|{date: string}} options
 * @return {string}
 */
export const generateReportUrl = (section, type, acceptType, options = {}) => {
  return [
    window.REPORTS_API_URL,
    type ? `/v1/${section}/${type}?` : `/v1/${section}?`,
    [
      ...Object.keys(options)
        .filter((key) => Boolean(options[key]) || options[key] === 0)
        .map((key) => `${key}=${options[key]}`),
      `accept=${acceptType}`,
    ].join("&"),
  ].join("");
};
