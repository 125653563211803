import { formatSize } from "../../../../utils/size";

/**
 * @see <https://api.highcharts.com/highcharts/yAxis.labels.formatter>
 * @return {string}
 */
function axisLabelFormatter() {
  return `<b>${formatSize(this.value)}</b>`;
}

export default axisLabelFormatter;
