import React from "react";
import { useDispatch } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";
import { setVmFilterValueAct } from "../../../../store/actions/virtualMachinesActions";
import RoleCheckLink from "../../../../components/RoleCheckLink";
import LinearProgressBar from "./LinearProgressBar";
import { FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";

import styles from "./styles";

const useStyles = makeStyles(styles);

const TopUsedDataVmItem = ({ name, sizeLabel, percents }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleOnClick = () => dispatch(setVmFilterValueAct(name));

  return (
    <Grid className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="baseline">
        <Grid item className={classes.infoBox}>
          <RoleCheckLink
            feature={FEATURES.IAAS_VIRTUAL_MACHINES}
            accessTypes={[USER_ACCESS.VIEW]}
            onClick={handleOnClick}
            to="/infra/vm"
            className={classes.vmLabel}
          >
            {name}
          </RoleCheckLink>
        </Grid>
        <Grid item>
          <span className={classes.sizeLabel}>{sizeLabel}</span>
        </Grid>
      </Grid>
      <LinearProgressBar variant="determinate" value={percents} />
    </Grid>
  );
};

export default TopUsedDataVmItem;
