import { EVENT_HISTORY_PAGE, VNC_FEATURE } from "../constants/features";
import Dashboard from "../pages/Dashboard";
import Datacenters from "../pages/Datacenters";
import Regions from "../pages/Regions";
import VmEditPage from "../pages/VMs/VMs_create_edit/VmEditPage";
import VmCreatePage from "../pages/VMs/VMs_create_edit/VmCreatePage";
import Vnc from "../pages/VMs/Vnc/VncPage";
import VMs from "../pages/VMs/VMs";
import Disks from "../pages/Disks/Disks";
import EventHistory from "../pages/EventHistory";
import Reports from "../pages/Reports/Reports";

export const ROOT = "/infra/overview";
export const REGIONS = "/infra/region";
export const DATACENTERS = "/infra/datacenter";
export const VM_LIST = "/infra/vm";
export const VM_CREATE = "/infra/vm/create";
export const VM_UPDATE = "/infra/vm/edit/:id";
export const VNC = VNC_FEATURE ? "/infra/vm/vnc/:id" : "";
export const DISKS = "/infra/disks";
export const REPORTS = "/infra/reports";
export const EVENT_HISTORY = EVENT_HISTORY_PAGE ? "/infra/event-history" : "";

export const PUBLIC_PATHS = [ROOT, EVENT_HISTORY].filter(Boolean);
export const PROTECTED_PATHS = [REGIONS, DATACENTERS].filter(Boolean);

export const routeMap = {
  [ROOT]: {
    exact: true,
    component: Dashboard,
  },
  [VM_UPDATE]: {
    exact: true,
    component: VmEditPage,
  },
  [VM_LIST]: {
    exact: true,
    component: VMs,
  },
  [DISKS]: {
    exact: true,
    component: Disks,
  },
  [EVENT_HISTORY]: {
    exact: true,
    component: EventHistory,
  },
  [REPORTS]: {
    component: Reports,
  },
  [VM_CREATE]: {
    exact: true,
    component: VmCreatePage,
  },
  [VNC]: {
    exact: true,
    component: Vnc,
  },
  [DATACENTERS]: {
    exact: true,
    component: Datacenters,
  },
  [REGIONS]: {
    exact: true,
    component: Regions,
  },
};
