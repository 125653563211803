import React from "react";
import MainRouter from "./routes/MainRouter";
import AppBuildInfo from "./components/AppBuildInfo";
import Providers from "./Providers";
import ProfileRenderer from "./components/ProfileRenderer/ProfileRenderer";

const App = ({ history }) => (
  <Providers history={history}>
    <ProfileRenderer>{({ profile }) => <MainRouter profile={profile} />}</ProfileRenderer>
    <AppBuildInfo />
  </Providers>
);

export default App;
