const styles = (theme) => ({
  page: theme.classes.page,
  header: {
    ...theme.classes.centralized,
    height: "55px",
    justifyContent: "space-between",
    fontSize: theme.fonts.bigger,
  },
  breadcrumbs: {
    fontSize: theme.fonts.small,
    color: "black",
    marginRight: "20px",
  },
  breadcrumbLink: {
    textDecoration: "none",
    color: "black",
  },
  breadcrumbLinkIcon: {
    marginRight: "4px",
  },
});

export default styles;
