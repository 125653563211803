import * as React from "react";
import Input from "../../../ui-kit/Input";
import { Button } from "@skyportal/ui-kit";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSearchTextAC } from "../../../store/actions/datacenterActions";

import styles from "./styles";

const useStyles = makeStyles(styles);

const HeaderTopBar = ({ toggleCreateNewRow }) => {
  const { t } = useTranslation("datacentersPage");
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSearchInputChange = (e) => dispatch(setSearchTextAC(e.target.value));

  return (
    <div className={classes.headerRowCell}>
      <div className={classes.tableHeadControls}>
        <div className={classes.tableSearchGroup}>
          <Input onChange={handleSearchInputChange} placeholder={t("searchPlaceholder")} />
        </div>
        <div className={classes.btnContainer}>
          <Button onClick={toggleCreateNewRow}>{t("iaasCommon:createNew")}</Button>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopBar;
