import React from "react";
import { makeStyles } from "@material-ui/core";
import classnames from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "./Button";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {{label: string, value: string}[]} dates
 * @param {string} selected
 * @param ((string) => void) onBtnClick
 * @return {JSX.Element}
 * @constructor
 */
const DateButtons = ({ dates, selected, onBtnClick }) => {
  const classes = useStyles();

  const makeHandleClick = (date) => () => onBtnClick(date);
  const handleTimeRangeChange = ({ target: { value } }) => onBtnClick(value);

  return (
    <>
      <div className={classes.dropdown}>
        <Select
          disableUnderline
          value={selected}
          onChange={handleTimeRangeChange}
          classes={{
            icon: classes.dropdownIcon,
            select: classes.selectedDropdown,
          }}
        >
          {dates.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={classes.container}>
        {dates.map((date) => (
          <Button
            className={classnames({
              [classes.selected]: date.value === selected,
            })}
            onClick={makeHandleClick(date.value)}
            key={date.value}
          >
            {date.label}
          </Button>
        ))}
      </div>
    </>
  );
};

export default DateButtons;
