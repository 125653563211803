const styles = (theme) => ({
  tag: {
    marginRight: "2px",
    marginBottom: "2px",
    "& img": {
      filter: "invert(1)",
    },
  },
  content: {
    position: "relative",
    borderTop: "4px solid #B4B4B4",
    borderRadius: "4px",
    backgroundColor: "white",
  },
  title: {
    textAlign: "center",
    fontSize: "34px",
    fontWeight: "normal",
    lineHeight: "40px",
    marginTop: "25px",
    marginBottom: "25px",
  },
  subTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "20px",
    marginTop: "14px",
    marginBottom: "14px",
  },
  tableAligner: {
    padding: "0 20px",
    fontSize: theme.fonts.smaller,
  },
  tableContainer: {
    margin: "0 16px",
    border: "1px solid #F0F0F0",
    borderTop: "none",
    borderRadius: "0px 0px 8px 8px",

    "@media(max-width: 767px)": {
      overflowX: "auto",
    },
  },
  table: {
    fontSize: theme.fonts.smaller,
    minHeight: "100px",
    boxSizing: "border-box",

    "@media(max-width: 767px) ": {
      width: "767px",
    },
  },
  tableWithTotalRow: {
    "@global": {
      table: {
        borderBottom: 0,
      },
      "table tbody tr": {
        "&:last-child": {
          "& .SkyPortalTable__cell": {
            fontWeight: 600,
            backgroundColor: "rgba(0, 0, 0, 0.1)",

            "&:first-child": {
              borderTopLeftRadius: "6px",
              borderBottomLeftRadius: "6px",
            },
            "&:last-child": {
              borderTopRightRadius: "6px",
              borderBottomRightRadius: "6px",
            },
          },
        },
      },
    },
  },
  reportsHeadControlsBar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 16px 24px",
  },
  shell: {
    width: "900px",
    overflowX: "auto",
  },
  pagingContainer: {
    ...theme.classes.table.pagingContainer,
  },
  tableBody: {
    minHeight: "100px",
  },
  tabContainer: {
    boxSizing: "border-box",
    borderRadius: "8px 8px 0px 0px",
    border: "1px solid #F0F0F0",
    display: "flex",
    margin: "0 16px",
  },
  tabItemWrapper: {
    display: "flex",
  },
  cursorNotAllowed: {
    cursor: "not-allowed",
  },
  disabledTabItem: {
    pointerEvents: "none",
    opacity: "0.5",
  },
  tabItem: {
    color: "#000000",
    fontSize: "14px",
    lineHeight: "16px",
    padding: "12px 0",
    textDecoration: "none",
    margin: "0 16px",
  },
  tabItemActive: {
    borderBottom: "2px solid #4F4F4F",
    fontWeight: "600",
  },
  reportBtn: {
    marginLeft: "15px",
  },
  checkIcon: {
    height: "24px",
  },
  exportControlsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "-36px -8px -8px -8px",
    padding: "8px 16px",
    flexWrap: "wrap",

    "& > button": {
      margin: "8px",

      "@media(max-width: 767px) ": {
        flexGrow: 1,
        minWidth: "180px",
      },
    },
  },
});

export default styles;
