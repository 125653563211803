import React from "react";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";
import CircleLabel from "../../../../ui-kit/CircleLabel/CircleLabel";
import RoleCheckLink from "../../../../components/RoleCheckLink";
import { filterVMsAct } from "../../../../store/actions/virtualMachinesActions";
import { VARIANT_CLASS_MAP, STATUS_ICON_MAP, VARIANT_FILTERS_MAP, STATUS_ICON_COLOR_MAP } from "./constants";
import { FEATURES, USER_ACCESS } from "@skyportal/auth-web-client";
import { Icon } from "@skyportal/ui-kit";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {string} label
 * @param {number} value - % value
 * @param {string} headLabel
 * @param {"TOTAL"|"PENDING"|"RUNNING"|"STOPPED"} variant
 * @return {JSX.Element}
 * @constructor
 */
const VmStatusItem = ({ label, headLabel, value, variant }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleLinkClick = () => dispatch(filterVMsAct(VARIANT_FILTERS_MAP[variant]));

  return (
    <Grid
      direction="row"
      alignItems="flex-end"
      container
      className={cn(classes.vmStatusItem, classes[VARIANT_CLASS_MAP[variant]])}
    >
      <Grid item className={classes.iconBox}>
        <Icon icon={STATUS_ICON_MAP[variant]} height={24} color={STATUS_ICON_COLOR_MAP[variant]} />
      </Grid>
      <Grid item className={classes.labelContainer}>
        <Grid container direction="column">
          <Grid item className={classes.variantLabel}>
            {headLabel}
          </Grid>
          <Grid item className={classes.label} onClick={handleLinkClick}>
            <RoleCheckLink
              feature={FEATURES.IAAS_VIRTUAL_MACHINES}
              accessTypes={[USER_ACCESS.VIEW]}
              to="/infra/vm"
              className={classes.linkText}
            >
              {label}
            </RoleCheckLink>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.circleBox}>
        <CircleLabel value={value} size={48} labelProps={{ className: classes.percentageLabel }} />
      </Grid>
    </Grid>
  );
};

export default VmStatusItem;
