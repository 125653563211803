import React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";

import UIButton from "../../../../ui-kit/Button";

import styles from "./styles";

const useStyles = makeStyles(styles);

const Button = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <UIButton {...rest} className={cn(classes.dateButton, className)}>
      {children}
    </UIButton>
  );
};

export default Button;
