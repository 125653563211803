import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import IconTooltip from "../../../../components/IconTooltip";
import { isResizingVmHardwareAllowed } from "../../../helpers";
import FormFieldset from "./FormFieldset";
import MemorySlider from "./MemorySlider";
import { formStyles } from "../styles";

const useStyles = makeStyles(formStyles);

export const MemoryFieldset = ({
  value,
  onChange,
  altValue,
  onAltChange,
  isEditVMPage = false,
  template,
  currentVm,
}) => {
  const { t } = useTranslation("vmPage");
  const memoryRef = useRef(null);
  const classes = useStyles();
  const isResizeDisallow = isEditVMPage && currentVm && !isResizingVmHardwareAllowed(currentVm);

  const handleMemoryChange = (_, _value) => onChange(_value);

  return (
    <FormFieldset
      ref={memoryRef}
      title={
        <>
          {t("createOrEdit.fieldset.memory")}
          {isResizeDisallow && (
            <IconTooltip
              testId="ram_info_icon"
              title={t("createOrEdit.fieldset.memoryEditTooltip")}
              className={classes.iconTooltip}
            />
          )}
        </>
      }
      subTitle={t("createOrEdit.fieldset.memoryHint")}
    >
      <div>
        <MemorySlider
          VmMemorySliderValue={value}
          setVmMemorySlider={onChange}
          handleMemoryChange={handleMemoryChange}
          selectedTemplate={template}
          VmMemoryInputValue={altValue}
          setVmMemoryInput={onAltChange}
          isEditVMPage={isEditVMPage}
          memoryRef={memoryRef}
          currentVm={currentVm}
        />
      </div>
    </FormFieldset>
  );
};

export default React.memo(MemoryFieldset);
