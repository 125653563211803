const styles = theme => ({
  checked: {
    color: theme.colors.black,
  },
  checkRoot: {
    padding: '0',

    '&$checked': {
      color: theme.colors.black,
    },
  },
});

export default styles;
