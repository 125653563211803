import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { resetReportsSortStateAC } from "../../../../store/actions/reportsActions";

function useUpdateEffect(callback, dependencies) {
  const firstRenderRef = useRef(true);

  // Since ref persists value between renders (and itself doesn't trigger a render when value is changed),
  // we can simply just set ref to a failing condition on our 1st render so that component only is re-rendered
  // when dependencies change and not also "onMount"
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    return callback();
  }, dependencies);
}

const useUnmountResetReportState = () => {
  const dispatch = useDispatch();

  useUpdateEffect(() => {
    return () => {
      dispatch(resetReportsSortStateAC());
    };
  }, [dispatch]);
};

export default useUnmountResetReportState;
