import React from "react";
import { WEEK_LABEL_LIST } from "../../pages/Reports/ReportsFilterBar/constants";
import { useTranslation } from "react-i18next";

const DatePickerWeekElement = ({ weekday }) => {
  const { t } = useTranslation("shortDayOfWeek");

  return <div className="DayPicker-Weekday">{t(WEEK_LABEL_LIST[weekday])}</div>;
};

export default DatePickerWeekElement;
