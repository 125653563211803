/**
 * @typedef {"TOTAL_VARIANT"|"PENDING_VARIANT"|"RUNNING_VARIANT"|"STOPPED_VARIANT"} VmStatusItemVariant
 */
/**
 * @type {VmStatusItemVariant}
 */
export const TOTAL_VARIANT = "TOTAL_VARIANT";
/**
 * @type {VmStatusItemVariant}
 */
export const PENDING_VARIANT = "PENDING_VARIANT";
/**
 * @type {VmStatusItemVariant}
 */
export const RUNNING_VARIANT = "RUNNING_VARIANT";
/**
 * @type {VmStatusItemVariant}
 */
export const STOPPED_VARIANT = "STOPPED_VARIANT";

export const VARIANT_CLASS_MAP = {
  [TOTAL_VARIANT]: "total",
  [PENDING_VARIANT]: "pending",
  [RUNNING_VARIANT]: "running",
  [STOPPED_VARIANT]: "stopped",
};
export const VARIANT_FILTERS_MAP = {
  [TOTAL_VARIANT]: "",
  [PENDING_VARIANT]: "pending",
  [RUNNING_VARIANT]: "running",
  [STOPPED_VARIANT]: "stopped",
};

export const STATUS_ICON_MAP = {
  [TOTAL_VARIANT]: "thumbs",
  [PENDING_VARIANT]: "info-attention",
  [RUNNING_VARIANT]: "thumbs-up",
  [STOPPED_VARIANT]: "thumbs-down",
};

export const STATUS_ICON_COLOR_MAP = {
  [TOTAL_VARIANT]: "popBlue",
  [PENDING_VARIANT]: "popBrown",
  [RUNNING_VARIANT]: "popGreen",
  [STOPPED_VARIANT]: "popRed",
};
