import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../pages/shareableComponents/PageHeader";
import { makeStyles } from "@material-ui/core/styles";
import { vmSel } from "../../store/selectors/vmSelectors";
import { getDataCenterList } from "../../store/selectors/dcSelectors";
import { filterVMsAct, setAllVmsAct, setVmFilterValueAct } from "../../store/actions/virtualMachinesActions";
import { getAllImagesAct } from "../../store/actions/imagesActions";
import { getVmUpdateInterval } from "../../config";
import VMTileBar from "./VMsContent/VMTileBar";
import VMsContent from "./VMsContent/VMsContent";
import useInterval from "../../hooks/useInterval";
import useScrollToTop from "../../hooks/useScrollToTop";
import useUnmount from "../../hooks/useUmnount";

import styles from "./styles";

const useStyles = makeStyles(styles);

const VMs = () => {
  const { t } = useTranslation("vmPage");
  const dispatch = useDispatch();
  const classes = useStyles();
  const { vms, refreshTimerValue } = useSelector(vmSel);
  const dataCenterList = useSelector(getDataCenterList);

  const fetchPageData = useCallback(() => {
    if (dataCenterList.length) {
      dispatch(setAllVmsAct());
      dispatch(getAllImagesAct());
    }
  }, [dataCenterList, dispatch]);

  useEffect(fetchPageData, [fetchPageData]);

  useInterval(fetchPageData, getVmUpdateInterval(refreshTimerValue));

  useScrollToTop();

  useUnmount(() => {
    dispatch(filterVMsAct(""));
    dispatch(setVmFilterValueAct(""));
  });

  return (
    <div className={classes.page}>
      <PageHeader title={t("title")} />
      <VMTileBar vms={vms} />
      <VMsContent />
    </div>
  );
};

export default VMs;
