import React from "react";
import Autocomplete from "../../../../ui-kit/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getSummaryReportFilterStatuses } from "../../../../store/selectors/reportSelectors";
import { setReportFilterStatuses } from "../../../../store/actions/reportsActions";
import { SummaryReportVmStatuses } from "../../../../constants/options";

const VmMultiSelect = () => {
  const dispatch = useDispatch();
  const selectedValues = useSelector(getSummaryReportFilterStatuses);
  const handleChange = (selectedOptionList) => dispatch(setReportFilterStatuses(selectedOptionList));

  return (
    <Autocomplete
      multiple
      disableClearable
      value={selectedValues}
      testId="vmStatuses"
      options={SummaryReportVmStatuses.filter((option) => !selectedValues.includes(option))}
      onChange={handleChange}
    />
  );
};

export default React.memo(VmMultiSelect);
