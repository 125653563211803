import React, { useMemo } from "react";
import { formatDate, formatTime } from "../../utils/dates";

/**
 * @param {ActionHistory} event
 * @return {JSX.Element}
 * @constructor
 */
const ActionStartTimeCell = ({ event }) => {
  const label = useMemo(() => `${formatDate(event.stime * 1000)} ${formatTime(event.stime * 1000)}`, [event.stime]);

  return <span>{label}</span>;
};

export default ActionStartTimeCell;
