import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../../ui-kit/Input";
import { Button } from "@skyportal/ui-kit";
import { maxInputLength } from "../../constants";

import styles from "./styles";

const useStyles = makeStyles(styles);

const NewRegionRow = ({ isVisible, saveNewRegion, cancelCreation }) => {
  const { t } = useTranslation("regionPage");
  const classes = useStyles();
  const [codeState, setCode] = useState("");
  const [nameState, setName] = useState("");

  const changeRegionCode = (event) => {
    const isInputCorrect = event.target.value.length < maxInputLength;
    if (isInputCorrect) {
      setCode(event.target.value);
    }
  };
  const changeRegionName = (event) => setName(event.target.value);
  const saveRegion = () => {
    // also need to close row
    if (!codeState.length || !nameState.length) return;
    saveNewRegion({
      code: codeState,
      name: nameState,
      datacenters: [],
    });
    setCode("");
    setName("");
    cancelCreation();
  };

  return isVisible ? (
    <div className={classes.newRow} data-testid="NewRegionRow">
      <div className={classes.codeCell}>
        <Input
          className={classes.newRegionCell}
          onChange={changeRegionCode}
          value={codeState}
          placeholder={t("regionCodePlaceholder")}
        />
      </div>
      <div className={classes.nameCell}>
        <Input
          className={classes.newRegionCell}
          onChange={changeRegionName}
          value={nameState}
          placeholder={t("regionNamePlaceholder")}
        />
      </div>
      <div className={classes.codeCell}>{0}</div>
      <div className={classes.controls}>
        <Button data-testid="cancelNewRegionBtn" type="secondary" className={classes.undoBtn} onClick={cancelCreation}>
          <span>{t("iaasCommon:Cancel")}</span>
        </Button>
        <Button data-testid="saveNewRegionBtn" className={classes.saveBtn} onClick={() => saveRegion()}>
          <span>{t("iaasCommon:Save")}</span>
        </Button>
      </div>
    </div>
  ) : null;
};

export default NewRegionRow;
