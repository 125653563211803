import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

export const ExpansionPanel = withStyles({
  root: {
    borderBottom: "1px solid #F4F4F4",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
      backgroundColor: "#f3f3f3",
    },
  },
  expanded: {},
})(MuiAccordion);

export const PanelSummary = withStyles({
  root: {
    marginBottom: -1,
    padding: 0,
    minHeight: 38,
    "&$expanded": {
      minHeight: 38,
    },
  },
  content: {
    padding: 0,
    margin: 0,
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
  expandIcon: {
    transform: "none",
    "&$expanded": {
      transform: "none",
    },
  },
})(MuiAccordionSummary);

export const PanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
}))(MuiAccordionDetails);
