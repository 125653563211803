import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Icon, Spinner } from "@skyportal/ui-kit";
import { makeStyles } from "@material-ui/core";
import useAllMetricsRequestPending from "../../../../hooks/useAllMetricsRequestPending";
import { BUTTON_COLOR_MAP, CLASS_NAME_MAP, FA_ICON_MAP } from "./constants";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {UsageStatisticCardVariant} variant
 * @param {string} label
 * @param {string} labelHint
 * @param {string} linkTo
 * @return {JSX.Element}
 * @constructor
 */
const UsageStatisticCard = ({ variant, label, labelHint, linkTo, userAccessToBtn, isAnyAccessGranted }) => {
  const { t } = useTranslation("usageStatisticBlock");
  const classes = useStyles();
  const wrapperClasses = cn(classes.root, classes[CLASS_NAME_MAP[variant]], {
    [classes.rootMinHeight]: isAnyAccessGranted,
  });

  return (
    <div className={wrapperClasses}>
      <Spinner show={useAllMetricsRequestPending()} />
      <div className={classes.label} title={label}>
        {label}
      </div>
      <div className={classes.hintLabel}>{labelHint}</div>
      {userAccessToBtn && (
        <Link to={linkTo} className={classes.link}>
          <Button primaryTypeColor={BUTTON_COLOR_MAP[variant]} rightIcon="arrow-right">
            {t("moreInfoLink")}
          </Button>
        </Link>
      )}
      <Icon type="fontawesome" className={classes.iconLabel} icon={FA_ICON_MAP[variant]} />
    </div>
  );
};

export default UsageStatisticCard;
