import React from "react";
import MuiRadio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

const Radio = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <MuiRadio
      ref={ref}
      size="small"
      color="primary"
      inputProps={{ "aria-label": props.value }}
      iconstyle={{ fill: "#000" }}
      classes={{ checked: classes.checked, root: classes.checkRoot }}
      {...props}
    />
  );
});

export default Radio;
