import { axiosClient } from "./web";
import { IAAS_API_VERSION } from "../constants/config";

/**
 * @typedef {object} NetworkApiModel
 * @property {string} _id - id Mongo
 * @property {number} id - id OpenNebula
 * @property {number} group - group id OpenNebula
 * @property {string} name
 */

/**
 * @param {string} dcUrl
 */
export const getList = (dcUrl) =>
  axiosClient.request({
    method: "GET",
    url: [dcUrl, IAAS_API_VERSION, "network"].join("/"),
  });
