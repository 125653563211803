const styles = (theme) => ({
  root: {
    margin: "12px 0",
  },
  infoBox: {
    padding: "5px 0",
  },
  vmLabel: {
    color: theme.colors.black,
    fontSize: "14px",
    lineHeight: "18px",
    textDecoration: "none",

    "&:hover": {
      fontWeight: 600,
    },
  },
  sizeLabel: {
    color: theme.colors.black,
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "16px",
  },
});

export default styles;
