import {
  getVMList,
  saveVm,
  editVm,
  deleteVM,
  runSingleVM,
  stopSingleVM,
  getSnapshots,
  rollbackSnapshot,
  deleteSnapshot,
  createSnapshot,
  getVncUrl,
  detachNetwork,
  attachNetwork,
  attachDisk,
  detachDisk,
} from "../../api/vmService";
import { getSelectedColsSel, vmSel } from "../selectors/vmSelectors";
import { getDataCenterList } from "../selectors/dcSelectors";
import { notificationAC, notificationErrorAC } from "./notificationActions";
import { parseApiErrorMessage } from "../../api/utils";
import i18n from "../../locales";
import { saveInLSNewCreatedVmId, storeVmListColumnsMap, storeVmsPerPageValue } from "../../utils/vm";
import {
  SET_VMS,
  FILTER_VMS,
  SORT_VMS,
  SET_VM_FILTER_VALUE,
  SET_CURRENT_VM_PAGE,
  TOGGLE_VM_COLUMN,
  SELECT_VM,
  DELETE_VM,
  SET_TO_PENDING,
  SET_REFRESH_TIMER_VALUE,
  TOGGLE_SELECT_ALL_VMS,
  DELETE_SNAPSHOT,
  GET_SNAPSHOTS,
  SET_SNAPSHOT_DISABLE,
  UNSET_SNAPSHOT_DISABLE,
  VM_LIST_REQUEST,
  VM_LIST_REQUEST_FAILURE,
  VM_VNC_URL_REQUEST,
  VM_VNC_URL_REQUEST_FAILURE,
  VM_VNC_URL_REQUEST_SUCCESS,
  VM_VNC_URL_RESET,
  SET_VM_PER_PAGE,
  SET_VM_DC_INCLUDE,
  VM_CREATE_PENDING,
  VM_UPDATE_PENDING,
  DELETE_IMAGE,
} from "../types";
import { storeDatacenterIncludeMap } from "../../utils/vm";
import { getUserProfile } from "../selectors/userSelectors";

export const vmListRequestAC = () => ({ type: VM_LIST_REQUEST });
export const vmListRequestFailureAC = () => ({ type: VM_LIST_REQUEST_FAILURE });

export const setAllVmsAct = () => async (dispatch, getState) => {
  const datacenters = getDataCenterList(getState());

  try {
    dispatch(vmListRequestAC());

    const promises = await Promise.allSettled(datacenters.map((dc) => getVMList(dc.api.url)));

    const payload = promises.reduce((acc, result, index) => {
      if (result.status !== "rejected") {
        result.value.data.forEach((vm) => {
          acc.push({
            ...vm,
            dcOwner: datacenters[index],
          });
        });
      }

      return acc;
    }, []);

    dispatch({ type: SET_VMS, payload });
  } catch (error) {
    dispatch(vmListRequestFailureAC());
  }
};

export const filterVMsAct = (payload) => ({
  type: FILTER_VMS,
  payload,
});

export const sortVMsAct = (payload) => ({
  type: SORT_VMS,
  payload,
});

export const setVmFilterValueAct = (payload) => ({
  type: SET_VM_FILTER_VALUE,
  payload,
});

/**
 * @param {number} payload
 * @return {{payload: number, type: "SET_CURRENT_VM_PAGE"}}
 */
export const setCurrentVmPageAct = (payload) => ({
  type: SET_CURRENT_VM_PAGE,
  payload,
});

/**
 * @param {PaginationStep} payload
 * @return {{payload: PaginationStep, type: "SET_VM_PER_PAGE"}}
 */
export const setPerPageAC = (payload) => ({
  type: SET_VM_PER_PAGE,
  payload,
});

export const setPerPageValue = (value) => (dispatch, getState) => {
  const { id, email } = getUserProfile(getState()) || {};

  if (email) {
    storeVmsPerPageValue(value, String(id));
  }

  dispatch(setPerPageAC(value));
};

export const selectColumnAct = (payload) => ({
  type: TOGGLE_VM_COLUMN,
  payload,
});

export const toggleVmColumnView = (value) => (dispatch, getState) => {
  const { id, email } = getUserProfile(getState()) || {};
  const selectedCols = getSelectedColsSel(getState()) || {};
  const reselectedColumns = selectedCols.map((col) => {
    if (col.name === value) {
      return {
        ...col,
        selected: !col.selected,
      };
    }
    return col;
  });

  if (email) {
    storeVmListColumnsMap(reselectedColumns, String(id));
  }

  dispatch(selectColumnAct(reselectedColumns));
};

export const selectVM = (payload) => ({
  type: SELECT_VM,
  payload,
});

export const deleteVMAct = (vm, disksIds, disksStringIds) => async (dispatch) => {
  try {
    await deleteVM(vm, disksIds);
    dispatch({ type: DELETE_VM, payload: vm });
    dispatch(notificationAC(i18n.t("notification:vmDeleteSuccess")));
    disksStringIds.forEach((diskStringId) => {
      dispatch({ type: DELETE_IMAGE, payload: diskStringId });
      dispatch(notificationAC(i18n.t("notification:diskDeletingSuccess")));
    });
  } catch (error) {
    dispatch(
      notificationErrorAC({ message: i18n.t("notification:vmDeleteFailed"), description: parseApiErrorMessage(error) })
    );
    throw error;
  }
};

export const vmPowerOn = () => async (dispatch, getState) => {
  const { vms, selectedVMs } = vmSel(getState());
  const vmListToStart = vms.filter((vm) => selectedVMs.includes(vm._id));
  const promises = vmListToStart.map(({ dcOwner, _id }) => runSingleVM(dcOwner.api.url, _id));

  const result = await Promise.allSettled(promises);
  const rejectedVMs = [];
  const errors = [];
  const launchedVMs = [];
  result.forEach((actionResult, index) => {
    if (actionResult.status === "rejected") {
      rejectedVMs.push(vmListToStart[index]);
      errors.push(actionResult);
    } else {
      launchedVMs.push(vmListToStart[index]);
    }
  });

  if (rejectedVMs.length > 0) {
    dispatch(
      notificationErrorAC({
        message: `${rejectedVMs.map((vm) => vm.name).join(", ")} VM has not run.`,
        description: errors.map((error) => parseApiErrorMessage(error.reason)).join(" / "),
      })
    );
  }

  if (launchedVMs.length > 0) {
    await dispatch(setAllVmsAct());
    dispatch(notificationAC(`${launchedVMs.map((vm) => vm.name).join(", ")} has run.`));
  }
};

/**
 * @param {boolean} isHard
 * @param {string[]} vmIds
 */
export const vmPowerOff =
  ({ isHard = false, vmIds }) =>
  async (dispatch, getState) => {
    const { vms, selectedVMs } = vmSel(getState());
    const vmListToStop = vms.filter((vm) => (vmIds || selectedVMs).includes(vm._id));

    const promises = vmListToStop.map(({ dcOwner, _id }) => stopSingleVM(dcOwner.api.url, _id, isHard));

    const result = await Promise.allSettled(promises);
    const rejectedVMs = [];
    const errors = [];
    const stoppedVMs = [];
    result.forEach((actionResult, index) => {
      if (actionResult.status === "rejected") {
        rejectedVMs.push(vmListToStop[index]);
        errors.push(actionResult);
      } else {
        stoppedVMs.push(vmListToStop[index]);
      }
    });
    if (rejectedVMs.length > 0) {
      dispatch(
        notificationErrorAC({
          message: `${rejectedVMs.map((vm) => vm.name).join(", ")} VM has not stopped.`,
          description: errors.map((error) => parseApiErrorMessage(error.reason)).join(" / "),
        })
      );
    }

    if (stoppedVMs.length > 0) {
      await dispatch(setAllVmsAct());
      dispatch(notificationAC(`${stoppedVMs.map((vm) => vm.name).join(", ")} has stopped.`));
    }
  };

export const setToPendingAct = (payload) => ({ type: SET_TO_PENDING, payload });
export const setRefreshTimerValue = (payload) => ({ type: SET_REFRESH_TIMER_VALUE, payload });

/**
 * @param {string[]} vmIdList
 * @return {{payload: string[], type: "TOGGLE_SELECT_ALL_VMS"}}
 */
export const toggleSelectAllVMsAct = (vmIdList) => ({
  type: TOGGLE_SELECT_ALL_VMS,
  payload: vmIdList,
});

export const deleteSnapshotAct = (payload) => ({
  type: DELETE_SNAPSHOT,
  payload,
});
export const setSnapshotDisableAct = (payload) => ({
  type: SET_SNAPSHOT_DISABLE,
  payload,
});
export const unsetSnapshotDisableAct = (payload) => ({
  type: UNSET_SNAPSHOT_DISABLE,
  payload,
});
export const setSnapshotsToVm = (vmId, snapshots) => ({
  type: GET_SNAPSHOTS,
  payload: { _id: vmId, snapshots },
});
export const getSnapshotsAct = (vmId, dcUrl) => async (dispatch) => {
  const result = await getSnapshots(vmId, dcUrl);
  // todo API need fix response model
  const snapshots = Array.isArray(result.data) ? result.data : [result.data];

  dispatch(setSnapshotsToVm(vmId, snapshots));
};

/**
 * @param {object} data
 * @param {string} data.vmId
 * @param {string} data.dcUrl
 * @param {string} data.name
 */
export const creteSnapshotRequestAct = (data) => async (dispatch) => {
  try {
    await createSnapshot(data);
    dispatch(getSnapshotsAct(data.vmId, data.dcUrl));
    dispatch(notificationAC(i18n.t("notification:snapshotCreatingSuccess", { name: data.name })));
  } catch (error) {
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:snapshotCreationFailed"),
        description: parseApiErrorMessage(error),
      })
    );
  }
};

/**
 * @param {boolean} payload
 * @return {{payload: boolean, type: string}}
 */
const vmCreatePendingAC = (payload) => ({
  type: VM_CREATE_PENDING,
  payload,
});

/**
 * @param {VmCreatePayload} data - payload for request
 * @param {DataCenterApiModel} dcOwner - dataCanter
 */
export const saveVmAct = (data, dcOwner) => async (dispatch) => {
  try {
    dispatch(vmCreatePendingAC(true));
    const response = await saveVm({ baseURL: dcOwner.api.url, data });
    saveInLSNewCreatedVmId(response.data._id);
    dispatch(notificationAC(i18n.t("notification:vmCreateSuccess")));
    dispatch(vmCreatePendingAC(false));
  } catch (error) {
    dispatch(
      notificationErrorAC({ message: i18n.t("notification:vmCreateFailed"), description: parseApiErrorMessage(error) })
    );
    dispatch(vmCreatePendingAC(false));
    throw error;
  }
};

/**
 * @param {boolean} payload
 * @return {{payload: boolean, type: string}}
 */
const vmUpdatePendingAC = (payload) => ({
  type: VM_UPDATE_PENDING,
  payload,
});

/**
 * @param {string} dcUrl
 * @param {string} vmId
 * @param {VmUpdatePayload} payload
 */
export const editVmAct = (dcUrl, vmId, payload) => async (dispatch) => {
  try {
    dispatch(vmUpdatePendingAC(true));
    await editVm(dcUrl, vmId, payload);
    dispatch(notificationAC(i18n.t("notification:vmUpdateSuccess")));
    dispatch(vmUpdatePendingAC(false));
  } catch (error) {
    dispatch(
      notificationErrorAC({ message: i18n.t("notification:vmUpdateFailed"), description: parseApiErrorMessage(error) })
    );
    dispatch(vmUpdatePendingAC(false));
    throw error;
  }
};

const delayAction = (cb, delay) =>
  new Promise((resolve) =>
    setTimeout(() => {
      resolve(cb());
    }, delay)
  );

/**
 * @param {object} data
 * @param {string} data.dcUrl
 * @param {string} data.snapshotId
 * @param {string} data.vmId
 */
export const rollbackSnapshotRequestAct = (data) => async (dispatch) => {
  try {
    await rollbackSnapshot(data);
    dispatch(setSnapshotDisableAct(data));
    dispatch(notificationAC(i18n.t("notification:snapshotRollbackSuccess")));
    await delayAction(() => dispatch(unsetSnapshotDisableAct(data), 2100));
  } catch (error) {
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:snapshotRollbackFailed"),
        description: parseApiErrorMessage(error),
      })
    );
  }
};

/**
 * @param {object} data
 * @param {string} data.dcUrl
 * @param {string} data.snapshotId
 * @param {string} data.vmId
 */
export const deleteSnapshotRequestAct = (data) => async (dispatch) => {
  try {
    await deleteSnapshot(data);
    dispatch(deleteSnapshotAct(data));
    dispatch(notificationAC(i18n.t("notification:snapshotDeletingSuccess")));
  } catch (error) {
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:snapshotDeletingFailed"),
        description: parseApiErrorMessage(error),
      })
    );
  }
};

const getVncUrlRequestAC = () => ({
  type: VM_VNC_URL_REQUEST,
});

const getVncUrlRequestSuccessAC = (url) => ({
  type: VM_VNC_URL_REQUEST_SUCCESS,
  payload: url,
});

const getVncUrlRequestFailureAC = () => ({
  type: VM_VNC_URL_REQUEST_FAILURE,
});

export const resetVncUrl = () => ({
  type: VM_VNC_URL_RESET,
});

/**
 * @param {string} dcUrl
 * @param {string} vmId
 */
export const vncUrlRequest = (dcUrl, vmId) => async (dispatch) => {
  try {
    dispatch(getVncUrlRequestAC());
    const { data } = await getVncUrl(dcUrl, vmId);
    dispatch(getVncUrlRequestSuccessAC(data.url));
  } catch (error) {
    dispatch(getVncUrlRequestFailureAC());
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:vncConnectionFailed"),
        description: parseApiErrorMessage(error),
      })
    );
  }
};

/**
 * @param {VmStateModel} vm
 * @param {number} networkId
 */
export const detachNetworkRequest = (vm, networkId) => async (dispatch) => {
  await detachNetwork(vm.dcOwner.api.url, vm._id, networkId);
  dispatch(notificationAC(i18n.t("notification:detachNetworkSuccess")));
};

/**
 * @param {VmStateModel} vm
 * @param {number} networkId
 */
export const attachNetworkRequest = (vm, networkId) => async (dispatch) => {
  await attachNetwork(vm.dcOwner.api.url, vm._id, networkId);
  dispatch(notificationAC(i18n.t("notification:attachNetworkSuccess")));
};

/**
 * @param {VmStateModel} vm
 * @param {number} diskId
 */
export const attachDiskRequest = (vm, diskId) => async (dispatch) => {
  await attachDisk(vm.dcOwner.api.url, vm._id, diskId);
  dispatch(notificationAC(i18n.t("notification:attachDiskSuccess")));
};

/**
 * @param {VmStateModel} vm
 * @param {number} diskId
 */
export const detachDiskRequest = (vm, diskId) => async (dispatch) => {
  try {
    await detachDisk(vm.dcOwner.api.url, vm._id, diskId);
    dispatch(notificationAC(i18n.t("notification:detachDiskSuccess")));
  } catch (error) {
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:detachDiskFailed"),
        description: parseApiErrorMessage(error),
      })
    );
    throw error;
  }
};
/**
 * @param {object} payload
 * @return {{payload: object, type: string}}
 */
export const setDcIncludeAC = (payload) => ({
  type: SET_VM_DC_INCLUDE,
  payload,
});

export const setDcIncludeMap = (dcIncludeMap) => (dispatch, getState) => {
  const { id, email } = getUserProfile(getState()) || {};

  if (email) {
    storeDatacenterIncludeMap(dcIncludeMap, String(id));
  }

  dispatch(setDcIncludeAC(dcIncludeMap));
};
