const styles = (theme) => ({
  body: {
    position: "absolute",
    width: "360px",
    maxWidth: "90%",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: "210px",
    backgroundColor: "white",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  title: {
    padding: "24px",
    fontSize: "28px",
    textAlign: "center",
  },
  modalCard: theme.classes.elems.modalCard,
  text: {
    textAlign: "center",
  },
  contentContainer: {
    "& > p": {
      marginTop: 0,
      marginBottom: "8px",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    padding: "24px",
    justifyContent: "center",
    margin: "-8px",

    "& > button": {
      margin: "8px",
      flexShrink: 0,
    },

    "@media(max-width: 767px)": {
      flexWrap: "wrap",

      "& > button": {
        flexGrow: 1,
        minWidth: "160px",
      },
    },
  },
});

export default styles;
