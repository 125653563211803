const styles = (theme) => ({
  badge: {
    padding: "3px 8px",
    fontSize: "14px",
    lineHeight: "18px",
    color: "rgba(0, 0, 0, 0.5)",
    borderRadius: "2px",
    display: "inline-block",
  },
  success: {
    backgroundColor: theme.colors.lightGreen,
    color: theme.colors.darkGreen,
  },
  failed: {
    backgroundColor: theme.colors.lightRed,
    color: theme.colors.ruby,
  },
});

export default styles;
