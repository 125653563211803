import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventHistoryGetRequest,
  getEventHistorySlice,
  getEventHistoryTable,
} from "../../../store/selectors/eventHistorySelectors";
import { keyGen } from "../../../utils/eventHistory";
import { REQUEST_PENDING } from "../../../constants/request";
import Spinner from "../../../pages/shareableComponents/Spinner/Spinner";
import SortableTable from "../../../ui-kit/SortableTable/SortableTable";
import {
  setEvenHistoryPerPageAC,
  setEventHistoryPageAC,
  setEventHistorySortFieldAC,
} from "../../../store/actions/eventHistoryActions";
import PaginationBox from "../../../components/PaginationBox";
import useEventHistoryColumnConfig from "../../../hooks/useEventHistoryColumnConfig";

const EventHistoryTable = () => {
  const requestStatus = useSelector(getEventHistoryGetRequest);
  const { sortField, sortOrder, perPage, currentPage } = useSelector(getEventHistorySlice);
  const { list, pageCount } = useSelector(getEventHistoryTable);
  const dispatch = useDispatch();
  const columnsConfig = useEventHistoryColumnConfig();
  const handleSortFieldChange = useCallback((field) => dispatch(setEventHistorySortFieldAC(field)), []);
  const handlePerPageChange = useCallback((value) => dispatch(setEvenHistoryPerPageAC(value)), []);
  const handlePageChange = useCallback((value) => dispatch(setEventHistoryPageAC(value)), []);

  return (
    <>
      <Spinner show={requestStatus === REQUEST_PENDING} />
      <SortableTable
        testId="EventHistory_table"
        data={list}
        onSortFieldChange={handleSortFieldChange}
        columnsConfig={columnsConfig}
        sortField={sortField}
        sortOrder={sortOrder}
        keyGen={keyGen}
      />
      <PaginationBox
        perPage={perPage}
        onChangePage={handlePageChange}
        onPerPageChange={handlePerPageChange}
        currentPage={currentPage}
        pageCount={pageCount}
      />
    </>
  );
};

export default EventHistoryTable;
