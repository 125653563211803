import React, { useMemo, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { selectImageForEditAct, deleteImageAct } from "../../../../store/actions/imagesActions";
import { makeStyles } from "@material-ui/core/styles";
import { formatTimestampToFullDate } from "../../../helpers";
import { formatSize } from "../../../../utils/size";
import { getVmsFromDisk } from "../../../../utils/disks";
import { hasContractAccessToFeature, FEATURES, USER_ACCESS, CONTRACT_TYPES } from "@skyportal/auth-web-client";
import Input from "../../../../ui-kit/Input";
import ConfirmationModal from "../../../../components/ConfirmationModal";

import styles from "./styles";
import { Button, Tooltip } from "@skyportal/ui-kit";

const useStyles = makeStyles(styles);

/**
 * @param {DiskStateModel} image
 * @param {VmStateModel[]} vms
 * @param {ColumnConfigItem[]} selectedCols
 * @param {UserInfoModel} userProfile
 * @return {JSX.Element}
 * @constructor
 */
const DiskRow = ({ image, vms, selectedCols, userProfile }) => {
  const { t } = useTranslation("imagesPage");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isRequesting, setIsRequesting] = useState(false);
  const [isModalOpen, setModalState] = useState(false);
  const attachedVms = useMemo(() => getVmsFromDisk(image, vms), [image, vms]);
  const attacheToLabel = attachedVms.length
    ? attachedVms.map((item) => item.name).join(", ")
    : t("iaasCommon:NotAttached");
  const isEditBtnDisabled = !hasContractAccessToFeature(
    userProfile,
    image.agreement,
    CONTRACT_TYPES.IA,
    FEATURES.IAAS_DISKS,
    [USER_ACCESS.UPDATE]
  );
  const isDeleteBtnDisabled =
    image.vms.length > 0 ||
    !hasContractAccessToFeature(userProfile, image.agreement, CONTRACT_TYPES.IA, FEATURES.IAAS_DISKS, [
      USER_ACCESS.DELETE,
    ]);
  const [confirmationText, setConfirmationText] = useState("");
  const confirmWord = t("deleteModal.confirmWord");

  const editImage = () => dispatch(selectImageForEditAct(image));

  const handleConfirmDeleteClick = async () => {
    try {
      setIsRequesting(true);
      await dispatch(deleteImageAct(image.dcOwner.api.url, image._id));
      setModalState(false);
    } catch (e) {
      console.error(e);
    }
    setIsRequesting(false);
  };

  const handleCloseModal = () => setModalState(false);

  return (
    <>
      <div className={classes.row} key={image._id} data-testid="DiskRow">
        {selectedCols
          .filter((col) => col.selected)
          .map((item) => (
            <div key={item.name} className={classes.cell}>
              <div className={classes.cellShell}>
                <span className={classes.inCell}>
                  {item.name === "name" && (image.name || "-")}
                  {item.name === "tier" && (image.tier !== "null" && image.tier ? image.tier : "-")}
                  {item.name === "group" && (image.__groupName || "-")}
                  {item.name === "datacenter" && (image.dcOwner.name || "-")}
                  {item.name === "created" && formatTimestampToFullDate(image[item.name])}
                  {item.name === "size" && formatSize(image.size)}
                  {item.name === "vms" && (
                    <span
                      data-testid="DiskRow__vmLabel"
                      title={attacheToLabel}
                      className={cn(classes.statusLabel, {
                        [classes.attached]: attachedVms.length,
                        [classes.notAttached]: !attachedVms.length,
                      })}
                    >
                      <span className={classes.statusLabelText}>{attacheToLabel}</span>
                    </span>
                  )}
                </span>
              </div>
            </div>
          ))}

        <div className={cn(classes.cell, classes.dcRowControls)}>
          <Tooltip title={t("iaasCommon:Edit")}>
            <Button
              data-testid="editDiskBtn"
              type="tertiary"
              leftIcon="pen"
              disabled={isEditBtnDisabled}
              onClick={editImage}
            />
          </Tooltip>
          <Tooltip title={t("iaasCommon:Delete")}>
            <Button
              data-testid="deleteDiskBtn"
              type="tertiary"
              leftIcon="trash-bin"
              disabled={isDeleteBtnDisabled}
              onClick={() => setModalState(true)}
            />
          </Tooltip>
        </div>
      </div>
      <ConfirmationModal
        isRequesting={isRequesting}
        title={t("deleteModal.title")}
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirmed={handleConfirmDeleteClick}
        onCancel={handleCloseModal}
        isDisabled={confirmWord !== confirmationText.toLowerCase()}
      >
        <p>{t("deleteModal.confirmQuestion")}</p>
        <Input
          className={classes.fullWidthInput}
          placeholder={t("deleteModal.placeholder", { text: confirmWord })}
          type="text"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
        />
      </ConfirmationModal>
    </>
  );
};

export default DiskRow;
