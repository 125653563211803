/**
 * @typedef {"VM_VARIANT"|"CPU_VARIANT"|"DATA_VARIANT"|"MEMORY_VARIANT"} UsageStatisticCardVariant
 */

/**
 * @type {UsageStatisticCardVariant}
 */
export const VM_VARIANT = "VM_VARIANT";
/**
 * @type {UsageStatisticCardVariant}
 */
export const CPU_VARIANT = "CPU_VARIANT";
/**
 * @type {UsageStatisticCardVariant}
 */
export const DATA_VARIANT = "DATA_VARIANT";
/**
 * @type {UsageStatisticCardVariant}
 */
export const MEMORY_VARIANT = "MEMORY_VARIANT";

export const CLASS_NAME_MAP = {
  [VM_VARIANT]: "vmVariant",
  [CPU_VARIANT]: "cpuVariant",
  [DATA_VARIANT]: "dataVariant",
  [MEMORY_VARIANT]: "memoryVariant",
};

export const FA_ICON_MAP = {
  [VM_VARIANT]: "database",
  [CPU_VARIANT]: "file-archive",
  [DATA_VARIANT]: "chart-pie",
  [MEMORY_VARIANT]: "list",
};

export const BUTTON_COLOR_MAP = {
  [VM_VARIANT]: "lightBlue",
  [CPU_VARIANT]: "lightGrey",
  [DATA_VARIANT]: "lightGreen",
  [MEMORY_VARIANT]: "lightBrown",
};
