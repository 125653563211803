import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Slider } from "@material-ui/core";
import { isResizingVmHardwareAllowed } from "../../../helpers";
import Input from "../../../../ui-kit/Input";

import {
  attributeParser,
  enhanceLimits,
  TEMPLATE_VALIDATION_TYPE_ANY,
  TEMPLATE_VALIDATION_TYPE_FIXED,
  TEMPLATE_VALIDATION_TYPE_LIST,
  TEMPLATE_VALIDATION_TYPE_RANGE,
} from "../constantsNHelpers";
import { formStyles } from "../styles";

const useStyles = makeStyles(formStyles);
const DEFAULT_CPU_STEP = 1;
const DEFAULT_CPU_MARK_MIN_VALUE = 1;
const DEFAULT_CPU_MARK_MAX_VALUE = 4;
const DEFAULT_CPU_MARKS = [DEFAULT_CPU_MARK_MIN_VALUE, DEFAULT_CPU_MARK_MAX_VALUE];
const DEFAULT_CPU_MARK_OPTIONS = DEFAULT_CPU_MARKS.map((mark) => ({ value: mark, label: String(mark) }));
const DEFAULT_CPU_ATTRIBUTES = {};

const composeMarksByValidationRules = (attributes) => {
  switch (attributes.type) {
    case TEMPLATE_VALIDATION_TYPE_LIST:
    case TEMPLATE_VALIDATION_TYPE_RANGE:
      return attributes.options.map((option) => ({
        value: Number(option),
        label: option,
      }));
    case TEMPLATE_VALIDATION_TYPE_FIXED:
      return [
        DEFAULT_CPU_MARKS[0],
        {
          value: Number(attributes.defaultValue),
          label: attributes.defaultValue,
        },
      ];
    default:
      return DEFAULT_CPU_MARKS;
  }
};

// todo: Refactor with MemorySlider (duplicated logic)

const CpuSlider = ({
  VmCpuSliderValue,
  setVmCpuSlider,
  handleCpuChange,
  selectedTemplate,
  VmCpuInputValue,
  setVmCpuInput,
  isEditVMPage,
  cpuRef,
  currentVm,
}) => {
  const { t } = useTranslation("vmPage");
  const classes = useStyles();
  const [attributes, setAttributes] = useState(DEFAULT_CPU_ATTRIBUTES);
  const [marks, setMarks] = useState(DEFAULT_CPU_MARK_OPTIONS);

  const setSliderAndInputValue = (value) => {
    setVmCpuSlider(Number(value));
    setVmCpuInput(String(value));
  };

  const updateSliderMarks = (value) => {
    if (!attributes.type || attributes.type === TEMPLATE_VALIDATION_TYPE_ANY) {
      enhanceLimits(marks, setMarks, value || VmCpuSliderValue);
    }
  };

  const onInputSizeBlur = (event) => {
    const maxCpu = marks[marks.length - 1].value;
    const { value } = event.target;
    if (/^\d+$/.test(value)) {
      const digitalized = parseInt(value, 10);
      if (attributes.type === TEMPLATE_VALIDATION_TYPE_RANGE) {
        if (digitalized > Number(attributes.options[1]) || digitalized < Number(attributes.options[0])) {
          setSliderAndInputValue(attributes.defaultValue);
          return;
        }
      }
      if (attributes.type === TEMPLATE_VALIDATION_TYPE_LIST) {
        if (attributes.options.includes(value)) {
          setSliderAndInputValue(digitalized);
          return;
        }
        setSliderAndInputValue(attributes.defaultValue);
        return;
      }
      if (digitalized >= maxCpu) {
        setSliderAndInputValue(digitalized);
      } else if (digitalized <= DEFAULT_CPU_MARK_MIN_VALUE) {
        setSliderAndInputValue(DEFAULT_CPU_MARK_MIN_VALUE);
      } else {
        setSliderAndInputValue(digitalized);
      }
      updateSliderMarks(digitalized);
    }
  };

  useEffect(() => {
    if (!selectedTemplate) {
      return;
    }

    const { vcpu } = selectedTemplate.hardware.validations;

    if (typeof vcpu !== "string") {
      setMarks(DEFAULT_CPU_MARK_OPTIONS);
      setAttributes(DEFAULT_CPU_ATTRIBUTES);
      setSliderAndInputValue(selectedTemplate.hardware.vcpu || DEFAULT_CPU_MARK_MIN_VALUE);
      return;
    }

    const parsedAttribute = attributeParser(vcpu);
    setAttributes(parsedAttribute);

    setSliderAndInputValue(parsedAttribute.defaultValue);
    setMarks(composeMarksByValidationRules(parsedAttribute));
  }, [selectedTemplate]);

  useEffect(() => {
    const handleMouseUp = () => updateSliderMarks();

    if (cpuRef.current) {
      cpuRef.current.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      if (cpuRef.current) {
        cpuRef.current.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [attributes, marks, setMarks, VmCpuSliderValue]);

  const areInputsDisabled =
    (!isEditVMPage && !selectedTemplate) ||
    attributes.type === TEMPLATE_VALIDATION_TYPE_FIXED ||
    (isEditVMPage && !isResizingVmHardwareAllowed(currentVm));

  return (
    <>
      <div className={classes.fieldLabel}>{t("createOrEdit.fields.vCpu.label")}</div>
      <div className={classes.slideWrapper}>
        <div className={classes.sliderInner} data-testid="CpuSlider__slide-wrapper">
          <Slider
            className={classes.slider}
            value={VmCpuSliderValue || DEFAULT_CPU_MARK_MIN_VALUE}
            onChange={handleCpuChange}
            min={marks[0].value}
            max={marks[marks.length - 1].value}
            step={attributes.type === TEMPLATE_VALIDATION_TYPE_LIST ? null : DEFAULT_CPU_STEP}
            disabled={areInputsDisabled}
            marks={marks}
          />
        </div>
        <div className={classes.slideInputWrapper}>
          <Input
            data-testid="CpuSlider__input"
            className={cn(classes.numberInputField, classes.inputSuffixOffset)}
            value={VmCpuInputValue}
            onChange={(event) => setVmCpuInput(event.target.value)}
            disabled={areInputsDisabled}
            onBlur={onInputSizeBlur}
          />
        </div>
      </div>
    </>
  );
};

export default CpuSlider;
