import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { getDcIdFromEntity } from "../../../../utils/groups";
import { ecVMSel } from "../../../../store/selectors/ecVMSelectors";
import TemplateItem from "./TemplateItem";
import { formStyles } from "../styles";

const useStyles = makeStyles(formStyles);

/**
 * @param {string} value
 * @param {function(value: TemplateModel) : void} onChange
 * @param {boolean} [disabled]
 * @param {string} datacenterId
 * @return {JSX.Element}
 * @constructor
 */
export const TemplateSelect = ({ value, onChange, disabled, datacenterId }) => {
  const classes = useStyles();
  const { templates } = useSelector(ecVMSel);
  const datacenterTemplates = useMemo(
    () => (datacenterId ? templates.filter((item) => getDcIdFromEntity(item) === datacenterId) : []),
    [datacenterId, templates]
  );
  const handleChange = (event) => onChange(templates.find((template) => template._id === event.target.value));

  return (
    <Select
      IconComponent={KeyboardArrowDownIcon}
      classes={{
        select: classes.muiSelectRoot,
        icon: classes.muiSelectIcon,
      }}
      id="template_select"
      className={classes.muiTemplateRoot}
      disableUnderline
      name="template"
      value={value}
      onChange={handleChange}
      disabled={disabled}
      MenuProps={{
        classes: {
          paper: classes.menuPaper,
          list: classes.selectMenu,
        },
      }}
    >
      {datacenterTemplates.map((template) => (
        <MenuItem classes={{ root: classes.listItemRoot }} value={template._id} key={template._id}>
          <TemplateItem template={template} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default React.memo(TemplateSelect);
