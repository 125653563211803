import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@skyportal/ui-kit";
import Input from "../../../ui-kit/Input";
import { setSearchTextAC } from "../../../store/actions/regionActions";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {function} onCreateBtnClick
 * @return {JSX.Element}
 * @constructor
 */
const RegionTopBar = ({ onCreateBtnClick }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation("regionPage");

  const changeFilterValue = (e) => dispatch(setSearchTextAC(e.target.value));

  return (
    <div className={classes.headerRowCell}>
      <div className={classes.divControls}>
        <div className={classes.tableSearchGroup}>
          <Input onChange={changeFilterValue} placeholder={t("searchPlaceholder")} />
        </div>
        <div className={classes.btnContainer}>
          <Button data-testid="RegionCreateBtn" onClick={onCreateBtnClick}>
            {t("iaasCommon:createNew")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegionTopBar;
