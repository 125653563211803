/**
 * @typedef {"days"|"months"|"years"} ConsumptionTimeLabels
 */
/**
 * @type {ConsumptionTimeLabels}
 */
export const DAYS_LABEL = "days";
/**
 * @type {ConsumptionTimeLabels}
 */
export const MONTHS_LABEL = "months";
/**
 * @type {ConsumptionTimeLabels}
 */
export const YEARS_LABEL = "years";
/**
 * @type {ConsumptionTimeLabels[]}
 */
export const TIME_RANGE = Object.freeze([DAYS_LABEL, MONTHS_LABEL, YEARS_LABEL]);
export const GROUP_TYPE = Object.freeze({
  [DAYS_LABEL]: "DAILY",
  [MONTHS_LABEL]: "MONTHLY",
  [YEARS_LABEL]: "YEARLY",
});
