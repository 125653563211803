import React from "react";
import cn from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {string} text
 * @param {"left"|"right"} [orientation]
 * @return {JSX.Element}
 * @constructor
 */
const Divider = ({ text, orientation }) => {
  const classes = useStyles();

  return (
    <div
      className={cn(classes.root, { [classes.left]: orientation === "left", [classes.right]: orientation === "right" })}
      role="separator"
    >
      {text && <span className={classes.text}>{text}</span>}
    </div>
  );
};

export default Divider;
