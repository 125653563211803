import { NOTIFICATION } from "../types";

export const notificationAC = (payload) => ({
  type: NOTIFICATION,
  payload: {
    message: payload,
    description: null,
  },
});

export const notificationErrorAC = (payload) => ({
  type: NOTIFICATION,
  payload: {
    message: `Error: ${payload.message}`,
    description: payload.description || null,
  },
});
