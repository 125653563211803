export const templates = [{ name: "Template1", memory: "768MB", cpu: 1, disk: "8Gb", imageSrc: "" }];
export const CPU_STEPS = 20;
// steps 16, 32, 64, 128, 256, 512, 1024
export const MEMORY_STEPS = 12.5;
export const SLIDER_MAX = 100;
export const SLIDER_MIN = 0;
export const cpuMarks = [
  { value: SLIDER_MIN, label: "1" },
  { value: SLIDER_MAX, label: "6" },
];

export const TEMPLATE_VALIDATION_TYPE_LIST = "list";
// todo check existing "range-float" or its "range"
export const TEMPLATE_VALIDATION_TYPE_RANGE = "range";
export const TEMPLATE_VALIDATION_TYPE_FIXED = "fixed";
export const TEMPLATE_VALIDATION_TYPE_ANY = "anyvalue";
/**
 * @deprecated
 */
export const getCpuValue = (value) => (value / CPU_STEPS + 1).toFixed();

export const attributeParser = (attribute) => {
  const parsed = {};
  const rawData = attribute.split("|");
  if (rawData.length === 3) {
    parsed.editable = rawData[0] || "";
    parsed.type = rawData[1] || "";
    parsed.value = rawData[2] || "";
    parsed.title = rawData[2] || "";
  } else if (rawData.length === 5) {
    let options;

    if (rawData[3].includes("..")) {
      options = rawData[3].split("..");
    } else {
      const dryString = rawData[3].charAt(0) === " " ? rawData[3].substring(1) : rawData[3];
      options = dryString.split(",");
    }

    parsed.editable = rawData[0] || "";
    parsed.type = rawData[1] || "";
    parsed.title = rawData[2] || "";
    parsed.options = options || [];
    parsed.defaultValue = rawData[4] || "";
  } else {
    parsed.editable = true;
    parsed.type = "anyvalue";
    parsed.value = "0";
  }
  return parsed;
};

export const enhanceLimits = (marks, setMarks, value) => {
  const maxExistingMarkValue = Number(marks[marks.length - 1].value);
  const inputValue = Number(value);

  if (inputValue >= maxExistingMarkValue) {
    const _marks = [...marks];
    const newMaxMark = inputValue * 2;
    _marks[_marks.length - 1].value = newMaxMark;
    _marks[_marks.length - 1].label = String(newMaxMark);
    setMarks(_marks);
  }
};

export const getTemplateHardwareValidations = (template) => template.hardware.validations;
export const getParsedTemplateHardwareValidations = (template) =>
  Object.entries(getTemplateHardwareValidations(template)).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: typeof value === "string" ? attributeParser(value) : {} }),
    {}
  );

export const composeHumanFriendlyValidationInfo = (
  validation,
  formatter = (item) => String(item),
  defaultValue = 0
) => {
  const defaultResult = `${formatter(defaultValue)}`;
  if (!validation) return ``;

  switch (validation.type) {
    case TEMPLATE_VALIDATION_TYPE_FIXED:
      return `[${formatter(defaultValue || validation.defaultValue)}]`;
    case TEMPLATE_VALIDATION_TYPE_RANGE:
      return `[${formatter(validation.options[0])}...${formatter(validation.options[1])}]`;
    case TEMPLATE_VALIDATION_TYPE_LIST:
      return `[${validation.options.map(formatter).join(", ")}]`;
    default:
      return defaultResult;
  }
};
