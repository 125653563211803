import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import * as regionSelectors from "../../../store/selectors/regionSelectors";
import * as dcSelectors from "../../../store/selectors/dcSelectors";
import { Spinner } from "@skyportal/ui-kit";
import { REQUEST_PENDING, REQUEST_UNCALLED } from "../../../constants/request";
import PaginationPerSizeSelect from "../../../components/PaginationPerSizeSelect";
import { toggleNewDCModal, setCurrentPageAC, setPerPageAC } from "../../../store/actions/datacenterActions";
import DatacenterRow from "./DatacenterRow/DatacenterRow";
import DatacentersTableHead from "./DatacentersTableHead";
import HeaderTopBar from "./HeaderTopBar";

import styles from "./styles";

const useStyles = makeStyles(styles);

const DatacentersContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { list, pageCount } = useSelector(dcSelectors.getTableDatacenters);
  const { currentPage, perPage } = useSelector(dcSelectors.dcSel);
  const { regions } = useSelector(regionSelectors.regionSel);
  const deleteRequestStatus = useSelector(dcSelectors.getDeleteRequestStatus);
  const updateSettingsRequestStatus = useSelector(dcSelectors.getUpdateSettingsRequestStatus);
  const isDatacenterRequesting = [REQUEST_UNCALLED, REQUEST_PENDING].includes(
    useSelector(dcSelectors.getDataCenterRequest)
  );

  const toggleCreateNewRow = () => dispatch(toggleNewDCModal());

  const handlePaginationChange = (_, page) => dispatch(setCurrentPageAC(page));
  const handlePerPageCountChange = (step) => dispatch(setPerPageAC(step));

  return (
    <div className={classes.pageBox}>
      <Spinner show={isDatacenterRequesting} />
      <HeaderTopBar toggleCreateNewRow={toggleCreateNewRow} />
      <div className={classes.tableContainer}>
        <div className={classes.table}>
          <DatacentersTableHead toggleCreateNewRow={toggleCreateNewRow} />
          <div>
            {list.map((datacenter) => (
              <DatacenterRow
                key={datacenter.id}
                rowProps={datacenter}
                regions={regions}
                deleteRequestStatus={deleteRequestStatus}
                updateSettingsRequestStatus={updateSettingsRequestStatus}
              />
            ))}
          </div>
        </div>
      </div>

      <div className={classes.pagingContainer}>
        <PaginationPerSizeSelect onChange={handlePerPageCountChange} value={perPage} />
        <Pagination
          page={currentPage}
          count={pageCount}
          onChange={handlePaginationChange}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
};

export default DatacentersContent;
