export default (theme) => ({
  hintIcon: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.inactiveGrey,
    cursor: "pointer",

    "& > *": {
      color: "#fff",
      width: "10px",
      height: "10px",
    },
  },
});
