/**
 * @param {string} group
 * @param {string} agreementId
 * @param {object} rest
 * @return {{_groupWithAgreementID: string, agreementId: string, group: string}}
 */
export const decorateWithMergedGroupAndAgreementIdFiled = ({ group, agreementId, ...rest }) => ({
  ...rest,
  group,
  agreementId,
  _groupWithAgreementID: `${group} (${agreementId})`,
});
