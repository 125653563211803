import { getGroupMapByDcId, getGroupNameFromListById } from "../../utils/groups";
import {
  getPaginationSlice,
  getVmsTotalHardware,
  makeFindObjectValueMatch,
  makeSortByDisks,
  makeSortByGroup,
  makeSortByHardwareKey,
  makeSortByHostname,
  makeSortByName,
  makeSortByStatus,
  makeSortByDatecenter,
  makeSortByOs,
  makeUniversalSort,
  networkSortNormalizer,
  networkIpSortNormalizer,
  networkMacSortNormalizer,
} from "../../pages/helpers";
import { getNetworkIdList, getVmStartTime } from "../../utils/vm";
import * as groupSelectors from "./groupSelectors";
import * as networkSelectors from "./networkSelectors";

/**
 * @param {RootState} state
 * @return {VmState}
 */
export const vmSel = (state) => state.vm;
/**
 * @param {RootState} state
 * @return {VmStateModel[]}
 */
export const getVMsSel = (state) => state.vm.vms || [];
/**
 * @param {RootState} state
 * @return {VmSelectedColumn[]}
 */
export const getSelectedColsSel = (state) => state.vm.selectedCols;
export const getSnapshotColsSel = (state) => state.vm.snapshotCols;
export const getVmListRequest = (state) => state.vm.vmListRequest;
/**
 * @param {RootState} state
 * @return {Object}
 */
export const getDcIncludeMap = (state) => vmSel(state).datacenterIncludeMap;

const sortHandlerMap = {
  cpu: makeSortByHardwareKey("vcpu"),
  memory: makeSortByHardwareKey("memory"),
  size: makeSortByHardwareKey("size"),
  status: makeSortByStatus,
  datacenter: makeSortByDatecenter,
  os: makeSortByOs,
  name: makeSortByName,
  disk: makeSortByDisks,
  group: makeSortByGroup,
  network: makeUniversalSort(networkSortNormalizer),
  ip: makeUniversalSort(networkIpSortNormalizer),
  mac: makeUniversalSort(networkMacSortNormalizer),
  hostname: makeSortByHostname,
};

const defaultSort = makeUniversalSort(getVmStartTime);

export const getTableVms = (state) => {
  const { selectedFilter, sorterValue, filterValue, currentVMPage, sortWay, vms, perPage, datacenterIncludeMap } =
    vmSel(state);
  const groupMapByDcId = getGroupMapByDcId(groupSelectors.getGroups(state));
  const networkMapByDcId = getGroupMapByDcId(networkSelectors.getNetworkList(state));
  let _tableVms = [...vms];

  if (Object.values(datacenterIncludeMap).some(Boolean)) {
    _tableVms = _tableVms.filter((item) => datacenterIncludeMap[item.dcOwner.id]);
  }

  _tableVms = _tableVms.map((vm) => ({
    ...vm,
    __groupName: getGroupNameFromListById(groupMapByDcId[vm.dcOwner.id], vm.group, ""),
    __networkNameList: (networkMapByDcId[vm.dcOwner.id] || [])
      .filter((network) => getNetworkIdList(vm).includes(network.id))
      .map((item) => item.name),
    __networkIpList: vm.hardware.networks.map((net) => net.ip),
    __networkMacList: vm.hardware.networks.map((net) => net.mac),
  }));

  if (filterValue) {
    _tableVms = _tableVms.filter(
      makeFindObjectValueMatch(
        filterValue,
        ["_id"],
        ["__groupName", "__networkNameList", "__networkIpList", "__networkMacList"]
      )
    );
  }

  if (selectedFilter) {
    _tableVms = _tableVms.filter((vm) => vm.status.name === selectedFilter);
  }

  if (sortHandlerMap[sorterValue]) {
    _tableVms.sort(sortHandlerMap[sorterValue](sortWay));
  } else {
    _tableVms.sort(defaultSort("desc"));
  }

  const total = _tableVms.length;
  const list = getPaginationSlice(_tableVms, currentVMPage, perPage);

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list,
    totalCpu: getVmsTotalHardware(vms, "vcpu"),
    totalMemory: getVmsTotalHardware(vms, "memory"),
    totalAllocatedSize: getVmsTotalHardware(vms, "size"),
  };
};

/**
 * @param {string} id
 * @return {function(RootState): VmStateModel[]}
 */
export const makeGetVmsByDcId = (id) => (state) => getVMsSel(state).filter((item) => !id || item.dcOwner.id === id);
