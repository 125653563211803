import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { makeStyles } from "@material-ui/core/styles";
import { FILTER_KEY_LIST, isAnyFilterExists } from "../../../utils/eventHistory";
import { getDateRange, getEventHistoryFilters } from "../../../store/selectors/eventHistorySelectors";
import {
  fetchEventHistoryMetrics,
  resetEventHistoryFiltersAC,
  setEventHistoryFiltersAC,
} from "../../../store/actions/eventHistoryActions";
import Divider from "../../../components/Divider";
import EventHistoryFilter from "../EventHistoryFilter";
import DateRangeSelectionBar from "./DateRangeSelectionBar";
import styles from "./styles";
import { Button } from "@skyportal/ui-kit";

const useStyles = makeStyles(styles);

const labels = {
  action_name: "action",
  name: "name",
  type: "type",
  gdisplayname: "group",
  username: "username",
  reason: "status",
};

const FilterBar = () => {
  const [isCommitted, setIsCommitted] = useState(false);
  const { t } = useTranslation("eventHistory");
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector(getEventHistoryFilters);
  const dateRange = useSelector(getDateRange);
  /**
   * @type {React.MutableRefObject<EventHistoryFilters>}
   */
  const draftFilterRef = useRef(cloneDeep(filters));
  /**
   * @param {EventHistorySortFields} key
   * @param {string|number} value
   */
  const handleChangeFilter = (key, value) => {
    draftFilterRef.current[key] = value;
    setIsCommitted(false);
  };

  const handleApplyClick = () => {
    dispatch(setEventHistoryFiltersAC(draftFilterRef.current));
    setIsCommitted(true);
  };

  const handleResetClick = () => {
    dispatch(resetEventHistoryFiltersAC());
    setIsCommitted(true);
  };

  useEffect(() => {
    dispatch(resetEventHistoryFiltersAC());
    setIsCommitted(false);
    dispatch(fetchEventHistoryMetrics());
  }, [dateRange, dispatch]);

  return (
    <div data-testid="FilterBar">
      <DateRangeSelectionBar />
      <Divider />
      <div className={classes.container}>
        {FILTER_KEY_LIST.map((eventKey) => (
          <div key={eventKey} className={classes.controlWrap} data-testid={`${eventKey}_control`}>
            <div className={classes.label}>{t(`tableColumns.${labels[eventKey]}`)}</div>
            <EventHistoryFilter keyIndex={eventKey} onChange={handleChangeFilter} />
          </div>
        ))}
      </div>
      <div className={classes.controlsContainer}>
        <div className={classes.controlItem}>
          <Button
            data-testid="reset_btn"
            type="secondary"
            disabled={!isAnyFilterExists(filters)}
            onClick={handleResetClick}
          >
            {t("iaasCommon:Reset")}
          </Button>
        </div>
        <div className={classes.controlItem}>
          <Button data-testid="apply_btn" disabled={isCommitted} onClick={handleApplyClick}>
            {t("iaasCommon:Apply")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
