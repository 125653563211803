const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    margin: "15px 0",
    textAlign: "center",
    color: theme.colors.black,
    fontWeight: 600,
    width: "100%",
    borderTop: 0,
    borderTopColor: "rgba(0,0,0,0.06)",

    "&:before": {
      content: '""',
      display: "inline-block",
      boxSizing: "border-box",
      position: "relative",
      top: "50%",
      width: "50%",
      transform: "translateY(50%)",
      borderTop: "2px solid transparent",
      borderTopColor: "inherit",
      borderBottom: 0,
    },

    "&:after": {
      content: '""',
      boxSizing: "border-box",
      position: "relative",
      top: "50%",
      width: "50%",
      transform: "translateY(50%)",
      borderTop: "2px solid transparent",
      borderTopColor: "inherit",
      borderBottom: 0,
    },
  },
  left: {
    "&:before": {
      top: "50%",
      width: "5%",
    },

    "&:after": {
      top: "50%",
      width: "95%",
    },
  },
  right: {
    "&:before": {
      top: "50%",
      width: "95%",
    },

    "&:after": {
      top: "50%",
      width: "5%",
    },
  },
  text: {
    display: "inline-block",
    padding: "0 1em",
    whiteSpace: "nowrap",
  },
});

export default styles;
