/**
 * @param {RootState} state
 * @return {GroupSlice}
 */
export const getGroupSlice = (state) => state.group;
/**
 * @param {RootState} state
 * @return {GroupStateModel[]}
 */
export const getGroups = (state) => getGroupSlice(state).list;
