import React from "react";
import { useSelector } from "react-redux";
import { REQUEST_PENDING } from "../../../constants/request";
import { Spinner } from "@skyportal/ui-kit";
import {
  getDetailedReportRequest,
  getReportRequest,
  getStorageDetailsReportRequest,
} from "../../../store/selectors/reportSelectors";

const ReportsSpinner = () => {
  const isShow = [
    useSelector(getReportRequest),
    useSelector(getDetailedReportRequest),
    useSelector(getStorageDetailsReportRequest),
  ].some((status) => status === REQUEST_PENDING);

  return <Spinner show={isShow} />;
};

export default React.memo(ReportsSpinner);
