import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MONTH_LABEL_LIST } from "../../pages/Reports/ReportsFilterBar/constants";
import { useTranslation } from "react-i18next";
import { Icon } from "@skyportal/ui-kit";

import styles from "./styles";

const useStyles = makeStyles(styles);

const DatePickerNavBar = ({ onPreviousClick, onNextClick, month }) => {
  const { t } = useTranslation("months");
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <button className={classes.navBtn} type="button" onClick={() => onPreviousClick()}>
        <Icon type="fontawesome" className={classes.icon} icon="chevron-left" />
      </button>
      <div className={classes.label}>{`${t(MONTH_LABEL_LIST[month.getMonth()])} ${month.getFullYear()}`}</div>
      <button className={classes.navBtn} type="button" onClick={() => onNextClick()}>
        <Icon type="fontawesome" className={classes.icon} icon="chevron-right" />
      </button>
    </div>
  );
};

export default DatePickerNavBar;
