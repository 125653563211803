import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { getDataCenterList } from "../../store/selectors/dcSelectors";
import * as dashboardActions from "../../store/actions/dashboardActions";
import PageHeader from "../shareableComponents/PageHeader";
import {
  ConsumptionCardContent,
  StoredDataChartCardContent,
  TopUsedMemoryCard,
  VmTotalsCard,
  TopUsedDataCard,
  UsageStatisticCards,
  ActionHistoryCard,
} from "./Components";

import styles from "./styles";

const useStyles = makeStyles(styles);

const Dashboard = () => {
  const { t } = useTranslation("dashboardPage");
  const dispatch = useDispatch();
  const dcList = useSelector(getDataCenterList);
  const classes = useStyles();

  useEffect(() => {
    if (dcList.length) {
      dispatch(dashboardActions.fetchMetrics());
    }
  }, [dcList, dispatch]);

  return (
    <div className={classes.page}>
      <PageHeader title={t("title")} />

      <UsageStatisticCards />

      <div className={classes.chartsContainer}>
        <div className={classes.bigChartContainer}>
          <StoredDataChartCardContent />
        </div>
        <div className={classes.smChartContainer}>
          <ConsumptionCardContent />
        </div>

        <div className={classes.smChartContainer}>
          <VmTotalsCard />
        </div>
        <div className={classes.smChartContainer}>
          <TopUsedMemoryCard />
        </div>
        <div className={classes.smChartContainer}>
          <TopUsedDataCard />
        </div>
        <div className={classes.bigChartContainer} data-testid="ActionHistoryCard">
          <ActionHistoryCard />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
