import { PAGINATION_STEPS } from "../../../constants/pagination";
import {
  GET_REGIONS_REQUEST_SUCCESS,
  CREATE_REGION,
  UPDATE_REGION,
  DELETE_REGION,
  SET_REGION_SORT_FIELD,
  SET_REGION_SEARCH_TEXT,
  SET_REGION_CURRENT_PAGE,
  SET_REGION_PER_PAGE,
  GET_REGIONS_REQUEST,
  GET_REGIONS_REQUEST_FAILURE,
} from "../../types";
import { REQUEST_FAILED, REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_UNCALLED } from "../../../constants/request";

/**
 * @typedef {""|"name"|"code"|"datacenters"} RegionSortField
 */

/**
 * @typedef {object} RegionDatacenter
 * @property {string} _id
 * @property {string} id
 * @property {object} api
 * @property {string} api.url
 * @property {string} name
 * @property {string} description
 */

/**
 * @typedef {object} RegionStateModel
 * @property {string} id
 * @property {string} name
 * @property {string} code
 * @property {RegionDatacenter[]} datacenters
 */

/**
 * @typedef {object} RegionState
 * @property {RegionStateModel[]} regions
 * @property {RequestStatus} regionRequest
 * @property {number} currentPage - Region page pagination number
 * @property {string} searchText - search input value
 * @property {"asc"|"desc"} sortOrder
 * @property {RegionSortField} sortField
 * @property {PaginationStep} perPage
 */

/**
 * @type {RegionState}
 */
export const initialRegionState = {
  regionRequest: REQUEST_UNCALLED,
  regions: [],
  currentPage: 1,
  searchText: "",
  sortOrder: "asc",
  sortField: "",
  perPage: PAGINATION_STEPS[0],
};

/**
 *
 * @param {RegionState} state
 * @param action
 * @return {RegionState}
 */
const regionReducer = (state = initialRegionState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_REGIONS_REQUEST: {
      return {
        ...state,
        regionRequest: REQUEST_PENDING,
      };
    }
    case GET_REGIONS_REQUEST_SUCCESS: {
      return {
        ...state,
        regions: payload,
        regionRequest: REQUEST_SUCCESS,
      };
    }
    case GET_REGIONS_REQUEST_FAILURE: {
      return {
        ...state,
        regionRequest: REQUEST_FAILED,
      };
    }
    case CREATE_REGION: {
      return {
        ...state,
        regions: [...state.regions, payload],
      };
    }
    case UPDATE_REGION: {
      return {
        ...state,
        regions: state.regions.map((region) => (region.id === payload.id ? payload : region)),
      };
    }
    case DELETE_REGION: {
      const updatedRegions = state.regions.filter((r) => r.id !== payload.id);
      return {
        ...state,
        regions: updatedRegions,
      };
    }
    case SET_REGION_SORT_FIELD: {
      return {
        ...state,
        sortField: payload,
        sortOrder: state.sortField === payload && state.sortOrder === "desc" ? "asc" : "desc",
      };
    }
    case SET_REGION_SEARCH_TEXT: {
      return {
        ...state,
        searchText: payload,
        currentPage: 1,
      };
    }
    case SET_REGION_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: payload,
      };
    }
    case SET_REGION_PER_PAGE: {
      return {
        ...state,
        perPage: payload,
        currentPage: 1,
      };
    }
    default:
      return { ...state };
  }
};

export default regionReducer;
