import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PaginationPerSizeSelect from "../../../components/PaginationPerSizeSelect";
import { getCurrentReportPage, getPerPage } from "../../../store/selectors/reportSelectors";
import { setCurrentReportPageAct, setReportsPerPageAC } from "../../../store/actions/reportsActions";
import useUnmountResetReportState from "./hooks/useUnmountResetReportState";

import styles from "./styles";

const useStyles = makeStyles(styles);

const TablesPagination = ({ pageCount, ...rest }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const onChangePage = (_, page) => dispatch(setCurrentReportPageAct(page));
  const handlePerPageChange = (step) => dispatch(setReportsPerPageAC(Number(step)));
  const currentReportPage = useSelector(getCurrentReportPage);
  const perPage = useSelector(getPerPage);

  useUnmountResetReportState();

  return (
    <div className={classes.pagingContainer} {...rest}>
      <PaginationPerSizeSelect onChange={handlePerPageChange} value={perPage} />
      <Pagination
        page={currentReportPage}
        count={pageCount}
        onChange={onChangePage}
        variant="outlined"
        shape="rounded"
      />
    </div>
  );
};

export default React.memo(TablesPagination);
