import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Input = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { classType, className, ...rest } = props;
  return (
    <input
      ref={ref}
      type="text"
      className={classnames(classType ? classes[classType] : classes.input, className || "")}
      {...rest}
    />
  );
});

export default Input;
