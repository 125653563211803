import React from "react";
import { Icon, Tooltip } from "@skyportal/ui-kit";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";

import styles from "./styles";

const useStyles = makeStyles(styles);

/**
 * @param {string} title
 * @param {string} [faIcon]
 * @param {string} [testId]
 * @param {string} [className]
 * @return {JSX.Element}
 * @constructor
 */
const IconTooltip = ({ title, faIcon = "info", testId = "IconTooltip", className }) => {
  const classes = useStyles();

  return (
    <Tooltip placement="top" title={title}>
      <div data-testid={testId} className={cn(classes.hintIcon, className)}>
        <Icon type="fontawesome" icon={faIcon} />
      </div>
    </Tooltip>
  );
};

export default IconTooltip;
