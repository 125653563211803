import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Layout from "../../layouts";
import PageHeader from "../../pages/shareableComponents/PageHeader";
import useDatacenterOnLoad from "../../hooks/useDatacenterOnLoad";
import { fetchEventHistoryMetrics } from "../../store/actions/eventHistoryActions";
import FilterBar from "./FilterBar/FilterBar";
import EventHistoryTable from "./EventHistoryTable/EventHistoryTable";

const EventHistory = () => {
  const { t } = useTranslation("eventHistory");
  const dispatch = useDispatch();

  useDatacenterOnLoad(() => dispatch(fetchEventHistoryMetrics()));

  return (
    <Layout.PageContent>
      <PageHeader title={t("title")} />
      <Layout.PageBox marginBottom>
        <FilterBar />
      </Layout.PageBox>
      <Layout.PageBox relative>
        <EventHistoryTable />
      </Layout.PageBox>
    </Layout.PageContent>
  );
};

export default EventHistory;
