const expansionPanelStyle = () => ({
  root: {
    padding: 0,

    "&:hover": {
      backgroundColor: "#f4f4f4",
    },
  },
  summaryRoot: {
    padding: 0,
    margin: 0,
  },
  summaryContent: {
    margin: 0,
  },
});

export default expansionPanelStyle;
