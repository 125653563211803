import { notificationErrorAC } from "../store/actions/notificationActions";
import i18n from "../locales";

/**
 * @return {string | null}
 */
export const parseApiErrorMessage = (error) => {
  if (!error || !error.isAxiosError) {
    return null;
  }

  try {
    const { msg: message } = error.response.data;
    if (message.startsWith("[") && message.includes("] ")) {
      return message.split("] ")[1] || null;
    }
    return message || null;
  } catch (e) {
    return null;
  }
};

/**
 * @param {any} error
 * @return {[string, number]|[null, null]}
 */
export const parseDirectoryApiError = (error) => {
  if (!error || !error.isAxiosError) {
    return [null, null];
  }
  try {
    const {
      msg,
      data: { code },
    } = error.response.data;
    return [msg, code];
  } catch (e) {
    return [null, null];
  }
};

/**
 * @param {any} error
 * @return {string}
 */
export const getTranslatedApiErrorMessage = (error) => {
  const [message, code] = parseDirectoryApiError(error);

  return i18n.t(`directoryApiErrorCodes:${code}`, message || "");
};

/**
 * @param {any} error
 * @param {function} dispatch
 */
export const showDirectoryApiErrorNotification = (error, dispatch) => {
  const translateApiErrorMessage = getTranslatedApiErrorMessage(error);

  if (translateApiErrorMessage) {
    dispatch(notificationErrorAC({ message: translateApiErrorMessage }));
  }
};

const proxyFulfilled = (value) => value;

/**
 * @param {AxiosInstance} axiosClient
 * @param {function} handler
 * @return {number}
 */
export const injectResponseRejectedInterceptor = (axiosClient, handler) => {
  return axiosClient.interceptors.response.use(proxyFulfilled, handler);
};
/**
 * @param {AxiosInstance} axiosClient
 * @param {function} handler
 * @return {number}
 */
export const injectRequestInterceptor = (axiosClient, handler) => {
  return axiosClient.interceptors.request.use(handler, (err) => Promise.reject(err));
};

/**
 * @param {AxiosError} error
 * @return {boolean}
 */
export const isUnauthorizedError = (error) => Boolean(error && error.isAxiosError && error.response.status === 401);

/**
 * @param {AxiosError} error
 * @return {string}
 */
export const getAxiosFailedUrl = (error) => {
  if (!error || !error.isAxiosError) {
    return "";
  }

  return error.request.url;
};
