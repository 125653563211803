import axios from "axios";
import createAuthClient from "@skyportal/auth-web-client";

export const directoryClient = axios.create({
  baseURL: window.DIRECTORY_API_URL,
  withCredentials: true,
});

export const axiosClient = axios.create({
  withCredentials: true,
});

export const axiosReportClient = axios.create({
  baseURL: window.REPORTS_API_URL,
  withCredentials: true,
});

export const pricelistClient = axios.create({
  baseURL: window.PRICELIST_API_URL,
  withCredentials: true,
});

export const authClient = createAuthClient({
  authApiURL: window.AUTH_API_URL,
  authWebURL: window.AUTH_WEB_URL,
});
