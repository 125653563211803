const styles = () => ({
  root: {
    margin: "0 16px",
    paddingTop: "32px",
    borderRight: "1px solid #F0F0F0",
    borderLeft: "1px solid #F0F0F0",
  },
});

export default styles;
