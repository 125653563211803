const styles = (theme) => ({
  modalContainer: {
    width: "300px",
    backgroundColor: "#fff",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
  },
  modalHeader: {
    ...theme.classes.elems.modalHeader,
    borderBottom: `1px solid ${theme.colors.lightGrey}`,
  },
  modalBody: {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
  },
  controlRow: {
    marginBottom: "5px",
    display: "flex",
    flexDirection: "column",
  },
  controlLabel: {
    display: "block",
    fontSize: theme.fonts.smaller,
    marginBottom: "3px",
  },
  input: {
    width: "100%",
  },
  errMsg: {
    color: theme.colors.lightRed,
    fontSize: theme.fonts.small,
    height: theme.fonts.smaller,
    padding: "2px 5px",
  },
  cardFooter: {
    borderTop: `1px solid ${theme.colors.lightGrey}`,
    display: "flex",
    justifyContent: "flex-end",
    margin: "-8px",
    padding: "16px 24px",
    flexWrap: "wrap",

    "& > *": {
      margin: "8px",

      "@media(max-width: 767px) ": {
        flexGrow: 1,
        minWidth: "180px",
        height: "32px",
      },
    },
  },
  muiSelectRoot: {
    backgroundColor: theme.colors.lightGrey,
    fontSize: theme.fonts.smaller,
    paddingLeft: "24px",
    textTransform: "uppercase",
  },
  optionItem: {
    textTransform: "uppercase",
  },
});

export default styles;
