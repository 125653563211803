import { REQUEST_FAILED, REQUEST_PENDING, REQUEST_SUCCESS, REQUEST_UNCALLED } from "constants/request";
import {
  CONTRACT_PRICELIST_REQUEST,
  CONTRACT_PRICELIST_REQUEST_FAILURE,
  CONTRACT_PRICELIST_REQUEST_SUCCESS,
  RESET_PRICELIST_DATA,
  SET_PRICELIST_CURRENT_PAGE,
  SET_PRICELIST_SORT_FIELD,
} from "store/types";

export const initState = {
  contractId: "",
  list: [],
  requestStatus: REQUEST_UNCALLED,
  sortField: "",
  sortOrder: "desc",
  currentPage: 1,
  perPage: 5,
};

export default function pricelistReducer(state = initState, action) {
  switch (action.type) {
    case CONTRACT_PRICELIST_REQUEST: {
      return {
        ...state,
        requestStatus: REQUEST_PENDING,
      };
    }
    case CONTRACT_PRICELIST_REQUEST_SUCCESS: {
      return {
        ...state,
        contractId: action.payload.contractId,
        list: action.payload.list,
        requestStatus: REQUEST_SUCCESS,
      };
    }
    case CONTRACT_PRICELIST_REQUEST_FAILURE: {
      return {
        ...state,
        requestStatus: REQUEST_FAILED,
      };
    }
    case RESET_PRICELIST_DATA: {
      return {
        ...initState,
      };
    }
    case SET_PRICELIST_SORT_FIELD: {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
        currentPage: 1,
      };
    }
    case SET_PRICELIST_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
}
