const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  dateButton: {
    background: "none",
    padding: "4px 8px",
    height: "auto",
    fontFamily: `"Dustin Sans", serif`,
    fontSize: "12px",
    lineHeight: "16px",
    flexShrink: 0,

    "@media(max-width: 767px)": {
      display: "none",
    },
  },
  selectedDropdown: {
    borderRadius: "4px",
    padding: "4px 8px",
    height: "auto",
    fontFamily: `"Dustin Sans", serif`,
    fontSize: "12px",
    lineHeight: "16px",
    backgroundColor: theme.colors.skyBlueAlt,
  },
  dropdown: {
    display: "none",

    "@media(max-width: 767px)": {
      display: "block",
    },
  },
  selected: { backgroundColor: theme.colors.skyBlueAlt },
});

export default styles;
