import i18n from "../locales";

/**
 * @param {string} a
 * @param {string} b
 * @return {number}
 */
export const sortString = (a, b) => {
  try {
    return a.localeCompare(b, i18n.language, { ignorePunctuation: true });
  } catch (error) {
    console.error(error);
  }
  return a.toLowerCase() >= b.toLowerCase() ? 1 : -1;
};
