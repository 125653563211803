import React from "react";
import ReactDOM from "react-dom";
import { history as browserHistory } from "./history";
import "./index.css";

import App from "./App";

if (process.env.REACT_APP_IS_MF) {
  window.renderInfra = (containerId, history) => {
    ReactDOM.render(<App history={history} />, document.getElementById(containerId));
  };

  window.unmountInfra = (containerId) => {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
  };
} else {
  ReactDOM.render(<App history={browserHistory} />, document.getElementById("root"));
}
