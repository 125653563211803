/**
 * @param {ConsumptionMetric[]} acc
 * @param {ConsumptionMetric[]} list
 * @return {this}
 */
import { transformApiEventHistory } from "./eventHistory";

export const reduceConsumptionMetricList = (acc, list) => {
  const duplicates = list.filter((item) => acc.find((_item) => _item._id === item._id));
  const merged = acc.map((item) => {
    const duplicateItem = duplicates.find((_item) => item._id === _item._id);
    if (duplicateItem) {
      return {
        _id: item._id,
        vcpu_count: item.vcpu_count + duplicateItem.vcpu_count,
        memory_size: item.memory_size + duplicateItem.memory_size,
      };
    }
    return item;
  });
  return [...merged, ...list.filter((item) => !duplicates.includes(item))].sort(
    (a, b) => new Date(Number(a._id)) - new Date(Number(b._id))
  );
};

/**
 * @param {ReportTierApiModel[]} list
 * @param {ReportTierApiModel[]} list2
 * @return {ReportTierApiModel[]}
 */
export const mergeStoredTiers = (list, list2) =>
  [...list, ...list2].reduce((acc, item) => {
    const sameTier = acc.find((accItem) => accItem.name === item.name);
    if (sameTier) {
      sameTier.image_size += item.image_size;
    } else {
      acc.push(item);
    }

    return acc;
  }, []);

/**
 * @param {StoredMetric[]} acc
 * @param {StoredMetric[]} list
 * @return {this}
 */
export const reduceStoredMetricList = (acc, list) => {
  const duplicates = list.filter((item) => acc.find((_item) => _item._id === item._id));
  const merged = acc.map((item) => {
    const duplicateItem = duplicates.find((_item) => item._id === _item._id);
    if (duplicateItem) {
      return {
        _id: item._id,
        image_size: item.image_size + duplicateItem.image_size,
        vm_count: item.vm_count + duplicateItem.vm_count,
        tiers: mergeStoredTiers(item.tiers, duplicateItem.tiers),
      };
    }
    return item;
  });
  return [...merged, ...list.filter((item) => !duplicates.includes(item))].sort(
    (a, b) => new Date(Number(a._id)) - new Date(Number(b._id))
  );
};

/**
 * @param {TopMemoryVM[]} acc
 * @param {TopMemoryVM[]} list
 * @return {this}
 */
export const reduceTopMemoryMetricList = (acc, list) => {
  return [...acc, ...list].sort((a, b) => b.hardware.memory - a.hardware.memory).slice(0, 5);
};

/**
 * @param {TopCpuVM[]} acc
 * @param {TopCpuVM[]} list
 * @return {this}
 */
export const reduceTopCpuMetricList = (acc, list) => {
  return [...acc, ...list].sort((a, b) => b.hardware.cpu - a.hardware.cpu).slice(0, 5);
};

/**
 * @param {VmTotals} acc
 * @param {VmTotals} item
 * @return {VmTotals}
 */
export const reduceVmTotalsList = (acc, item) => ({
  total: (acc.total || 0) + (item.total || 0),
  stopped: (acc.stopped || 0) + (item.stopped || 0),
  running: (acc.running || 0) + (item.running || 0),
  pending: (acc.pending || 0) + (item.pending || 0),
});

/**
 * @param {UsageStatistic} acc
 * @param {UsageStatistic} item
 * @return {UsageStatistic}
 */
export const reduceUsageStatisticList = (acc, item) => ({
  vmTotal: (acc.vmTotal || 0) + (item.vmTotal || 0),
  memoryTotal: (acc.memoryTotal || 0) + (item.memoryTotal || 0),
  dataStored: (acc.dataStored || 0) + (item.dataStored || 0),
  cpuTotal: (acc.cpuTotal || 0) + (item.cpuTotal || 0),
  vcpuTotal: (acc.vcpuTotal || 0) + (item.vcpuTotal || 0),
});

/**
 * @param {TopUsedDataVM[]} acc
 * @param {TopUsedDataVM[]} list
 * @return {TopUsedDataVM[]}
 */
export const reduceTopUsedDataVmList = (acc, list) => {
  return [...acc, ...list].sort((a, b) => b.size - a.size).slice(0, 5);
};

/**
 * @param {ActionHistory[]} acc
 * @param {ActionHistory[]} list
 * @return {ActionHistory[]}
 */
export const reduceActionHistoryList = (acc, list) => {
  return [...acc, ...list].sort((a, b) => a.stime - b.stime).map(transformApiEventHistory);
};
