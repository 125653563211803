import React, { useMemo, useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Spinner } from "@skyportal/ui-kit";
import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as dashboardSelectors from "../../../../store/selectors/dashboardSelectors";
import { composeStoredChartData } from "./utils";
import styles from "../../../../pages/Dashboard/styles";
import { REQUEST_PENDING } from "../../../../constants/request";
import { getPastDay } from "../../../../utils/dates";
import useAllMetricsRequestPending from "../../../../hooks/useAllMetricsRequestPending";
import { fetchStoredMetrics } from "../../../../store/actions/dashboardActions";
import StoredDataChart from "./StoredDataChart";
import DateButtons from "../DateButtons/DateButtons";
import { DATE_CONTROL_LABELS, MONTH_LABEL, DAY_OFFSET } from "./constants";

const useStyles = makeStyles(styles);

const StoredDataChartCardContent = () => {
  const { t } = useTranslation("storedDataBlock");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDate, setSelected] = useState(MONTH_LABEL);
  const datesLabels = useMemo(
    () =>
      DATE_CONTROL_LABELS.map((key) => ({
        label: t(`ranges.${key.toLowerCase()}`),
        value: key,
      })),
    []
  );
  /**
   * @type {StoredMetric[]}
   */
  const storedData = useSelector(dashboardSelectors.getStored);
  /**
   * @type {RequestStatus}
   */
  const storedRequest = useSelector(dashboardSelectors.getStoredRequest);
  const chartData = useMemo(() => composeStoredChartData(storedData, t), [storedData]);

  const handleDateControlClick = (btnLabel) => {
    setSelected(btnLabel);
    dispatch(fetchStoredMetrics(getPastDay(DAY_OFFSET[btnLabel]), new Date()));
  };

  return (
    <Card className={classes.smallCard} data-testid="StoredDataChartCardContent">
      <Spinner show={useAllMetricsRequestPending() || storedRequest === REQUEST_PENDING} />
      <CardHeader
        title={t("title")}
        className={classes.blueHeader}
        classes={{
          title: classes.title,
        }}
        action={<DateButtons dates={datesLabels} selected={selectedDate} onBtnClick={handleDateControlClick} />}
      />
      <CardContent
        classes={{
          root: classnames(classes.cardContent, classes.marginTop),
        }}
      >
        <StoredDataChart data={chartData} />
      </CardContent>
    </Card>
  );
};

export default StoredDataChartCardContent;
