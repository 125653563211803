import React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const CheckBox = ({ className, isChecked, checkHandler, testId, ...rest }) => {
  const classes = useStyles();

  return (
    <span data-testid={testId} onClick={checkHandler} className={classes.cbContainer} {...rest}>
      <span
        role={`presentation ${isChecked ? "checked" : "unchecked"}`}
        className={cn(classes.cbCheckmark, { [classes.checked]: isChecked })}
      />
    </span>
  );
};

export default CheckBox;
