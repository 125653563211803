import React from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";

import styles from "./styles";

const useStyles = makeStyles(styles);

const TableRaw = ({ item, columns }) => {
  const classes = useStyles();

  return (
    <tr className={classes.tableRow}>
      {columns.map((column) => (
        <td
          title={!column.render ? item[column.key] : ""}
          key={column.key}
          className={cn(classes.tableCell, column.cellClassName)}
          width={column.width}
        >
          {column.render ? column.render(item) : item[column.key]}
        </td>
      ))}
    </tr>
  );
};

export default TableRaw;
