import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "@skyportal/ui-kit";
import CheckBox from "ui-kit/Checkbox";
import Input from "../../../../ui-kit/Input";
import { makeStyles } from "@material-ui/core/styles";
import { regionSel } from "../../../../store/selectors/regionSelectors";
import * as dcSelectors from "../../../../store/selectors/dcSelectors";
import { updateDatacenterAct, createDCAct } from "../../../../store/actions/datacenterActions";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getRegionsAct } from "../../../../store/actions/regionActions";
import { maxInputLength } from "../../../constants";
import Select from "@material-ui/core/Select";
import { REQUEST_PENDING } from "../../../../constants/request";
import MenuItem from "@material-ui/core/MenuItem";
import Spinner from "../../../../pages/shareableComponents/Spinner/Spinner";

import styles from "./styles";

const useStyles = makeStyles(styles);

const ModalBody = ({ isEdit, data = {}, closeModal }) => {
  const { t } = useTranslation("datacentersPage");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { regions } = useSelector(regionSel);
  const createRequestStatus = useSelector(dcSelectors.getCreateRequestStatus);
  const updateRequestStatus = useSelector(dcSelectors.getUpdateRequestStatus);
  const [name, setName] = useState(data.name || "");
  const [description, setDescription] = useState(data.description || "");
  const [region, setRegion] = useState((data.region && data.region._id) || "");
  const [apiurl, setApiurl] = useState((data.api && data.api.url) || "");
  const [disabled, setDisabled] = useState((data.api && data.disabled) || false);
  const [validationErrors, setValidationErrors] = useState({});

  const fieldsInvalid = (payload) => {
    const fieldsValidation = {
      name: payload.name === "",
      description: payload.description === "",
      region: !payload.region,
      "api.url": payload.api.url === "",
    };
    setValidationErrors(fieldsValidation);
    return Object.values(fieldsValidation).some(Boolean);
  };

  const save = () => {
    const payload = {
      id: data.id,
      name,
      description,
      disabled,
      region: regions.find(({ id }) => id === region),
      api: { url: apiurl },
    };
    if (fieldsInvalid(payload)) return;
    dispatch(updateDatacenterAct(payload, closeModal));
  };

  const create = () => {
    const payload = {
      name,
      description,
      disabled,
      region: regions.find(({ id }) => id === region),
      api: {
        url: apiurl,
      },
    };
    if (fieldsInvalid(payload)) return;
    dispatch(createDCAct(payload, closeModal));
  };

  const makeChangeDCField = (field, setField) => (event) => {
    if (validationErrors[field]) {
      delete validationErrors[field];
      setValidationErrors(validationErrors);
    }
    setField(event.target.value);
  };

  const changeDCApiUrl = (e) => {
    if (validationErrors["api.url"]) {
      delete validationErrors["api.url"];
      setValidationErrors(validationErrors);
    }
    setApiurl(e.target.value);
  };

  const toggleDcDisabled = () => setDisabled(!disabled);

  useEffect(() => {
    dispatch(getRegionsAct());
  }, [dispatch]);

  return (
    <div className={classes.modalBody} data-testid="DatacenterCreateEditModal">
      <Spinner show={createRequestStatus === REQUEST_PENDING || updateRequestStatus === REQUEST_PENDING} />
      <div className={classes.modalHeader}>
        {isEdit ? t("editOrCreateModal.editTitle") : t("editOrCreateModal.createTitle")}
      </div>
      <div className={classes.modalCard}>
        <div className={classes.cardRow}>
          <div className={cn(classes.cardRowLabel, { [classes.invalid]: Boolean(validationErrors.name) })}>
            {t("tableColumns.name")}
          </div>
          <Input
            name="name"
            className={classes.cardRowInput}
            placeholder={t("namePlaceholder")}
            value={name}
            onChange={makeChangeDCField("name", setName)}
            maxLength={maxInputLength}
          />
        </div>
        <div className={classes.cardRow}>
          <div className={cn(classes.cardRowLabel, { [classes.invalid]: Boolean(validationErrors.description) })}>
            {t("tableColumns.description")}
          </div>
          <Input
            name="description"
            className={classes.cardRowInput}
            placeholder={t("descriptionPlaceholder")}
            value={description}
            onChange={makeChangeDCField("description", setDescription)}
          />
        </div>
        <div className={classes.cardRow}>
          <div className={cn(classes.cardRowLabel, { [classes.invalid]: Boolean(validationErrors.region) })}>
            {t("tableColumns.region")}
          </div>
          <Select
            name="region"
            IconComponent={KeyboardArrowDownIcon}
            disableUnderline
            classes={{
              root: classes.muiSelectRoot,
              icon: classes.muiSelectIcon,
            }}
            id={region}
            title={t("regionPlaceholder")}
            onChange={makeChangeDCField("region", setRegion)}
            value={region}
          >
            {regions.map((r) => (
              <MenuItem key={r.id} classes={{ root: classes.muiMenuItem }} value={r.id}>
                {r.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.cardRow}>
          <div className={cn(classes.cardRowLabel, { [classes.invalid]: Boolean(validationErrors["api.url"]) })}>
            {t("tableColumns.apiUrl")}
          </div>
          <Input
            name="apiUrl"
            className={classes.cardRowInput}
            placeholder={t("apiUrlPlaceholder")}
            value={apiurl}
            onChange={changeDCApiUrl}
          />
        </div>
        <div className={classes.cardRow}>
          <CheckBox isChecked={disabled} checkHandler={toggleDcDisabled} />
          <span className={classes.checkboxLabel} onClick={toggleDcDisabled}>
            {t("editOrCreateModal.disabled")}
          </span>
        </div>
        <div className={classes.cardFooter} data-testid="DatacenterCreateEditModal__controls">
          {isEdit ? (
            <Button onClick={save} loading={updateRequestStatus === REQUEST_PENDING}>
              {t("iaasCommon:Save")}
            </Button>
          ) : (
            <Button onClick={create} loading={createRequestStatus === REQUEST_PENDING}>
              {t("iaasCommon:Create")}
            </Button>
          )}
          <Button type="secondary" onClick={closeModal}>
            {t("iaasCommon:Close")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalBody;
