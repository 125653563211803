const styles = (theme) => ({
  root: {
    border: "none",
    outline: "none",
    padding: "3px 8px",
    transition: "0.3s",
    height: "32px",
    minWidth: "45px",
    backgroundColor: theme.colors.black,
    borderRadius: "2px",

    "&:hover": {
      opacity: ".75",
      cursor: "pointer",
    },
  },
  icon: {
    width: "16px !important",
    height: "16px !important",
    color: "#fff",
    filter: "invert(100%) sepia(100%) saturate(0%) hue-rotate(58deg) brightness(110%) contrast(102%)",
    verticalAlign: "middle",
  },
});

export default styles;
