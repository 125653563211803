import React, { useState, useRef } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Icon, Tooltip } from "@skyportal/ui-kit";
import useClickOutsideElement from "../../hooks/useClickOutsideElement";

import styles from "./styles";

const useStyles = makeStyles(styles);

export const arrowDownSmallIcon =
  "data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgODAwIDgwMCI+CjxwYXRoIGQ9Ik02MTAuNDEsMzA0Ljc2bC0yOC4yOC0yOC4yOGMtMS45Ni0xLjk2LTUuMTYtMS45NS03LjEsMC4wM2wtMTQ1LjYsMTM2LjhsLTcsMzYuMTFoLTIyLjRoLTIyLjRsLTctMzYuMTFsLTE0NS42LTEzNi44MQoJYy0xLjkzLTEuOTctNS4wOS0xLjk4LTcuMDQtMC4wM2wtMjguMzEsMjguMzFjLTEuOTUsMS45NS0xLjk1LDUuMTIsMCw3LjA3TDM5Ni42LDQ5Ny43NGMxLjkyLDEuODEsNC45MywxLjgxLDYuODUsMGwyMDYuOTYtMTg1LjkxCglDNjEyLjM2LDMwOS44OCw2MTIuMzYsMzA2LjcyLDYxMC40MSwzMDQuNzZ6Ii8+Cjwvc3ZnPg==";

const IconicDropdown = ({
  mainIcon,
  children,
  tooltipTitle,
  label = "",
  additionalAct,
  hideOnClick = false,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const toggleMenu = (event) => {
    event.stopPropagation();

    setIsOpen((_isOpen) => !_isOpen);

    if (additionalAct) {
      dispatch(additionalAct());
    }
  };

  const handleOptionsContainerClick = (event) => {
    if (hideOnClick) {
      event.stopPropagation();

      setIsOpen(() => false);
    }
  };

  const handleOutsideClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useClickOutsideElement(wrapperRef, handleOutsideClick);

  const button = (
    <div tabIndex={0} onClick={toggleMenu} role="button" className={classes.mainBar}>
      <div className={classes.iconShell}>
        {label && <span className={classes.label}>{label}</span>}
        <Icon icon={mainIcon} color="white" />
      </div>
      <div className={classes.iconShell}>
        <Icon icon="arrow-down-small" height={20} color="white" />
      </div>
    </div>
  );

  return (
    <>
      <div className={cn(classes.dropdown, className)} {...rest} ref={wrapperRef}>
        {tooltipTitle && !isOpen ? <Tooltip title={tooltipTitle}>{button}</Tooltip> : button}
      </div>
      <div className={cn(classes.elements, { [classes.isExpanded]: isOpen })} onClick={handleOptionsContainerClick}>
        {children}
      </div>
    </>
  );
};

export default IconicDropdown;
