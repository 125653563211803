import { useMemo } from "react";
import { useSelector } from "react-redux";
import { composeDatacenterOptions } from "../utils/datacenter";
import { getDataCenterList } from "../store/selectors/dcSelectors";

const useDatacenterOptions = (customDcList) => {
  const dcList = useSelector(getDataCenterList);
  return useMemo(() => composeDatacenterOptions(customDcList ? customDcList : dcList), [dcList, customDcList]);
};

export default useDatacenterOptions;
